import React from 'react'
import renderer from 'react-test-renderer'

import { StyledTab, StyledTabPanel } from '../../styles/tab'

describe.skip('StyledTab', () => {
  it('applies default styles', () => {
    const tree = renderer.create(<StyledTab />).toJSON()
    expect(tree).toHaveStyleRule('color', /#2ecc71/i)
    expect(tree).toHaveStyleRule('border', /1px solid #2ecc71/i)
    expect(tree).toHaveStyleRule('background', 'transparent')
    expect(tree).toHaveStyleRule('font-size', '0.7rem')
  })

  it('applies styles from props', () => {
    const tree = renderer
      .create(<StyledTab isActive={true} fontSize={'0.8rem'} />)
      .toJSON()
    expect(tree).toHaveStyleRule('color', 'white')
    expect(tree).toHaveStyleRule('border', '1px solid transparent')
    expect(tree).toHaveStyleRule('background', /#2ecc71/i)
    expect(tree).toHaveStyleRule('font-size', '0.8rem')
  })
})

describe.skip('StyledTabPanel', () => {
  it('applies display block by default', () => {
    const tree = renderer.create(<StyledTabPanel />).toJSON()
    expect(tree).toHaveStyleRule('display', 'block')
  })

  it('applies display none when hidden', () => {
    const tree = renderer.create(<StyledTabPanel hidden={true} />).toJSON()
    expect(tree).toHaveStyleRule('display', 'none')
  })
})
