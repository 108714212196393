import React from 'react'
import PropTypes from 'prop-types'

const DocumentDeleteDisabled = (_props, { translations }) => {
  return (
    <button className="document__delete" disabled>
      <i
        className="fas fa-check disabled"
        data-content={translations.delete_disabled}
        data-bs-toggle="popover"
        ref={(i) => initializePopover(i)}
      />
    </button>
  )
}

DocumentDeleteDisabled.contextTypes = {
  translations: PropTypes.object,
}

export default DocumentDeleteDisabled
