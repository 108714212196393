import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Button from '../engine-ui/Button'
import Checkbox from '../engine-ui/Checkbox'

const data = {
  value: [
    'EmailTemplate#5d1386c809a0d305cba878d4',
    'EmailTemplate#5cf680bc37dcf4447360b0b3',
  ],
  options: [
    {
      title:
        'wenigermiete.de - Fristen zur Mieterhöhung nicht versäumen | 1/111683/191211/ME',
      value: 'EmailTemplate#5cf680bc37dcf4447360b0b3',
      errors: ['Dangerous'],
      subtitle: 'hello',
    },
    {
      title:
        'Gerne prüfen wir Ihre Mieterhöhung | 1/111683/191211/ME | wenigermiete.de',
      value: 'EmailTemplate#5d1386c809a0d305cba878d4',
      errors: [],
      subtitle: 'hello there!',
    },
  ],
}

const PaymentActionsForm = () => {
  const [editMode, setEditMode] = useState(false)
  const handleEditModeClick = () => setEditMode(true)

  return (
    <div className="payment-actions-form">
      <div className="payment-actions-form__edit-button">
        <Button onClick={handleEditModeClick} className="btn p-0">
          <i className="fas fa-edit" />
        </Button>
      </div>
      {data.options.map((item) => {
        const actionEnabledIconClassName = classNames(
          'payment-actions-form__checkbox-view',
          {
            'payment-actions-form__checkbox-view--checked': (
              data.value || []
            ).includes(item.value),
          },
        )
        const subtitleIconClassName = classNames({
          info: !item.errors.length,
          'fas fa-exclamation-triangle text-danger': item.errors.length,
        })

        return (
          <div key={item.value} className="d-flex">
            {editMode ? (
              <Checkbox value={item.value} />
            ) : (
              <div className={actionEnabledIconClassName} />
            )}
            <div>
              <h5 className="actions-triggered__item-ttl">
                <i
                  className={classNames(item.icon, 'color-primary', 'me-2')}
                ></i>
                {item.title}
              </h5>
              <p className="info payment-actions-form__subtitle">
                <i className={subtitleIconClassName}>
                  {item.errors.length
                    ? item.errors
                        .map((error) => `${error} ist nicht gültig`)
                        .join(', ')
                    : item.subtitle}
                </i>
              </p>
            </div>
          </div>
        )
      })}
    </div>
  )
}

PaymentActionsForm.propTypes = {
  data: PropTypes.object,
}

export default PaymentActionsForm
