import React from 'react'
import PropTypes from 'prop-types'
import { Accordion } from 'react-bootstrap'

import LogItemHeader from '../LogItemHeader'

export default function EconsultSentInvoice(props) {
  const { data, id, open, onClick } = props
  const {
    econsult_sent_invoice_id,
    amount,
    partners_requests_invitation_id,
    date,
  } = data.content_info

  return (
    <div>
      <div
        onClick={() => {
          onClick(id)
        }}
      >
        <LogItemHeader data={data} />
      </div>
      <Accordion.Collapse eventKey={id} in={open}>
        <div className="log-item-content__container">
          <div className="log-item-content__card">
            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Amount</th>
                  <th>partners_requests_invitation ID</th>
                  <th className="break-long-word">Date</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{econsult_sent_invoice_id}</td>
                  <td>{amount}</td>
                  <td>{partners_requests_invitation_id}</td>
                  <td>{date}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Accordion.Collapse>
    </div>
  )
}

EconsultSentInvoice.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  open: PropTypes.bool,
  onClick: PropTypes.func,
}
