import React from 'react'
import PropTypes from 'prop-types'

import { getDocumentUrl } from './helpers'
import Button from '../engine-ui/Button'
import Tooltip from '../engine-ui/Tooltip'

export default function DocumentCardActions(props) {
  const {
    sensitive,
    requestId,
    documentId,
    onMarkAsSensitive,
    onEdit,
    onDelete,
  } = props

  return (
    <div className="doc-actions">
      <Tooltip
        text={
          sensitive
            ? 'Vertraulichkeit aufheben'
            : 'Dokument als vertraulich markieren'
        }
        placement="top"
      >
        <Button onClick={onMarkAsSensitive} className="doc-actions__item">
          <i className={`fas ${sensitive ? 'fa-eye' : 'fa-eye-slash'}`} />
        </Button>
      </Tooltip>
      <Tooltip text="Dokument herunterladen" placement="top">
        <a
          href={`${getDocumentUrl(requestId, documentId)}?inline=false`}
          target="_blank"
          rel="noopener noreferrer"
          className="doc-actions__item"
        >
          <i className="fa fa-download"></i>
        </a>
      </Tooltip>
      <Tooltip text="Dokument ansehen" placement="top">
        <a
          href={`${getDocumentUrl(requestId, documentId)}?inline=true`}
          target="_blank"
          rel="noopener noreferrer"
          className="doc-actions__item"
        >
          <i className="fas fa-external-link-alt"></i>
        </a>
      </Tooltip>
      <Tooltip text="Dokument bearbeiten" placement="top">
        <Button onClick={onEdit} className="doc-actions__item">
          <i className="fas fa-pencil-alt"></i>
        </Button>
      </Tooltip>
      <Tooltip text="Dokument löschen" placement="top">
        <Button onClick={onDelete} className="doc-del">
          <i className="fas fa-trash-alt"></i>
        </Button>
      </Tooltip>
    </div>
  )
}

DocumentCardActions.propTypes = {
  sensitive: PropTypes.bool,
  requestId: PropTypes.number,
  documentId: PropTypes.number,
  onMarkAsSensitive: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
}
