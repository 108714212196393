import React, { useState, useEffect } from 'react'

import LogList from './LogList'
import FilterBarContainer from './FilterBar/FilterBarContainer'
import client from '../../../../client'
import ActivityLogProvider from './store/ActivityLogProvider'
import Spinner from '../engine-ui/Spinner'
import filterFeedItems from './filter'

export default function ActivityLogContainer(props) {
  const { requestId, jwtToken, saasNotEnabled } = props
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [filteredEntries, setFilteredEntries] = useState([])
  const [filterObject, setFilterObject] = useState({
    subject: 'title',
    direction: 'title',
    type: [
      'email_log',
      'document',
      'note',
      'life_cycle',
      'econsult_sent_invoice',
      'automatic_physical_mail',
      'bea_file',
      'advo_assist',
    ],
  })

  useEffect(() => {
    fetchData({ subject: '', direction: '', type: '' })
  }, [])

  useEffect(() => {
    if (data.feed_items) {
      const filteredItems = filterFeedItems(data.feed_items, filterObject)

      return setFilteredEntries(filteredItems)
    }
  }, [filterObject])

  const fetchData = ({ subject, direction, type }) => {
    const url = `/admin/v2/requests/${requestId}/feed_items.json?feed_items%5Bsubject%5D=${subject}&feed_items%5Bdirection%5D=${direction}&feed_items%5Btype%5D=${type}`
    setIsLoading(true)
    fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        token: jwtToken,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data || [])
        setFilteredEntries(filterFeedItems(data.feed_items, filterObject) || {})
        setIsLoading(false)
      })
  }

  // note that this function brokes 'single source of truth principle'
  // this modify the the state to keep updated 'marked' data until next http request
  // better, if switch to centralized state managment or do the filtering on server
  const handleItemMarkedChange = (logId, markedValue) => {
    const clonedData = { ...data }
    const item = clonedData.feed_items.find((item) => item.id === logId)
    item.marked = markedValue
    setData(clonedData)
  }

  const handleItemMarkedRestore = (logId, markedValue) => {
    const clonedData = { ...data }
    const item = clonedData.feed_items.find((item) => item.id === logId)
    item.soft_deleted = markedValue
    setData(clonedData)
  }

  const handleSelectChange = (event) => {
    const { name, value } = event
    if (value === 'title') {
      setFilterObject({ ...filterObject, [name]: 'title' })
    } else setFilterObject({ ...filterObject, [name]: value })
  }

  const handleFilterTypeClick = (type) => {
    let typeArray

    if (filterObject.type.includes(type)) {
      const index = filterObject.type.indexOf(type)
      typeArray = [
        ...filterObject.type.slice(0, index),
        ...filterObject.type.slice(index + 1),
      ]
      setFilterObject({ ...filterObject, type: typeArray })
    } else {
      typeArray = [...filterObject.type, type]
      setFilterObject({ ...filterObject, type: typeArray })
    }
  }

  const handleSelectAll = () =>
    setFilterObject({
      direction: 'title',
      subject: 'title',
      type: [
        'email_log',
        'document',
        'note',
        'life_cycle',
        'ticket',
        'case_edited',
        'payment',
        'econsult_sent_invoice',
        'automatic_physical_mail',
        'bea_file',
        'advo_assist',
      ],
    })

  const handleClearAll = () =>
    setFilterObject({
      direction: 'title',
      subject: 'title',
      type: [],
    })

  const handleDelete = (id, type) => {
    const url = `/admin/requests/${requestId}/${type}/${id}.json`
    client
      .delete(url)
      .then(() => fetchData({ subject: '', direction: '', type: '' }))
  }

  const handleEdit = (id, text) => {
    const url = `/admin/requests/${requestId}/activity_logs/${id}.json`
    const data = {
      value: text,
    }
    client.patch(url, data)
  }

  const handleNoteSubmit = (text, type) => {
    const url = `/admin/requests/${requestId}/activity_logs.json`
    const data = {
      content: text,
      type: type,
    }
    client
      .post(url, data)
      .then(() => fetchData({ subject: '', direction: '', type: '' }))
  }

  const handleFeedbackSubmit = (text) => {
    const url = `/admin/requests/${requestId}/feedbacks.json`
    const data = {
      note: text,
    }
    client
      .post(url, data)
      .then(() => fetchData({ subject: '', direction: '', type: '' }))
  }

  return (
    <div className="activitylog__container">
      <FilterBarContainer
        requestId={requestId}
        onNoteSubmit={handleNoteSubmit}
        onFeedbackSubmit={handleFeedbackSubmit}
        data={data.feedbacks || []}
        onFilterTypeClick={handleFilterTypeClick}
        onSelectChange={handleSelectChange}
        onSelectAll={handleSelectAll}
        onClearAll={handleClearAll}
        activeTypes={filterObject.type}
        valueDirection={filterObject.direction}
        valueSubject={filterObject.subject}
        saasNotEnabled={saasNotEnabled}
      />
      <ActivityLogProvider
        requestId={requestId}
        onDelete={handleDelete}
        onEdit={handleEdit}
        onItemMarkedChange={handleItemMarkedChange}
        onItemMarkedRestore={handleItemMarkedRestore}
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <LogList
            data={filteredEntries || []}
            refetch={() => fetchData({ subject: '', direction: '', type: '' })}
          />
        )}
      </ActivityLogProvider>
    </div>
  )
}
