import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Collapse } from 'react-bootstrap'

export default function SelectFiles(props) {
  const [open, setOpen] = useState(false)

  const { items, checkedItems, updateParent } = props

  const bytesToSize = (bytes) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    if (bytes === 0) return 'n/a'
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1000)), 10)
    if (i === 0) return `${bytes} ${sizes[i]})`
    return `${(bytes / 1000 ** i).toFixed(1)} ${sizes[i]}`
  }

  const handleChange = (id, isChecked) => {
    let checkedItemsSet = new Set(checkedItems)
    isChecked ? checkedItemsSet.add(id) : checkedItemsSet.delete(id)
    updateParent('files', Array.from(checkedItemsSet))
  }

  const handleClick = () => setOpen(!open)

  const generateCheckboxes = () => {
    return items.map((item) => {
      const fileSizeColor =
        item.size > 23068672 ? 'text-danger' : 'text-success'
      return (
        <label className="v2-attch mb-1" key={'file-id-' + item.id}>
          <input
            name={item.name}
            checked={new Set(checkedItems).has(item.id)}
            onChange={(e) => handleChange(item.id, e.target.checked)}
            value={new Set(checkedItems).has(item.id)}
            type="checkbox"
            className="attch-cb me-1"
          />
          <span>
            {' '}
            <strong>{item.name},&nbsp;</strong>({item.type})
          </span>
          <a href={item.link} target="_blank" rel="noopener noreferrer">
            <i className="fas fa-external-link-alt text-danger mx-2" />
          </a>{' '}
          <span className={fileSizeColor}> {bytesToSize(item.size)} </span>
        </label>
      )
    })
  }

  const tooManyFilesSelected = (checkedFiles) => {
    if (items.length === 0) return null

    var checkedFilesSize = checkedFiles.reduce((acc, file) => {
      return acc + items.find((e) => e.id == file).size
    }, 0)

    if (checkedFilesSize > 23068672) {
      return (
        <div className="text-danger">
          Die aneinandergereihten Anhänge überschreiten die maximale Größe von
          23,1 MB
        </div>
      )
    } else null
  }

  return (
    <>
      <div className="MailerApp__attch-btn" onClick={handleClick}>
        <i className={`fas fa-chevron-${open ? 'up' : 'down'} me-1 fa-sm`} />
        Anhänge ({checkedItems.length} ausgewählt)
      </div>
      <Collapse in={open}>
        <div className="files-row input-rows">
          <div className="d-flex flex-column">
            {generateCheckboxes()}
            {tooManyFilesSelected(checkedItems)}
          </div>
        </div>
      </Collapse>
    </>
  )
}

SelectFiles.propTypes = {
  items: PropTypes.array,
  checkedItems: PropTypes.array,
  updateParent: PropTypes.func,
}

SelectFiles.defaultProps = {
  items: [],
  checkedItems: [],
}
