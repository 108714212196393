import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { flatten } from 'lodash/fp'

import AdminDatepicker from '../admin_datepicker'
import Textarea from '../engine-ui/TextArea'
import Timepicker from '../engine-ui/Timepicker'
import Select from '../engine-ui/Select/Select'

export default class TodoForm extends Component {
  static propTypes = {
    data: PropTypes.object,
    formAction: PropTypes.string,
    formMethod: PropTypes.string,
    action: PropTypes.string,
    formTitle: PropTypes.string,
    teamCollection: PropTypes.array,
    titleCollection: PropTypes.array,
    deadlineTypeCollection: PropTypes.array,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    categoriesForDeadlineTypeCollection: PropTypes.array,
    categoriesForPrefilledBodyCollection: PropTypes.object,
    fristenEnabled: PropTypes.bool,
  }

  handleSubmit = () => {
    this.props.onSubmit(this.props.action)
  }

  formatDate = () => {
    if (!this.props.data.due_on) return null

    const iso =
      /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z)/
    if (this.props.data.due_on.match(iso)) {
      return moment.utc(this.props.data.due_on).format()
    }
    return moment.utc(this.props.data.due_on, 'DD.MM.YYYY').toISOString()
  }

  render() {
    const {
      data: { title, todo_category_key, comment, assignee_id, deadline_type },
      teamCollection,
      titleCollection,
      deadlineTypeCollection,
      categoriesForDeadlineTypeCollection,
      categoriesForPrefilledBodyCollection,
      fristenEnabled,
      onChange,
    } = this.props

    const getTodoTitles = () =>
      titleCollection.map((title) => ({
        text: title.name,
        value: title.type_key,
      }))

    const getTeamMembers = () => {
      return teamCollection.reduce((teamAcc, team) => {
        const [name, members] = team

        const teamNameOptGroup = <optgroup label={name} key={name}></optgroup>
        const memberOptions = members.map(([member, id]) => (
          <option value={id} key={id}>
            {member}
          </option>
        ))
        teamAcc = [...teamAcc, teamNameOptGroup, ...memberOptions]
        return teamAcc
      }, [])
    }

    const select_or_show = () => {
      var available_keys = titleCollection.map((a) => a.type_key)
      var can_edit = available_keys.includes(todo_category_key)
      if (!todo_category_key || can_edit) {
        return (
          <div className="todo-form__item">
            <label>* Typ</label>
            <Select
              className="form-group"
              name="type"
              value={todo_category_key}
              onChange={onChange}
              options={flatten(getTodoTitles())}
              disabled={this.props.action === 'editTodo'}
            ></Select>
          </div>
        )
      } else {
        return (
          <div className="todo-form__item">
            <label>* Typ</label>
            <input
              type="text"
              value={title}
              disabled={true}
              className="form-group"
              style={{ height: '38px' }}
            />
          </div>
        )
      }
    }

    const renderFristenField = () => {
      if (!fristenEnabled) return null

      return (
        <div className="todo-form__item">
          <label>* Frist</label>
          <Select
            className="form-group"
            name="deadline_type"
            value={deadline_type}
            onChange={onChange}
            options={deadlineTypeCollection}
            disabled={
              !categoriesForDeadlineTypeCollection.includes(todo_category_key)
            }
          ></Select>
        </div>
      )
    }

    const renderCommentField = () => {
      const body = categoriesForPrefilledBodyCollection[todo_category_key]

      return (
        <div className="todo-form__item">
          <label>Beschreibung</label>
          <Textarea
            name="comment"
            onChange={onChange}
            value={comment ? comment : body}
            rows="2"
          ></Textarea>
        </div>
      )
    }

    return (
      <div className="todo-form">
        <h3 className="todo-form__title">{`Todo ${this.props.formTitle}`}</h3>
        {select_or_show()}
        {renderCommentField()}
        <div className="todo-form__item mt-3 d-flex flex-row w-100">
          <div className="w-50 me-3">
            <label>* Frist setzen bis</label>
            <AdminDatepicker
              past
              required
              name="due_on"
              question={{ name: 'due_on' }}
              value={this.formatDate() || new Date()}
              className="df_datepicker"
              onChange={onChange}
            />
          </div>
          <div className="w-50 ms-3">
            <div className="d-flex flex-column">
              <label>Uhrzeit (optional)</label>
              <Timepicker
                name="due_time"
                onChange={onChange}
                hours={24}
                minutes={60}
                value={this.props.data.due_time}
              />
            </div>
          </div>
        </div>
        {renderFristenField()}
        <div className="todo-form__item mb-5">
          <label>Bearbeiter zuweisen (optional)</label>
          <Select name="assignee_id" value={assignee_id} onChange={onChange}>
            {getTeamMembers}
          </Select>
        </div>
        <div className="todo-form__btn">
          <button
            className="btn btn-primary btn-rounded"
            onClick={this.handleSubmit}
          >
            Speichern
          </button>
        </div>
      </div>
    )
  }
}
