import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Button from '../../engine-ui/Button'
import classNames from 'classnames'
import moment from 'moment'

export default function TableRow(props) {
  const { data, onEditClick, onDeleteClick, headings } = props

  const renderActions = (actions) => {
    if (actions)
      return (
        <div className="text-nowrap">
          {actions.map((action, index) => (
            <Button
              key={index}
              disabled={!action.enable}
              className={classNames('btn', 'tab-table-row__action-button')}
              onClick={() => executeAction(action.type)}
            >
              <i
                className={classNames('fas', {
                  'fa-edit': action.type === 'update',
                  'fa-trash-alt': action.type === 'delete',
                })}
              ></i>
            </Button>
          ))}
        </div>
      )
  }

  const executeAction = (actionType) => {
    switch (actionType) {
      case 'update':
        onEditClick()
        break
      case 'delete':
        onDeleteClick()
        break
    }
  }

  const format = (item) => {
    if (item.type === 'date') {
      return moment(item.text).format('DD.MM.YYYY')
    }
    if (item.type === 'yes_no') {
      if (item.text === 'yes') return 'Ja'
      else if (item.text === 'no') return 'Nein'
      return ''
    }
    if (item.text === true) {
      return (
        <div className="question_display-checkbox question_display-checkbox-true"></div>
      )
    }
    if (item.text === false) {
      return <div className="question_display-checkbox"></div>
    }
    if (Array.isArray(item.text)) {
      return item.text.map((el, index) => <div key={index}>{el}</div>)
    }
    if (typeof item.text === 'number') {
      return item.text.toString().replace(/\./g, ',')
    }
    if (item.text) return item.text
  }

  const renderTableCell = (item) => {
    if (item.text !== null && item.text !== undefined && item.text !== '') {
      return format(item)
    }
    return renderActions(item.actions)
  }

  const sortedData = _.orderBy(
    headings
      .filter((i) => i.sort_order !== 1)
      .map((h) => {
        const hit = data.content.find((i) => i.sort_order === h.sort_order)
        return hit
          ? hit
          : { text: undefined, sort_order: h.sort_order, type: h.type }
      }),
    ['sort_order'],
  )
  return (
    <Fragment>
      <td className="tab-table-row__cell">{data.id}</td>
      {sortedData.map((item, index) => (
        <td key={index} className="tab-table-row__cell">
          {renderTableCell(item)}
        </td>
      ))}
    </Fragment>
  )
}

TableRow.propTypes = {
  data: PropTypes.object,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
}
