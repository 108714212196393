import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Modal from '../../engine-ui/Modal'
import TableRow from './TableRow'
import FormContainer from './FormContainer'
import Spinner from '../../engine-ui/Spinner'
import AlertBanner from '../../engine-ui/AlertBanner'

export default function DynamicTableRowContainer(props) {
  const { data, token, tableScaffoldId, requestId, setData, headings } = props
  const [isModalOpen, setModalOpen] = useState(false)
  const [editData, setEditData] = useState('')
  const [initialData, setInitialData] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const rowId = data.id
  const toggle = () => {
    setModalOpen(!isModalOpen)
  }

  const handleEditClick = () => {
    toggle()
    setLoading(true)
    const url = `/api/admin/requests/${requestId}/dynamic_table_scaffolds/${rowId}/edit?table_scaffold_key=${tableScaffoldId}`
    fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setEditData(data.table)
        fillInitialInputData(data.table.fields)
      })
      .catch((err) => {
        console.error(err)
        setError(true)
      })
      .finally(() => setLoading(false))
  }

  const fillInitialInputData = (initialData) => {
    const res = initialData.reduce(
      (obj, item) => ((obj[item.name] = item.answer), obj),
      {},
    )
    setInitialData(res)
  }

  const handleEditSubmit = (selectedActions, inputData) => {
    toggle()
    const payload = Object.keys(inputData).map((key) => {
      return {
        field: key,
        answer: inputData[key],
      }
    })

    const url = `/api/admin/requests/${requestId}/dynamic_table_scaffolds/${rowId}?table_scaffold_key=${tableScaffoldId}`
    return fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        token: token,
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
      },
      body: JSON.stringify({
        dynamic_table_scaffold: {
          fields: payload,
          actions: selectedActions,
        },
      }),
    })
      .then((res) => res.json())
      .then((data) => setData(data))
  }

  const handleDeleteSubmit = () => {
    const proceed = window.confirm(
      'Sind Sie sicher, dass Sie diese Reihe löschen wollen?',
    )
    if (!proceed) return

    const url = `/api/admin/requests/${requestId}/dynamic_table_scaffolds/${rowId}?table_scaffold_key=${tableScaffoldId}`
    fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        token: token,
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
      },
    })
      .then((res) => res.json())
      .then((data) => setData(data))
  }

  return (
    <tr className="tab-table-row">
      <TableRow
        data={data}
        headings={headings}
        onEditClick={handleEditClick}
        onDeleteClick={handleDeleteSubmit}
      />
      <td width="1%">
        <Modal isOpen={isModalOpen} onClose={toggle}>
          {error ? (
            <AlertBanner />
          ) : loading ? (
            <Spinner />
          ) : (
            <FormContainer
              data={editData}
              onSubmit={handleEditSubmit}
              token={token}
              tableScaffoldId={tableScaffoldId}
              requestId={requestId}
              initialData={initialData}
            />
          )}
        </Modal>
      </td>
    </tr>
  )
}

DynamicTableRowContainer.propTypes = {
  data: PropTypes.object,
  requestId: PropTypes.number,
  tableScaffoldId: PropTypes.string,
  token: PropTypes.string,
  setData: PropTypes.func,
}
