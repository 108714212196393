import { useContext } from 'react'

import Context from '../context/Context'
import { initializeMetaData } from '../ducks/metaData'

export default function useMetaData() {
  const { state, dispatch } = useContext(Context)
  const { metaData } = state

  const initialize = (payload) => dispatch(initializeMetaData(payload))

  return {
    metaData,
    initializeMetaData: initialize,
  }
}
