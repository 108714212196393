import React, { useEffect, useContext, useState } from 'react'
import { filter } from 'lodash/fp'

import { Container, SubmitMessage, Button, Label } from './styles/documentAttr'
import Context from '../../context/Context'
import NameField from './NameField'
import DirectionField from './DirectionField'
import TypeField from './TypeField'
import CourtIdField from './CourtIdField'
import Checkbox from '../../../engine-ui/Checkbox'
import useForm from '../../hooks/useForm'
import useDocument from '../../hooks/useDocument'
import useCallbackStatus from '../../hooks/useCallbackStatus'

import client from '../../../../../../client'

export default function DocumentAttributes() {
  const [submitMessage, setSubmitMessage] = useState([])
  const { changeDocument, document } = useDocument()
  const {
    change,
    initialize: initializeForm,
    initialized,
    formValues,
  } = useForm(document)
  const { run: runUpdateDocument, isPending } = useCallbackStatus()
  const { ziebToken, agentEmail, documentId, showPrompt } = useContext(Context)

  const [subject, setSubject] = useState(formValues.subject?.value || '')

  useEffect(() => {
    const { subject: initialSubject } = formValues
    if (initialSubject?.value) {
      setSubject(initialSubject.value)
    }
  }, [formValues.subject?.value])

  const [prompt, setPrompt] = useState(formValues.prompt?.value || '')
  useEffect(() => {
    const { prompt: initialPrompt } = formValues
    if (initialPrompt?.value) {
      setPrompt(initialPrompt.value)
    }
  }, [formValues.prompt?.value])

  const validInput = () => {
    const { court_id } = formValues
    if ((court_id.value ?? '') === '' && subject === 'court') {
      return false
    }
    return true
  }
  const displayValidationHint = () => {
    setSubmitMessage(['error', 'Aktenzeichen darf nicht leer sein'])
    setTimeout(() => {
      setSubmitMessage([])
    }, 2000)
  }

  const handleSubmit = () => {
    if (!validInput()) {
      displayValidationHint()
      return
    }
    const filtered = filter({ pristine: false })(formValues)
    const mapped = filtered.map((item) => {
      if (item.name === 'direction') {
        if (item.value === 'incoming') return { name: 'incoming', value: true }
        return { name: 'incoming', value: false }
      }
      return { name: item.name, value: item.value }
    })

    const documentPayload = mapped.reduce(
      (acc, item) => ({ ...acc, [item.name]: item.value }),
      {},
    )

    const payload = {
      document: documentPayload,
      agent_email: agentEmail,
    }

    const headers = { token: ziebToken }

    runUpdateDocument(
      client.patch(`/api/zieb/documents/${documentId}`, payload, headers),
    ).then(({ status, result }) => {
      if (status !== 'rejected') {
        changeDocument(documentPayload),
          setSubmitMessage(['success', 'Erfolgreich aktualisiert']),
          setPrompt(result.prompt)
        setTimeout(() => {
          setSubmitMessage([])
        }, 2000)
      } else {
        setSubmitMessage(['error', 'Ein Problem ist aufgetreten'])
        setTimeout(() => {
          setSubmitMessage([])
        }, 2000)
      }
    })
  }

  const handleChange = ({ name, value, changedSubject }) => {
    if (changedSubject !== undefined) {
      setSubject(changedSubject)
    }
    change(name, value)
  }

  const handleCopyToClipboard = (content) => {
    navigator.clipboard
      .writeText(content)
      .then(() => {
        setSubmitMessage(['success', 'Prompt copied to clipboard'])
        setTimeout(() => {
          setSubmitMessage([])
        }, 2000)
      })
      .catch(() => {
        setSubmitMessage(['error', 'Failed to copy prompt to clipboard'])
        setTimeout(() => {
          setSubmitMessage([])
        }, 2000)
      })
  }

  useEffect(() => {
    const { data, initialized } = document
    if (initialized) {
      initializeForm(data)
    }
  }, [document.initialized])

  return (
    <Container>
      {initialized && (
        <>
          <NameField onChange={handleChange} formValues={formValues} />
          <DirectionField onChange={handleChange} formValues={formValues} />
          <TypeField onChange={handleChange} formValues={formValues} />
          <CourtIdField onChange={handleChange} formValues={formValues} />
          <Label>Vertrauliche Daten: </Label>
          <Checkbox
            onChange={handleChange}
            value={formValues.sensitive.value}
            name="sensitive"
          />
        </>
      )}
      <div className="d-block mt-5">
        <div className="d-flex justify-content-end">
          {prompt.length && showPrompt ? (
            <Button onClick={() => handleCopyToClipboard(`${prompt}`)}>
              Copy Prompt
            </Button>
          ) : null}
          <Button onClick={handleSubmit} disabled={isPending}>
            {isPending ? (
              <i className="fas fa-spinner fa-spin text-white"></i>
            ) : (
              'Aktualisieren'
            )}
          </Button>
        </div>
        <div className="d-flex justify-content-end mt-3">
          {submitMessage.length ? (
            <SubmitMessage color={submitMessage[0]}>
              {submitMessage[1]}
            </SubmitMessage>
          ) : null}
        </div>
      </div>
    </Container>
  )
}
