import React from 'react'
import PropTypes from 'prop-types'

export default function DynamicTableHeadings(props) {
  const { data } = props

  const renderHeadings = () => {
    if (data && data.headings)
      return (
        <tr className="tab-table-row__headings">
          {data.headings.map((heading, index) => (
            <th width={`${heading.width}%`} key={index}>
              {heading.text}
            </th>
          ))}
          <th width="1%"></th>
        </tr>
      )
  }

  return <thead>{renderHeadings()}</thead>
}

DynamicTableHeadings.propTypes = {
  data: PropTypes.object,
}
