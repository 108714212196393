/**
 * @jest-environment jsdom
 */

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import SelectReceiverButton from './SelectReceiverButton'
export default function SelectReceiver(props) {
  const [selected, setSelected] = useState(props.selected)
  const [customEmail, setCustomEmail] = useState(props.customEmail || '')
  const [isInvalidEmail, setIsInvalidEmail] = useState(false)
  const [touched, setTouched] = useState(false)

  useEffect(() => {
    setSelected(props.selected)
    setCustomEmail(props.success ? '' : customEmail)
  }, [props.selected, props.success])

  useEffect(() => {
    setCustomEmail(props.customEmail || '')
  }, [props.customEmail])

  useEffect(() => {
    validateCustomEmail()
  }, [customEmail])

  const toggleSelected = (key) => {
    const _selected = new Set(selected)

    if (_selected.has(key)) {
      _selected.delete(key)
    } else {
      _selected.add(key)
      if (key == 'client') {
        _selected.delete('counter_party')
        _selected.delete('lawyer')
        _selected.delete('external_lawyer')
      } else if (key == 'external_lawyer') {
        _selected.delete('counter_party')
        _selected.delete('lawyer')
        _selected.delete('client')
      } else {
        _selected.delete('client')
        _selected.delete('external_lawyer')
      }
    }

    const selectedList = Array.from(_selected)
    setSelected(selectedList)
    props.updateParent('receiver', selectedList)
  }

  const unselected = (key) => {
    return !selected.includes(key)
  }

  const emailSpecified = (button) => {
    return (
      props.replyToProps != undefined &&
      props.replyToProps.responseType == button.key &&
      props.replyToProps.email != ''
    )
  }

  const selectedEmail = (button) => {
    if (emailSpecified(button)) {
      return props.replyToProps.email
    } else return button.string
  }

  const validateCustomEmail = () => {
    const regExp = /\S+@\S+\.\S+/
    if (!customEmail || !touched) {
      setIsInvalidEmail(false)
      return
    }

    if (regExp.test(customEmail)) {
      setIsInvalidEmail(false)
      return
    }

    setIsInvalidEmail(true)
  }

  const handleChange = (event) => {
    const { value } = event.target

    setCustomEmail(value)
    props.onCustomEmailChange(value)
  }

  const handleBlur = () => {
    setTouched(true)
    validateCustomEmail()
  }

  return (
    <>
      <p
        className={classNames('error_msg_email', {
          'd-block': isInvalidEmail == true,
        })}
      >
        Bitte gültige Emailadresse eingeben.
      </p>
      <div className="to-row input-rows">
        <div className="label">AN:</div>
        <div className="field d-flex flex-row">
          {props.replyToProps !== undefined ? (
            <div className="ms-3">{props.replyToProps.email}</div>
          ) : (
            props.items.map((item) => {
              return (
                <SelectReceiverButton
                  key={item.key}
                  item={item}
                  unselected={unselected}
                  toggleSelected={toggleSelected}
                  selectedEmail={selectedEmail}
                ></SelectReceiverButton>
              )
            })
          )}
          {props.replyToProps == undefined && (
            <input
              id="to-custom_email"
              type="email"
              key="receiver-tab-custom_email"
              data-key="custom_email"
              name="custom_email"
              placeholder="Weitere Email-Adresse"
              className="to-row__custom-email not-draggable"
              value={customEmail}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          )}
        </div>
      </div>
    </>
  )
}

SelectReceiver.propTypes = {
  items: PropTypes.array,
  selected: PropTypes.array,
  updateParent: PropTypes.func,
  success: PropTypes.bool,
  replyToProps: PropTypes.object,
  onCustomEmailChange: PropTypes.func,
}

SelectReceiver.defaultProps = {
  items: [],
}
