import React from 'react'
import PropTypes from 'prop-types'

import Spinner from '../../../engine-ui/Spinner'

export default function SearchablePDF(props) {
  const { url } = props

  return (
    <>
      {url ? (
        <iframe width="100%" height="100%" src={`${url}#zoom=350`}></iframe>
      ) : (
        <Spinner />
      )}
    </>
  )
}

SearchablePDF.propTypes = {
  url: PropTypes.string,
}
