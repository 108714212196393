import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class Button extends Component {
  static propTypes = {
    value: PropTypes.any,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    children: PropTypes.any,
    className: PropTypes.string,
  }

  handleClick = () => {
    const { value, onClick } = this.props
    onClick && onClick(value)
  }

  render() {
    const { disabled, children, className } = this.props

    return (
      <button
        onClick={this.handleClick}
        disabled={disabled}
        className={className}
      >
        {children}
      </button>
    )
  }
}
