export interface IRequest {
  id: string
  organization: IOrganization
}
export interface IOrganization {
  id: string
  name: string
}

export function isOrganization(
  organization: IOrganization | null,
): organization is IOrganization {
  return (organization as IOrganization).name !== undefined
}
