const INITIALIZE_METADATA = 'INITIALIZE_METADATA'

export function initializeMetaData(payload = {}) {
  return { type: INITIALIZE_METADATA, payload }
}

const initialState = { data: {}, initialized: false }
export default function reducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case INITIALIZE_METADATA:
      return {
        data: { ...payload },
        initialized: true,
      }
    default:
      return state
  }
}
