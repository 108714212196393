import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export default class RadioOption extends Component {
  static propTypes = {
    value: PropTypes.any,
    label: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
  }

  handleChange = () => {
    const { value, onChange } = this.props

    onChange && onChange(value)
  }

  render() {
    const { label, value, checked, className } = this.props
    const containerClassName = classNames('radio-option', className)

    return (
      <div className={containerClassName}>
        <input
          name={value.toString()}
          type="radio"
          checked={checked}
          onChange={this.handleChange}
          className="radio-option__input"
        />
        <label htmlFor={value.toString()} className="radio-option__label">
          {label}
        </label>
      </div>
    )
  }
}
