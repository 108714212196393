import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTable } from 'react-table'
import { Table as BTable } from 'react-bootstrap'
import classNames from 'classnames'

import { config } from './config'

const Table = (props) => {
  const { data: dataProp, filters } = props

  const columns = useMemo(() => config, [])
  const data = useMemo(
    () =>
      dataProp.map((item) => ({
        ...item,
        extra_column_1: true,
        extra_column_2: true,
      })),
    [dataProp],
  )

  const { rows, prepareRow, headerGroups, getTableProps } = useTable({
    data,
    columns,
  })

  return (
    <BTable striped size="sm" {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup, headerRowIndex) => (
          <tr key={headerRowIndex} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, headerCellIndex) => (
              <th key={headerCellIndex} {...column.getHeaderProps()}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {rows.map((row, rowIndex) => {
          prepareRow(row)
          return (
            <tr key={rowIndex} {...row.getRowProps()}>
              {row.cells.map((cell, cellIndex) => {
                const className = classNames({
                  'deadline-tickets-cell--truncated': cellIndex === 0,
                  'deadline-tickets-cell': cellIndex !== 0,
                })

                return (
                  <td
                    key={cellIndex}
                    {...cell.getCellProps({
                      key: cellIndex,
                      width: cell.column.maxWidth,
                      className,
                    })}
                  >
                    {cell.render('Cell', { filters })}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </BTable>
  )
}

Table.propTypes = {
  data: PropTypes.array,
  filters: PropTypes.object,
}

export default Table
