import React, { useState, createContext, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import DraggableWrapper from '../engine-ui/DraggableWrapper'
import Button from '../engine-ui/Button'
import classNames from 'classnames'
import MailerApp from './MailerApp'

const MailerAppContainerContext = createContext()
const SENT_EMAIL_TIMEOUT = 30_000
const DRAFT_LOCALSTORAGE_NAME = 'emailDrafts'
const MAX_SUBJECT_LENGTH_TO_SHOW = 50

const createSentEMailAlertHTML = ({ subject, from, to, jid, requestId }) => `
<div id="${jid}-${requestId}" class="alert alert-warning alert-dismissible fade show mailer-app-cancel-email-alert mt-1" role="alert">
  Email wird in <strong><span class="mailer-app-cancel-email-count-down">30</span> Sekunden</strong> gesendet.
  <span class="cancel-email-link">Hier klicken</span> zum abbrechen.<br><span class="one-line">
    von <strong>${from}</strong> an <strong>${to}</strong> mit Betreff: <strong>${subject}</strong>
  </span>
  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"/>
</div>`

export default function MailerAppContainer(props) {
  const [minimized, setMinimized] = useState(false)
  const sentEmailAlertsContainerRef = useRef()
  const sentEmailTimeoutRef = useRef()
  const sentEmailCountDownRef = useRef()
  const dismissableAlertRef = useRef()

  const closeMailer = () => {
    const mailerAppPopup = document.querySelector('.mailerApp-popup')
    mailerAppPopup.innerHTML = ''
  }

  const toggleMinimize = () => {
    setMinimized(!minimized)
  }

  const handleGetSavedDrafts = () => {
    try {
      const draft = window.JSON.parse(
        window.localStorage.getItem(DRAFT_LOCALSTORAGE_NAME),
      )
      return draft && typeof draft === 'object' && !Array.isArray(draft)
        ? draft
        : {}
    } catch {
      return {}
    }
  }

  const handleSetSavedDrafts = (drafts) => {
    if (drafts && typeof drafts === 'object' && !Array.isArray(drafts)) {
      try {
        window.localStorage.setItem(
          DRAFT_LOCALSTORAGE_NAME,
          window.JSON.stringify(drafts),
        )
      } catch {
        if (Object.keys(newDraft).length > 1) {
          const keyToDelete = Object.keys(drafts).sort(
            (item1, item2) =>
              new Date(drafts[item1].lastSavedDraftOn) -
              new Date(drafts[item2].lastSavedDraftOn),
          )[0]
          const newDraft = { ...drafts }
          delete newDraft[keyToDelete]
          this.handleSetSavedDrafts(newDraft)
        } else {
          window.localStorage.setItem(DRAFT_LOCALSTORAGE_NAME, {})
        }
      }
    }
  }
  useEffect(() => {
    sentEmailAlertsContainerRef.current = $('.sent-email-alerts-container')
    if (!sentEmailAlertsContainerRef.current.length) {
      sentEmailAlertsContainerRef.current = $(
        '<div class="sent-email-alerts-container p-2"></div>',
      )
      $(window.document.body).append(sentEmailAlertsContainerRef.current)
    }
    return () => {
      if (dismissableAlertRef.current) {
        dismissableAlertRef.current.alert('dispose')
        dismissableAlertRef.current = null
        if (!sentEmailTimeoutRef.current) {
          window.clearTimeout(sentEmailTimeoutRef.current)
          sentEmailTimeoutRef.current = null
        }
        if (sentEmailCountDownRef.current) {
          window.clearInterval(sentEmailCountDownRef.current)
          sentEmailCountDownRef.current = null
        }
      }
    }
  }, [])

  const handleSentMail = ({ subject, from, to, jid, requestId }) => {
    const deleteDraftTimer = window.setTimeout(() => {
      const savedDrafts = handleGetSavedDrafts()
      if (savedDrafts) {
        const currentDraftId = Object.keys(savedDrafts).find(
          (k) => savedDrafts[k].jid === jid,
        )
        if (currentDraftId) {
          const newDrafts = { ...savedDrafts }
          delete newDrafts[currentDraftId]
          handleSetSavedDrafts(newDrafts)
        }
      }
    }, SENT_EMAIL_TIMEOUT)
    if (!dismissableAlertRef.current) {
      dismissableAlertRef.current = $(
        createSentEMailAlertHTML({ subject, from, to, jid, requestId }),
      )
      $(sentEmailAlertsContainerRef.current).append(dismissableAlertRef.current)
    } else {
      $(dismissableAlertRef.current).html(
        createSentEMailAlertHTML({ subject, from, to, jid, requestId }),
      )
    }
    dismissableAlertRef.current.alert()
    dismissableAlertRef.current.find('.cancel-email-link').click(() => {
      deleteDraftTimer && window.clearTimeout(deleteDraftTimer)
      fetch(`/admin/requests/${requestId}/emails/abort`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
        },
        body: JSON.stringify({
          jid,
        }),
      }).finally(() => {
        dismissableAlertRef.current.alert('close')
        if (!sentEmailTimeoutRef.current) {
          window.clearTimeout(sentEmailTimeoutRef.current)
          sentEmailTimeoutRef.current = null
        }
        if (sentEmailCountDownRef.current) {
          window.clearInterval(sentEmailCountDownRef.current)
          sentEmailCountDownRef.current = null
        }
      })
    })

    const mailerAppCancelEmailCountdown = dismissableAlertRef.current.find(
      '.mailer-app-cancel-email-count-down',
    )
    let initialCountDown = SENT_EMAIL_TIMEOUT / 1000

    sentEmailCountDownRef.current = window.setInterval(() => {
      initialCountDown--
      mailerAppCancelEmailCountdown.text(initialCountDown.toString())
      if (initialCountDown <= 1) {
        window.clearInterval(sentEmailCountDownRef.current)
        sentEmailCountDownRef.current = null
      }
    }, 1000)

    sentEmailTimeoutRef.current = window.setTimeout(() => {
      if (dismissableAlertRef.current) {
        dismissableAlertRef.current.alert('close')
      }
      if (sentEmailCountDownRef.current) {
        window.clearInterval(sentEmailCountDownRef.current)
        sentEmailCountDownRef.current = null
      }
      sentEmailTimeoutRef.current = null
    }, SENT_EMAIL_TIMEOUT)
  }

  return (
    <MailerAppContainerContext.Provider value={{ requestId: props.requestId }}>
      <div className="mailer-app__draggable-container">
        <Button
          className={classNames('mailer-app__minimized', {
            'd-none': !minimized,
          })}
          onClick={toggleMinimize}
        >
          <i className="fas fa-envelope" />
          Mailer App
        </Button>
        <DraggableWrapper>
          <div
            className={classNames('mailer-app__container', {
              'd-none': minimized,
            })}
          >
            <div className="mailer-app__container-header">
              <Button
                onClick={toggleMinimize}
                className="mailer-app__minimize-button me-1"
              >
                <i className="fa fa-minus"></i>
              </Button>
              <Button
                onClick={closeMailer}
                className="mailer-app__close-button btn-close"
              ></Button>
            </div>
            <MailerApp
              {...props}
              handleSetSavedDrafts={handleSetSavedDrafts}
              handleGetSavedDrafts={handleGetSavedDrafts}
              onMailSent={handleSentMail}
            />
          </div>
        </DraggableWrapper>
      </div>
    </MailerAppContainerContext.Provider>
  )
}

MailerAppContainer.propTypes = {
  requestId: PropTypes.number,
}

export { MailerAppContainerContext }
