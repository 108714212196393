import React from 'react'
import PropTypes from 'prop-types'
import SelectReceiver from './SelectReceiver'
import SelectSender from './SelectSender'
import SelectBody from './SelectBody'
import SelectFiles from './SelectFiles'
import SelectDrafts from './SelectDrafts'
import classNames from 'classnames'
import { isEqual } from 'lodash/fp'

const DRAFT_DEBOUNCE = 500

export default class MailerForm extends React.Component {
  constructor(props) {
    super(props)
    const drafts = props.getSavedDrafts()
    this.drafts = drafts
    this.lastSavedDraft = {}
    this.lastSavedDraft.receiver = props.receiver
    this.lastSavedDraft.sender = props.sender
    this.lastSavedDraft.subject = props.subject || ''
    this.lastSavedDraft.body = props.body || ''
    this.lastSavedDraft.replyTo = props.replyToProps
      ? { ...props.replyToProps }
      : undefined
    this.lastSavedDraft.files = []

    this.state = {
      receiver: props.receiver,
      sender: props.sender,
      subject: props.subject || '',
      body: props.body || '',
      replyTo: props.replyToProps ? { ...props.replyToProps } : undefined,
      files: [],
      missing: [],
      sending: false,
      success: false,
      error: '',
      customEmail: undefined,
      signature: '',
      lastSavedDraftOn: '',
      currentDraftId: this.generateDraftId(props.id),
      currentRequestDrafts: this.getCurrectRequestDrafts(this.drafts),
    }
  }

  generateDraftId = (id) => `${id}-${new Date().toISOString()}`

  handleEmailTypeChange = (e) => {
    this.setState({ sendType: e.value })
  }

  updateData = (key, value) => {
    this.setState({ [key]: value })
  }

  closeMailer = () => {
    const isConfirmed = confirm(
      'Die E-Mail schließen? Die Änderungen werden nicht gespeichert!',
    )
    if (!isConfirmed) {
      return
    }
    this.handleRemoveDraft(this.state.currentDraftId)
    this.reset()
    V2.trigger('mailer.hide')
  }

  handleCustomEmailChange = (value) => {
    this.setState({ customEmail: value })
  }

  onUpdateSubject = (event) => {
    this.setState({ subject: event.target.value })
  }

  componentDidMount() {
    const defaultSignature = this.state.sender
      ? this.findSignature(this.state.sender)
      : null
    if (defaultSignature) {
      this.lastSavedDraft.body =
        defaultSignature + this.state.body.replaceAll('\r\n', '\n')

      this.setState({
        body: this.lastSavedDraft.body,
        signature: defaultSignature,
      })
    } else return
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      receiver: nextProps.receiver,
      sender: nextProps.sender,
    })
  }

  componentDidUpdate(prevProps, _) {
    if (
      !this.lastSavedDraft ||
      this.lastSavedDraft.body !== this.state.body ||
      this.lastSavedDraft.replyTo?.email !== this.state.replyTo?.email ||
      this.lastSavedDraft.replyTo?.responseType !==
        this.state.replyTo?.responseType ||
      this.lastSavedDraft.sender !== this.state.sender ||
      this.lastSavedDraft.subject !== this.state.subject ||
      this.lastSavedDraft.customEmail !== this.state.customEmail ||
      this.state.files.length !== this.lastSavedDraft.files.length ||
      this.state.receiver?.length !== this.lastSavedDraft.receiver?.length ||
      this.state.files.find(
        (file) => !this.lastSavedDraft.files.find((item) => item === file),
      ) ||
      (this.state.receiver && this.lastSavedDraft.receiver
        ? this.state.receiver.find(
            (receiver) =>
              !this.lastSavedDraft.receiver.find((item) => item === receiver),
          )
        : this.state.receiver !== this.lastSavedDraft.receiver)
    ) {
      this.saveDraft()
    }

    if (
      this.props.receiver[0] &&
      !isEqual(this.props.receiver, prevProps.receiver)
    ) {
      if (this.props.receiver.includes('lawyer')) {
        this.setState({
          subject: this.props.receiver_subject_map['lawyer'],
        })
      } else if (this.props.receiver.includes('counter_party')) {
        this.setState({
          subject: this.props.receiver_subject_map['counter_party'],
        })
      } else {
        this.setState({
          subject: this.props.receiver_subject_map['client'],
        })
      }
    }
  }

  getCurrectRequestDrafts = (drafts) =>
    Object.entries(drafts)
      .filter(([key]) => key.startsWith(`${this.props.id}-`))
      .map(([key, value]) => ({ ...value, id: key }))

  handleSaveDraft = () => {
    if (this.state.currentDraftId) {
      this.drafts = this.props.getSavedDrafts()
      this.drafts[this.state.currentDraftId] = {
        ...this.drafts[this.state.currentDraftId],
        sender: this.state.sender,
        subject: this.state.subject,
        body: this.state.body,
        customEmail: this.state.customEmail,
        files: [...this.state.files],
        lastSavedDraftOn: new Date().toLocaleString(),
        replyTo: this.state.replyTo ? { ...this.state.replyTo } : undefined,
        receiver: this.state.receiver ? [...this.state.receiver] : undefined,
      }
      this.props.setSavedDrafts(this.drafts)
      this.lastSavedDraft = this.drafts[this.state.currentDraftId]
      this.setState({
        lastSavedDraftOn: `Zuletzt gespeichert am ${this.lastSavedDraft.lastSavedDraftOn}`,
        currentRequestDrafts: this.getCurrectRequestDrafts(this.drafts),
      })
    }
  }

  handleRemoveDraft = (id) => {
    if (this.draftTimeout) {
      window.clearTimeout(this.draftTimeout)
      this.draftTimeout = undefined
    }
    this.drafts = this.props.getSavedDrafts()
    delete this.drafts[id]
    this.props.setSavedDrafts(this.drafts)
    this.setState({
      currentDraftId:
        id === this.state.currentDraftId
          ? this.generateDraftId(this.props.id)
          : this.state.currentDraftId,
      currentRequestDrafts: this.getCurrectRequestDrafts(this.drafts),
    })
  }

  handleSelectDraft = (id) => {
    this.drafts = this.props.getSavedDrafts()
    const draft = this.drafts[id]
    if (draft) {
      const defaultSignature = draft.sender
        ? this.findSignature(draft.sender)
        : null
      this.lastSavedDraft = this.drafts[id]
      this.setState({
        receiver: draft.receiver,
        sender: draft.sender,
        subject: draft.subject || '',
        body: draft.body || '',
        files: draft.files,
        replyTo: draft.replyTo,
        missing: [],
        sending: false,
        success: false,
        error: '',
        customEmail: draft.customEmail || undefined,
        signature: '',
        currentDraftId: id,
        lastSavedDraftOn: `Zuletzt gespeichert am ${draft.lastSavedDraftOn}`,
        signature: defaultSignature,
        currentRequestDrafts: this.getCurrectRequestDrafts(this.drafts),
      })
    }
  }

  draftTimeout = null

  saveDraft = () => {
    if (this.draftTimeout) {
      window.clearTimeout(this.draftTimeout)
    }
    this.draftTimeout = window.setTimeout(() => {
      this.draftTimeout = null
      this.handleSaveDraft()
    }, DRAFT_DEBOUNCE)
  }

  reset = () => {
    this.drafts = undefined
    this.lastSavedDraft = undefined
    this.setState({
      receiver: [],
      sender: '',
      subject: '',
      sending: false,
      error: '',
      body: '',
      files: [],
      customEmail: undefined,
      signature: '',
      lastSavedDraftOn: '',
      replyTo: undefined,
      currentDraftId: '',
      currentRequestDrafts: [],
    })
  }

  sendTo = () => {
    if (this.state.replyTo) {
      return [this.state.replyTo.email]
    } else {
      return [
        ...(this.state.receiver?.filter((el) => el != 'null') ?? []),
        this.state.customEmail,
      ]
    }
  }

  submit = () => {
    fetch(`/admin/requests/${this.props.id}/emails`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
      },
      body: JSON.stringify({
        email: {
          to: this.sendTo(),
          from: this.state.sender,
          subject: this.state.subject,
          body: this.state.body,
          files: this.state.files,
          reply_to: this.state.receiver,
        },
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.missing) {
          this.setState({ missing: response.missing, sending: false })
        } else if (response.error) {
          this.setState({ error: response.error, sending: false })
        } else {
          this.setState({ success: true }, () => {
            if (this.drafts[this.state.currentDraftId]) {
              this.drafts[this.state.currentDraftId].jid = response.jid
            }
            this.props.setSavedDrafts(this.drafts)
            this.props.onMailSent({
              requestId: this.props.id,
              jid: response.jid,
              to: this.sendTo().join(', '),
              from: this.state.sender,
              subject: this.state.subject,
            })
            this.reset()
            setTimeout(() => {
              V2.trigger('mailer.hide')
            }, 1500)
          })
        }
      })
  }

  missingAttachmentValidation = () => {
    if (
      this.state.body.match(
        /anhang|anhänge|angehängt|anbei|anliegend|füg|attach/gi,
      ) &&
      this.state.files.length === 0
    ) {
      const confirmMsg = confirm(
        'Möglicherweise wurde ein Dateianhang vergessen?\n\nIn der Nachricht ist evtl. ein Anhang erwähnt, aber es konnte keine angehängte Datei festgestellt werden.\n\nNachricht trotzdem senden?',
      )

      if (confirmMsg) {
        this.setState({ sending: true, error: '' }, this.submit)
      }
    } else {
      this.setState({ sending: true, error: '' }, this.submit)
    }
  }

  filesTooBig = () => {
    if (this.state.files.length == 0 || this.props.files == 0) return false
    var totalFileSize = this.state.files.reduce((acc, file) => {
      return acc + this.props.files.find((e) => e.id == file).size
    }, 0)
    return totalFileSize > 23068672
  }

  validate = () => {
    const { customEmail, receiver } = this.state
    const regExp = /\S+@\S+\.\S+/

    if (!receiver.length && customEmail && regExp.test(customEmail)) {
      return true
    } else if (receiver.length && !customEmail) {
      return true
    }

    return false
  }

  updateSender = (filter, value) => {
    this.props.updateFilters(filter, value)
    this.updateSignature(value)
  }

  updateSignature = (value) => {
    const nextSignature = this.findSignature(value)
    const prevSignature = this.state.signature.length
      ? this.state.signature.replaceAll('\r\n', '\n')
      : ''

    if (prevSignature && this.state.body.includes(prevSignature)) {
      const index = this.state.body.indexOf(prevSignature)
      const nextBody =
        this.state.body.slice(0, index) +
        nextSignature +
        this.state.body.slice(index + prevSignature.length)
      this.setState({ body: nextBody, signature: nextSignature })
    } else if (
      this.state.body.includes('-----------------------------------')
    ) {
      const index = this.state.body.indexOf(
        '-----------------------------------',
      )
      const nextBody =
        this.state.body.slice(0, index) +
        nextSignature +
        '\n\n' +
        this.state.body.slice(index + prevSignature.length)
      this.setState({ body: nextBody, signature: nextSignature })
    } else
      this.setState({
        body: this.state.body + nextSignature,
        signature: nextSignature,
      })
  }

  findSignature = (sender) => {
    if (this.props.senders.length && sender) {
      const found = this.props.senders.find((el) => el.key === sender)
      return found ? found.signature.replaceAll('\r\n', '\n') : null
    } else return null
  }

  render() {
    return (
      <div className="d-flex flex-column new-email">
        <div
          className={classNames('success', {
            show:
              this.state.success &&
              !this.state.sending &&
              this.state.missing.length == 0 &&
              this.state.error == '',
          })}
        >
          Email wurde versandt
        </div>
        <div className={classNames('sending', { show: this.state.sending })}>
          E-Mail wird gesendet...
        </div>
        <div
          className={classNames('error', {
            show: this.state.missing.length > 0,
          })}
        >
          Achtung! Bitte alle Felder ausfüllen.
        </div>
        <div
          className={classNames('error', {
            show: this.state.error != '',
          })}
        >
          {this.state.error}
        </div>
        <form>
          <div className="form">
            <div className="border-gray MailerApp__to">
              <SelectReceiver
                items={this.props.receivers}
                success={this.state.success}
                updateParent={this.props.updateFilters}
                selected={this.state.receiver}
                replyToProps={this.state.replyTo}
                onCustomEmailChange={this.handleCustomEmailChange}
                customEmail={this.state.customEmail}
              />
            </div>
            <div className="border-gray MailerApp__from">
              <SelectSender
                items={this.props.senders}
                updateParent={this.updateSender}
                selected={this.state.sender}
              />
            </div>
            <div className="border-gray">
              <div className="subject-row d-flex">
                <div className="subject-label label">BETREFF: </div>
                <div className="field">
                  <input
                    className="subject-field not-draggable"
                    id="subject-field"
                    type="text"
                    onChange={this.onUpdateSubject}
                    value={this.state.subject}
                  />
                </div>
              </div>
            </div>
            <div className="border-gray MailerApp__textbody">
              <SelectBody
                updateParent={this.updateData}
                value={this.state.body}
              />
            </div>
            <div className="border-gray MailerApp__attch">
              <SelectFiles
                items={this.props.files}
                checkedItems={this.state.files}
                updateParent={this.updateData}
              />
            </div>
            <div className="border-gray MailerApp__drafts">
              <div className=".editor-statusbar">
                {this.state.lastSavedDraftOn}
              </div>
              <SelectDrafts
                drafts={this.state.currentRequestDrafts}
                currentDraftId={this.state.currentDraftId}
                onSelectDraft={this.handleSelectDraft}
                onDeleteDraft={this.handleRemoveDraft}
              />
            </div>
          </div>
          <div
            className={classNames('success', {
              show:
                this.state.success &&
                !this.state.sending &&
                this.state.missing.length == 0 &&
                this.state.error == '',
            })}
          >
            Email wurde versandt.
          </div>
          <div className={classNames('sending', { show: this.state.sending })}>
            E-Mail wird gesendet...
          </div>
          <div
            className={classNames('error', { show: this.state.error != '' })}
          >
            {this.state.error}
          </div>
          <div className="buttons-buttons float-right">
            <button
              type="button"
              className="btn btn-secondary btn-rounded me-2"
              onClick={this.closeMailer}
            >
              Abbrechen
            </button>
            <button
              type="button"
              className="btn btn-primary btn-rounded"
              onClick={this.missingAttachmentValidation}
              disabled={
                this.filesTooBig() || this.state.sending || !this.validate()
              }
            >
              Senden
            </button>
          </div>
        </form>
      </div>
    )
  }
}

MailerForm.propTypes = {
  receivers: PropTypes.array,
  senders: PropTypes.array,
  sender: PropTypes.string,
  receiver: PropTypes.array,
  files: PropTypes.array,
  body: PropTypes.string,
  id: PropTypes.number,
  product: PropTypes.string,
  updateFilters: PropTypes.func,
}

MailerForm.defaultProps = {
  senders: [],
  body: '',
  files: [],
}
