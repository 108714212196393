import React from 'react'
import { getDocumentUrl } from '../helpers'
import classNames from 'classnames'

interface IDocument {
  id: number
  uncategorized: boolean
  eb_date: string | null
}

interface IProps {
  document: IDocument
  requestId: string
  filename: string
}
const handleEdit = (document) => {
  V2.trigger('document.show', {
    url: document.edit_url,
    filename: document.filename,
    documentId: document.id,
  })
}

export default function FilenameCell({
  document,
  requestId,
  filename,
}: IProps) {
  const linkClassNames = classNames(
    'docs-filename',
    'me-3',
    'overflow-hidden',
    'text-truncate',
    'text-nowrap',
    {
      'docs-filename--uncat': document.uncategorized,
      'docs-filename--no-ebdate': document.eb_date !== null,
    },
  )

  return (
    <div className="d-flex">
      <span style={{ maxWidth: '90%' }}>
        <a
          href={`${getDocumentUrl(requestId, document.id)}?inline=true`}
          target="_blank"
          title={document.filename}
          rel="noopener noreferrer"
          className={linkClassNames}
          id={`${document.id}`}
        >
          {filename}
        </a>
      </span>
      <span>
        <a
          className="icon-button fa-lg me-5"
          href="#"
          target="_self"
          rel="noreferrer"
          onClick={() => handleEdit(document)}
        >
          <i className="fas fa-pencil-alt"></i>
        </a>
      </span>
    </div>
  )
}
