import reducer, {
  initializeCaseData,
  changeCaseData,
  removeCaseData,
} from '../caseData'

describe('caseData duck', () => {
  describe('action: initializeCaseData', () => {
    it('should return action with payload', () => {
      const payload = { 'user.first_name': 'James', 'user.last_name': 'Bond' }
      expect(initializeCaseData(payload)).toEqual({
        type: 'INITIALIZE_CASE_DATA',
        payload,
      })
    })
  })

  describe('action: changeCaseData', () => {
    it('should return action with payload', () => {
      const payload = { 'user.first_name': 'James', 'user.last_name': 'Bond' }
      expect(changeCaseData(payload)).toEqual({
        type: 'CHANGE_CASE_DATA',
        payload,
      })
    })

    it('should return action with empty payload', () => {
      expect(changeCaseData()).toEqual({
        type: 'CHANGE_CASE_DATA',
        payload: {},
      })
    })
  })

  describe('action: removeCaseData', () => {
    it('should return action without payload', () => {
      expect(removeCaseData()).toEqual({
        type: 'REMOVE_CASE_DATA',
      })
    })
  })

  describe('reducer', () => {
    it('should return initialized state', () => {
      const action = {
        type: 'INITIALIZE_CASE_DATA',
        payload: { 'user.first_name': 'James', 'user.last_name': 'Bond' },
      }
      expect(reducer(undefined, action)).toEqual({
        data: { 'user.first_name': 'James', 'user.last_name': 'Bond' },
        initialized: true,
      })
    })

    it('should return not initialized state', () => {
      const action = { type: '', payload: {} }
      expect(reducer(undefined, action)).toEqual({
        data: {},
        initialized: false,
      })
    })

    it('should return updated state', () => {
      const state = reducer(undefined, {
        type: 'INITIALIZE_CASE_DATA',
        payload: { 'user.first_name': 'James', 'user.last_name': 'Bond' },
      })
      const action = {
        type: 'CHANGE_CASE_DATA',
        payload: { 'user.last_name': 'Boom' },
      }
      expect(reducer(state, action)).toEqual({
        data: { 'user.first_name': 'James', 'user.last_name': 'Boom' },
        initialized: true,
      })
    })

    it('should return state with empty data and initialized false', () => {
      const state = reducer(undefined, {
        type: 'INITIALIZE_CASE_DATA',
        payload: { 'user.first_name': 'James', 'user.last_name': 'Bond' },
      })
      const action = { type: 'REMOVE_CASE_DATA' }
      expect(reducer(state, action)).toEqual({
        data: {},
        initialized: false,
      })
    })
  })
})
