import React, { Fragment, useState } from 'react'
import { Accordion } from 'react-bootstrap'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import LogItemHeader from '../LogItemHeader'
import ActionButton from '../ActionButton'
import Tooltip from '../../engine-ui/Tooltip'
import { formatDateLong } from '../../engine-ui/helpers/format'

export default function EmailLog(props) {
  const { data, id, open, onClick } = props
  const [isShown, setIsShown] = useState(false)
  const [isMetadataShown, setIsMetadataShown] = useState(false)

  const handleClick = (action) => {
    V2.trigger('mailer.show', { url: action.url })
  }

  const renderActionButtons = () => {
    return data.actions.map((action, index) => {
      return <ActionButton action={action} key={index} onClick={handleClick} />
    })
  }

  const syntaxHighlight = (json) => {
    if (typeof json !== 'string') {
      json = JSON.stringify(json, null, 2)
    }
    json = json
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(
        /("(\\u[\da-fA-F]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
        (match) => {
          let cls = 'number'
          if (/^"/.test(match)) {
            if (/:$/.test(match)) {
              cls = 'key'
            } else {
              cls = 'string'
            }
          } else if (/true|false/.test(match)) {
            cls = 'boolean'
          } else if (/null/.test(match)) {
            cls = 'null'
          }
          return `<span class="${cls}">${match}</span>`
        },
      )
    return json
  }

  return (
    <div>
      <div
        onClick={() => {
          onClick(id)
        }}
      >
        <LogItemHeader data={data} />
      </div>
      <Accordion.Collapse eventKey={id} in={open}>
        <div className="log-item-content__container">
          <div className="log-item-content-header__container">
            <div>
              <p className="log-item__text">{data.content_title.title}</p>
              <p className="log-item__text">
                VON: {data.content_title.email_from}
              </p>
              <p className="log-item__text">
                AN: {data.content_title.email_to}
              </p>
              {data.content_title.original_to !=
                data.content_title.email_to && (
                <p className="log-item__text">
                  Ursprünglich gesendet an: {data.content_title.original_to}
                </p>
              )}
              {data.content_title.email_cc.length > 0 && (
                <p className="log-item__text">
                  CC: {data.content_title.email_cc}
                </p>
              )}
              {data.content_title.email_bcc.length > 0 && (
                <p className="log-item__text">
                  BCC: {data.content_title.email_bcc}
                </p>
              )}
            </div>
            <div className="log-item-content-header__actions">
              {renderActionButtons()}
            </div>
          </div>
          <iframe
            srcDoc={`<pre style='white-space: pre-wrap; font-family: sans-serif; font-size: 90%'>${data.content_text}</pre>`}
            className="log-item-content__iframe"
          />
          <div className="log-item-content-footer__container">
            <div>
              {data.attachment_names &&
                data.attachment_names.map((item, index) => (
                  <div key={index}>
                    <Tooltip
                      text="Dieses Dokument wird nicht gespeichert/bearbeitet."
                      key={index}
                      className="footer-button"
                      placement="top"
                    >
                      <a
                        href={data.styled_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fas fa-file footer-icon me-1" />
                        {item}
                      </a>
                    </Tooltip>
                  </div>
                ))}
            </div>
            <div>
              {data.content_info.attachments &&
                data.content_info.attachments.map((item, index) => (
                  <div key={index}>
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fas fa-file footer-icon me-1" />
                      {item.filename}
                      <i className="fas fa-download footer-icon log-item__link ms-1" />
                    </a>
                  </div>
                ))}
            </div>
            <div className="footer-item3">
              {data.content_info.actions &&
                data.content_info.actions.map((action, index) => {
                  if (action.data_content) {
                    return (
                      <Tooltip
                        text={action.data_content}
                        key={index}
                        className="footer-button"
                        placement="top"
                      >
                        <i className={`footer-icon ${action.icon}`} />
                      </Tooltip>
                    )
                  }
                })}
            </div>
            {data.content_info.events &&
              data.content_info.events.length !== 0 && (
                <Fragment>
                  <div
                    className="footer-item4 log-item__link"
                    onClick={() => setIsShown(!isShown)}
                  >
                    <i className="fas fa-calendar-alt footer-icon log-item__link me-1" />
                    Events anzeigen
                  </div>
                  <div
                    className={classNames('footer-events', {
                      'footer-events--hidden': !isShown,
                    })}
                  >
                    {data.content_info.events.map((event, index) => (
                      <div key={index}>
                        <p>
                          {event.email}: <strong>{event.event}</strong> <br />
                          {formatDateLong(event.timestamp * 1000)} <br />
                          {event.reason && `Grund: ${event.reason}`}
                        </p>
                      </div>
                    ))}
                  </div>
                </Fragment>
              )}

            {data.content_info.metadata &&
              data.content_info.events.length !== 0 && (
                <Fragment>
                  <div
                    className="footer-item4 log-item__link"
                    onClick={() => setIsMetadataShown(!isMetadataShown)}
                  >
                    <i className="fas fa-table footer-icon log-item__link me-1" />
                    Events metadata
                  </div>
                  <div
                    className={classNames('footer-events wider-footer-events', {
                      'footer-events--hidden': !isMetadataShown,
                    })}
                  >
                    <pre
                      className="json-prettified"
                      dangerouslySetInnerHTML={{
                        __html: syntaxHighlight(data.content_info.metadata),
                      }}
                    />
                  </div>
                </Fragment>
              )}
          </div>
        </div>
      </Accordion.Collapse>
    </div>
  )
}

EmailLog.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  open: PropTypes.bool,
  onClick: PropTypes.func,
}
