import React from 'react'
import Button from '../../engine-ui/Button'
import Tooltip from '../../engine-ui/Tooltip'
import classNames from 'classnames'

interface ILogFilterButtonProps {
  type: string // this will available type instead of string
  isActive: boolean
  title: string
  icon: string
  isLargeScreen: boolean
  text: string
  onFilterTypeClick: (_type: string) => void
}

interface IButtonContentProps {
  isLargeScreen: boolean
  type: string // this will available type instead of string
  title: string
  icon: string
}

function ButtonContent({
  isLargeScreen,
  type,
  icon,
  title,
}: IButtonContentProps) {
  if (isLargeScreen || type === 'marked') {
    return (
      <div className="log-filter__type-button-text">
        {isLargeScreen || type === 'marked' ? <i className={icon} /> : null}
        {title}
      </div>
    )
  }

  return <div className="log-filter__type-button-text">{title}</div>
}

export default function LogFilterButton({
  text,
  icon,
  type,
  title,
  isActive,
  isLargeScreen,
  onFilterTypeClick,
}: ILogFilterButtonProps) {
  const handleClick = () => {
    onFilterTypeClick(type)
  }

  return (
    <Button
      className={classNames(
        'btn',
        'log-filter__type-button',
        `log-filter__type-button__${type}`,
        {
          [`log-item__bg-color--${type} log-filter__type-button--active`]:
            isActive,
        },
      )}
      onClick={handleClick}
    >
      <Tooltip text={text} placement="top">
        <ButtonContent
          icon={icon}
          title={title}
          type={type}
          isLargeScreen={isLargeScreen}
        />
      </Tooltip>
    </Button>
  )
}
