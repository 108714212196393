import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'

const TextInput = ({ question, state, handleFormChange, handleBlur, type }) => {
  function onChange(event) {
    handleFormChange(
      question.name,
      event.target.value,
      isValid(event.target.value),
    )
  }

  function isValid(value) {
    if (isEmpty(value)) {
      return !question.required
    }

    return true
  }
  function onBlur() {
    handleBlur(question.name)
  }

  const value = state.value || ''

  return (
    <div
      className={`form-group ${
        state.showErrors && !isValid(value) ? 'invalid' : ''
      }`}
    >
      <label className="control-label" htmlFor={question.name}>
        {question.text}
      </label>
      <div>
        <input
          className="form-control"
          type={type}
          name={question.name}
          id={question.name.replace('[', '_').replace(']', '')}
          onChange={onChange}
          value={value}
          disabled={state.disabled}
          onBlur={onBlur}
        />
      </div>
      <div className="validation-message">Bitte füllen Sie dieses Feld aus</div>
      {question.info && <div className="question__info">{question.info}</div>}
    </div>
  )
}

TextInput.propTypes = {
  question: PropTypes.object.isRequired,
  type: PropTypes.string,
  state: PropTypes.object,
  handleFormChange: PropTypes.func,
  handleBlur: PropTypes.func,
  disabled: PropTypes.bool,
}

TextInput.defaultProps = {
  type: 'text',
  state: {},
}

export default TextInput
