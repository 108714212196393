import React from 'react'
import PropTypes from 'prop-types'

import Modal from '../bootstrap/modal'
import DocumentDeleteDisabled from './document_delete_disabled'

const DocumentDelete = ({ doc, handleDelete }, { translations }) => {
  const openModal = () => {
    $(`#deleteModal${doc.id}`).modal()
  }

  if (doc.user_deletable) {
    return (
      <div>
        <button
          className="document__delete"
          type="button"
          onClick={() => openModal(doc)}
        >
          <i className="fas fa-trash"></i>
        </button>
        <Modal
          id={`deleteModal${doc.id}`}
          header={translations.modal.header}
          dismissButton={translations.modal.dismiss}
          confirmButton={translations.modal.confirm}
          confirmCallback={handleDelete}
        />
      </div>
    )
  } else {
    return <DocumentDeleteDisabled />
  }
}

DocumentDelete.propTypes = {
  doc: PropTypes.object.isRequired,
  handleDelete: PropTypes.func,
}

DocumentDelete.contextTypes = {
  translations: PropTypes.object,
}

export default DocumentDelete
