import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DataTable from '../engine-ui/DataTable'

export default class PaymentsDataTable extends Component {
  static propTypes = {
    data: PropTypes.array,
    fieldsOrder: PropTypes.array,
    translations: PropTypes.object,
  }

  renderRow = (rowData, { index: rowIndex, fieldsOrder }) => {
    const { translations } = this.props

    return (
      <tr key={rowIndex} className="tab-table-row">
        {fieldsOrder.map((field, cellIndex) => (
          <td key={cellIndex}>
            {translations[field]}: <strong>{rowData[field]}</strong> EUR
          </td>
        ))}
      </tr>
    )
  }

  render() {
    const { data, fieldsOrder } = this.props

    return (
      <DataTable
        rows={data}
        renderRow={this.renderRow}
        className="table table-striped table-dark-engine-funnel"
        fieldsOrder={fieldsOrder}
      />
    )
  }
}
