import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'

export default class Modal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    noBackdrop: PropTypes.bool,
    onHide: PropTypes.func,
    onClose: PropTypes.func,
    children: PropTypes.any,
  }

  handleCloseRequest = (event) => {
    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      !this.props.noBackdrop
    ) {
      this.handleClose()
    }
  }

  handleHide = () => {
    const { onHide } = this.props
    onHide && onHide()
  }

  handleClose = () => {
    const { onClose } = this.props
    onClose && onClose()
  }

  render() {
    const { onHide, isOpen, children } = this.props

    return (
      <Fragment>
        {isOpen && (
          <div className="engine-modal" onClick={this.handleCloseRequest}>
            <div
              ref={(wrapperRef) => (this.wrapperRef = wrapperRef)}
              className="engine-modal__wrapper"
            >
              <div className="engine-modal__actionbtns">
                {onHide && (
                  <i className="fas fa-minus me-3" onClick={this.handleHide} />
                )}
                <i className="btn-close" onClick={this.handleClose} />
              </div>
              {children}
            </div>
          </div>
        )}
      </Fragment>
    )
  }
}
