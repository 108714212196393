import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

import Search from './Search'
import Modal from 'react-bootstrap/Modal'

export default function SearchContainer(props) {
  const [isModalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    const event = new Event('autopilot:start')
    document.dispatchEvent(event)
  }, [isModalOpen])

  const toggleModal = () => {
    setModalOpen(!isModalOpen)
  }

  return (
    <div className="search">
      <button
        className={classNames('search__btn', 'autopilot-tracking', {
          active_tracking: isModalOpen,
        })}
        onClick={toggleModal}
        id="requestSearch"
      >
        <i className="fas fa-search" />
        Suchen
      </button>
      <Modal show={isModalOpen} onHide={toggleModal} size="lg">
        <Modal.Header closeButton />
        <Modal.Body>
          <Search
            url={props.url}
            jwt_token={props.jwt_token}
            token={props.token}
          />
        </Modal.Body>
      </Modal>
    </div>
  )
}
