import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Tooltip from '../engine-ui/Tooltip'
import EvidenceTitle from './EvidenceTitle'
import EvidenceTable from './EvidenceTable'
import Form from './Form'

export default function EvidenceBlock(props) {
  const {
    data,
    funnelDataFields,
    evidenceOptions,
    files,
    requestId,
    onEditSubmit,
    onFileRemoval,
  } = props

  const [mode, setMode] = useState('view')

  const handleChangeModeToView = () => setMode('view')
  const handleChangeModeToEdit = () => setMode('edit')

  const handleEditSubmit = (formValues) => {
    onEditSubmit(data.question_id, formValues)
  }

  const evidenceOptionsWithDisabled = evidenceOptions.map((item) => {
    const existingFiles = data.files.map((item) => item.id)
    if (existingFiles.includes(item.value)) return { ...item, isDisabled: true }
    return item
  })

  return (
    <div className="mb-5">
      {mode === 'edit' ? (
        <Form
          funnelDataFields={funnelDataFields}
          evidenceOptions={evidenceOptionsWithDisabled}
          onCancelForm={handleChangeModeToView}
          onFormSubmit={handleEditSubmit}
          data={data}
          files={files}
          mode={'edit'}
        />
      ) : (
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ height: '49px' }}
        >
          <div className="d-flex align-items-center">
            <EvidenceTitle title={data.question} mode={mode} />
          </div>
          <div>
            <Tooltip text="Beweis bearbeiten" placement="top">
              <i
                className="far fa-2x fa-edit icon-btn ms-4"
                onClick={handleChangeModeToEdit}
              />
            </Tooltip>
          </div>
        </div>
      )}
      <EvidenceTable
        files={data.files}
        requestId={requestId}
        onFileRemoval={onFileRemoval}
        question_id={data.question_id}
      />
    </div>
  )
}

EvidenceBlock.propTypes = {
  data: PropTypes.object,
  funnelDataFields: PropTypes.array,
  evidenceOptions: PropTypes.array,
  files: PropTypes.array,
  requestId: PropTypes.number,
  onEditSubmit: PropTypes.func,
  onFileRemoval: PropTypes.func,
}
