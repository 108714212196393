import document from './ducks/document'
import metaData from './ducks/metaData'
import caseData from './ducks/caseData'
import contentPages from './ducks/contentPages'
import documentViewer from './ducks/documentViewer'

import { combineReducers } from './utils'

export default combineReducers({
  document,
  metaData,
  caseData,
  contentPages,
  documentViewer,
})
