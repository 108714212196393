import React, { useContext, useState } from 'react'
import { Accordion } from 'react-bootstrap'
import Button from '../../engine-ui/Button'

import LogItemHeader from '../LogItemHeader'
import ActionButton from '../ActionButton'
import { ActivityLogContext } from '../store'

export default function Note(props) {
  const { data, id, open, onClick } = props
  const [contentText, setContentText] = useState(data.content_text)
  const [editMode, setEditMode] = useState(false)

  const context = useContext(ActivityLogContext)
  const noteId = parseInt(id.split('-')[1])

  const handleClick = (action) => {
    if (action.title === 'delete') {
      const isConfirmed = confirm('Kommentar sicher löschen?')
      if (!isConfirmed) {
        return
      }
      context.onDelete && context.onDelete(noteId, 'activity_logs')
    }

    if (action.title === 'edit') {
      setEditMode(!editMode)
    }
  }

  const handleNoteSubmit = () => {
    context.onEdit && context.onEdit(noteId, contentText)
    setEditMode(false)
  }

  const renderActionButtons = () => {
    return data.actions.map((action, index) => {
      return <ActionButton action={action} key={index} onClick={handleClick} />
    })
  }

  return (
    <div>
      <div
        onClick={() => {
          onClick(id)
        }}
      >
        <LogItemHeader data={data} />
      </div>
      <Accordion.Collapse eventKey={id} in={open}>
        <div className="log-item-content__container">
          <div className="log-item-content-header__container">
            <p className="log-item__text">{data.content_title}</p>
            <div className="log-item-content-header__actions">
              <div className="log-item-content-header__actions">
                {renderActionButtons()}
              </div>
            </div>
          </div>
          {data.content_text && (
            <div className="log-item-content__card">
              {editMode ? (
                <div className="d-flex flex-column">
                  <textarea
                    className="form-control"
                    value={contentText}
                    onChange={(e) => setContentText(e.target.value)}
                  />
                  <Button
                    className="btn btn-primary btn-rounded align-self-end mt-3"
                    onClick={handleNoteSubmit}
                  >
                    Speichern
                  </Button>
                </div>
              ) : (
                <pre
                  className="log-item-content__pre"
                  dangerouslySetInnerHTML={{ __html: contentText }}
                />
              )}
            </div>
          )}
        </div>
      </Accordion.Collapse>
    </div>
  )
}
