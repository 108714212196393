import React, { useState, useEffect } from 'react'

import TextInput from '../../../engine-ui/TextInput'
import Select from '../../../engine-ui/Select'
import AdminDatepicker from '../../../admin_datepicker'
import useForm from '../../hooks/useForm'
import {
  Container,
  Heading,
  FormContainer,
  ButtonContainer,
  Button,
  Label,
} from './styles/stepRentCalculator'
import validate from '../../../ContentPage/validate'
import { calculateRentSteps } from '../../helpers/calculations'
import { formatStringToNumber } from '../../../engine-ui/helpers/format'
import PropTypes from 'prop-types'

export default function RentStepCalculator(props) {
  const { onSubmit } = props
  const [errors, setErrors] = useState({})
  const { initialize, initialized, change, formValues } = useForm()

  useEffect(() => {
    const initialValues = {
      firstStepStart: '',
      lastStepStart: '',
      numberOfSteps: '',
      firstStepRent: '',
      lastStepRent: '',
      slope: 'linear',
    }
    initialize(initialValues)
  }, [])

  const handleChange = ({ name, value }, type, required) => {
    change(name, value)
    setErrors({
      ...errors,
      [name]: validate(type, { required: required })(value),
    })
  }

  const handleSubmit = () => {
    const values = getCalculationValues()
    const rentSteps = calculateRentSteps(values)
    onSubmit(rentSteps)
  }

  const getCalculationValues = () => {
    const {
      firstStepStart,
      lastStepStart,
      firstStepRent,
      lastStepRent,
      numberOfSteps,
      slope,
    } = formValues

    const values = {
      firstStepStart: firstStepStart.value,
      lastStepStart: lastStepStart.value,
      firstStepRent: formatStringToNumber(firstStepRent.value),
      lastStepRent: formatStringToNumber(lastStepRent.value),
      numberOfSteps: parseInt(numberOfSteps.value),
      slope: slope.value,
    }

    return values
  }

  const isInvalid =
    Object.values(errors).some((item) => item) ||
    Object.values(formValues).some((item) => !item.value || item.value === '0')
  if (initialized) {
    return (
      <Container>
        <Heading>Rechner</Heading>
        <FormContainer>
          <div>
            <div className="form-group">
              <Label>Erste Staffel ab</Label>
              <AdminDatepicker
                past
                onChange={(event) => handleChange(event, 'date', true)}
                value={formValues['firstStepStart'].value}
                question={{ name: 'firstStepStart', text: '' }}
                name="firstStepStart"
                className="df_datepicker"
              />
            </div>
            <div className="form-group">
              <Label>Letzte Staffel ab</Label>
              <AdminDatepicker
                past
                onChange={(event) => handleChange(event, 'date', true)}
                value={formValues['lastStepStart'].value}
                question={{ name: 'lastStepStart', text: '' }}
                name="lastStepStart"
                className="df_datepicker"
              />
            </div>
            <div className="form-group">
              <Label>Anzahl Staffeln</Label>
              <TextInput
                onChange={(event) => handleChange(event, 'number', true)}
                name="numberOfSteps"
                value={formValues['numberOfSteps'].value}
                type="number"
                placeholder="0,00"
                className="form-control form-control-sm w-100"
                error={errors['numberOfSteps']}
              />
            </div>
          </div>
          <div>
            <div className="form-group">
              <Label>Erste Staffel Miete (€)</Label>
              <TextInput
                onChange={(event) => handleChange(event, 'number', true)}
                name="firstStepRent"
                value={formValues['firstStepRent'].value}
                type="number"
                placeholder="0,00"
                className="form-control form-control-sm w-100"
                error={errors['firstStepRent']}
              />
            </div>
            <div className="form-group">
              <Label>Letzte Staffel Miete (€)</Label>
              <TextInput
                onChange={(event) => handleChange(event, 'number', true)}
                name="lastStepRent"
                value={(formValues['lastStepRent'] || {}).value}
                type="number"
                placeholder="0,00"
                className="form-control form-control-sm w-100"
                error={errors['lastStepRent']}
              />
            </div>
            <div className="form-group">
              <Label>Steigung</Label>
              <Select
                name="slope"
                value={formValues['slope'].value}
                onChange={(event) => handleChange(event, 'select', true)}
                options={[
                  { text: 'Linear', value: 'linear' },
                  { text: 'Prozentual', value: 'percentual' },
                ]}
                className="form-control form-control-sm w-100"
              />
            </div>
          </div>
        </FormContainer>
        <ButtonContainer>
          <Button onClick={handleSubmit} disabled={isInvalid}>
            Berechnen
          </Button>
        </ButtonContainer>
      </Container>
    )
  }
  return null
}

RentStepCalculator.propTypes = {
  onSubmit: PropTypes.func,
}
