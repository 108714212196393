import React from 'react'
import Tooltip from '../engine-ui/Tooltip'
import classNames from 'classnames'

export default function ActionButton(props) {
  const { action, onClick, className } = props

  const actionsWithHref = ['save-as-document', 'print', 'download', 'view']

  return (
    <Tooltip className={className} text={action.text} placement="top">
      {actionsWithHref.includes(action.title) ? (
        <div className="log-item-content__action-button">
          <a href={action.url} target={action.target || '_self'}>
            <i className={`${action.icon} log-item-content__action-icon`} />
          </a>
        </div>
      ) : (
        <div
          className={classNames('log-item-content__action-button', {
            'mailer-email-trigger':
              action.title === 'answer' || action.title === 'forward',
          })}
          onClick={() => onClick(action)}
        >
          <i className={`${action.icon} log-item-content__action-icon`} />
        </div>
      )}
    </Tooltip>
  )
}
