import React, { useState, useEffect } from 'react'

import PropTypes from 'prop-types'
import { omit, find } from 'lodash/fp'

import PaymentForm from './PaymentForm'
import PaymentActionsForm from './PaymentActionsForm'
import PaymentsDataTable from './PaymentsDataTable'
import CalculationsDataTable from './CalculationsDataTable'
import { Modal } from 'react-bootstrap'
import Button from '../engine-ui/Button'
import Stepper, { Step } from '../engine-ui/Stepper'

import { formatNumber } from './helpers'
import client from '../../../../client'
import withFetchedDocuments from '../DocumentsTab/hoc/withFetchedDocuments'
import AlertBanner from '../engine-ui/AlertBanner'

const DEFAULT_PAYMENT_DATA = {
  amount: '',
  party: 'party-gegn',
  on_hold: false,
  comment: '',
  category: 'category-za',
  currency: 'EUR',
  direction: 'direction-ein',
  payment_date: moment.utc().toISOString(),
  payment_type: 'type-prov',
  court_instance: 'court_instance-ooc',
  payment_purpose: '',
  primary_party: '',
  invoice_document_id: '',
}

function PaymentsTab(props) {
  const initialState = {
    payments: {
      data: [],
      fields_order: [],
    },
    calculations: {
      data: '',
      fields_order: [],
    },
    translations: {},
  }

  const { token } = props

  const [state, setState] = useState({ ...initialState })
  const [isModalHidden, setIsModalHidden] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [showTriggers, setShowTriggers] = useState(false)
  const [triggers, setTriggers] = useState([])
  const [documents, setDocuments] = useState([])
  const [newPayment, setNewPayment] = useState({ ...DEFAULT_PAYMENT_DATA })
  const [saveErrorMessage, setSaveErrorMessage] = useState('')

  const getFormattedPayment = (payment) => {
    return {
      ...payment,
      primary_party: payment.long_primary_party,
      amount: formatNumber(payment.amount),
      payment_date: moment
        .utc(payment.payment_date, 'DD.MM.YYYY')
        .toISOString(),
    }
  }

  const [firstLoadDone, setFirstLoadDone] = useState(false)

  useEffect(() => {
    if (!firstLoadDone) {
      fetchPayments()
      fetchDocuments()
      setFirstLoadDone(true)
    }
  })

  const createPayment = ({ data }) => {
    const { requestId } = props
    return client.post(`/admin/requests/${requestId}/payments`, data)
  }

  const updatePayment = ({ id, data }) => {
    const { requestId } = props
    const { id: paymentId } = newPayment
    return client.patch(
      `/admin/requests/${requestId}/payments/${paymentId}`,
      data,
    )
  }

  const fetchPayments = () => {
    const { requestId } = props
    client
      .get(`/admin/requests/${requestId}/payments`)
      .then((response) => response.json())
      .then((data) => setState((prevState) => ({ ...prevState, ...data })))
  }

  const fetchDocuments = () => {
    const { requestId } = props
    fetch(`/api/admin/requests/${requestId}/documents.json`, {
      headers: {
        token: token,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        return response.json()
      })
      .then(({ request: data }) => {
        setDocuments(
          [{ label: '', value: null }].concat(
            data.files.map((doc) => {
              return { label: doc.filename, value: doc.id }
            }),
          ),
        )
      })
      .catch((error) => {
        console.error('Error fetching documents:', error)
      })
  }

  const handleEdit = (id) => {
    const { payments } = state
    const payment = find({ id })(payments.data)
    setNewPayment(getFormattedPayment(payment))
    handleModalOpen()
  }

  const handleClone = (id) => {
    const { payments } = state
    const payment = omit('id')(find({ id })(payments.data))
    setNewPayment(getFormattedPayment(payment))
    handleModalOpen()
  }

  const handleSplit = (id) => {
    const { requestId } = props
    const res = ''
    client
      .post(`/admin/requests/${requestId}/payments/${id}/children`)
      .then((res) => res.json())
      .then((data) => {
        if (data?.success) {
          const { amount, parent_attributes, parent_id } = data.payment
          const { payment_date, primary_party } = parent_attributes
          const newPayment = {
            ...DEFAULT_PAYMENT_DATA,
            amount,
            parent_id,
            payment_date: new Date(payment_date),
            long_primary_party: primary_party,
          }
          setNewPayment(getFormattedPayment(newPayment))
          handleModalOpen()
        }
      })
  }

  const handleMarkPaid = (id) => {
    if (
      window.confirm(
        'Sind Sie sicher, dass Sie eine Zahlung in einer neuen Reihe erstellen wollen?',
      )
    ) {
      const { requestId } = props
      client
        .post(`/admin/requests/${requestId}/payments/${id}/paid`)
        .then((res) => res.json())
        .then((data) => {
          if (data?.success) {
            fetchPayments()
          }
        })
    }
  }

  const handleCancel = (id) => {
    if (
      window.confirm(
        'Sicher, dass Sie diesen Eintrag stornieren wollen? Falls ja, wird ein entsprechender neuer Eintrag angelegt-',
      )
    ) {
      const { requestId } = props
      client
        .post(`/admin/requests/${requestId}/payments/${id}/cancellations`)
        .then((res) => res.json())
        .then((data) => {
          if (data?.success) {
            fetchPayments()
          }
        })
    }
  }

  const handleDelete = (id) => {
    const { requestId } = props
    const isConfirmed = confirm(`
      Sind Sie sicher, dass Sie diese Zahlung entfernen möchten?
      Das kann nicht rückgängig gemacht werden!
    `)
    if (!isConfirmed) {
      return
    }
    return client
      .delete(`/admin/requests/${requestId}/payments/${id}`)
      .then(fetchPayments)
  }

  const handleArchive = (id) => {
    const { requestId } = props
    const isConfirmed = confirm(`
      Sind Sie sicher, dass Sie diese Zahlung archivieren möchten?
      Das kann rückgängig gemacht werden :)
    `)
    if (!isConfirmed) {
      return
    }
    return client
      .delete(`/admin/requests/${requestId}/payments/${id}/archive`)
      .then(fetchPayments)
  }

  const onVerify = () => {
    const { agentId, requestId } = props
    const paymentData = omit(['id', 'payment_date'])(newPayment)
    const amount =
      typeof paymentData.amount === 'string'
        ? paymentData.amount.replaceAll(',', '.')
        : paymentData.amount
    const data = {
      payment: {
        ...paymentData,
        amount: parseFloat(amount),
        agent_id: agentId,
        request_id: requestId,
      },
      payment_date: newPayment.payment_date,
    }
    client
      .post(`/admin/payment_triggers/list_triggers`, data)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setShowTriggers(true)
          setTriggers(data.triggers)
        }
      })
  }

  const handleSubmit = () => {
    const { agentId, requestId } = props
    setSaveErrorMessage('')

    const paymentData = omit(['id', 'payment_date'])(newPayment)
    const request = newPayment.id ? updatePayment : createPayment
    const amount =
      typeof paymentData.amount === 'string'
        ? paymentData.amount.replaceAll(',', '.')
        : paymentData.amount
    return request({
      id: newPayment.id,
      data: {
        payment: {
          ...paymentData,
          amount: parseFloat(amount),
          agent_id: agentId,
          request_id: requestId,
        },
        payment_date: newPayment.payment_date,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          handleModalClose()
        } else {
          setSaveErrorMessage(data.errors.join(',\n'))
        }
      })
  }

  const onNewClick = () => {
    setNewPayment({ ...DEFAULT_PAYMENT_DATA })
    handleModalOpen()
  }

  const handleModalOpen = () => {
    setIsModalOpen(true)
  }

  const handleModalHide = () => {
    setIsModalOpen(!isModalOpen)
    setIsModalHidden(true)
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
    setIsModalHidden(false)
    fetchPayments()
    // resetPaymentForm
    setState((prevState) => ({
      ...prevState,
      newPayment: { ...DEFAULT_PAYMENT_DATA },
    }))
    setShowTriggers(false)
  }

  const handleChange = ({ name, value }) => {
    setNewPayment({ ...newPayment, [name]: value })
  }

  return (
    <>
      <p className="form_category__title">Zahlungen</p>
      <PaymentsDataTable
        data={state.payments?.data || []}
        onEdit={handleEdit}
        onClone={handleClone}
        onCancel={handleCancel}
        onMarkPaid={handleMarkPaid}
        onSplit={handleSplit}
        onDelete={handleDelete}
        onArchive={handleArchive}
        fieldsOrder={state.payments?.fields_order || []}
        translations={state.translations}
      />
      <div className="d-flex justify-content-between mt-4">
        <p className="form_category__title">Total</p>
        <div>
          {isModalHidden && (
            <Button
              onClick={handleModalOpen}
              className="btn btn-primary btn-rounded me-3 mb-3 mt-0"
            >
              Fortsetzen
            </Button>
          )}
          <Button
            onClick={onNewClick}
            className="btn btn-primary btn-rounded me-3 mb-3 mt-0"
          >
            Neu
          </Button>
        </div>
      </div>
      <CalculationsDataTable
        data={[state.calculations.data]}
        fieldsOrder={state.calculations.fields_order}
        translations={state.translations}
      />
      <Modal
        show={isModalOpen}
        size="lg"
        onHide={handleModalHide}
        onClose={handleModalClose}
      >
        <Modal.Header>
          {newPayment.id ? 'Zahlung' : 'Neue Zahlung'}
        </Modal.Header>
        <Modal.Body>
          <>
            {saveErrorMessage.length > 0 && (
              <AlertBanner message={saveErrorMessage} />
            )}
            <PaymentForm
              data={newPayment}
              onChange={handleChange}
              documents={documents}
              translations={state.translations}
              showTriggers={showTriggers}
              triggers={triggers}
            />
          </>
        </Modal.Body>
        <Modal.Footer>
          <div className="stepper__buttons-container">
            <span className="me-3 ms-auto">
              <Button
                className="btn btn-info btn-rounded mb-3 mt-0"
                onClick={onVerify}
              >
                Verify
              </Button>
              <Button
                className="btn btn-primary btn-rounded ms-1 mb-3 mt-0"
                onClick={handleSubmit}
              >
                Speichern
              </Button>
            </span>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

// export default class PaymentsTab extends Component {
//   static propTypes = {
//     agentId: PropTypes.number.isRequired,
//     payments: PropTypes.object,
//     requestId: PropTypes.string.isRequired,
//     translations: PropTypes.object,
//     calculations: PropTypes.object
//   }

// }
export default PaymentsTab
