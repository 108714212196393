// eslint-disable-next-line
/*global process:true*/

const getConfig = (mailProcessorUrl) => {
  const navitems = [
    {
      id: 'todos',
      text: 'ToDos',
      link: '/admin/tickets',
      icon: 'fa-calendar-check',
      displayCondition: '',
    },
    {
      id: 'requests',
      text: 'Fälle',
      link: '/admin/requests',
      icon: 'fa-list-ol',
      displayCondition: '',
    },
    {
      id: 'mailprocessor',
      text: 'E-Mails',
      link: mailProcessorUrl,
      icon: 'fa-envelope',
      displayCondition: '',
    },
    {
      id: 'bea',
      text: 'beA',
      link: '/admin/bea_documents',
      icon: 'fa-box',
      displayCondition: 'adminOrLawyer',
    },
    {
      id: 'ega',
      text: 'EGA',
      link: '/admin/scanned_unassigned_documents',
      icon: 'fa-folder-open',
      displayCondition: 'adminOrLawyer',
    },
    {
      id: 'physicalmail',
      text: 'Postausgang',
      link: '/admin/physical_mails',
      icon: 'fa-inbox',
      displayCondition: 'physicalMailEnabled',
    },
    {
      id: 'courthearings',
      text: 'Verhandlung',
      link: '/admin/court_hearings',
      icon: 'fa-gavel',
      displayCondition: 'adminOrLawyer',
    },
    {
      id: 'jira',
      text: 'Jira-Support',
      link: 'https://wenigermiete.atlassian.net/servicedesk/customer/portals',
      icon: 'fa-bug',
      displayCondition: 'saasNotEnabled',
    },
  ]

  const othernavitems = [
    {
      id: 'cityconfig',
      text: 'City Configs',
      link: '/admin/city_configs',
      icon: 'fa-city',
      displayCondition: 'saasNotEnabledAndAdmin',
    },
    {
      id: 'agentconfig',
      text: 'Agent configs',
      link: '/admin/agents',
      icon: 'fa-user-cog',
      displayCondition: 'adminPrivileges',
    },
    {
      id: 'postmailloader',
      text: 'Scanned Docs',
      link: '/admin',
      icon: 'fa-cloud-upload-alt',
      displayCondition: 'loadScannedDocsEnabled',
    },
    {
      id: 'users',
      text: 'Users',
      link: '/admin/users',
      icon: 'fa-users',
      displayCondition: 'saasNotEnabled',
    },
    {
      id: 'leaderboard',
      text: 'Leaderboard',
      link: '/admin/leaderboard',
      icon: 'fa-chart-bar',
      displayCondition: 'saasNotEnabled',
    },
    {
      id: 'lawyersafeid',
      text: 'Gegenseite Safe-Id',
      link: '/admin/counter-party-lawyer-safe-ids',
      icon: 'fa-key',
      displayCondition: 'saasNotEnabled',
    },
    {
      id: 'masstriggerstatuses',
      text: 'Mass Trigger Status',
      link: '/admin/mass_trigger_statuses',
      icon: 'fa-file-csv',
      displayCondition: 'adminPrivileges',
    },
    {
      id: 'pri',
      text: 'Versicherung',
      link: '/admin/partners_requests_invitations',
      icon: 'fa-shield-alt',
      displayCondition: 'rsvEnabled',
    },
    {
      id: 'search',
      text: 'Search',
      link: '/admin/search',
      icon: 'fa-search',
      displayCondition: 'saasNotEnabled',
    },
  ]

  return { navitems, othernavitems }
}

export default getConfig
