import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  min-height: 75vh;
`

export const Sidebar = styled.div`
  flex: 1;
  flex-basis: 33.33%;
  margin: 1rem;
`
