import React, { useRef, useEffect, forwardRef, useState } from 'react'
import client from '../../../../client'
import Modal from 'react-bootstrap/Modal'
import Button from '../engine-ui/Button'
import { sPrintQuery } from '../tickets/Filter'
import AsyncSelect from 'react-select/async'

const UserChangeButton = (props) => {
  const { request, user } = props
  const [selectedUser, setSelectedUser] = React.useState(null)
  const [isModalOpen, setIsModalOpen] = React.useState(false)

  const selectRef = useRef(null)

  useEffect(() => {
    if (isModalOpen) {
      selectRef.current.focus()
    }
  }, [isModalOpen])

  const changeUser = () => {
    client
      .patch(
        `/admin/v2/requests/${request.id}`,
        { fields: [{ field: 'request.user_id', answer: selectedUser.id }] },
        { contentType: 'application/json', accept: 'application/json' },
      )
      .then((response) => response.json())
      .then(() => {
        // If we don't reload, the erb modal would still show the old user.
        // If we migrate the User modal to react we can remove the reload.
        window.location.reload()
      })
      .catch((error) => {
        alert(error)
      })
  }

  return (
    <>
      <Button
        className="btn btn-primary"
        onClick={() => setIsModalOpen(!isModalOpen)}
      >
        User wechseln
      </Button>
      <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)}>
        <Modal.Header closeButton>
          <h5 className="bea-ui__modal-title">User Wechseln</h5>
        </Modal.Header>
        <Modal.Body>
          <UserSelect
            id="change-user-select"
            setSelectedUser={setSelectedUser}
            value={selectedUser}
            user={user}
            ref={selectRef}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-secondary btn-rounded"
            onClick={() => {
              setIsModalOpen(false)
              setSelectedUser(null)
            }}
          >
            {' '}
            Abbrechen{' '}
          </button>
          <Button
            onClick={changeUser}
            className="btn btn-primary btn-rounded"
            disabled={!selectedUser}
          >
            {' '}
            Speichern{' '}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const UserSelect = forwardRef(function UserSelect(props, ref) {
  const { setSelectedUser, user } = props
  const [isLoading, setIsLoading] = useState(false)
  const [defaultOptions, setDefaultOptions] = useState(null)

  const loadOptions = (input, callback) => {
    client
      .get(`/admin/users?${sPrintQuery({ search: input })}`, null, {
        accept: 'application/json',
      })
      .then((response) => response.json())
      .then((data) => callback(data.data))
  }

  const loadDefaultOptions = () => {
    if (!user) {
      return
    }

    setIsLoading(true)
    client
      .get(`/admin/users?${sPrintQuery({ search: user.last_name })}`, null, {
        accept: 'application/json',
      })
      .then((response) => response.json())
      .then((json) => setDefaultOptions(json.data))
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    loadDefaultOptions()
  }, [])

  return (
    <>
      <AsyncSelect
        cacheOptions
        defaultOptions={defaultOptions}
        isLoading={isLoading}
        placeholder="Email oder Name"
        loadOptions={loadOptions}
        onChange={setSelectedUser}
        getOptionLabel={(e) => `${e.attributes.name} (${e.attributes.email})`}
        getOptionValue={(e) => e.id}
        {...props}
        ref={ref}
      />
    </>
  )
})

export default UserChangeButton
