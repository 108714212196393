import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import validate from '../validate'
import PropTypes from 'prop-types'
import EditCell from '../EditCell'

export default function Form(props) {
  const { data, onChange, inputData, setIsValidInput, touched } = props
  const [errors, setErrors] = useState({})

  const format = (f) => {
    if (f !== undefined && f !== null) {
      f = f.toString().replace(/\./g, ',')
    }
    return f
  }
  useEffect(() => {
    const errorsTmp = {}
    data.fields.forEach((field) => {
      if (
        validate(field.type, { required: false })(
          touched ? inputData[field.name] : field.answer || '',
        )
      ) {
        errorsTmp[field.name] = true
      }
    })
    setErrors(errorsTmp)
  }, [data, inputData, touched])

  useEffect(() => {
    setIsValidInput(Object.keys(errors).length === 0)
  }, [errors])

  if (data && data.fields) {
    return (
      <div className="dynamic-table-form">
        <div className="dynamic-table-form__inputs-container">
          {data.fields.map((field) => (
            <div
              key={field.name}
              className={classNames('dynamic-table-form__input-group', {
                'dynamic-table-form__input-group--checkbox':
                  field.type === 'checkbox',
              })}
            >
              <label>{field.label}</label>
              <EditCell
                name={field.name}
                type={field.type}
                onChange={onChange}
                value={
                  touched
                    ? inputData[field.name]
                    : field.type == 'number'
                      ? format(field.answer)
                      : field.answer || ''
                }
              >
                <EditCell.TextInput
                  step={field.type === 'number' ? '0,01' : undefined}
                  error={errors[field.name]}
                  className="df_input-select"
                />
                <EditCell.Checkbox className="df_input-checkbox me-2" />
                <EditCell.TextArea rows={2} className="df_input-text" />
                <EditCell.MultiSelect
                  options={field.options}
                  className="d-flex my-2 align-items-center multi-select df-fields"
                  labelClassName="ms-2 mt-0"
                />
                <EditCell.Select
                  options={[{ text: '', value: '' }, ...field.options]}
                  className="df_input-select"
                />
                <EditCell.AdminDatepicker
                  past
                  error={errors[field.name]}
                  question={{ name: field.name }}
                  className="df_datepicker"
                />
              </EditCell>
            </div>
          ))}
        </div>
      </div>
    )
  }
  return null
}

Form.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  inputData: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  touched: PropTypes.bool,
}
