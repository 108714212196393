import React, { useState, useContext, useEffect } from 'react'
import { filter } from 'lodash/fp'

import {
  Container,
  ContentContainer,
  SubmitMessage,
  Button,
} from './styles/documentCaseData'
import { formatStringToNumber } from '../../../engine-ui/helpers/format'
import Context from '../../context/Context'
import useCaseData from '../../hooks/useCaseData'
import useContentPages from '../../hooks/useContentPages'
import useForm from '../../hooks/useForm'
import useCallbackStatus from '../../hooks/useCallbackStatus'

import Tabs, { Tab, TabList, TabPanel } from '../../../Tabs'
import DynamicForm from './DynamicForm'
import RentStepCalculator from './RentStepCalculator'
import client from '../../../../../../client'
import validate from '../../../ContentPage/validate'
import TriggeredActionsList from '../../../engine-ui/TriggeredActionsList/'

export default function DocumentCaseData() {
  const [submitMessage, setSubmitMessage] = useState([])
  const [triggers, setTriggers] = useState([])
  const [errors, setErrors] = useState({})
  const { changeCaseData, caseData } = useCaseData()
  const { contentPages } = useContentPages()
  const { change, changeBatch, initialize, formValues } = useForm()
  const { run: runUpdateCaseData, isPending } = useCallbackStatus()
  const { ziebToken, agentEmail, documentId, token } = useContext(Context)

  const handleChange = ({ name, value }, type, required, validations) => {
    change(name, value)
    setErrors({
      ...errors,
      [name]: validate(type, { required: required, validations })(value),
    })
  }

  const handleBatchChange = (updatedFields) => {
    changeBatch(updatedFields)
  }

  const handleSubmit = () => {
    const types = Object.keys(contentPages.data)
      .reduce((acc, item) => {
        const { fields } = contentPages.data[item]
        return [...acc, fields]
      }, [])
      .flat()
      .map((item) => {
        return { [item.data.field]: item.data.question_type }
      })

    const filtered = filter({ pristine: false })(formValues)

    const mapped = filtered.map((item) => {
      const type = (types.find((el) => el[item.name]) || '')[item.name]
      if (type && type === 'number') {
        return {
          name: item.name,
          value: formatStringToNumber(item.value),
        }
      }
      return { name: item.name, value: item.value }
    })

    const caseDataPayload = mapped.reduce(
      (acc, item) => ({ ...acc, [item.name]: item.value }),
      {},
    )

    const payload = {
      fields: caseDataPayload,
      agent_email: agentEmail,
    }

    const headers = { token: token }

    runUpdateCaseData(
      client.post(
        `/api/admin/zieb/documents/${documentId}/data_page`,
        payload,
        headers,
      ),
    ).then(({ status, result }) => {
      if (status !== 'rejected') {
        changeCaseData(caseDataPayload), setTriggers(result?.triggers)
        setSubmitMessage(['success', 'Erfolgreich aktualisiert'])
      } else {
        setSubmitMessage(['error', 'Ein Problem ist aufgetreten'])
      }
    })
  }

  useEffect(() => {
    const { data, initialized } = caseData
    if (initialized) {
      initialize(data)
    }
  }, [caseData])

  const renderTab = ({ id, title }) => {
    return (
      <Tab value={id} key={id} fontSize={'0.7rem'}>
        <div>{title}</div>
      </Tab>
    )
  }
  const renderTabPanel = ({ fields, title }) => {
    const isStepRent = title === 'Staffeln'
    return (
      <TabPanel key={title}>
        <ContentContainer>
          {isStepRent && <RentStepCalculator onSubmit={handleBatchChange} />}
          <DynamicForm
            fields={fields}
            values={formValues}
            onChange={handleChange}
            onBatchChange={handleBatchChange}
            errors={errors}
            isStepRent={isStepRent}
            token={token}
          />
        </ContentContainer>
      </TabPanel>
    )
  }

  const { tabs, tabPanels } = Object.keys(contentPages.data).reduce(
    (acc, item) => {
      const { id, title, fields } = contentPages.data[item]

      return {
        tabs: [...acc.tabs, renderTab({ id, title })],
        tabPanels: [...acc.tabPanels, renderTabPanel({ fields, title })],
      }
    },
    { tabs: [], tabPanels: [] },
  )

  const isInvalid =
    Object.values(errors).some((item) => item) ||
    Object.values(formValues).every((item) => item.pristine)

  return (
    <Container className="zieb__case-data">
      <Tabs justifyContent="flex-start">
        <TabList>{tabs}</TabList>
        {tabPanels}
      </Tabs>
      <Button onClick={handleSubmit} disabled={isPending || isInvalid}>
        {isPending ? (
          <i className="fas fa-spinner fa-spin text-white"></i>
        ) : (
          'Speichern'
        )}
      </Button>
      {submitMessage.length > 0 && (
        <>
          <SubmitMessage color={submitMessage[0]}>
            {submitMessage[1]}
          </SubmitMessage>
          {triggers.valid ? (
            <SubmitMessage color={'neutral'}>
              Ausgelöste Aktionen:
            </SubmitMessage>
          ) : (
            <SubmitMessage color={'error'}>
              Folgende Aktionen konnten nicht ausgelöst werden:
            </SubmitMessage>
          )}

          <TriggeredActionsList
            data={triggers.actions}
            showCheckboxes={false}
            className={'zieb'}
          />
        </>
      )}
    </Container>
  )
}
