/* eslint react/prop-types: 0 */
import React, { Children, cloneElement } from 'react'

const TabList = (props) => {
  const { onSelect, children, blockName, selectedIndex } = props

  return (
    <>
      {Children.map(children, (child, index) =>
        cloneElement(child, {
          index,
          onSelect,
          blockName,
          selectedIndex,
        }),
      )}
    </>
  )
}

export default TabList
