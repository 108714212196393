import filterFeedItems from './filter'

const feedItemsMock = [
  {
    type: 'system_log',
    subtype: 'status_set',
    subject: 'internal',
  },
  {
    type: 'system_log',
    subtype: 'ticket_edited',
    subject: 'internal',
  },
  {
    type: 'system_log',
    subtype: 'document_accepted',
    subject: 'internal',
  },
  {
    type: 'email_log',
    subtype: 'incoming',
    subject: 'client',
  },
  {
    type: 'email_log',
    subtype: 'outgoing',
    subject: 'internal',
  },
  {
    type: 'document',
    subtype: 'incoming',
    subject: 'counter_party',
  },
  {
    type: 'document',
    subtype: 'outgoing',
    subject: 'internal',
  },
  {
    type: 'note',
    subtype: 'note/inbound_call',
    subject: 'internal',
  },
  {
    type: 'ticket',
    subtype: 'any',
    subject: 'internal',
  },
  {
    type: 'admin_edited',
    subtype: 'any',
    subject: 'internal',
  },
  {
    type: 'payment',
    subtype: 'incoming',
    subject: 'internal',
  },
  {
    type: 'payment',
    subtype: 'outgoing',
    subject: 'internal',
  },
  {
    type: 'automatic_physical_mail',
    subtype: 'outgoing',
    title: 'Autom. Postausgang',
    subject: 'internal',
  },
  {
    type: 'bea_file',
    subtype: 'outgoing',
    title: 'BEA',
    subject: 'internal',
  },
]

test('default filter: renders emails, documents, comments, life_cycle related system_logs', () => {
  const filterObjectMock = {
    subject: 'title',
    direction: 'title',
    type: ['email_log', 'document', 'note', 'life_cycle'],
  }
  expect(filterFeedItems(feedItemsMock, filterObjectMock)).toEqual([
    {
      type: 'system_log',
      subtype: 'status_set',
      subject: 'internal',
    },
    {
      type: 'email_log',
      subtype: 'incoming',
      subject: 'client',
    },
    {
      type: 'email_log',
      subtype: 'outgoing',
      subject: 'internal',
    },
    {
      type: 'document',
      subtype: 'incoming',
      subject: 'counter_party',
    },
    {
      type: 'document',
      subtype: 'outgoing',
      subject: 'internal',
    },
    {
      type: 'note',
      subtype: 'note/inbound_call',
      subject: 'internal',
    },
  ])
})

test('renders everything on selectAll', () => {
  const filterObjectMock = {
    direction: 'title',
    subject: 'title',
    type: [
      'email_log',
      'document',
      'note',
      'life_cycle',
      'ticket',
      'case_edited',
      'payment',
      'automatic_physical_mail',
      'bea_file',
    ],
  }
  expect(filterFeedItems(feedItemsMock, filterObjectMock)).toEqual(
    feedItemsMock,
  )
})

test('renders nothing on clearAll', () => {
  const filterObjectMock = {
    direction: 'title',
    subject: 'title',
    type: [],
  }
  expect(filterFeedItems(feedItemsMock, filterObjectMock)).toEqual([])
})

test('filter by direction', () => {
  const filterObjectMock = {
    direction: 'outgoing',
    subject: 'title',
    type: [
      'email_log',
      'document',
      'note',
      'life_cycle',
      'ticket',
      'case_edited',
      'payment',
      'automatic_physical_mail',
      'bea_file',
    ],
  }
  expect(filterFeedItems(feedItemsMock, filterObjectMock)).toEqual([
    {
      type: 'email_log',
      subtype: 'outgoing',
      subject: 'internal',
    },
    {
      type: 'document',
      subtype: 'outgoing',
      subject: 'internal',
    },
    {
      type: 'payment',
      subtype: 'outgoing',
      subject: 'internal',
    },
    {
      type: 'automatic_physical_mail',
      subtype: 'outgoing',
      title: 'Autom. Postausgang',
      subject: 'internal',
    },
    {
      type: 'bea_file',
      subtype: 'outgoing',
      title: 'BEA',
      subject: 'internal',
    },
  ])
})

test('filter by subject, direction and type', () => {
  const filterObjectMock = {
    direction: 'outgoing',
    subject: 'internal',
    type: ['document'],
  }
  expect(filterFeedItems(feedItemsMock, filterObjectMock)).toEqual([
    {
      type: 'document',
      subtype: 'outgoing',
      subject: 'internal',
    },
  ])
})

test('does not return ticket and life_cycle related system_logs if they are not chosen', () => {
  const filterObjectMock = {
    direction: 'title',
    subject: 'title',
    type: ['document', 'case_edited'],
  }
  expect(filterFeedItems(feedItemsMock, filterObjectMock)).toEqual([
    {
      type: 'system_log',
      subtype: 'document_accepted',
      subject: 'internal',
    },
    {
      type: 'document',
      subtype: 'incoming',
      subject: 'counter_party',
    },
    {
      type: 'document',
      subtype: 'outgoing',
      subject: 'internal',
    },
    {
      type: 'admin_edited',
      subtype: 'any',
      subject: 'internal',
    },
  ])
})
