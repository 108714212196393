import React from 'react'
import PropTypes from 'prop-types'

const SubmitButton = ({
  cssClasses,
  handleClick,
  label,
  loading,
  disabled,
}) => {
  return (
    <button
      type="button"
      className={`btn ${cssClasses}`}
      onClick={() => handleClick()}
      disabled={loading || disabled}
    >
      <i className={`spinner ${loading ? 'visible' : 'invisible'}`}></i>
      <span className={`${loading ? 'invisible' : 'visible'}`}>{label}</span>
    </button>
  )
}

SubmitButton.propTypes = {
  cssClasses: PropTypes.string,
  handleClick: PropTypes.func,
  label: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default SubmitButton
