import React, { useState } from 'react'
import PropTypes from 'prop-types'
import CreateableSelect from 'react-select/creatable'
import { Label, InputBox } from './styles/documentAttr'

const CourtIdField = ({ onChange, formValues }) => {
  const courtId = formValues?.court_id.value
  const court_ids_collection = formValues?.court_ids.value.filter((n) => n)
  const options = court_ids_collection
    .filter((opt) => opt !== 'kein Aktenzeichen' && opt !== 'keinAktenzeichen')
    .map((opt) => {
      const trimmedOpt = opt.replace(/\s/g, '')
      return { label: trimmedOpt, value: trimmedOpt }
    })

  const [selectedOption, setSelectedOption] = useState({
    label: courtId === 'keinAktenzeichen' ? 'kein Aktenzeichen' : courtId,
    value: courtId,
  })

  const [displayedOptions, setDisplayedOptions] = useState(options)

  const handleChange = (selected, event) => {
    if (event.action === 'clear') {
      setSelectedOption({ label: '', value: '' })
      onChange && onChange({ name: 'court_id', value: '' })
    } else {
      setSelectedOption(selected)
      onChange && onChange({ name: 'court_id', value: selected.value })
    }
  }

  const handleCreate = (inputValue) => {
    const newOptionVal = inputValue.replace(/\s/g, '')
    const newOption = { label: newOptionVal, value: newOptionVal }
    setDisplayedOptions([...options, newOption])
    setSelectedOption(newOption)
    onChange && onChange({ name: 'court_id', value: newOptionVal })
  }

  return (
    <>
      <Label>Aktenzeichen bei Gericht: </Label>
      <InputBox>
        <CreateableSelect
          isClearable
          className="autocomplete__select"
          name="court_id"
          value={selectedOption}
          options={[
            ...displayedOptions,
            { label: 'kein Aktenzeichen', value: 'keinAktenzeichen' },
          ]}
          defaultOptions={displayedOptions}
          onCreateOption={handleCreate}
          onChange={handleChange}
          placeholder="Auswählen oder hinzufügen"
          backspaceRemovesValue={false}
        />
      </InputBox>
    </>
  )
}

export default CourtIdField

CourtIdField.propTypes = {
  onChange: PropTypes.func,
  formValues: PropTypes.object,
}
