import React from 'react'
import useMetaData from '../useMetaData'

describe('useMetaData', () => {
  let dispatch
  beforeEach(() => {
    dispatch = jest.fn()
    React.useContext = jest.fn(() => ({ dispatch, state: { metaData: {} } }))
  })

  afterEach(() => {
    dispatch.mockClear()
    React.useContext.mockReset()
  })

  describe('useMetaData properties', () => {
    test('should return metaData properties', () => {
      expect(useMetaData()).toHaveProperty('metaData')
      expect(useMetaData()).toHaveProperty('initializeMetaData')
    })
  })

  describe('useMetaData: initializeMetaData', () => {
    test('should call dispatch initializeMetaData once', () => {
      const { initializeMetaData } = useMetaData()
      initializeMetaData({})
      expect(dispatch.mock.calls.length).toEqual(1)
    })
  })
})
