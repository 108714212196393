import React from 'react'
import PropTypes from 'prop-types'
import Table from 'react-bootstrap/Table'

import EvidenceRow from './EvidenceRow'

const EvidenceTable = (props) => {
  const { files, requestId, onFileRemoval, question_id } = props

  return (
    <div>
      <Table className="table table-dark-engine-funnel table-striped mt-2">
        <thead>
          <tr id="status-tab__head-row">
            <th>Nr.</th>
            <th>Datum</th>
            <th>Titel</th>
            <th>Dokument Typ</th>
            <th>Hinzugefügt von</th>
            <th>Aktionen</th>
          </tr>
        </thead>
        <tbody>
          {files.map((file, index) => (
            <EvidenceRow
              file={file}
              key={file.id}
              index={index}
              requestId={requestId}
              onFileRemoval={onFileRemoval}
              question_id={question_id}
            />
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default EvidenceTable

EvidenceTable.propTypes = {
  files: PropTypes.array,
  requestId: PropTypes.number,
  onFileRemoval: PropTypes.func,
  question_id: PropTypes.string,
}
