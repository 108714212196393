import React from 'react'
import PropTypes from 'prop-types'

import { StyledTabPanel } from './styles/tab'

const TabPanel = (props) => {
  const { index, children, selectedIndex } = props

  return (
    <StyledTabPanel hidden={index !== selectedIndex}>{children}</StyledTabPanel>
  )
}

export default TabPanel

TabPanel.propTypes = {
  index: PropTypes.number,
  selectedIndex: PropTypes.number,
  children: PropTypes.object,
}
