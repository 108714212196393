import React from 'react'

const Spinner = () => {
  return (
    <div className="engine-spinner">
      <span className="loader">
        <span className="loader-inner"></span>
      </span>
    </div>
  )
}
export default Spinner
