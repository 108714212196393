import { createContext } from 'react'

interface IActivityLogContext {
  requestId: string
  handleDelete: (_id: string, _type: string) => void
  handleEdit: (_id: string, _text: string) => void
  onItemMarkedChange: (_id: string, _value: boolean) => void
}

const defaultValues: IActivityLogContext = {
  requestId: '',
  handleDelete: (_id, _type) => {},
  handleEdit: (_id, _text) => {},
  onItemMarkedChange: (_id, _value) => {},
}

export const ActivityLogContext =
  createContext<IActivityLogContext>(defaultValues)
