import React, { useState, useEffect } from 'react'
import client from '../../../../client'

export default function ActiveCases(props) {
  const [cases, setCases] = useState([])

  useEffect(() => {
    client
      .get(`/admin/v2/requests/${props.requestId}/court_cases`, null, {
        Accept: 'application/json',
      })
      .then((response) => {
        return response.json()
      })
      .then((cases) => {
        setCases(cases.filter((c) => c.currently_active))
      })
  }, [])

  return (
    <div className="court-cases">
      <h6>Aktive Verfahren</h6>
      {cases.length ? (
        <ul style={{ listStyle: 'none', padding: 0 }}>
          {cases.map((c, i) => {
            return (
              <li key={c.id}>
                {i ? <hr className="mx-0 my-1" /> : null}
                <span>
                  <b>Klage:&nbsp;</b>
                  {caseTypeTranslator(c.lawsuit_type)}
                </span>
                <br />
                <span>
                  <b>Instanz:&nbsp;</b>
                  {c.case_instance}
                </span>
              </li>
            )
          })}
        </ul>
      ) : null}
    </div>
  )
}

const caseTypeTranslator = function (type) {
  switch (type) {
    case 'normal':
      return 'Conny-Klage'
    case 'state_liability':
      return 'Staatshaftungsklage'
    case 'declaratory':
      return 'Feststellungsklage'
    case 'rvg':
      return 'RVG-Klage'
    default:
      return type
  }
}
