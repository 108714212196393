import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class Select extends Component {
  static displayName = 'Select'
  static propTypes = {
    name: PropTypes.string,
    value: PropTypes.any,
    options: PropTypes.array,
    children: PropTypes.any,
    onChange: PropTypes.func,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    label: PropTypes.string,
  }

  handleChange = (event) => {
    const { value } = event.target
    const { name, onChange } = this.props

    onChange && onChange({ name, value })
  }

  render() {
    const { value, options, name, children, className, disabled, label } =
      this.props

    const itemLabel = label ? label : 'text'
    return (
      <select
        disabled={disabled}
        name={name}
        value={value}
        onChange={this.handleChange}
        className={className}
      >
        {typeof children === 'function'
          ? children()
          : options.map((item) => (
              <option key={item.value} value={item.value}>
                {item[itemLabel]}
              </option>
            ))}
      </select>
    )
  }
}
