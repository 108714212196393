import React from 'react'

import SystemLog from './logtypes/SystemLog'
import Document from './logtypes/Document'
import EmailLog from './logtypes/EmailLog'
import Note from './logtypes/Note'
import Todo from './logtypes/Todo'
import AdminEdited from './logtypes/AdminEdited'
import Payment from './logtypes/Payment'
import EconsultSentInvoice from './logtypes/EconsultSentInvoice'
import AutomaticPhysicalMail from './logtypes/AutomaticPhysicalMail'
import BeaFile from './logtypes/BeaFile'
import AdvoAssist from './logtypes/AdvoAssist'

export default function LogItem(props) {
  const { data, id, open, onClick, previousDate, refetch } = props

  const logComponents = {
    system_log: SystemLog,
    admin_edited: AdminEdited,
    document: Document,
    email_log: EmailLog,
    note: Note,
    ticket: Todo,
    payment: Payment,
    econsult_sent_invoice: EconsultSentInvoice,
    automatic_physical_mail: AutomaticPhysicalMail,
    bea_file: BeaFile,
    advo_assist: AdvoAssist,
  }

  const marginTop = () => {
    const inputDate = moment(data.date)
    const diffDays = previousDate.diff(inputDate, 'days')

    switch (true) {
      case diffDays <= 1:
        return '1'
      case diffDays <= 7:
        return '5'
      case diffDays <= 30:
        return '10'
      case diffDays <= 180:
        return '25'
      default:
        return '40'
    }
  }

  const margin = marginTop()

  const ComponentName = logComponents[data.type] || SystemLog

  return (
    <div
      className="log-item hash-anchor"
      style={{ marginTop: margin + 'px' }}
      name={id}
      id={id}
    >
      <ComponentName
        id={id}
        data={data}
        eventKey={id}
        open={open}
        onClick={onClick}
        refetch={refetch}
      />
    </div>
  )
}
