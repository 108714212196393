import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'

const Select = ({ state, question, handleFormChange, handleBlur }) => {
  function onChange(event) {
    handleFormChange(
      question.name,
      event.target.value,
      isValid(event.target.value),
    )
  }

  function isValid(value) {
    if (isEmpty(value)) {
      return !question.required
    }

    return true
  }
  function onBlur() {
    handleBlur(question.name)
  }

  const value = state.value || ''

  return (
    <div
      className={`form-input form-group form-select ${
        state.showErrors && !isValid(value) ? 'invalid' : ''
      }`}
    >
      <label className="form-select-label" htmlFor={question.name}>
        <span>{question.text}</span>
      </label>
      <div>
        <select
          name={question.name}
          id={question.name.replace('[', '_').replace(']', '')}
          className="form-control"
          onChange={onChange}
          value={value}
          disabled={state.disabled}
          onBlur={onBlur}
        >
          <option value="">Bitte auswählen</option>
          {question.options.map((option) => {
            return (
              <option value={option.name} key={option.name}>
                {option.label}
              </option>
            )
          })}
        </select>
      </div>
      <div className="validation-message">Bitte füllen Sie dieses Feld aus</div>
    </div>
  )
}

Select.propTypes = {
  question: PropTypes.object.isRequired,
  handleFormChange: PropTypes.func,
  handleBlur: PropTypes.func,
  state: PropTypes.object,
}

export default Select
