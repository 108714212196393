import { useContext } from 'react'

import Context from '../context/Context'
import { changePage } from '../ducks/documentViewer'

export default function useDocumentViewer() {
  const { state, dispatch } = useContext(Context)
  const { pageNumber } = state.documentViewer

  const change = (payload) => dispatch(changePage(payload))

  return {
    pageNumber,
    change,
  }
}
