import Operation from '../utils/operation'

describe('Correct Operations', () => {
  JSON
  const opJson = {
    operator: '<',
    operand: '5',
    field: 'user.age',
  }
  test('new isntance', () => {
    let operation = Operation.fromJSON(opJson)
    expect(operation.operator).toBe('<')
    expect(operation.operand).toBe('5')
    expect(operation.field).toBe('user.age')
  })

  test('deserialize from json', () => {
    let operation = Operation.fromJSON(opJson)
    expect(operation.operator).toBe('<')
    expect(operation.operand).toBe('5')
    expect(operation.field).toBe('user.age')
  })

  test('deserialize json array', () => {
    let jsonArray = [opJson, opJson]
    let operationsArray = Operation.fromJsonArray(jsonArray)
    expect(operationsArray[0]).toBeInstanceOf(Operation)
  })

  test('sample run', () => {
    let operation = Operation.fromJSON(opJson)
    JSON
    const resJson = {
      'user.age': 4,
    }
    expect(operation.returns(resJson)).toBeTruthy()
  })
})

describe('invalid operations', () => {
  JSON
  const opJson = {
    operator: '<<',
    operand: '5',
    field: 'user.age',
  }
  let operation = Operation.fromJSON(opJson)
  test('illegal operator', () => {
    JSON
    const resJson = {
      'user.age': 4,
    }
    expect(operation.returns(resJson)).toBeFalsy()
  })
})

describe('invalid', () => {
  JSON
  const opJson = {
    operator: '<',
    operand: '5',
    field: 'user.age',
  }
  let operation = Operation.fromJSON(opJson)
  test('calculated false', () => {
    JSON
    const resJson = {
      'user.age': 6,
    }
    expect(operation.returns(resJson)).toBeFalsy()
  })
})

describe('equal operator for date ranges', () => {
  JSON
  const resJson = {
    'construction.dates': '1950-01-01;1964-12-31',
  }

  test('both operands are date ranges', () => {
    JSON
    const opJson = {
      operator: '==',
      operand: '1950-01-01;1964-12-31',
      field: 'construction.dates',
    }
    let operation = Operation.fromJSON(opJson)
    expect(operation.returns(resJson)).toBeTruthy()
  })

  test('existence of a date in a range', () => {
    JSON
    const opJson = {
      operator: '==',
      operand: '1960-01-01',
      field: 'construction.dates',
    }
    let operation = Operation.fromJSON(opJson)
    expect(operation.returns(resJson)).toBeTruthy()
  })

  test('operand neither a date nor a range', () => {
    JSON
    const opJson = {
      operator: '==',
      operand: 'abc',
      field: 'construction.dates',
    }
    let operation = Operation.fromJSON(opJson)
    expect(operation.returns(resJson)).toBeFalsy()
  })
})

describe('less operator for date ranges', () => {
  JSON
  const resJson = {
    'construction.dates': '1950-01-01;1964-12-31',
  }

  test('if both are ranges, compares the FROM parts', () => {
    JSON
    const opJson = {
      operator: '<',
      operand: '1960-01-01;1964-12-31',
      field: 'construction.dates',
    }
    let operation = Operation.fromJSON(opJson)
    expect(operation.returns(resJson)).toBeTruthy()
  })

  test('if one a range, compares the FROM part of range and a date', () => {
    JSON
    const opJson = {
      operator: '<',
      operand: '1955-01-01',
      field: 'construction.dates',
    }
    let operation = Operation.fromJSON(opJson)
    expect(operation.returns(resJson)).toBeTruthy()
  })

  test('operand neither a date nor a range', () => {
    JSON
    const opJson = {
      operator: '<',
      operand: 'Hello',
      field: 'construction.dates',
    }
    let operation = Operation.fromJSON(opJson)
    expect(operation.returns(resJson)).toBeFalsy()
  })
})

describe('greator operator for date ranges', () => {
  JSON
  const resJson = {
    'construction.dates': '1950-01-01;1964-12-31',
  }

  test('if both are ranges, compares the TO parts', () => {
    JSON
    const opJson = {
      operator: '>',
      operand: '1960-01-01;1962-12-31',
      field: 'construction.dates',
    }
    let operation = Operation.fromJSON(opJson)
    expect(operation.returns(resJson)).toBeTruthy()
  })

  test('if one a range, compares the TO part of range and a date', () => {
    JSON
    const opJson = {
      operator: '>',
      operand: '1955-01-01',
      field: 'construction.dates',
    }
    let operation = Operation.fromJSON(opJson)
    expect(operation.returns(resJson)).toBeTruthy()
  })

  test('operand neither a date nor a range', () => {
    JSON
    const opJson = {
      operator: '>',
      operand: 'Hello',
      field: 'construction.dates',
    }
    let operation = Operation.fromJSON(opJson)
    expect(operation.returns(resJson)).toBeFalsy()
  })
})

describe('involve operator for date ranges', () => {
  JSON
  const resJson = {
    'construction.dates': '1950-01-01;1964-12-31',
  }

  test('existence of a date in a range', () => {
    JSON
    const opJson = {
      operator: 'include?',
      operand: '1960-01-01',
      field: 'construction.dates',
    }
    let operation = Operation.fromJSON(opJson)
    expect(operation.returns(resJson)).toBeTruthy()
  })
})
