/* eslint react/prop-types: 0 */
import React from 'react'

import { StyledTab } from './styles/tab'

const Tab = (props) => {
  const { index, onSelect, children, selectedIndex } = props

  const handleClick = () => {
    const { value } = props
    onSelect && onSelect(index, value)
  }

  const isActive = index === selectedIndex ? true : false

  return (
    <StyledTab onClick={handleClick} isActive={isActive} {...props}>
      {children}
    </StyledTab>
  )
}

export default Tab
