import React from 'react'
import useDocumentViewer from '../useDocumentViewer'

describe('useDocumentViewer', () => {
  let dispatch
  beforeEach(() => {
    dispatch = jest.fn()
    React.useContext = jest.fn(() => ({
      dispatch,
      state: { documentViewer: { pageNumber: 1 } },
    }))
  })

  afterEach(() => {
    dispatch.mockClear()
    React.useContext.mockReset()
  })

  describe('useDocumentViewer properties', () => {
    test('should return pageNumber properties', () => {
      expect(useDocumentViewer()).toHaveProperty('pageNumber')
      expect(useDocumentViewer()).toHaveProperty('change')
    })
  })

  describe('useDocumentViewer: change', () => {
    test('should call dispatch change once', () => {
      const { change } = useDocumentViewer()
      change({})
      expect(dispatch.mock.calls.length).toEqual(1)
    })
  })
})
