import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import Section from './Section'
import { format, transformChangesValue } from './helpers'

// Add here the names of the pages which have to editing disabled.
// There used to be 'rechnung' here
const EDITING_DISABLED_PAGES = []

export default class ContentPage extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    sections: PropTypes.array.isRequired,
    changes: PropTypes.object.isRequired,
    requestId: PropTypes.number.isRequired,
    productName: PropTypes.string.isRequired,
    token: PropTypes.string,
  }

  state = {
    data: {},
    changes: {},
    sections: [],
    responses: {},
  }

  getSectionFieldsData = (section, data, changes) => {
    return section.fields.reduce((acc, field) => {
      let result = {}
      if (field.type === 'table-scaffold') {
        result = {
          tableScaffordId: field.id,
        }
      } else if (field.type === 'display_field') {
        const value = data[field.data.placeholder]
        result = {
          rhs: field.rhs,
          text: format(typeof value)(value),
          name: field.data.title + field.data.placeholder,
          label: field.data.title,
          infobox: field.infobox,
          hasEvidence: field.has_evidence,
        }
      } else if (field.type === 'question') {
        const value =
          field.data.question_type === 'number'
            ? format('number')(data[field.data.field])
            : data[field.data.field]

        const changesValue =
          (changes === undefined ? {} : changes[field.data.field]) || {}
        transformChangesValue(changesValue, field.data)

        result = {
          rhs: field.rhs,
          type: field.data.question_type,
          name: field.data.field,
          changes: changesValue,
          text: format(field.data.question_type)(
            data[field.data.field],
            field.data.options,
          ),
          label: field.data.internal_name,
          value: value,
          options: field.data.options,
          required: field.required,
          infobox: field.infobox,
          defaultDisabled: field.default_disabled,
          dependencies: field.dependencies,
          hasEvidence: field.has_evidence,
          validations: field.data.validations,
        }
      }

      acc[result.name] = result
      return acc
    }, {})
  }

  isSectionDisabled = () => {
    const { title } = this.props

    return EDITING_DISABLED_PAGES.includes(title.toLowerCase())
  }

  componentDidMount() {
    const { data, sections, changes } = this.props

    const responses = Object.keys(data).reduce((acc, key) => {
      if (data[key] === '') return { ...acc }
      else return { ...acc, [key]: data[key] }
    }, {})

    this.setState({ data, sections, responses, changes })
  }

  handleSectionSuccessSubmit = () => {
    const { id, requestId } = this.props

    fetch(`/admin/v2/requests/${requestId}/content_pages/${id}.json`, {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
      },
    })
      .then((response) => response.json())
      .then(({ data, sections, changes }) =>
        this.setState({ data, sections, changes }),
      )
  }

  handleResponcesChange = (name, value) => {
    this.setState({ responses: { ...this.state.responses, [name]: value } })
  }

  render() {
    const { data, sections, responses, changes } = this.state
    const { requestId, productName, token } = this.props

    const responsesData = Object.keys(responses).reduce((acc, key) => {
      return { ...acc, [key.replace(/\./g, '-')]: responses[key] }
    }, {})

    return (
      <Fragment>
        {sections.map((section) => (
          <Section
            id={section.id}
            key={section.id}
            title={section.title}
            disabled={this.isSectionDisabled()}
            requestId={requestId}
            fieldsData={this.getSectionFieldsData(section, data, changes)}
            productName={productName}
            onSuccessSubmit={this.handleSectionSuccessSubmit}
            fieldType={section.fields[0].type}
            token={token}
            responses={responsesData}
            onResponcesChange={this.handleResponcesChange}
          />
        ))}
      </Fragment>
    )
  }
}
