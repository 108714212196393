import { DependencyResolver } from '../utils/dependencyResolver'

describe('dependency resolved', () => {
  JSON
  const dep = [
    {
      conditions: [
        {
          operator: '>',
          operand: '5',
          field: 'user.age',
        },
        {
          operator: 'present?',
          field: 'user.name',
        },
      ],
      action: 'disable',
      priority: 1,
    },
    {
      conditions: [
        {
          operator: '>',
          operand: '5',
          field: 'req.page',
        },
        {
          operator: 'blank?',
          field: 'req.game',
        },
      ],
      action: 'enable',
      priority: 2,
    },
    {
      conditions: [
        {
          operator: '>',
          operand: '5',
          field: 'reqe.page',
        },
        {
          operator: 'blank?',
          field: 'reqe.game',
        },
      ],
      action: 'hide',
      priority: 3,
    },
  ]
  it('correctly', () => {
    let dr = new DependencyResolver(dep)
    const resJson = {
      'user.age': 4,
      'user.name': 'Yodo',
      'req.page': 6,
      'req.gamed': 2,
    }
    expect(dr.isEnable(resJson)).toBeTruthy()
  })
})

describe('dependency including count of fields', () => {
  JSON
  const resJson = {
    'user.robot.age': 4,
    'user.robot.j.name': 'Katbot',
    'user.robot.j.legs': 2,
    'req.gamed': 2,
  }

  it('resolves the scope correctly to true', () => {
    const dep = [
      {
        conditions: [
          {
            operator: '>',
            operand: '2',
            field: 'count',
          },
        ],
        action: 'disable',
        priority: 1,
        scope: 'user.robot',
      },
    ]
    let dr = new DependencyResolver(dep)
    expect(dr.isDisable(resJson)).toBeTruthy()
  })
  it('resolves the scope correctly to false', () => {
    const dep = [
      {
        conditions: [
          {
            operator: '>',
            operand: '2',
            field: 'count',
          },
        ],
        action: 'disable',
        priority: 1,
        scope: 'user.robot.j',
      },
    ]
    let dr = new DependencyResolver(dep)
    expect(dr.isDisable(resJson)).toBeFalsy()
  })
})
