/**
 * @jest-environment jsdom
 */

import React from 'react'
import { render, fireEvent } from '@testing-library/react'

import SubTopic from './Subtopic'
import { TopicContext } from './Topic'
import { MailerAppContainerContext } from '../MailerApp/MailerAppContainer'
import client from '../../../../client'

const partialText = 'partial-text'
document.execCommand = jest.fn()
const mockedPostRequest = (client.post = jest.fn())

function example() {
  return (
    <MailerAppContainerContext.Provider value="request-id">
      <TopicContext.Provider value={{ partialName: 'partial-name' }}>
        <SubTopic
          partialId="partial-id"
          name="partial-name"
          text={partialText}
        />
      </TopicContext.Provider>
      <textarea data-testid="textarea"></textarea>
    </MailerAppContainerContext.Provider>
  )
}

test.skip('renders the content with name', () => {
  const { getByText } = render(example())

  getByText('partial-name')
})

test.skip('Copies text into clipboard', () => {
  const { getByTestId } = render(example())
  const textarea = getByTestId('textarea')

  mockedPostRequest.mockResolvedValueOnce({ ok: true })

  fireEvent.click(getByTestId('copy-link'))
  fireEvent.paste(textarea, { target: { value: partialText } })

  expect(textarea.value).toBe(partialText)
})

test.skip('Clicking to partial should send tracking request', () => {
  const { getByTestId } = render(example())
  mockedPostRequest.mockResolvedValueOnce({ ok: true })

  fireEvent.click(getByTestId('copy-link'))

  expect(mockedPostRequest).toHaveBeenCalled()
})
