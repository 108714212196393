const chunkWithPosition = (array) => {
  let result = []
  let index = 0
  while (index < array.length) {
    const nextItem = array[index + 1]
    const currentItem = array[index]

    if (currentItem.rhs) {
      result = [...result, {}, currentItem]
      index += 1
      continue
    }

    if (!currentItem.rhs && (nextItem || {}).rhs) {
      result = [...result, currentItem, nextItem]
      index += 2
      continue
    }

    result = [...result, currentItem, {}]
    index += 1
  }

  return result
}

export { chunkWithPosition }
