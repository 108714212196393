import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class Checkbox extends Component {
  static displayName = 'Checkbox'

  static propTypes = {
    name: PropTypes.any,
    value: PropTypes.any,
    onChange: PropTypes.func,
    className: PropTypes.string,
    disabled: PropTypes.bool,
  }

  handleChange = (event) => {
    const { checked } = event.target
    const { name, onChange } = this.props

    onChange && onChange({ name, value: checked })
  }

  render() {
    const { name, value, className, disabled, ...props } = this.props

    return (
      <input
        {...props}
        type="checkbox"
        name={name}
        onChange={this.handleChange}
        className={className}
        checked={value}
        disabled={disabled}
      />
    )
  }
}
