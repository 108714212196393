import React from 'react'
import PropTypes from 'prop-types'

const ChangeLogItem = (props) => {
  const { changed_from, author, changed_at } = props
  return (
    <div className="viewtab__changelog-list-item">
      <div className="viewtab__changelog-item-title">
        <span className="viewtab__changelog-prefix">Vorheriger Wert: </span>
        <span className="viewtab__changelog-value">{changed_from}</span>
      </div>
      <div className="viewtab__changelog-item-date">
        {`Geändert von ${author ? author : 'unbekannt'} | ${changed_at}`}
      </div>
    </div>
  )
}

const ChangeLog = (props) => {
  const { logs } = props
  if (!logs || !logs.length) return null

  return (
    <div className="viewtab__changelog-list">
      {logs.map((log, index) => (
        <ChangeLogItem key={index} {...log} />
      ))}
    </div>
  )
}

export default ChangeLog

ChangeLog.propTypes = {
  logs: PropTypes.array,
}

ChangeLogItem.propTypes = {
  changed_from: PropTypes.any,
  changed_at: PropTypes.string,
  author: PropTypes.string,
}
