import styled from 'styled-components'

export const Container = styled.div`
  padding: 1rem;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: 2.5rem 2.5rem 2.5rem 2.5rem 1rem 2rem;
  grid-gap: 0.5rem 1rem;
`

export const Label = styled.label`
  grid-column: 1 / 2;
  color: white;
  font-size: 0.9rem;
`
export const InputBox = styled.div`
  grid-column: 2 / 4;
`

export const SubmitMessage = styled.p`
  grid-column: 1 /4;
  font-size: 0.8rem;
  font-weight: 600;
  color: ${(props) => (props.color === 'success' ? '#2ecc71' : '#e82201')};
`

export const Button = styled.button`
  grid-column: 3 / 4;
  grid-row: 6 / 7;
  justify-self: end;
  background-color: #2ecc71;
  color: #f7f7f9;
  border-radius: 20px;
  border: 1px solid #2ecc71;
  font-weight: 600;
  font-size: 0.8rem;
  padding: 0.375rem 1rem;
  width: 120px;

  &:hover:not(:disabled) {
    filter: brightness(80%);
  }

  &:active:not(:disabled) {
    filter: brightness(60%);
  }

  &:disabled {
    opacity: 0.65;
  }
`
