import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FlipMove from 'react-flip-move'

export default class BoardSection extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    onShuffle: PropTypes.func.isRequired,
  }

  renderRow = (item) => {
    return (
      <p key={item.name} className="d-flex">
        <span className="teammember-name">{item.name}: </span>
        <span className="ms-3 teammember-number">{item.total_done}</span>
      </p>
    )
  }

  render() {
    const { data } = this.props

    return (
      <div className="teamsection">
        <div className="teamsection-header d-flex justify-content-between align-items-end">
          <h2>{data.name}</h2>
          <div className="d-flex">
            <p>
              Done: <br /> <span className="team-done">{data.total_done}</span>
            </p>
            <p>
              Due: <br />{' '}
              <span className="team-due">&nbsp;/&nbsp;{data.total_due}</span>
            </p>
          </div>
        </div>
        <div className="teammember-wrapper">
          <div className="teammember">
            <FlipMove easing="ease-out">
              {data.members.map((item) => this.renderRow(item))}
            </FlipMove>
          </div>
        </div>
      </div>
    )
  }
}
