import React from 'react'
import PropTypes from 'prop-types'

const Clipboard = (props) => (
  <span
    className="clipboard"
    data-clipboard-text={props.value}
    data-bs-toggle="tooltip"
    data-bs-placement="top"
    title="kopieren"
  >
    <i className="far fa-clipboard question-clipboard"></i>
  </span>
)

Clipboard.propTypes = {
  value: PropTypes.string.isRequired,
}

export default Clipboard
