import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import Select from 'react-select'
import client from '../../../../../client'

const Assignemnt = (props) => {
  const { requestId } = props
  const [agentName, setAgentName] = useState(props.agentName)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedAgent, setSelectedAgent] = useState({
    value: '',
    label: 'unassigned',
  })
  const [options, setOptions] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  const submit = () => {
    client
      .post(
        `/admin/requests/${requestId}/assignment`,
        {
          assignment: {
            agent_id: selectedAgent.value,
          },
        },
        { Accept: 'application/json' },
      )
      .then((response) => {
        if (!response.ok) {
          alert('something went wrong')
        }
        return response.json()
      })
      .then((data) => {
        setAgentName(data.agent_name)
      })
  }
  const loadOptions = () => {
    client
      .get('/admin/v2/agent/team_members', null, { Accept: 'application/json' })
      .then((response) => response.json())
      .then((data) => {
        setOptions(
          data.map((data) => {
            return {
              label: data[0],
              options: data[1].map((item) => {
                return { label: item[0], value: item[1] }
              }),
            }
          }),
        )
        setIsLoading(false)
      })
  }
  useEffect(loadOptions, [])

  return (
    <>
      <button className="casehandler" onClick={toggleModal}>
        <p className="casehandler__text">Verantwortlicher: {agentName} </p>
      </button>
      <Modal size="lg" show={isModalOpen} onHide={toggleModal}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Select
            isClearable
            isSearchable
            options={options}
            isLoading={isLoading}
            value={selectedAgent}
            onChange={(selected) => {
              setSelectedAgent(selected || { value: '', label: 'unassigned' })
            }}
          />
          <Modal.Footer>
            <div className="bea-ui__modal-footer">
              <div>
                <button
                  className="btn btn-outline-secondary btn-rounded mt-0"
                  onClick={toggleModal}
                >
                  Abbrechen
                </button>
                <button
                  className="btn btn-primary btn-rounded mx-3 mt-0"
                  onClick={() => {
                    submit()
                    toggleModal()
                  }}
                >
                  {' '}
                  Zuweisen
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </>
  )
}
export default Assignemnt
