import React, { ReactNode, useReducer } from 'react'
import { feedbackReducer, FeedbackContext } from './feedbacksStore'

interface IProps {
  children: ReactNode
}

export default function FeedbackProvider({ children }: IProps) {
  const [state, dispatch] = useReducer(feedbackReducer, {})

  return (
    <FeedbackContext.Provider value={[state, dispatch]}>
      {children}
    </FeedbackContext.Provider>
  )
}
