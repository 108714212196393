import React from 'react'
import PropTypes from 'prop-types'

import Tooltip from '../engine-ui/Tooltip'
import { getDocumentUrl } from '../DocumentsTab/helpers'
import { formatDateShort } from '../engine-ui/helpers/format'

export default function EvidenceRow(props) {
  const { file, requestId, onFileRemoval, question_id, index } = props

  const handleFileRemoval = (fileId) => {
    if (
      window.confirm(
        'Sind Sie sicher, dass Sie diesen Beweis entfernen wollen?',
      )
    ) {
      onFileRemoval(question_id, fileId)
    }
  }
  return (
    <tr className="tab-table-row status-tab__row">
      <td>#{index + 1}</td>
      <td>{formatDateShort(file.marked_as_evidence_at)}</td>
      <td className="tab-table__row--wide">{file.filename}</td>
      <td className="tab-table__row--wide">{file.type}</td>
      <td>{file.uploaded_by}</td>
      <td>
        <Tooltip text="Beweis ansehen" placement="top">
          <a
            href={`${getDocumentUrl(requestId, file.id)}?inline=true`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fas fa-external-link-alt icon-btn" />
          </a>
        </Tooltip>
        <Tooltip text="Beweis herunterladen" placement="top">
          <a
            href={`${getDocumentUrl(requestId, file.id)}?inline=false`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-download ms-3 icon-btn"></i>
          </a>
        </Tooltip>
        <Tooltip text="Beweis entfernen" placement="top">
          <i
            className="fas fa-times ms-3 icon-btn"
            onClick={() => handleFileRemoval(file.id)}
          />
        </Tooltip>
      </td>
    </tr>
  )
}

EvidenceRow.propTypes = {
  index: PropTypes.number,
  file: PropTypes.object,
  requestId: PropTypes.number,
  onFileRemoval: PropTypes.func,
  question_id: PropTypes.string,
}
