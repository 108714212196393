export default {
  endpoint(token) {
    return `/api/requests/${token}/documents`
  },

  handleError(response) {
    var error = new Error(response.statusText)
    error.response = response
    throw error
  },

  fetchDocuments(token, auth_token) {
    return fetch(this.endpoint(token), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        token: auth_token,
      },
    }).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.json()
      }

      return this.handleError(response)
    })
  },

  create({ objectKey, documentType, token, auth_token }) {
    const params = {
      object_key: objectKey,
      document_type: documentType,
    }

    return fetch(this.endpoint(token), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        token: auth_token,
      },
      body: JSON.stringify(params),
    }).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.json()
      }

      return this.handleError(response)
    })
  },

  delete(token, id, auth_token) {
    return fetch(`${this.endpoint(token)}/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        token: auth_token,
      },
    }).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return
      }

      return this.handleError(response)
    })
  },
}
