import React from 'react'
import PropTypes from 'prop-types'

const Modal = ({
  id,
  header,
  content,
  dismissButton,
  confirmButton,
  confirmCallback,
}) => {
  const handleConfirm = () => {
    $(`#${id}`).modal('hide')
    confirmCallback()
  }

  let headerBlock = null
  if (header) {
    headerBlock = (
      <div className="modal-header">
        <h5 className="modal-title" id={id}>
          {header}
        </h5>
      </div>
    )
  }

  let contentBlock = null
  if (content) {
    contentBlock = (
      <div className="modal-body">
        <p>{content}</p>
      </div>
    )
  }

  return (
    <div
      className="modal fade"
      id={id}
      tabIndex="-1"
      role="dialog"
      aria-labelledby={id}
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          {headerBlock}
          {contentBlock}
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light data-bs-dismiss"
              data-bs-dismiss="modal"
            >
              {dismissButton}
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleConfirm}
            >
              {confirmButton}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

Modal.propTypes = {
  id: PropTypes.string.isRequired,
  header: PropTypes.string,
  content: PropTypes.string,
  dismissButton: PropTypes.string,
  confirmButton: PropTypes.string,
  confirmCallback: PropTypes.func,
}

Modal.defaultProps = {
  dismissButton: 'Close',
  confirmButton: 'Yes',
}

export default Modal
