import { useContext } from 'react'

import Context from '../context/Context'
import { initializeDocument, changeDocument } from '../ducks/document'

export default function useDocument() {
  const { state, dispatch } = useContext(Context)
  const { document } = state

  const initialize = (payload) => dispatch(initializeDocument(payload))
  const change = (payload) => dispatch(changeDocument(payload))

  return {
    document,
    initializeDocument: initialize,
    changeDocument: change,
  }
}
