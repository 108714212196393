import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '../engine-ui/Button'
import Clipboard from '../engine-ui/Clipboard'
import Tooltip from '../engine-ui/Tooltip'
import classNames from 'classnames'

export default class TodoBody extends Component {
  static propTypes = {
    todo: PropTypes.object,
    id: PropTypes.string,
    requestId: PropTypes.string,
    comment: PropTypes.string,
    author: PropTypes.string,
    assignee: PropTypes.string,
    todoCount: PropTypes.number,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
    onReschedule: PropTypes.func,
    onComplete: PropTypes.func,
    isLoading: PropTypes.bool,
  }

  state = {
    showTooltip: false,
  }

  componentDidMount = () => {
    this.handleTooltip()
    window.addEventListener('resize', this.handleTooltip)
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.handleTooltip)
  }

  handleTooltip = () => {
    if (
      document.getElementsByClassName('ticket_accordion__body-text')[0]
        .scrollHeight > this.calcTextHeight()
    ) {
      this.setState({
        showTooltip: true,
      })
    }
  }

  calcHeightOfBody = () => {
    const containerheight = 222
    const itemHeight = 35 * this.props.todoCount
    const bodyHeight = containerheight - itemHeight

    return bodyHeight
  }

  calcTextHeight = () => {
    const outerHeight = this.calcHeightOfBody()
    const innerHeight = outerHeight - 30

    return innerHeight
  }

  handleDelete = () => {
    this.props.onDelete(this.props.todo)
  }

  handleEdit = () => {
    this.props.onEdit(this.props.todo)
  }

  handleReschedule = () => {
    this.props.onReschedule(this.props.todo)
  }

  handleComplete = () => {
    this.props.onComplete(this.props.todo)
  }

  render() {
    const { id, comment, assignee, author } = this.props.todo

    return (
      <div
        id={id}
        className="body-container"
        style={{ height: `${this.calcHeightOfBody()}px` }}
      >
        <div
          className={classNames({ 'comment-tooltip': this.state.showTooltip })}
        >
          <pre
            className="ticket_accordion__body-text"
            style={{ height: `${this.calcTextHeight()}px` }}
          >
            {comment}
          </pre>
          {comment && <div className="comment-tooltip-show">{comment}</div>}
        </div>
        <div className="d-flex justify-content-between align-items-end">
          <div className="todo-info">
            <span>
              <i className="fas fa-user me-1"></i> Zugewiesen:{' '}
              <strong>{assignee.name}</strong>
            </span>
            <span>
              <i className="far fa-user me-1"></i> Autor:{' '}
              <strong>{author.name}</strong>
            </span>
          </div>
          <div className="todo-action-button">
            <Tooltip text="Todo-Link kopieren" placement="top">
              <button className="todo-action-button-item copy-ticket">
                <Clipboard
                  value={`${window.location.origin}/admin/v2/requests/${this.props.requestId}?active_todo=${id}`}
                />
              </button>
            </Tooltip>
            <Tooltip text="Todo löschen" placement="top">
              <Button
                onClick={this.handleDelete}
                className="todo-action-button-item"
              >
                <i className="fas fa-trash-alt"></i>
              </Button>
            </Tooltip>
            <Tooltip text="Todo bearbeiten" placement="top">
              <Button
                onClick={this.handleEdit}
                className="todo-action-button-item"
              >
                <i className="fas fa-edit"></i>
              </Button>
            </Tooltip>
            <Tooltip text="Todo umplanen" placement="top">
              <Button
                onClick={this.handleReschedule}
                className="todo-action-button-item reschedule-ticket"
              >
                <i className="fas fa-redo-alt"></i>
              </Button>
            </Tooltip>
            <Tooltip text="Todo abhaken" placement="top">
              <Button
                onClick={this.handleComplete}
                className="todo-action-button-done ticket-completed"
                disabled={this.props.isLoading}
              >
                {this.props.isLoading ? (
                  <i className="fas fa-spinner fa-spin"></i>
                ) : (
                  <i className="far fa-check-circle"></i>
                )}
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>
    )
  }
}
