import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'

import Select from '../engine-ui/Select'
import DependentSelect from '../engine-ui/DependentSelect'
import DynamicSelect from '../engine-ui/DynamicSelect'
import Checkbox from '../engine-ui/Checkbox'
import TextArea from '../engine-ui/TextArea'
import TextInput from '../engine-ui/TextInput'
import MultiSelect from '../engine-ui/MultiSelect'
import Autocomplete from '../engine-ui/Autocomplete'
import AdminDatepicker from '../admin_datepicker'

export default class EditCell extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    children: PropTypes.any.isRequired,
    disabled: PropTypes.bool,
    fieldsData: PropTypes.object,
  }

  static Select = Select
  static DependentSelect = DependentSelect
  static DynamicSelect = DynamicSelect
  static Checkbox = Checkbox
  static TextArea = TextArea
  static TextInput = TextInput
  static MultiSelect = MultiSelect
  static Autocomplete = Autocomplete
  static AdminDatepicker = AdminDatepicker

  render() {
    const { type, name, value, onChange, children, disabled } = this.props

    const inputTypes = ['string', 'number', 'email', 'iban', 'phone']
    const defaultProps = { type, name, value, onChange, disabled }

    return (
      <Fragment>
        {React.Children.map(children, (child) => {
          if (type === 'select' && child.type.displayName === 'Select') {
            return React.cloneElement(child, { ...defaultProps })
          }
          if (
            type === 'bank_data' &&
            child.type.displayName === 'DependentSelect'
          ) {
            return React.cloneElement(child, { ...defaultProps })
          }
          if (
            type === 'dynamic_select' &&
            child.type.displayName === 'DynamicSelect'
          ) {
            return React.cloneElement(child, { ...defaultProps })
          }
          if (
            inputTypes.includes(type) &&
            child.type.displayName === 'TextInput'
          ) {
            return React.cloneElement(child, { ...defaultProps })
          }
          if (
            type === 'autocomplete' &&
            child.type.displayName === 'Autocomplete'
          ) {
            return React.cloneElement(child, { ...defaultProps })
          }
          if (type === 'text' && child.type.displayName === 'TextArea') {
            return React.cloneElement(child, { ...defaultProps })
          }
          if (type === 'checkbox' && child.type.displayName === 'Checkbox') {
            return React.cloneElement(child, { ...defaultProps })
          }
          if (type === 'date' && child.type.displayName === 'AdminDatepicker') {
            return React.cloneElement(child, { ...defaultProps })
          }
          if (
            type === 'multi_select' &&
            child.type.displayName === 'MultiSelect'
          ) {
            return React.cloneElement(child, { ...defaultProps })
          }
          if (type === 'yes_no' && child.type.displayName === 'Select') {
            return React.cloneElement(child, {
              ...defaultProps,
              options: [
                { text: '', value: '' },
                { text: 'Ja', value: 'yes' },
                { text: 'Nein', value: 'no' },
              ],
            })
          }
        })}
      </Fragment>
    )
  }
}
