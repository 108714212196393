import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Tooltip from '../../../engine-ui/Tooltip'
import EditCell from '../../../ContentPage/EditCell'
import { format } from '../../../ContentPage/helpers'
import {
  FieldGroup,
  Label,
  InputBox,
  SuggestionSpan,
} from './styles/documentCaseData'
import useDocumentViewer from '../../hooks/useDocumentViewer'

export default function Field(props) {
  const [hover, setHover] = useState(false)
  const { field, values, onChange, onBatchChange, errors, isStepRent, token } =
    props
  const { data, required, suggestion } = field
  const { change: changePage } = useDocumentViewer()

  const getSuggestionValue = () => {
    if (suggestion?.value && data.question_type == 'date') {
      return moment.utc(suggestion.value).format()
    } else if (suggestion?.value && data.question_type == 'number') {
      return format('number')(suggestion.value)
    }
    return suggestion?.value
  }

  const suggestionValue = getSuggestionValue()
  const editableValue = (values[data?.field] || {}).value || ''
  const value = hover && suggestionValue ? suggestionValue : editableValue

  const handleChange = (event) => {
    const validations = data.validations || []
    onChange(event, data.question_type, required, validations)
  }

  const handleBatchChange = (updatedFields) => {
    onBatchChange(updatedFields)
  }

  const handleClick = () => {
    const event = suggestionValue
      ? { name: data.field, value: suggestionValue }
      : { name: data.field, value: editableValue }

    onChange(event, data.question_type, required)
  }

  const handleHover = () => {
    setHover(true)
    if (suggestion.page) {
      changePage(suggestion.page)
    }
  }
  const changed = (values[data?.field] || {}).pristine === false

  return Object.keys(field).length === 0 ? (
    <FieldGroup isStepRent={isStepRent} />
  ) : (
    <FieldGroup isStepRent={isStepRent}>
      <Label>{data.body}</Label>
      <InputBox>
        <div className="field form-group input-group">
          <EditCell
            type={data.question_type}
            name={data.field}
            value={value}
            onChange={handleChange}
          >
            <EditCell.Autocomplete
              value={value}
              options={data.options}
              error={errors[data.name]}
              onChangeBatch={handleBatchChange}
              className="w-100"
              token={token}
            />
            <EditCell.TextInput
              error={errors[data.field]}
              className="form-control"
            />
            <EditCell.Checkbox />
            <EditCell.TextArea rows={2} className="form-control" />
            <EditCell.MultiSelect
              options={data.options}
              className="d-flex mb-3 align-items-center multi-select df-fields"
              labelClassName="ms-2 mb-0 df_label"
            />
            <EditCell.Select
              options={[{ text: '', value: '' }, ...(data.options || [])]}
              className="form-control"
            />
            <EditCell.AdminDatepicker
              past
              question={{ name: data.field }}
              value={value}
              className="df_datepicker"
              inputClassName="zieb_datepicker w-100"
            />
          </EditCell>
          {suggestion && suggestion.icon ? (
            <div
              className={
                data.question_type === 'checkbox'
                  ? 'ms-2'
                  : 'input-group-append'
              }
            >
              <SuggestionSpan
                className="input-group-text"
                onMouseEnter={handleHover}
                onMouseLeave={() => setHover(false)}
                onClick={handleClick}
                backgroundColor={changed ? 'success' : suggestion.icon?.name}
              >
                <Tooltip text={suggestion.icon?.hint} placement="top">
                  <i className={`${suggestion.icon?.icon} text-white`} />
                </Tooltip>
              </SuggestionSpan>
            </div>
          ) : null}
        </div>
      </InputBox>
    </FieldGroup>
  )
}

Field.propTypes = {
  field: PropTypes.object,
  values: PropTypes.object,
  onChange: PropTypes.func,
  onBatchChange: PropTypes.func,
  errors: PropTypes.object,
  isStepRent: PropTypes.bool,
  token: PropTypes.string,
}
