import React, { Component } from 'react'
import classNames from 'classnames'
import client from '../../../../client'
import Select from '../engine-ui/Select'
import Button from '../engine-ui/Button'
import TextInput from '../engine-ui/TextInput'
import Tooltip from '../engine-ui/Tooltip'
import DateCell from './DocumentRowCells/DateCell'
import FilenameCell from './DocumentRowCells/FilenameCell'

import moment from 'moment'
import PropTypes from 'prop-types'

const COURT_PROCESSING_GROUPS = {
  'K1-K10': ['K1', 'K2', 'K3', 'K4', 'K5', 'K6', 'K7', 'K8', 'K9', 'K10'],
  'B1-B10': ['B1', 'B2', 'B3', 'B4', 'B5', 'B6', 'B7', 'B8', 'B9', 'B10'],
  'ASt1-ASt10': [
    'ASt1',
    'ASt2',
    'ASt3',
    'ASt4',
    'ASt5',
    'ASt6',
    'ASt7',
    'ASt8',
    'ASt9',
    'ASt10',
  ],
  'Ag1-Ag10': [
    'Ag1',
    'Ag2',
    'Ag3',
    'Ag4',
    'Ag5',
    'Ag6',
    'Ag7',
    'Ag8',
    'Ag9',
    'Ag10',
  ],
  'R1-R10': ['R1', 'R2', 'R3', 'R4', 'R5', 'R6', 'R7', 'R8', 'R9', 'R10'],
}

const DIGITAL_OUTBOX_OPTIONS = [
  { value: 'normal_postage', text: 'Normalporto (E)' },
  { value: 'registered_post', text: 'Einwurfeinschreiben (E)' },
  { value: 'personal_registered_post', text: 'Einschreiben eigenhändig' },
  { value: 'bea', text: 'beA' },
  { value: 'normal_postage_o', text: 'Normalporto (O)' },
  { value: 'registered_post_o', text: 'Einwurfeinschreiben (O)' },
]

export default class DocumentRow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sentAsPost: props.item.physical_mails.length != 0,
      postGroup: '',
      comment: '',
      sendType: '',
      physicalMails: props.item.physical_mails,
      editingGroup: false,
      editingComment: false,
      courtProcessingKind: props.item.court_processing_kind,
      courtId: props.item.court_id,
      filename: props.item.filename,
      isAddingNewPhysicalMail: false,
      newPhysicalMail: {
        comment: '',
        postGroup: '',
        sendType: DIGITAL_OUTBOX_OPTIONS[0].value,
      },
    }
  }

  dateFormatShort = moment(this.props.item.created_at)
    .locale('de')
    .format('DD.MM.YYYY')

  sendTypeText = (currentType) => {
    return DIGITAL_OUTBOX_OPTIONS.find((opt) => opt.value == currentType).text
  }

  handleCommentChange = (e) =>
    this.setState({
      newPhysicalMail: { ...this.state.newPhysicalMail, comment: e.value },
    })
  handleSelectChange = (e) => {
    const { token } = this.props
    const headers = {
      token: token,
    }
    client
      .patch(
        `/api/admin/requests/${this.props.requestId}/documents/${this.props.item.id}.json`,
        { document: { court_processing_kind: e.value } },
        headers,
      )
      .then((r) => r.json())
      .then((data) =>
        this.setState({
          courtProcessingKind: data.court_processing_kind,
          filename: data.filename,
        }),
      )
  }

  renderCourtKindSelect = () => {
    const selectOptions = []

    for (const [key, value] of Object.entries(COURT_PROCESSING_GROUPS)) {
      const options = value.map((kind) => {
        return (
          <option key={kind} value={kind}>
            {kind}
          </option>
        )
      })
      selectOptions.push(<optgroup key={key} label={key}></optgroup>)
      selectOptions.push(...options)
    }

    const options = () => [
      <option key={-1} value="">
        -
      </option>,
      ...selectOptions,
    ]

    return (
      <Select
        name="court-kind-select"
        onChange={this.handleSelectChange}
        value={this.state.courtProcessingKind || ''}
      >
        {options}
      </Select>
    )
  }

  renderLink = () => {
    return (
      <FilenameCell
        document={this.props.item}
        filename={this.state.filename}
        requestId={this.props.requestId}
      />
    )
  }

  cancelPhysicalSend = (mail) => {
    fetch(
      `/admin/requests/${this.props.requestId}/physical_mails/${mail.id}?document_id=${this.props.item.id}`,
      {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
        },
      },
    )
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          sentAsPost: response.physical_mails.length != 0,
          physicalMails: response.physical_mails,
        })
      })
  }

  handleCourtIdChange = (document) => (e) => {
    const payload = {
      agent_email: this.props.agent.email,
      document: {
        court_id: e.value,
      },
    }
    client
      .patch(`/api/zieb/documents/${document.id}`, payload, {
        token: this.props.ziebToken,
      })
      .then((response) => {
        if (!response.ok || response.status !== 200) {
          alert('Wir konnten das Aktenzeichen nicht speichern')
          return
        }
        return response.json()
      })
      .then((data) => {
        this.setState({
          courtId: data.document.court_id,
        })
      })
  }
  renderCourtId = (document) => {
    const options = [
      ...this.props.courtIds
        .filter((id) => id !== 'kein Aktenzeichen' && id !== 'keinAktenzeichen')
        .map((id) => {
          return { value: id, text: id }
        }),
      { value: 'keinAktenzeichen', text: 'kein Aktenzeichen' },
    ]
    if (!this.state.courtId) {
      options.unshift({ value: '-', text: '-' })
    }
    return (
      <Select
        name="court-id-select"
        value={this.state.courtId || '-'}
        onChange={this.handleCourtIdChange(document)}
        options={options}
      ></Select>
    )
  }

  renderUncategorizedRow = () => {
    const { item } = this.props
    return (
      <tr className="tab-table-row">
        <td>{this.renderLink()}</td>
        <td>{item.type}</td>
        <td>
          {item.incoming ? (
            <Tooltip text="eingehend" placement="top">
              <i className="fas fa-sign-in-alt text-success" />
            </Tooltip>
          ) : (
            <Tooltip text="ausgehend" placement="top">
              <i className="fas fa-sign-out-alt fa-rotate-180 text-danger" />
            </Tooltip>
          )}
        </td>
        <td>{this.dateFormatShort}</td>
        <td> {this.renderCourtId(item)}</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td className="doctab-row-numbers">
          <i className="fas fa-times"></i>
        </td>
      </tr>
    )
  }

  renderRowsForPhysicalMails = () => {
    const existingRows = this.state.physicalMails.map((mail, index) => {
      const isFirstItem = index == 0
      return this.renderTableRow({ mail, isFirstItem })
    })

    const rowForNewPhysicalMail = this.state.isAddingNewPhysicalMail
      ? this.renderEditableTableRow()
      : null
    return [...existingRows, rowForNewPhysicalMail]
  }

  renderTableRow = ({ mail, isFirstItem }) => {
    const cancelAction = () => this.cancelPhysicalSend(mail)
    const documentIndex = this.props.index
    const index = `d${documentIndex}m${mail.id}`
    const { item } = this.props
    const rowFormToggle = this.state.isAddingNewPhysicalMail ? (
      <Button onClick={() => this.setState({ isAddingNewPhysicalMail: false })}>
        <i className="fas fa-minus green"></i>
      </Button>
    ) : (
      <Button onClick={() => this.setState({ isAddingNewPhysicalMail: true })}>
        <i className="fas fa-plus green"></i>
      </Button>
    )

    return (
      <tr className="tab-table-row">
        <td className={classNames('docs-link')}>
          {isFirstItem && this.renderLink()}
        </td>
        <td>{this.props.item.type}</td>
        <td>
          {item.incoming ? (
            <Tooltip text="eingehend" placement="top">
              <i className="fas fa-sign-in-alt text-success" />
            </Tooltip>
          ) : (
            <Tooltip text="ausgehend" placement="top">
              <i className="fas fa-sign-out-alt fa-rotate-180 text-danger" />
            </Tooltip>
          )}
        </td>
        <td>{this.dateFormatShort}</td>
        <td> {this.renderCourtId(mail)}</td>
        <td className={classNames('mx-1')}>
          {isFirstItem && this.renderCourtKindSelect()}
        </td>
        <td className={classNames('docs-field-text-left')}>
          {this.sendTypeText(mail.send_type)}
        </td>
        <td className={classNames('docs-field-text-left')}>
          {mail.sending_number}
        </td>
        <td className={classNames('docs-field-text-left')}>
          {mail.sending_status}
        </td>
        <td className={classNames('docs-field-text-left')}>
          {mail.post_group}
        </td>

        <td className={classNames('docs-field-text-left')}>{mail.comment}</td>

        <td className={classNames('mx-1')}>
          {mail.can_be_cancelled && (
            <Button
              value="preview"
              onClick={cancelAction}
              className="btn btn-primary btn-rounded p-1 m-1"
            >
              Stornieren
            </Button>
          )}
        </td>

        <td className="docs-field-input">
          {isFirstItem && rowFormToggle}
          <i className="fas fa-check pull-right"></i>
        </td>
      </tr>
    )
  }

  renderEditableTableRow = () => {
    const { item } = this.props
    const isAddingNewPhysicalMail = this.state.isAddingNewPhysicalMail
    const postGroup = this.state.newPhysicalMail.postGroup
    const setPostGroup = (e) =>
      this.setState({
        newPhysicalMail: {
          ...this.state.newPhysicalMail,
          postGroup: e.target.value,
        },
      })

    const comment = this.state.newPhysicalMail.comment

    return (
      <tr className="tab-table-row">
        <td className={classNames('docs-link')}>
          {isAddingNewPhysicalMail ? null : this.renderLink()}
        </td>
        <td>{item.type}</td>
        <td>
          {item.incoming ? (
            <Tooltip text="eingehend" placement="top">
              <i className="fas fa-sign-in-alt text-success" />
            </Tooltip>
          ) : (
            <Tooltip text="ausgehend" placement="top">
              <i className="fas fa-sign-out-alt fa-rotate-180 text-danger" />
            </Tooltip>
          )}
        </td>
        <td>{this.dateFormatShort}</td>
        <td> {this.renderCourtId(item)}</td>
        <td className={classNames('mx-1')}>
          {isAddingNewPhysicalMail ? null : this.renderCourtKindSelect()}
        </td>
        <td className={classNames('mx-1')}>
          {this.renderDigitalOutboxSelect()}
        </td>
        <td className={classNames('docs-field-text')}>-</td>
        <td className={classNames('docs-field-text')}>-</td>

        {this.state.editingGroup ? (
          <td className={classNames('docs-field-input')}>
            <input value={postGroup} onChange={setPostGroup} type="text" />
            &nbsp;
            <Button onClick={this.endPostGroupEdit}>
              <i className="fas fa-check"></i>
            </Button>
          </td>
        ) : (
          <td className={classNames('docs-field-input')}>
            {`${this.state.newPhysicalMail.postGroup || '-'}`}&nbsp;
            <Button onClick={this.startPostGroupEdit}>
              <i className="fas fa-pencil-alt"></i>
            </Button>
          </td>
        )}

        {this.state.editingComment ? (
          <td className={classNames('docs-field-comment')}>
            <TextInput
              value={comment}
              onChange={this.handleCommentChange}
              type="text"
            />
            &nbsp;
            <Button onClick={this.handleEndCommentEdit}>
              <i className="fas fa-check"></i>
            </Button>
          </td>
        ) : (
          <td className={classNames('docs-field-comment')}>
            {`${this.state.newPhysicalMail.comment || '-'}`}&nbsp;
            <Button onClick={this.handleStartCommentEdit}>
              <i className="fas fa-pencil-alt"></i>
            </Button>
          </td>
        )}

        <td className={classNames('mx-1')}>
          <Button
            value="preview"
            onClick={this.submitPhysicalMail}
            className="btn btn-primary btn-rounded p-1 m-1"
          >
            Postversand
          </Button>
        </td>
        <td className="doctab-row-numbers p-3">
          <DateCell
            documentId={item.id}
            requestId={this.props.requestId}
            date={item.eb_date}
            fieldName="eb_date"
            token={this.props.token}
          />
        </td>
      </tr>
    )
  }

  startPostGroupEdit = () => this.setState({ editingGroup: true })
  endPostGroupEdit = () => this.setState({ editingGroup: false })

  handleStartCommentEdit = () => this.setState({ editingComment: true })
  handleEndCommentEdit = () => this.setState({ editingComment: false })

  handleMailTypeChange = (e) => {
    this.setState({
      newPhysicalMail: { ...this.state.newPhysicalMail, sendType: e.value },
    })
  }

  renderDigitalOutboxSelect = () => {
    return (
      <Select
        name="digital-outbox-select"
        onChange={this.handleMailTypeChange}
        options={DIGITAL_OUTBOX_OPTIONS}
        value={this.state.newPhysicalMail.sendType}
      ></Select>
    )
  }

  submitPhysicalMail = () => {
    fetch(`/admin/requests/${this.props.requestId}/physical_mails`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
      },
      body: JSON.stringify({
        mail: {
          send_type: this.state.newPhysicalMail.sendType,
          document_id: this.props.item.id,
          post_group: this.state.newPhysicalMail.postGroup,
          comment: this.state.newPhysicalMail.comment,
        },
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          isAddingNewPhysicalMail: false,
          physicalMails: response.physical_mails,
          sentAsPost: true,
        })
      })
  }

  render() {
    if (this.props.item.uncategorized) return this.renderUncategorizedRow()
    return this.state.sentAsPost
      ? this.renderRowsForPhysicalMails()
      : this.renderEditableTableRow()
  }
}

DocumentRow.propTypes = {
  item: PropTypes.object,
  requestId: PropTypes.number,
  index: PropTypes.number,
  token: PropTypes.string,
}
