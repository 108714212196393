import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import client from '../../../../client'

import Tooltip from '../engine-ui/Tooltip'
import Spinner from '../engine-ui/Spinner'
import AlertBanner from '../engine-ui/AlertBanner'
import EvidenceBlock from './EvidenceBlock'
import Form from './Form'

const EvidenceTab = (props) => {
  const { requestId, token } = props
  const [evidences, setEvidences] = useState([])
  const [showForm, setShowForm] = useState(false)
  const [files, setFiles] = useState([])
  const [fields, setFields] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const tabClick = () => {
    const listener = () => {
      getEvidences()
      getFields()
      getFiles()
    }
    document.querySelector('#evidence-tab').addEventListener('click', listener)

    return () => {
      document
        .querySelector('#evidence-tab')
        .removeEventListener('click', listener)
    }
  }

  useEffect(tabClick)

  const getFiles = () => {
    const headers = {
      token: token,
    }

    const path = `/api/admin/requests/${requestId}/documents.json`

    fetch(path, {
      method: 'GET',
      headers: headers,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        return response.json()
      })
      .then((data) => {
        if (data) setFiles(data.request.files)
      })
      .catch((error) => {
        console.error('Error fetching files:', error)
      })
  }

  const getFields = () => {
    const path = `/admin/v2/requests/${requestId}/fields`
    client
      .get(path)
      .then((response) => response.json())
      .then((data) => {
        if (data) setFields(data)
      })
  }

  const getEvidences = () => {
    setLoading(true)
    const path = `/admin/v2/requests/${requestId}/evidences`
    client
      .get(path)
      .then((response) => response.json())
      .then((data) => {
        if (data.evidences) setEvidences(data.evidences)
      })
      .catch((err) => {
        console.error(err)
        setError(true)
      })
      .finally(() => setLoading(false))
  }

  const saveNewEvidence = (formValues) => {
    let payload = {}
    payload.question_id = formValues.question_id
    payload.document_ids = formValues.files.map((file) => file.id)

    const path = `/admin/v2/requests/${requestId}/evidences`
    client
      .post(path, payload)
      .then((response) => {
        if (response.ok) {
          setEvidences([formValues, ...evidences])
        }
      })
      .catch((err) => {
        console.error(err)
        setError(true)
      })
  }

  const saveEditEvidence = (formValues, found) => {
    let payload = {}
    payload.old_question_id = found.question_id
    payload.question_id = formValues.question_id
    payload.document_ids = formValues.files.map((file) => file.id)

    const path = `/admin/v2/requests/${requestId}/evidences`
    client
      .post(path, payload)
      .then((response) => {
        if (response.ok) {
          const index = evidences.indexOf(found)
          const editedEvidences = [
            ...evidences.slice(0, index),
            formValues,
            ...evidences.slice(index + 1),
          ]
          setEvidences(editedEvidences)
        }
      })
      .catch((err) => {
        console.error(err)
        setError(true)
      })
  }

  const existingQuestions = evidences.map((item) => item.question_id)

  const funnelDataFields = fields.map((data) => {
    if (existingQuestions.includes(data.name)) {
      return { label: data.label, value: data.name, isDisabled: true }
    }
    return { label: data.name, value: data.field }
  })

  const evidenceOptions = files.map((el) => ({
    label: el.filename,
    value: el.id,
  }))

  const handleNewSubmit = (formValues) => {
    saveNewEvidence(formValues)
  }

  const handleEditSubmit = (id, formValues) => {
    const found = evidences.find((item) => item.question_id === id)
    const foundCopy = { ...found }
    foundCopy.files = [...foundCopy.files, ...formValues.files]
    if (formValues.question) {
      foundCopy.question = formValues.question
      foundCopy.question_id = formValues.question_id
    }
    saveEditEvidence(foundCopy, found)
  }

  const handleFileRemoval = (id, fileId) => {
    const found = evidences.find((item) => item.question_id === id)
    const foundCopy = { ...found }
    foundCopy.files = foundCopy.files.filter((item) => item.id !== fileId)

    saveEditEvidence(foundCopy, found)
  }

  if (loading) return <Spinner />
  if (error) return <AlertBanner />

  return (
    <div className="tab-content pt-0">
      {showForm ? (
        <Form
          funnelDataFields={funnelDataFields}
          evidenceOptions={evidenceOptions}
          onCancelForm={() => setShowForm(false)}
          onFormSubmit={handleNewSubmit}
          files={files}
          mode={'new'}
        />
      ) : (
        <div
          className="w-100 d-flex justify-content-end align-items-center"
          style={{ height: '49px' }}
        >
          <Tooltip text="Neuen Beweis hinzufügen" placement="top">
            <div
              className="me-5 icon-btn-group"
              onClick={() => setShowForm(true)}
            >
              <i className="fas fa-plus" />
              <i className="far fa-2x fa-file-image" />
            </div>
          </Tooltip>
        </div>
      )}
      {evidences.map((data, index) => (
        <EvidenceBlock
          key={index}
          data={data}
          funnelDataFields={funnelDataFields}
          evidenceOptions={evidenceOptions}
          files={files}
          requestId={requestId}
          onEditSubmit={handleEditSubmit}
          onFileRemoval={handleFileRemoval}
        />
      ))}
    </div>
  )
}

export default EvidenceTab

EvidenceTab.propTypes = {
  requestId: PropTypes.number,
  token: PropTypes.string,
}
