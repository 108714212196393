import React from 'react'
import PropTypes from 'prop-types'
import Topic from './Topic'
import Search from './Search'

export default class EmailPartialsSelect extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      partials: [],
      query: '',
    }
  }

  componentDidMount() {
    this.getPartials()
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ ...nextProps }, () => {
      this.getPartials()
    })
  }

  getQueryParams = () => {
    return `?sender=${this.state.sender}&receiver=${this.state.receiver}`
  }

  getPartials = () => {
    if (this.props.receiver.length > 0) {
      fetch(this.props.url + this.getQueryParams())
        .then((response) => response.json())
        .then((response) => {
          this.setState({ partials: response.placeholders })
        })
    }
  }

  searchQuery = (event) => {
    this.setState({
      query: event.target.value,
    })
  }

  filterTopics = (topics) => {
    return (
      topics.name.match(new RegExp(`.*${this.state.query}.*`, 'i')) ||
      topics.subtopics.reduce((acc, val) => {
        return acc || val.name.match(new RegExp(`.*${this.state.query}.*`, 'i'))
      }, false)
    )
  }

  renderPartials = () => {
    return this.state.partials.filter(this.filterTopics).map((el, index) => {
      return (
        <div
          className="accordion"
          id="emailPartialsaSelect"
          key={`topic-id-${index}`}
        >
          <Topic {...el} />
        </div>
      )
    })
  }

  render() {
    return (
      <div className="panel-group" role="tablist">
        <div className="modal-body">
          <Search query={this.state.query} searchQuery={this.searchQuery} />
          <div className="panel panel-default">{this.renderPartials()}</div>
        </div>
      </div>
    )
  }
}

EmailPartialsSelect.propTypes = {
  url: PropTypes.string.isRequired,
  sender: PropTypes.string,
  receiver: PropTypes.array,
}

EmailPartialsSelect.defaultProps = {
  sender: '',
  receiver: [],
}
