import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class ListItem extends Component {
  static propTypes = {
    value: PropTypes.any,
    children: PropTypes.any,
    onClick: PropTypes.func,
  }

  handleClick = () => {
    const { value, onClick } = this.props

    onClick && onClick(value)
  }

  render() {
    const { children } = this.props

    return <li onClick={this.handleClick}>{children}</li>
  }
}
