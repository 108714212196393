import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DocumentRow from './DocumentRow'
import DataTable from '../engine-ui/DataTable'

import { reverse, sortBy } from 'lodash'

export default class DocumentsDataTable extends Component {
  renderHead = () => (
    <tr className="table-head-row">
      <th>File</th>
      <th>Dokumententyp</th>
      <th>Richtung</th>
      <th>Erstellt</th>
      <th>Aktenzeichen</th>
      <th>Anlage beA</th>
      <th>Sendungstyp</th>
      <th>Sendungsnr</th>
      <th>Status</th>
      <th>Gruppierung Reihenfolge</th>
      <th>Hinweis</th>
      <th>Postausgang</th>
      <th>Datum EB</th>
    </tr>
  )

  renderRow = (rowData, props) => {
    const { courtIds } = props
    return (
      <DocumentRow
        index={rowData.id}
        key={rowData.id}
        item={rowData}
        requestId={this.props.requestId}
        physicalMails={rowData.physical_mails}
        courtIds={courtIds}
        ziebToken={this.props.ziebToken}
        agent={this.props.agent}
        token={this.props.token}
      />
    )
  }

  render() {
    const { documents, allDocuments } = this.props

    const documentsSortedByCreationDate = reverse(
      sortBy(documents, [(document) => document.created_at]),
    )

    const courtIds = allDocuments
      .map((document) => document.court_id)
      .filter((value, index, array) => array.indexOf(value) === index)
      .filter((courtId) => (courtId ?? '') !== '')
    return (
      <DataTable
        rows={documentsSortedByCreationDate}
        renderHead={this.renderHead}
        renderRow={this.renderRow}
        courtIds={courtIds}
        className="table table-striped table-dark-engine-funnel"
      />
    )
  }
}

DocumentsDataTable.propTypes = {
  requestId: PropTypes.number,
}
