import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { getTimeRange } from './helpers'
import List, { ListItem } from '../List'

export default class Timepicker extends Component {
  static propTypes = {
    value: PropTypes.any,
    name: PropTypes.any,
    onChange: PropTypes.func,
    hours: PropTypes.number,
    minutes: PropTypes.number,
  }

  state = {
    openTimepicker: undefined,
  }

  toggleTimepicker = () => {
    this.setState({ openTimepicker: !this.state.openTimepicker })
  }

  handleDelete = () => {
    this.props.onChange({ name: this.props.name, value: '' })
  }

  handleHourClick = ({ value }) => {
    this.props.onChange({
      name: this.props.name,
      value: moment(this.props.value || new Date())
        .hour(value)
        .toDate(),
    })
  }

  handleMinuteClick = ({ value }) => {
    this.props.onChange({
      name: this.props.name,
      value: moment(this.props.value || new Date())
        .minute(value)
        .toDate(),
    })
  }

  render() {
    const { hours, minutes } = this.props

    return (
      <Fragment>
        <div className="d-flex">
          <input
            name={this.props.name}
            className="todo-form__timepicker-selected"
            readOnly={true}
            value={
              this.props.value
                ? moment(this.props.value).local().format('HH:mm')
                : ''
            }
            onClick={this.toggleTimepicker}
          />
          <span
            className="todo-form__timepicker-selected-clear"
            onClick={this.handleDelete}
          >
            Löschen
          </span>
        </div>
        {this.state.openTimepicker && (
          <div className="todo-form__timepicker-wrapper">
            <div>
              <List
                onClick={this.handleHourClick}
                listClassName="todo-form__timepicker list-unstyled"
              >
                {getTimeRange(hours).map((value, index) => (
                  <ListItem key={index} value={value}>
                    {value}
                  </ListItem>
                ))}
              </List>
              <List
                onClick={this.handleMinuteClick}
                listClassName="todo-form__timepicker list-unstyled align-left"
              >
                {getTimeRange(minutes).map((value, index) => (
                  <ListItem key={index} value={value}>
                    {value}
                  </ListItem>
                ))}
              </List>
            </div>
            <i
              className="fas fa-times todo-form__timepicker-close"
              onClick={this.toggleTimepicker}
            ></i>
          </div>
        )}
      </Fragment>
    )
  }
}
