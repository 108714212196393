import styled from 'styled-components'

export const StyledTab = styled.div`
  color: ${(props) => (props.isActive ? 'white' : '#2ecc71')};
  border: 1px solid ${(props) => (props.isActive ? 'transparent' : '#2ecc71')};
  background: ${(props) => (props.isActive ? '#2ecc71' : 'transparent')};

  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  padding: 0.5rem 0.7rem;
  text-align: center;
  cursor: pointer;
  min-width: 70px;
  font-size: ${(props) => props.fontSize};

  &:hover {
    background-color: #48e68b;
    color: white;
  }

  &:active {
    background-color: #15b358;
    color: white;
  }
`

StyledTab.defaultProps = {
  fontSize: '0.7rem',
}

export const StyledTabPanel = styled.div`
  height: 100%;
  display: ${(props) => (props.hidden ? 'none' : 'block')};
`
