import React, { ReactNode } from 'react'
import { ActivityLogContext } from './'

interface IProps {
  requestId: 'string'
  handleDelete: (_id: string, _type: string) => void
  handleEdit: (_id: string, _text: string) => void
  onItemMarkedChange: (_id: string, _value: boolean) => void
  onItemMarkedRestore: (_id: string, _value: boolean) => void
  children: ReactNode
}

export default function ActivityLogProvider({ children, ...rest }: IProps) {
  return (
    <ActivityLogContext.Provider value={rest}>
      {children}
    </ActivityLogContext.Provider>
  )
}
