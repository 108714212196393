import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import { keys, values, chunk } from 'lodash/fp'

import EditCell from './EditCell'
import ViewCell from './ViewCell'
import SectionIcons from './SectionIcons'
import DataTable from '../engine-ui/DataTable'
import Clipboard from '../engine-ui/Clipboard'

import { format, chunkWithPosition } from './helpers'
import validate from './validate'
import DynamicTableContainer from './DynamicTable'
import { DependencyResolver } from './utils/dependencyResolver'

export default class Section extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    requestId: PropTypes.number.isRequired,
    fieldsData: PropTypes.object.isRequired,
    productName: PropTypes.string.isRequired,
    onSuccessSubmit: PropTypes.func.isRequired,
    fieldType: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    responses: PropTypes.object.isRequired,
    onResponcesChange: PropTypes.func.isRequired,
  }

  state = {
    data: {},
    errors: {},
    editMode: false,
    isMessageActive: false,
    backendErrors: {},
  }

  static getDerivedStateFromProps(props, state) {
    const { data } = state
    const { fieldsData } = props

    const newFieldsData = keys(props.fieldsData).reduce((acc, item) => {
      if (typeof data[item] !== 'undefined') {
        acc = {
          ...acc,
          [item]: {
            ...fieldsData[item],
            value: data[item],
            text: format(fieldsData[item].type)(
              data[item],
              fieldsData[item].options,
            ),
          },
        }
      } else {
        acc = { ...acc, [item]: { ...fieldsData[item] } }
      }
      return acc
    }, {})

    return { fieldsData: newFieldsData }
  }

  componentDidUpdate() {
    const { isMessageActive } = this.state

    if (isMessageActive) {
      setTimeout(() => this.setState({ isMessageActive: false }), 3000)
    }
  }

  toggleEditMode = () =>
    this.setState({ editMode: !this.state.editMode, data: {}, errors: {} })

  handleChange = (data) => {
    const { errors, fieldsData } = this.state
    const { onResponcesChange } = this.props

    const { name, value } = data

    onResponcesChange(name, value)

    this.setState({
      data: {
        ...this.state.data,
        [name]: value,
      },
      errors: {
        ...errors,
        [name]: validate(fieldsData[name].type, {
          required: fieldsData[name].required,
          validations: fieldsData[name].validations,
        })(value),
      },
    })
  }

  handleBatchChange = (updatedFields) => {
    this.setState({
      data: {
        ...this.state.data,
        ...updatedFields,
      },
      errors: this.state.errors,
    })
  }

  handleSubmit = () => {
    const { data, fieldsData } = this.state
    const { requestId, productName, onSuccessSubmit } = this.props

    const payload = Object.keys(data).map((key) => {
      let value
      if (!fieldsData[key]) value = data[key]
      else
        value =
          fieldsData[key].type === 'number' && data[key]
            ? parseFloat(data[key].replace(/\./g, '').replace(',', '.'))
            : fieldsData[key].type === 'iban' && data[key]
              ? data[key].replace(/-|\/|\s/g, '')
              : data[key]
      return {
        field: key,
        answer: value,
      }
    })

    return fetch('/admin/v2/dynamic_forms', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
      },
      body: JSON.stringify({
        fields: payload,
        request_id: requestId,
        product_name: productName,
      }),
    })
      .then((response) => {
        if (response.ok) {
          onSuccessSubmit()
          this.setState({ isMessageActive: true }, this.toggleEditMode)
        } else {
          this.setState({ isMessageActive: true }, this.toggleEditMode)
          return response.json()
        }
      })
      .then(data && ((data) => this.setState({ backendErrors: data })))
      .catch((error) => {
        console.log('error: ', error)
      })
  }

  disabled = (data) => {
    if (!data || !data.dependencies || data.dependencies.length === 0)
      return false
    const dependencyResolver = new DependencyResolver(data.dependencies)
    if (dependencyResolver.isDisable(this.props.responses)) return true
    return (
      data.defaultDisabled && !dependencyResolver.isEnable(this.props.responses)
    )
  }

  getFieldData = (field) => {
    return this.state.fieldsData[field]
  }

  renderField = (data, disabled) => {
    const { errors, editMode } = this.state
    const { token } = this.props

    if (!editMode || !data.type) {
      return (
        <td width="20%">
          <ViewCell data={data} />
        </td>
      )
    }

    return (
      <td width="20%">
        <EditCell
          type={data.type}
          name={data.name}
          value={data.value}
          onChange={this.handleChange}
          disabled={disabled}
        >
          <EditCell.Autocomplete
            options={data.options}
            error={errors[data.name]}
            onChangeBatch={this.handleBatchChange}
            className="w-100"
            token={token}
          />
          <EditCell.TextInput
            error={errors[data.name]}
            className="df_input-select w-100"
          />
          <EditCell.Checkbox className="df_input-checkbox" />
          <EditCell.TextArea rows={2} className="w-100 df_input-text" />
          <EditCell.MultiSelect
            options={data.options}
            className="d-flex my-2 align-items-center multi-select df-fields"
            labelClassName="ms-2 df_label"
          />
          <EditCell.DependentSelect
            options={data.options}
            getFieldData={this.getFieldData}
            className="df_input-select w-100"
            token={token}
          />
          <EditCell.DynamicSelect
            options={data.options}
            getFieldData={this.getFieldData}
            className="df_input-select w-100"
            token={token}
          />
          <EditCell.Select
            options={[{ text: '', value: '' }, ...data.options]}
            className="df_input-select w-100"
          />
          <EditCell.AdminDatepicker
            past
            question={{ name: data.name }}
            className="df_datepicker df_datepicker-left w-100"
          />
        </EditCell>
      </td>
    )
  }

  renderRow = (rowData, { index }) => {
    return (
      <tr key={index} className="viewtab__table-tr">
        {rowData.map((item, index) => {
          const disabled = this.disabled(item)
          if (!item) {
            return (
              <Fragment key={index}>
                <td width="25%" />
                <td width="5%" />
                <td width="20%" />
              </Fragment>
            )
          }

          return (
            <Fragment key={`${item.label}-${index}`}>
              <td
                width="25%"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={item.label}
                className={disabled ? 'viewtab__table-tr-disabled-field' : ''}
              >
                {item.label.length > 90
                  ? `${item.label.substring(0, 90)}...`
                  : item.label}
                :{item.label.length > 90 && <Clipboard value={item.label} />}
              </td>
              <td width="5%">
                <SectionIcons item={item} />
              </td>
              {this.renderField(item, disabled)}
            </Fragment>
          )
        })}
      </tr>
    )
  }

  renderMessage = () => {
    const { isMessageActive, backendErrors } = this.state

    if (isMessageActive && _.isEmpty(backendErrors)) {
      return (
        <div className="df-success">
          <div className="d-flex justify-content-center">
            <div className="text-center df-msg df-msg--success">
              <p>Erfolgreich aktualisiert</p>
            </div>
          </div>
        </div>
      )
    } else if (isMessageActive && !_.isEmpty(backendErrors)) {
      const errors = backendErrors.errors[Object.keys(backendErrors.errors)[0]]
      return (
        <div className="df-success">
          <div className="d-flex justify-content-center">
            <div className="text-center df-msg df-msg--error">
              {Object.keys(errors).map((key, index) => (
                <p key={index}>{`${key} ${errors[key].join(' & ')}`}</p>
              ))}
            </div>
          </div>
        </div>
      )
    } else return
  }

  renderActionButtons = () => {
    const { errors, editMode, fieldsData } = this.state
    const { disabled } = this.props

    const isDisabled = disabled || !values(fieldsData).some((item) => item.type)
    if (isDisabled) {
      return null
    }

    const isInvalid = values(errors).some((item) => item)

    return (
      <div className="d-flex viewtab__editnav">
        {editMode ? (
          <div className="d-flex align-items-center mb-3">
            <button
              className="btn mt-0 btn-outline-secondary btn-rounded"
              onClick={this.toggleEditMode}
            >
              Abbrechen
            </button>
            <button
              className="btn btn-primary btn-rounded mx-3 mt-0 df-submit"
              disabled={isInvalid}
              onClick={this.handleSubmit}
            >
              Speichern
            </button>
          </div>
        ) : (
          <a className="df_edit me-5" onClick={this.toggleEditMode}>
            <i className="far fa-2x fa-edit"></i>
          </a>
        )}
      </div>
    )
  }

  render() {
    const { fieldsData } = this.state
    const { id, title, fieldType, requestId, token } = this.props

    const data = values(fieldsData).some((item) => item.rhs)
      ? chunkWithPosition(values(fieldsData))
      : chunk(2)(values(fieldsData))

    if (fieldType === 'table-scaffold')
      return (
        <DynamicTableContainer
          tableScaffoldId={fieldsData.undefined.tableScaffordId}
          title={title}
          requestId={requestId}
          token={token}
        />
      )
    return (
      <div key={id}>
        {this.renderMessage()}
        <div className="d-flex align-items-center justify-content-between">
          <p className="form_category__title">
            <a className="hash-anchor" name={title.replace(/\s*/g, '')}>
              {title}
            </a>
          </p>
          {this.renderActionButtons()}
        </div>
        <div key={id}>
          <DataTable
            rows={data}
            renderRow={this.renderRow}
            className="table table-striped table-dark-engine-funnel"
          />
        </div>
      </div>
    )
  }
}
