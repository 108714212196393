import { transformChangesValue } from '../helpers.jsx'

describe('transformChangesValue', () => {
  describe('when there are no changes', () => {
    const changesValue = {}
    const fieldData = {
      question_type: 'checkbox',
      options: [],
    }
    transformChangesValue(changesValue, fieldData)

    it('leaves changesValue as empty object', () => {
      expect(changesValue).toEqual({})
    })
  })

  describe('when question type is date', () => {
    const changesValue = [
      {
        author: 'Admin',
        changed_at: '05.01.21',
        changed_from: '2020-11-01T00:00:00.000Z',
        changed_to: '2020-11-10T00:00:00Z',
        field: 'information.generic.rent.apartment_moved_in_at',
      },
    ]
    const fieldData = {
      question_type: 'date',
      options: [],
    }
    transformChangesValue(changesValue, fieldData)

    it("formats changed_from to 'DD.MM.YYYY'", () => {
      expect(changesValue[0].changed_from).toEqual('01.11.2020')
    })
  })

  describe('when question type is checkbox', () => {
    const changesValue = [
      {
        author: 'Admin',
        changed_at: '05.01.21',
        changed_from: 'nicht gesetzt',
        changed_to: 'Ja',
        field:
          'information.rent_index.berlin.v3_0.categories.kitchen.positive.built_in',
      },
      {
        author: 'Admin',
        changed_at: '05.01.21',
        changed_from: 'Ja',
        changed_to: 'Nein',
        field:
          'information.rent_index.berlin.v3_0.categories.kitchen.positive.built_in',
      },
    ]
    const fieldData = {
      question_type: 'checkbox',
      options: [],
    }
    transformChangesValue(changesValue, fieldData)

    it("changes 'nicht gesetz' to 'Nein'", () => {
      expect(changesValue[0].changed_from).toEqual('Nein')
    })

    it('keeps string in other cases', () => {
      expect(changesValue[1].changed_from).toEqual('Ja')
    })
  })

  describe('when question type is select', () => {
    const changesValue = [
      {
        author: 'Admin',
        changed_at: '05.01.21',
        changed_from: '1919-01-01;1949-12-31',
        changed_to: '1950-01-01;1964-12-31',
        field:
          'information.rent_index.berlin.v3_0.apartment_construction_dates',
      },
    ]
    const fieldData = {
      question_type: 'select',
      options: [
        { text: '1919 - 1949', value: '1919-01-01;1949-12-31' },
        { text: '1950 - 1964', value: '1950-01-01;1964-12-31' },
      ],
    }
    transformChangesValue(changesValue, fieldData)

    it('formats changed_from to text value', () => {
      expect(changesValue[0].changed_from).toEqual('1919 - 1949')
    })
  })

  describe('when question type is something else', () => {
    const changesValue = [
      {
        author: 'Admin',
        changed_at: '05.01.21',
        changed_from: '50',
        changed_to: '100',
        field: 'information.generic.rent.apartment_living_area',
      },
    ]
    const fieldData = {
      question_type: 'number',
      options: [],
    }
    transformChangesValue(changesValue, fieldData)

    it("doesn't change changed_from value", () => {
      expect(changesValue[0].changed_from).toEqual('50')
    })
  })
})
