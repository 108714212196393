import React, { Component } from 'react'
import SuggestionItem from './SuggestionItem'

class Suggestions extends Component {
  render() {
    const data = this.props.data
    const mapSuggestions = data.map((item, index) => {
      if (this.props.query.length >= 3) {
        return <SuggestionItem key={index} data={item} />
      }
    })

    return <div className="result__list w-100">{mapSuggestions}</div>
  }
}

export default Suggestions
