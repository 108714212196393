import React from 'react'
import PropTypes from 'prop-types'

import Datepicker from '../forms/datepicker'

export default class AdminDatepicker extends React.Component {
  static displayName = 'AdminDatepicker'
  constructor(props) {
    super(props)

    this.state = {
      value: this.props.value ? moment(this.props.value).utc().format() : '',
      showErrors: false,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.value !== prevState.value && !nextProps.rails_component) {
      return { value: moment(nextProps.value).utc().format() }
    } else return null
  }

  handleFormChange = (inputName, value) => {
    const { onChange, question } = this.props

    this.setState({ value: value })

    onChange && onChange({ name: question.name, value })
  }

  futureOnly = (current) => {
    return current.isSameOrAfter(moment().startOf('day'))
  }

  render() {
    const { className, inputClassName, disabled } = this.props
    return (
      <div className={className}>
        <Datepicker
          question={this.props.question}
          state={this.state}
          isValid={!this.props.past ? this.futureOnly : () => true}
          handleBlur={() => this.setState({ showErrors: true })}
          handleFormChange={this.handleFormChange}
          disabled={disabled}
          inputClassName={inputClassName}
        />
        <input
          type="hidden"
          name={this.props.question.name}
          readOnly={true}
          value={
            this.state.value ? moment(this.state.value).utc().format() : ''
          }
        />
        <div className="text-end">
          {this.props.options &&
            this.props.options.map((option) => (
              <div key={option.date}>
                <button
                  onClick={() => this.setState({ value: option.date })}
                  className="btn btn-link btn-sm p-0"
                  type="button"
                >
                  {option.label}
                </button>
              </div>
            ))}
        </div>
      </div>
    )
  }
}

AdminDatepicker.propTypes = {
  question: PropTypes.object.isRequired,
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  past: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  rails_component: PropTypes.bool,
  inputClassName: PropTypes.string,
}
