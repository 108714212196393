import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Button from '../engine-ui/Button'
import Dropzone from '../engine-ui/Dropzone'
import Spinner from '../engine-ui/Spinner'
import RadioGroup, { RadioOption } from '../engine-ui/RadioGroup'

export default class DocumentForm extends Component {
  state = {
    files: [],
    isSubmitted: false,
  }

  static propTypes = {
    files: PropTypes.arrayOf(PropTypes.object),
    document: PropTypes.object,
    onSubmit: PropTypes.func,
    onChange: PropTypes.func,
    uploaded: PropTypes.number,
    uploading: PropTypes.number,
    onFileAdded: PropTypes.func.isRequired,
  }

  handleFileAdded = (file) => {
    const { onFileAdded } = this.props
    this.setState(
      (prevState) => ({ files: prevState.files.concat(file) }),
      () => onFileAdded(file),
    )
  }

  render() {
    const { files, isSubmitted } = this.state
    const { document, onChange, onSubmit, uploaded, uploading } = this.props
    const allDocumentsUploaded = uploading === uploaded && uploaded !== 0
    const handleSubmit = () => {
      onSubmit()
      this.setState({ isSubmitted: true })
    }
    const message =
      uploading > uploaded
        ? 'Dokument lädt...'
        : uploaded > 0
          ? files.map((f) => f.name).join(', ')
          : ''

    const FormRows = () => {
      return [
        <div key="1" className="document-form__row">
          <div className="document-form__row-item document-form__row-item--horizontal-center">
            <Dropzone onFileAdded={this.handleFileAdded} />
          </div>
          <div className="document-form__row-item document-form__row-item--vertical-center">
            <RadioGroup
              name="incoming"
              value={document.incoming}
              onChange={onChange}
            >
              <RadioOption value={false} label="Ausgehendes Dokument" />
              <RadioOption value={true} label="Eingehendes Dokument" />
            </RadioGroup>
          </div>
        </div>,
        <div key="2" className="document-form__row">
          <div className="document-form__file-list">
            <span className="document-form__file">{message}</span>
          </div>
        </div>,
      ]
    }

    return (
      <div className="document-form__container">
        {isSubmitted ? <Spinner /> : <FormRows />}
        <div className="v2modal-fields document-form__buttons-container">
          <Button
            onClick={handleSubmit}
            disabled={!allDocumentsUploaded || isSubmitted}
            className="btn-modal"
          >
            Hochladen
          </Button>
        </div>
      </div>
    )
  }
}
