import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '../../engine-ui/Button'

export default function NotesForm(props) {
  const { onClick, closeForm, title, type, defaultValue, onValueChange } = props
  const [text, setText] = useState(defaultValue.text || '')

  const handleChange = (e) => {
    const value = e.target.value
    setText(value)
    onValueChange({ text: value })
  }

  return (
    <div className="logitem-container card notes__card">
      <div className="notes__card-header card-header">
        <p className="notes__card-header-title">{title}</p>
        <Button onClick={closeForm} className="btn-close"></Button>
      </div>
      <div className="card-body">
        <div className="card-body__box">
          <div className="form-group pb-3">
            <textarea
              type="textarea"
              className="form-control"
              rows="3"
              value={text}
              onChange={handleChange}
            />
          </div>
          <div className="d-flex justify-content-end">
            <Button
              className="btn btn-primary btn-rounded mt-0"
              onClick={() => onClick(text, type)}
            >
              Kommentar speichern
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

NotesForm.propTypes = {
  onClick: PropTypes.func,
  closeForm: PropTypes.func,
  title: PropTypes.string,
  type: PropTypes.string,
}
