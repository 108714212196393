import styled from 'styled-components'

const rotate = {
  0: 'rotate(0deg)',
  90: 'rotate(90deg)',
  180: 'rotate(180deg)',
  270: 'rotate(270deg)',
}

export const Container = styled.div`
  flex: 2;
  flex-basis: 66.66%;
  background-color: white;
  border-radius: 7px;
  color: #495057;
`

export const OCRContainer = styled.div`
  padding: 2rem;
`
export const RotateButton = styled.button`
  border: none;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 1rem 1rem 0 0;
  background-color: #4c8dff;
  z-index: 1000;
`

export const Image = styled.img`
  transform: ${(props) => rotate[props.rotateDegree]};
`
