import { useContext } from 'react'

import Context from '../context/Context'
import {
  initializeContentPages,
  removeContentPages,
} from '../ducks/contentPages'

export default function useContentPages() {
  const { state, dispatch } = useContext(Context)
  const { contentPages = { data: {} } } = state

  const initialize = (payload) => dispatch(initializeContentPages(payload))
  const remove = () => dispatch(removeContentPages())

  return {
    contentPages,
    initializeContentPages: initialize,
    removeContentPages: remove,
  }
}
