export default class S3Upload {
  constructor({
    file,
    handleUploadStart,
    handleUploadProgress,
    handleUploadFinish,
    handleUploadError,
  }) {
    this.file = file
    this.handleUploadStart = handleUploadStart
    this.handleUploadProgress = handleUploadProgress
    this.handleUploadFinish = handleUploadFinish
    this.handleUploadError = handleUploadError
  }

  objectName() {
    return this.file.name.replace(/[^\w\d_\-.]+/gi, '')
  }

  start(signedUrl) {
    this.handleUploadStart(this.file)

    const xmlhttp = new XMLHttpRequest()

    let disposition = 'attachment'
    if (this.file.type.substr(0, 6) === 'image/') {
      disposition = 'inline'
    } else {
      disposition = 'attachment'
    }

    xmlhttp.open('PUT', signedUrl, true)
    xmlhttp.setRequestHeader('Content-Type', this.file.type)
    xmlhttp.setRequestHeader(
      'Content-Disposition',
      disposition + '; filename="' + this.objectName() + '"',
    )

    xmlhttp.onprogress = (e) => {
      if (e.lengthComputable) {
        const percentLoaded = Math.round((e.loaded / e.total) * 100)
        this.handleUploadProgress(percentLoaded, this.file)
      }
    }

    xmlhttp.onload = () => {
      if (xmlhttp.status === 200) {
        this.handleUploadFinish(signedUrl, this.file)
      } else {
        this.handleUploadError(xmlhttp)
      }
    }

    xmlhttp.send(this.file)
  }
}
