import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Form from './Form'
import AlertBanner from '../../engine-ui/AlertBanner'
import Button from '../../engine-ui/Button'
import TriggeredActionsList from '../../engine-ui/TriggeredActionsList'

export default function FormContainer(props) {
  const { data, onSubmit, token, requestId, tableScaffoldId, initialData } =
    props

  const [inputData, setInputData] = useState('')
  const [touched, setTouched] = useState(false)
  const [triggeredActions, setTriggeredActions] = useState('')
  const [selectedActions, setSelectedActions] = useState([])
  const [showCheckboxes, setShowCheckboxes] = useState(false)
  const [submitDisabled, setSubmitDisabled] = useState(true)
  const [isValidInput, setIsValidInput] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    setInputData(initialData)
  }, [initialData])

  const handleInputChange = (event) => {
    const { name, value } = event
    setInputData({ ...inputData, [name]: value })
    setTouched(true)
  }

  const handleActionsShow = async () => {
    const payload = Object.keys(inputData).map((key) => {
      return {
        field: key,
        answer: inputData[key],
      }
    })

    setError(false)
    const url = `/api/admin/requests/${requestId}/dynamic_table_scaffold_triggers?table_scaffold_key=${tableScaffoldId}`
    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        token: token,
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
      },
      body: JSON.stringify({
        dynamic_table_scaffold: {
          fields: payload,
        },
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setTriggeredActions(data.actions)
        setSelectedActions(data.actions.map((action) => action.id))
        setSubmitDisabled(!data.valid)
      })
      .catch((err) => {
        console.error(err)
        setError(true)
      })
  }

  const handleActionsEdit = () => {
    setShowCheckboxes((prevShowCheckboxes) => !prevShowCheckboxes)
  }

  const handleActionCheck = (event) => {
    if (selectedActions.includes(event.target.id)) {
      const index = selectedActions.indexOf(event.target.id)
      setSelectedActions([
        ...selectedActions.slice(0, index),
        ...selectedActions.slice(index + 1),
      ])
    } else setSelectedActions([...selectedActions, event.target.id])
  }

  return (
    <>
      <Form
        data={data}
        onChange={handleInputChange}
        inputData={inputData}
        touched={touched}
        setIsValidInput={setIsValidInput}
        submitDisabled={submitDisabled}
      />
      {error && <AlertBanner />}
      <TriggeredActionsList
        data={triggeredActions}
        showCheckboxes={showCheckboxes}
        onActionCheck={handleActionCheck}
        selectedActions={selectedActions}
      />
      <div className="v2modal-fields dynamic-table-form__buttons-container">
        <Button
          className="btn-modal me-3"
          onClick={handleActionsShow}
          disabled={!submitDisabled || !isValidInput}
        >
          Überprüfen
        </Button>
        <Button
          className="btn-modal me-3"
          onClick={handleActionsEdit}
          disabled={submitDisabled}
        >
          Bearbeiten
        </Button>
        <Button
          className="btn-modal"
          onClick={() => onSubmit(selectedActions, inputData)}
          disabled={submitDisabled || !isValidInput}
        >
          Speichern
        </Button>
      </div>
    </>
  )
}

FormContainer.propTypes = {
  onSubmit: PropTypes.func,
  tableScaffoldId: PropTypes.string,
  token: PropTypes.string,
  requestId: PropTypes.number,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  initialData: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}
