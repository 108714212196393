import React from 'react'
import PropTypes from 'prop-types'

import DocumentDelete from './document_delete'

const DocumentMeta = ({ doc, handleDelete }) => {
  const Progress = <strong>{doc.percent} %</strong>

  return (
    <div className="document__meta">
      {doc.completed ? (
        <DocumentDelete doc={doc} handleDelete={handleDelete} />
      ) : (
        Progress
      )}
    </div>
  )
}

DocumentMeta.propTypes = {
  doc: PropTypes.object.isRequired,
  handleDelete: PropTypes.func,
}

export default DocumentMeta
