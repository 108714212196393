import React from 'react'
import PropTypes from 'prop-types'
import Badge from '../Badge'

const BadgeLinkCell = (props) => {
  const { type, text, href } = props

  if (text === 0) return '-'

  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      <Badge type={type} text={text} />
    </a>
  )
}

BadgeLinkCell.propTypes = {
  text: PropTypes.any,
  type: PropTypes.string,
  href: PropTypes.string,
}

export default BadgeLinkCell
