import React, { useState, useEffect } from 'react'
import client from '../../../../client'
import { Badge } from './TeamFilter'

export const sPrintQuery = (params = {}) => {
  const h = { ...params }
  Object.keys(h).forEach((k) => !h[k] && h[k] !== undefined && delete h[k])
  return new URLSearchParams(h).toString()
}

const Filter = (props) => {
  const {
    activeProduct,
    basePath = '/admin/tickets',
    searchParams = {},
  } = props

  const [products, setProducts] = useState([])
  const [productsTicketsCounts, setProductsTicketsCounts] = useState(null)
  const [showFilter, setShowFilter] = useState(false)

  const loadProducts = () => {
    client
      .get('/admin/v2/products', null, { Accept: 'application/json' })
      .then((response) => response.json())
      .then((data) => {
        setProducts(data.data)
      })
  }
  const loadProductsTicketsCounts = () => {
    client
      .get('/admin/v2/tickets_stats', null, { Accept: 'application/json' })
      .then((response) => response.json())
      .then(setProductsTicketsCounts)
  }

  useEffect(() => {
    showFilter && products.length === 0 && loadProducts()
  }, [showFilter])
  useEffect(() => {
    showFilter && productsTicketsCounts === null && loadProductsTicketsCounts()
  }, [showFilter])
  useEffect(() => {
    showFilter &&
      document
        .getElementById('product-filter-toggle')
        ?.scrollIntoView({ behavior: 'smooth' })
  }, [products, showFilter])

  return (
    <div className="sidebar">
      <div className="d-flex">
        <a
          id="product-filter-toggle"
          className="sidebar__header"
          href="#"
          onClick={(event) => {
            event.preventDefault()
            setShowFilter(!showFilter)
          }}
        >
          <i className="fa fa-filter me-1 sidebar__header-icon"></i>Produkte
          filtern
        </a>
      </div>

      {showFilter && (
        <ul>
          <li
            id="all-products"
            key="all-products"
            className={
              (activeProduct == null ? 'sidebar__item--active' : '') +
              ' sidebar__item'
            }
          >
            <a
              className="sidebar__header"
              href={
                basePath + '?' + sPrintQuery({ ...searchParams, product: '' })
              }
            >
              <i className="fas fa-share-square"></i>
              <span className="mx-1">Alle Produkte</span>
            </a>
          </li>
          {products.map((p) => {
            return (
              <li
                key={p.id}
                className={
                  (activeProduct == p.id ? 'sidebar__item--active' : '') +
                  ' sidebar__item'
                }
              >
                <a
                  className="sidebar__header"
                  href={
                    basePath +
                    '?' +
                    sPrintQuery({ ...searchParams, product: p.id })
                  }
                >
                  <i className={p.attributes.icon + ' fa--fixed-width'}></i>
                  <span className="mx-1">{p.attributes.name}</span>
                  <Badge
                    count={
                      productsTicketsCounts
                        ? productsTicketsCounts[p.id] || 0
                        : null
                    }
                  />
                </a>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

export default Filter
