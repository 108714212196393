import React from 'react'
import PropTypes from 'prop-types'

const Checkbox = ({ question, handleFormChange, state }) => {
  const onChange = (event) =>
    handleFormChange(question.name, event.target.checked, true)
  state = state || { value: false, disabled: false }
  const value = state.disabled ? false : state.value || false

  return (
    <div
      className={`form-group form-input form-check ${
        state.disabled ? 'deactivated' : ''
      }`}
    >
      <label className="form-check-label" htmlFor={question.name}>
        <input
          type="checkbox"
          name={question.name}
          id={question.name.replace('[', '_').replace(']', '')}
          className="form-check-input"
          onChange={onChange}
          checked={value}
          disabled={state.disabled}
        />
        <span>{question.text}</span>
      </label>
    </div>
  )
}

Checkbox.propTypes = {
  question: PropTypes.object.isRequired,
  state: PropTypes.object,
  handleFormChange: PropTypes.func,
}

export default Checkbox
