import React, { useState } from 'react'
import client from '../../../../client'
import AsyncSelect from 'react-select/async'
import Modal from 'react-bootstrap/Modal'

const newLawyerSafeId = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
    setLawyer({})
  }
  const [lawyer, setLawyer] = useState({})

  const loadOptions = (inputValue, callback) => {
    if (inputValue.length < 3) {
      return
    }

    let surname,
      firstname = ''
    const words = inputValue.split(',')
    surname = words[0]
    if (words.length > 1) {
      firstname = words[1].trim()
    }

    client
      .get(
        `/admin/counter-party-lawyer-safe-ids/search?surname=${encodeURI(
          surname,
        )}&firstname=${encodeURI(firstname)}`,
        null,
        { Accept: 'application/json' },
      )
      .then((response) => response.json())
      .then((values) => {
        callback(values)
      })
  }

  const handleChange = (event) => {
    if (event) {
      setLawyer({
        safeId: event.safeId,
        name: event.firstname + ' ' + event.surname,
      })
      return
    }
    setLawyer({})
  }

  const handleSubmit = () => {
    client
      .post(
        '/admin/counter-party-lawyer-safe-ids',
        { lawyer_safe_id: { name: lawyer.name, safe_id: lawyer.safeId } },
        { Accept: 'application/json' },
      )
      .then(
        (r) => {
          if (!r.ok) {
            alert(r.statusText)
          }
          toggleModal()
        },
        () => {
          alert('An unexpected error occured!')
        },
      )
  }

  return (
    <>
      <button
        type="button"
        className="selected-all-btn document-download btn btn-outline-primary btn-rounded"
        onClick={toggleModal}
      >
        Neu
      </button>
      <Modal size="lg" show={isModalOpen} onHide={toggleModal}>
        <Modal.Header closeButton>
          <h5 className="bea-ui__modal-title">BEA</h5>
        </Modal.Header>
        <Modal.Body>
          <AsyncSelect
            isClearable
            cacheOptions
            loadOptions={loadOptions}
            onChange={handleChange}
            placeholder="Nachname, Vorname"
            clearValue={() => setLawyer(null)}
            getOptionLabel={(e) => e.surname + ', ' + e.firstname}
            getOptionValue={(e) => e.id}
          />
          <div className="my-2">{lawyer.safeId}</div>
          <div className="my-2">
            <button
              className="btn btn-secondary btn-rounded mt-0"
              onClick={toggleModal}
            >
              Abbrechen
            </button>
            <button
              className="btn btn-primary btn-rounded mx-3 mt-0"
              disabled={!lawyer.safeId}
              onClick={handleSubmit}
            >
              Speichern
            </button>
          </div>
          <Modal.Footer></Modal.Footer>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default newLawyerSafeId
