import React from 'react'
import PropTypes from 'prop-types'

import Input from './input'

const FormCategory = ({
  category,
  handleFormChange,
  questions,
  handleBlur,
}) => (
  <div className="form_category clearfix">
    <p className="form_category__title">{category.title}</p>
    <div className="validation-message">Bitte füllen Sie dieses Feld aus</div>
    <div className="row px-2">
      {category.questions.map((question) => (
        <Input
          key={question.name}
          question={question}
          questions={questions}
          handleFormChange={handleFormChange}
          handleBlur={handleBlur}
        />
      ))}
    </div>
  </div>
)

FormCategory.propTypes = {
  category: PropTypes.object.isRequired,
  questions: PropTypes.object.isRequired,
  handleFormChange: PropTypes.func,
  handleBlur: PropTypes.func,
}

export default FormCategory
