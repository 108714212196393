import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'

import DocumentCard from './DocumentCard'
import Button from '../engine-ui/Button'
import Checkbox from '../engine-ui/Checkbox'

import { reverse, sortBy } from 'lodash'

const MAX_SELECTIONS = 75

export default class DocumentListForm extends Component {
  static propTypes = {
    onDelete: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    onEdit: PropTypes.func,
    requestId: PropTypes.number,
    documents: PropTypes.array,
    agentEmail: PropTypes.string,
    unprocessed: PropTypes.bool,
  }

  state = {
    selectedDocuments: [],
    loadingAction: null,
    message: null,
    messageType: 'success',
    filename: null,
  }

  componentDidMount() {
    document.addEventListener(
      'turbo:before-stream-render',
      this.handleTurboStreamUpdate,
    )
  }

  componentWillUnmount() {
    document.removeEventListener(
      'turbo:before-stream-render',
      this.handleTurboStreamUpdate,
    )
  }

  handleTurboStreamUpdate = (event) => {
    const { requestId } = this.props
    if (event.detail.newStream.target === `merge_or_delete_tag-${requestId}`) {
      this.handleMergeOrDeleteTag()
    }
  }

  handleMergeOrDeleteTag = () => {
    const event = new CustomEvent('filesMergedOrDeleted')
    document.dispatchEvent(event)
  }

  handleSubmit = async (action) => {
    const { onSubmit } = this.props
    const { selectedDocuments } = this.state
    if (action === 'delete_selection') {
      const isConfirmed = confirm(
        'Es werden keine Dokumente gelöscht, ' +
          'wenn sie mit einer beA-Datei verknüpft sind die sich nicht im "Erstellt"-Status ' +
          'befindet. Dokument sicher löschen?',
      )
      if (!isConfirmed) {
        return
      }
    }

    this.setState({ loadingAction: action })

    try {
      const response = await onSubmit({
        submit: action,
        documents: selectedDocuments,
      })
      const result = await response
      if (response && response.status === 'ok') {
        this.setState({
          message: `${
            action === 'attach_and_save'
              ? 'Gruppieren und Speichern'
              : 'Auswahl löschen'
          } in bearbeitung ⏳`,
          messageType: 'success',
          filename: result.filename || null,
        })
      } else {
        this.setState({
          message: `${
            action === 'attach_and_save'
              ? 'Gruppieren und Speichern'
              : 'Auswahl löschen'
          } fehlgeschlagen 😢`,
          messageType: 'error',
          filename: null,
        })
      }
    } catch (error) {
      this.setState({
        message: 'Ein Fehler ist aufgetreten!',
        messageType: 'error',
        filename: null,
      })
      console.error('Error submitting:', error)
    } finally {
      this.setState({ loadingAction: null })
    }
  }

  toggleSelectAll = (allImageDocuments) => {
    if (this.state.selectedDocuments.length === allImageDocuments.length) {
      this.setState({ selectedDocuments: [] })
    } else {
      const limitedSelection = allImageDocuments.slice(0, MAX_SELECTIONS)
      this.setState({ selectedDocuments: limitedSelection })
    }
  }

  handleDocumentSelect = ({ name: id, value }) => {
    const { selectedDocuments } = this.state
    if (value) {
      if (selectedDocuments.length < MAX_SELECTIONS) {
        this.setState({ selectedDocuments: [...selectedDocuments, id] })
      }
    } else {
      this.setState({
        selectedDocuments: selectedDocuments.filter((item) => item !== id),
      })
    }
  }

  render() {
    const { onDelete, onEdit, requestId, documents, agentEmail, unprocessed } =
      this.props
    const { selectedDocuments, loadingAction, message, messageType, filename } =
      this.state

    const documentsSortedByCreationDate = reverse(
      sortBy(documents, [(document) => document.created_at]),
    )

    const allImageDocuments = this.props.documents.reduce((acc, document) => {
      return document.image ? [...acc, document.id] : acc
    }, [])

    const allSelected = selectedDocuments.length === allImageDocuments.length

    return (
      <div className="documents__content">
        {allImageDocuments.length > 0 && unprocessed && (
          <div className="d-flex">
            <Checkbox
              onChange={() => this.toggleSelectAll(allImageDocuments)}
              value={allSelected}
            />
            <p className="ms-2 mb-1">
              {allSelected ? 'Alle abwählen' : 'Alle wählen'}
            </p>
          </div>
        )}
        {documentsSortedByCreationDate.map((document) => (
          <DocumentCard
            key={document.id}
            document={document}
            onDelete={onDelete}
            onEdit={onEdit}
            requestId={requestId}
            agentEmail={agentEmail}
            onDocumentSelect={this.handleDocumentSelect}
            documentSelected={selectedDocuments.includes(document.id)}
            disableCheckbox={
              selectedDocuments.length >= MAX_SELECTIONS &&
              !selectedDocuments.includes(document.id)
            }
          />
        ))}
        {documents.length ? (
          unprocessed &&
          allImageDocuments.length > 0 && (
            <Fragment>
              <Button
                value="attach_and_save"
                onClick={() => this.handleSubmit('attach_and_save')}
                className="btn btn-primary btn-rounded me-1"
                disabled={
                  !selectedDocuments.length ||
                  loadingAction === 'attach_and_save'
                }
              >
                {loadingAction === 'attach_and_save'
                  ? 'Gruppieren und Speichern...'
                  : 'Gruppieren und Speichern'}
              </Button>
              <Button
                value="delete_selection"
                onClick={() => this.handleSubmit('delete_selection')}
                className="btn btn-danger btn-rounded me-1"
                disabled={
                  !selectedDocuments.length ||
                  loadingAction === 'delete_selection'
                }
              >
                {loadingAction === 'delete_selection'
                  ? 'Auswahl löschen...'
                  : 'Auswahl löschen'}
              </Button>
            </Fragment>
          )
        ) : (
          <em className="ms-4">Es gibt keine Dokumente</em>
        )}
        {message && (
          <div className="mt-3" style={{ textAlign: 'center' }}>
            <p
              id={`merge_or_delete_tag-${requestId}`}
              style={{
                color: messageType === 'success' ? '#2ecc71' : '#e82202',
                fontSize: '2.2em',
                fontWeight: 'bold',
              }}
            >
              {message}
            </p>
            <p
              id={`merged_file_name_tag-${requestId}`}
              style={{
                fontSize: '1.2em',
                color: '#2ecc71',
                fontWeight: 'bold',
              }}
            ></p>
          </div>
        )}
      </div>
    )
  }
}
