import React, { Component } from 'react'
import Draggable from 'react-draggable'

export default class DraggableWrapper extends Component {
  state = {
    activeDrags: 0,
    deltaPosition: {
      x: 0,
      y: 0,
    },
    controlledPosition: {
      x: -400,
      y: 200,
    },
  }

  render() {
    const dragHandlers = { onStart: this.onStart, onStop: this.onStop }
    return (
      <Draggable cancel=".not-draggable" {...dragHandlers}>
        {this.props.children}
      </Draggable>
    )
  }
}
