/**
 * @jest-environment jsdom
 */

import React from 'react'
import { render, fireEvent } from '@testing-library/react'

import SelectReceiverButton from './SelectReceiverButton'
import { MailerAppContext } from './MailerApp'

const unselected = () => true
const toggleSelected = jest.fn()
const selectedEmail = () => 'string'

describe('disable state of gegenseite button', () => {
  // gegenseite button have item key of "counter_party"
  it('should disable gegenseite button if request does not have counter party email', () => {
    const { getByTestId } = render(
      <MailerAppContext.Provider value={{ request: {} }}>
        <SelectReceiverButton
          item={{
            key: 'counter_party',
            string: 'Kanzlei Conny',
            color: {
              box: 'darkslateblue',
            },
          }}
          unselected={unselected}
          toggleSelected={toggleSelected}
          selectedEmail={selectedEmail}
        />
      </MailerAppContext.Provider>,
    )

    fireEvent.click(getByTestId('select-receiver-button'))

    expect(toggleSelected).not.toHaveBeenCalled()
  })

  it('should disable gegenseite button if request have counter party email is empty string', () => {
    const { getByTestId } = render(
      <MailerAppContext.Provider
        value={{
          request: {
            counter_parties_manager: {
              default_recipient_email: '',
            },
          },
        }}
      >
        <SelectReceiverButton
          item={{
            key: 'counter_party',
            string: 'Kanzlei Conny',
            color: {
              box: 'darkslateblue',
            },
          }}
          unselected={unselected}
          toggleSelected={toggleSelected}
          selectedEmail={selectedEmail}
        />
      </MailerAppContext.Provider>,
    )

    fireEvent.click(getByTestId('select-receiver-button'))

    expect(toggleSelected).not.toHaveBeenCalled()
  })

  it('should disable gegenseite button if request  have counter party email of "info@conny.de"', () => {
    const { getByTestId } = render(
      <MailerAppContext.Provider
        value={{
          request: {
            counter_parties_manager: {
              default_recipient_email: 'info@conny.de',
            },
          },
        }}
      >
        <SelectReceiverButton
          item={{
            key: 'counter_party',
            string: 'Kanzlei Conny',
            color: {
              box: 'darkslateblue',
            },
          }}
          unselected={unselected}
          toggleSelected={toggleSelected}
          selectedEmail={selectedEmail}
        />
      </MailerAppContext.Provider>,
    )

    fireEvent.click(getByTestId('select-receiver-button'))

    expect(toggleSelected).not.toHaveBeenCalled()
  })

  it('should not disable gegenseite button if request have counter party email different than "info@conny.de"', () => {
    const { getByTestId } = render(
      <MailerAppContext.Provider
        value={{
          request: {
            counter_parties_manager: {
              default_recipient_email: 'info@not-conny.de',
            },
          },
        }}
      >
        <SelectReceiverButton
          item={{
            key: 'counter_party',
            string: 'Kanzlei Conny',
            color: {
              box: 'darkslateblue',
            },
          }}
          unselected={unselected}
          toggleSelected={toggleSelected}
          selectedEmail={selectedEmail}
        />
      </MailerAppContext.Provider>,
    )

    fireEvent.click(getByTestId('select-receiver-button'))

    expect(toggleSelected).toHaveBeenCalled()
  })

  it('should not disable button if it is not gegenseite button', () => {
    const { getByTestId } = render(
      <MailerAppContext.Provider value={{ request: {} }}>
        <SelectReceiverButton
          item={{
            key: 'not_counter_party',
            string: 'Kanzlei Conny',
            color: {
              box: 'darkslateblue',
            },
          }}
          unselected={unselected}
          toggleSelected={toggleSelected}
          selectedEmail={selectedEmail}
        />
      </MailerAppContext.Provider>,
    )

    fireEvent.click(getByTestId('select-receiver-button'))

    expect(toggleSelected).toHaveBeenCalled()
  })
})
