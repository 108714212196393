import React from 'react'
import PropTypes from 'prop-types'

import Select from '../../../engine-ui/Select'
import { Label, InputBox } from './styles/documentAttr'

export default function DirectionField({ onChange, formValues }) {
  const options = [
    {
      value: 'incoming',
      text: 'incoming',
    },
    {
      value: 'outgoing',
      text: 'outgoing',
    },
  ]

  return (
    <>
      <Label>Richtung: </Label>
      <InputBox>
        <Select
          name={'direction'}
          value={formValues.direction.value}
          onChange={onChange}
          options={options}
          className="form-control"
        />
      </InputBox>
    </>
  )
}

DirectionField.propTypes = {
  onChange: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
}
