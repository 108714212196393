import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Button from '../engine-ui/Button'

class StatusRow extends Component {
  handleDeactivate = () => {
    if (
      window.confirm(
        'Sind Sie sicher, dass Sie diesen Status deaktivieren wollen?',
      )
    ) {
      this.props.onDelete(this.props.row.id)
    }
  }

  render() {
    const row = this.props.row.attributes

    return (
      <tr
        className={classNames('tab-table-row', 'status-tab__row', {
          'status-tab__row--deactivated': row.deleted_at,
        })}
      >
        <td className="tab-table__row--wide">{row.status_title}</td>
        <td className="tab-table__row--wide">{row.reason_title}</td>
        <td>{new Date(row.created_at).toLocaleDateString('de-DE')}</td>
        <td>{row.created_by}</td>
        <td>
          {row.deleted_at
            ? new Date(row.deleted_at).toLocaleDateString('de-DE')
            : ''}
        </td>
        <td>{row.deleted_at ? row.updated_by : ''}</td>
        <td>
          <div className="p-0 d-flex justify-content-center">
            <Button
              className={classNames('btn', { 'd-none': row.deleted_at })}
              onClick={this.handleDeactivate}
            >
              <i className="fas fa-trash-alt"></i>
            </Button>
          </div>
        </td>
      </tr>
    )
  }
}

StatusRow.propTypes = {
  row: PropTypes.object,
}

export default StatusRow
