import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import FeedbackItem from './FeedbackItem'
import { FeedbackContext, loadFeedbacks } from '../store/feedbacksStore'

export default function FeedbackTable(props) {
  const [feedbacks, dispatch] = useContext(FeedbackContext)

  useEffect(() => {
    dispatch(loadFeedbacks(props.data))
  }, [props.data])

  return (
    <div className="feedback-table card-container">
      <div className="logitem-container">
        <table>
          <thead>
            <tr>
              <th className="created-at"> Erstellt am </th>
              <th className="note"> Hinweis </th>
              <th className="agent"> Bearbeiter </th>
              <th className="actions d-flex justify-content-end pe-4">
                {' '}
                Actions{' '}
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.values(feedbacks).map((feedback) => (
              <FeedbackItem feedback={feedback} key={feedback.id} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

FeedbackTable.propTypes = {
  data: PropTypes.array,
}
