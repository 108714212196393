import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const ViewCell = (props) => {
  const { data } = props

  if (data.type === 'multi_select') {
    return (
      <Fragment>
        {data.options.map((item) => {
          const cellClassName = classNames('question_display-checkbox', {
            'question_display-checkbox-true': (data.value || []).includes(
              item.value,
            ),
          })

          return (
            <div
              key={item.value}
              className="d-flex my-2 multi-select df-fields align-items-center"
            >
              <div className={cellClassName} />
              <span className="ms-2 df_label">{item.text}</span>
            </div>
          )
        })}
      </Fragment>
    )
  }

  const cellClassName = classNames({
    'question_display-checkbox': data.type === 'checkbox',
    'question_display-checkbox-true': data.type === 'checkbox' && data.value,
  })

  let text = data.text
  if (data.type === 'dynamic_select') {
    text += ` - ${
      data.options.find((item) => item.text === 'placeholder')?.value ??
      'Bearbeiten, um die Optionen anzuzeigen'
    }`
  }

  return <div className={cellClassName}>{text}</div>
}

ViewCell.propTypes = {
  data: PropTypes.object,
}

export default ViewCell
