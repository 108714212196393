import React from 'react'
import renderer from 'react-test-renderer'

import { StyledTabs } from '../../styles/tabs'

describe.skip('StyledTabs', () => {
  it('applies justify-content by default', () => {
    const tree = renderer.create(<StyledTabs />).toJSON()
    expect(tree).toHaveStyleRule('justify-content', 'flex-start')
  })

  it('applies justify-content props style', () => {
    const tree = renderer
      .create(<StyledTabs justifyContent={'flex-end'} />)
      .toJSON()
    expect(tree).toHaveStyleRule('justify-content', 'flex-end')
  })
})
