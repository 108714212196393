import React from 'react'
import PropTypes from 'prop-types'
import { Accordion } from 'react-bootstrap'

import LogItemHeader from '../LogItemHeader'

export default function AdminEdited(props) {
  const { data, id, open, onClick } = props

  return (
    <div>
      <div
        onClick={() => {
          onClick(id)
        }}
      >
        <LogItemHeader data={data} />
      </div>
      <Accordion.Collapse eventKey={id} in={open}>
        <div className="log-item-content__container">
          <div className="log-item-content-header__container">
            <p className="log-item__text">{data.content_title}</p>
          </div>
          <div className="log-item-content__card">
            <table className="table">
              <thead>
                <tr>
                  <th>Feld</th>
                  <th>Geändert von</th>
                  <th>Geändert zu</th>
                </tr>
              </thead>
              <tbody>
                {data.content_text.map((row, index) => (
                  <tr key={index}>
                    <td>{row.field}</td>
                    <td>
                      {typeof row.changed_from === 'object'
                        ? JSON.stringify(row.changed_from)
                        : row.changed_from}
                    </td>
                    <td>
                      {typeof row.changed_to === 'object'
                        ? JSON.stringify(row.changed_to)
                        : row.changed_to}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Accordion.Collapse>
    </div>
  )
}

AdminEdited.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  open: PropTypes.bool,
  onClick: PropTypes.func,
}
