import client from '../../../../client'

const getLifeCycles = (requestId) => {
  return client
    .get(`/admin/v2/requests/${requestId}/life_cycles.json`)
    .then((response) => response.json())
    .catch((err) => console.error(err))
}

const getPhases = (requestId) => {
  return client
    .get(`/admin/v2/requests/${requestId}/phases.json`)
    .then((response) => response.json())
    .catch((err) => console.error(err))
}

const getStatuses = (requestId) => {
  return client
    .get(`/admin/v2/requests/${requestId}/statuses.json`)
    .then((response) => response.json())
    .catch((err) => console.error(err))
}

const getReasons = (requestId, status) => {
  var statusId = status.split('#')[1]
  return client
    .get(`/admin/v2/requests/${requestId}/statuses/${statusId}/reasons.json`)
    .then((response) => response.json())
    .catch((err) => console.error(err))
}

const savePhase = (requestId, valuesArray, selectedActions) => {
  const triggersIds = valuesArray.filter((item) => item)
  const data = {
    triggers: triggersIds,
    actions: selectedActions,
  }

  return client
    .post(`/admin/v2/requests/${requestId}/mass_triggers.json`, data)
    .catch((err) => console.error(err))
}

const getActions = (paramsArray, requestId) => {
  const params = paramsArray
    .filter((item) => item)
    .reduce((acc, item) => {
      acc += `triggers[]=${item.replace('#', '%23')}&`
      return acc
    }, '')

  let url = `/admin/v2/requests/${requestId}/mass_triggers/new.json?${params.substring(
    0,
    params.length - 1,
  )}`
  return client.get(url)
}

const getSavedStatusesAndReasons = (requestId) => {
  return client
    .get(`/admin/v2/requests/${requestId}/histories.json`)
    .then((response) => response.json())
    .catch((err) => console.error(err))
}

const deleteStatus = (requestId, reasonRequestStatusId) => {
  return client
    .delete(
      `/admin/v2/requests/${requestId}/statuses/${reasonRequestStatusId}.json`,
    )
    .then((response) => response.json())
    .catch((err) => console.error(err))
}

export {
  getLifeCycles,
  getPhases,
  savePhase,
  getActions,
  getStatuses,
  getReasons,
  getSavedStatusesAndReasons,
  deleteStatus,
}
