import React, { useState } from 'react'

import LogItem from './LogItem'
import Button from '../engine-ui/Button'
import Tooltip from '../engine-ui/Tooltip'
import { Accordion } from 'react-bootstrap'

export default function LogList(props) {
  const { data, refetch } = props
  const [openItems, setOpenItems] = useState([])

  const handleClick = (eventKey) => {
    if (openItems.includes(eventKey)) {
      const index = openItems.indexOf(eventKey)
      setOpenItems([
        ...openItems.slice(0, index),
        ...openItems.slice(index + 1),
      ])
    } else setOpenItems([...openItems, eventKey])
  }

  const collapse = () => {
    setOpenItems([])
  }

  const expand = () => {
    setOpenItems(data.map((item) => item.id))
  }

  return (
    <div>
      <div className="d-flex">
        <Tooltip
          className="btn--no-styles"
          text="Alle ausklappen"
          placement="top"
        >
          <Button onClick={expand} className="btn--no-styles me-2 mb-2">
            <i className="fas fa-caret-square-down activitylog__color--link" />
          </Button>
        </Tooltip>
        <Tooltip
          className="btn--no-styles"
          text="Alle einklappen"
          placement="top"
        >
          <Button onClick={collapse} className="btn--no-styles mb-2">
            <i className="fas fa-caret-square-up activitylog__color--link" />
          </Button>
        </Tooltip>
      </div>
      <Accordion>
        {data &&
          data.map((item, index) => (
            <LogItem
              data={item}
              key={item.id}
              id={item.id}
              open={openItems.includes(item.id)}
              previousDate={
                index == 0 ? moment() : moment(data[index - 1].date)
              }
              onClick={handleClick}
              refetch={refetch}
            />
          ))}
      </Accordion>
    </div>
  )
}
