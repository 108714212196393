import React from 'react'
import PropTypes from 'prop-types'
import { Accordion } from 'react-bootstrap'

import LogItemHeader from '../LogItemHeader'

export default function Payment(props) {
  const { data, id, open, onClick } = props
  const {
    payment_id,
    parent_id,
    entry_id,
    amount,
    direction,
    primary_party,
    payment_date,
    category,
    payment_type,
    court_instance,
    party,
    payment_purpose,
    comment,
    on_hold,
  } = data.content_info

  return (
    <div>
      <div
        onClick={() => {
          onClick(id)
        }}
      >
        <LogItemHeader data={data} />
      </div>
      <Accordion.Collapse eventKey={id} in={open}>
        <div className="log-item-content__container">
          <div className="log-item-content__card">
            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Parent ID</th>
                  <th>Entry ID</th>
                  <th>Betrag</th>
                  <th>Richtung</th>
                  <th>Konto</th>
                  <th className="break-long-word">Belegdatum</th>
                  <th>Kategorie</th>
                  <th>Leistungsart</th>
                  <th>Instanz</th>
                  <th>Partei</th>
                  <th>VWZ</th>
                  <th>Kommentar</th>
                  <th className="break-long-word">Zurückgestellt</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{payment_id}</td>
                  <td>{parent_id}</td>
                  <td>{entry_id}</td>
                  <td>{amount}</td>
                  <td>{direction}</td>
                  <td>{primary_party}</td>
                  <td>{payment_date}</td>
                  <td>{category}</td>
                  <td>{payment_type}</td>
                  <td>{court_instance}</td>
                  <td>{party}</td>
                  <td>{payment_purpose}</td>
                  <td>{comment}</td>
                  <td>{on_hold}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Accordion.Collapse>
    </div>
  )
}

Payment.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  open: PropTypes.bool,
  onClick: PropTypes.func,
}
