import React from 'react'
import Form from 'react-bootstrap/Form'
import FormSelect from 'react-bootstrap/FormSelect'
import Button from '../engine-ui/Button'
import PropTypes from 'prop-types'
import classNames from 'classnames'

function StatusAndReasonSelect(props) {
  const {
    index,
    status,
    reason,
    valid,
    onStatusChange,
    onReasonChange,
    renderStatusOptions,
    renderReasonOptions,
    removeNewStatus,
    existingStatusAndReason,
    reasonIsMissing,
  } = props

  const reasonOptions = renderReasonOptions(status)

  return (
    <div>
      <div className="box-status">
        <div className="select-status mb-2">
          <Form.Group>
            <FormSelect
              onChange={() => onStatusChange(event, index)}
              disabled={existingStatusAndReason || valid}
              value={status || ''}
            >
              <option></option>
              {renderStatusOptions()}
            </FormSelect>
          </Form.Group>
        </div>
        <div className="select-reason mb-2">
          <Form.Group>
            <FormSelect
              onChange={() => onReasonChange(event, index)}
              value={reason || ''}
              disabled={
                existingStatusAndReason || valid || reasonOptions.length == 0
              }
              required={!existingStatusAndReason && reasonOptions.length !== 0}
            >
              <option></option>
              {reasonOptions}
            </FormSelect>
          </Form.Group>
          {reasonIsMissing && reasonOptions.length !== 0 && !reason ? (
            <h5 className="actions-triggered__item-ttl mb-3">
              <i className="fas fa-exclamation-triangle text-danger mb-1" />
              Bitte einen Reason auswählen
            </h5>
          ) : null}
        </div>
        <Button
          className={classNames('btn', 'mb-3', 'pr-0', {
            'd-none': existingStatusAndReason,
          })}
          disabled={valid}
          onClick={() => {
            removeNewStatus(index)
          }}
        >
          <i className="fas fa-lg fa-trash-alt"></i>
        </Button>
      </div>
    </div>
  )
}

StatusAndReasonSelect.propTypes = {
  index: PropTypes.number,
  status: PropTypes.string,
  reason: PropTypes.string,
  valid: PropTypes.bool,
  onStatusChange: PropTypes.func,
  onReasonChange: PropTypes.func,
  renderReasonOptions: PropTypes.func,
  renderStatusOptions: PropTypes.func,
  removeNewStatus: PropTypes.func,
  existingStatusAndReason: PropTypes.bool,
}

export default StatusAndReasonSelect
