import moment from 'moment'

const formatStringToNumber = (str) => {
  return parseFloat(str.replace(/\./g, '').replace(',', '.'))
}

const formatNumberToString = (num) => {
  return parseFloat(num).toFixed(2).toString().replace(/\./, ',')
}

const formatDateShort = (date) => {
  return moment(date).locale('de').format('DD.MM.YYYY')
}

const formatDateLong = (date) => {
  return moment(date).locale('de').format('dd, DD.MM.YYYY HH:mm')
}

export {
  formatStringToNumber,
  formatNumberToString,
  formatDateShort,
  formatDateLong,
}
