import React from 'react'
import useContentPages from '../useContentPages'

describe('useContentPages', () => {
  let dispatch
  beforeEach(() => {
    dispatch = jest.fn()
    React.useContext = jest.fn(() => ({
      dispatch,
      state: { contentPages: {} },
    }))
  })

  afterEach(() => {
    dispatch.mockClear()
    React.useContext.mockReset()
  })

  describe('useContentPages properties', () => {
    test('should return contentPages properties', () => {
      expect(useContentPages()).toHaveProperty('contentPages')
      expect(useContentPages()).toHaveProperty('initializeContentPages')
      expect(useContentPages()).toHaveProperty('removeContentPages')
    })
  })

  describe('useContentPages: initializeContentPages', () => {
    test('should call dispatch initializeContentPages once', () => {
      const { initializeContentPages } = useContentPages()
      initializeContentPages({})
      expect(dispatch.mock.calls.length).toEqual(1)
    })
  })

  describe('useContentPages: removeContentPages', () => {
    test('should call dispatch removeContentPages once', () => {
      const { removeContentPages } = useContentPages()
      removeContentPages({})
      expect(dispatch.mock.calls.length).toEqual(1)
    })
  })
})
