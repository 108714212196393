import React from 'react'
import PropTypes from 'prop-types'

import Input from './input'

const Group = ({ group, handleFormChange, questions, handleBlur }) => (
  <div className="form-input form-group form-select col-sm-12">
    <label className="form-select-label">
      <span>{group.text}</span>
    </label>
    <div className="validation-message">Bitte füllen Sie dieses Feld aus</div>
    {group.questions.map((question) => (
      <Input
        key={question.name}
        question={question}
        questions={questions}
        handleFormChange={handleFormChange}
        handleBlur={handleBlur}
      />
    ))}
  </div>
)

Group.propTypes = {
  group: PropTypes.object.isRequired,
  questions: PropTypes.object.isRequired,
  handleFormChange: PropTypes.func,
  handleBlur: PropTypes.func,
}

export default Group
