import React, { useState } from 'react'
import { Badge } from './TeamFilter'
import { InView } from 'react-intersection-observer'
import client from '../../../../client'
import { sPrintQuery } from './Filter'

const TodoOverview = (props) => {
  const { basePath, searchParams, currentAgent } = props

  const [overDueTicketCount, setOverDueTicketCount] = useState(null)
  const [urgentTicketCount, setUrgentTicketCount] = useState(null)

  const loadTicketCount = (params = {}, callback) => {
    client
      .get(
        '/admin/v2/agent/tickets_stats?' +
          sPrintQuery({ ...searchParams, ...params }),
      )
      .then((response) => response.json())
      .then(callback)
  }
  const loadOverDueTicketCount = () => {
    loadTicketCount({ due_yesterday: true }, setOverDueTicketCount)
  }
  const loadUrgentTicketCount = () => {
    loadTicketCount({ due_before_tomorrow: true }, setUrgentTicketCount)
  }

  return (
    <li className="sidebar__group">
      <InView
        triggerOnce={true}
        onChange={(inView) => {
          if (inView) {
            loadUrgentTicketCount()
          }
        }}
      >
        <a
          className="sidebar__header"
          href={`${basePath}?${sPrintQuery({ ...searchParams })}`}
        >
          <i className="fas fa-share-square"></i>
          <span className="mx-1">Todos</span>
          <Badge count={urgentTicketCount} />
        </a>
      </InView>
      <ul>
        <li className="sidebar__item">
          <InView
            triggerOnce={true}
            onChange={(inView) => {
              if (inView) {
                loadOverDueTicketCount()
              }
            }}
          >
            <a
              className="sidebar__header"
              href={`${basePath}?${sPrintQuery({
                ...searchParams,
                filter: 'due_yesterday',
                current_agent: currentAgent,
              })}`}
            >
              <i className="far fa-share-square"></i>
              <span className="mx-1">Überfällig</span>
              <Badge count={overDueTicketCount} />
            </a>
          </InView>
        </li>
        <li className="sidebar__item">
          <a
            href={`${basePath}?${sPrintQuery(searchParams)}`}
            className="sidebar__header"
          >
            <i className="far fa-share-square"></i>
            <span className="mx-1">Alle Offenen</span>
          </a>
        </li>
        <li className="sidebar__item">
          <a
            href={`${basePath}?${sPrintQuery({
              ...searchParams,
              state: 'completed',
            })}`}
            className="sidebar__header"
          >
            <i className="far fa-check-square"></i>
            <span className="mx-1">Abgearbeitet</span>
          </a>
        </li>
      </ul>
    </li>
  )
}

export default TodoOverview
