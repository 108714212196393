import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import SearchableSelect from '../engine-ui/SearchableSelect'

export default function Form(props) {
  const {
    funnelDataFields,
    evidenceOptions,
    onCancelForm,
    data,
    onFormSubmit,
    files,
    mode,
  } = props

  const [formValues, setFormValues] = useState({
    question: '',
    question_id: '',
    files: [],
  })

  useEffect(() => {
    if (data) {
      setFormValues({
        question: data.question,
        question_id: data.question_id,
        files: [],
      })
    }
  }, [])

  const onChange = (values) => {
    if (!values) {
      setFormValues({
        ...formValues,
        question: '',
        question_id: '',
      })
    } else {
      const { label, value } = values
      setFormValues({
        ...formValues,
        question: label,
        question_id: value,
      })
    }
  }

  const onMultiSelectionChange = (newFiles) => {
    const newFilesWithData = newFiles.map((item) => {
      const found = files.find((file) => file.id == item.value)
      return {
        id: found.id,
        filename: found.filename,
        type: found.type,
        uploaded_by: found.uploaded_by,
        marked_as_evidence_at: moment().format(),
      }
    })

    setFormValues({
      ...formValues,
      files: newFilesWithData,
    })
  }

  const onSubmit = () => {
    onFormSubmit(formValues)
    onCancelForm()
  }

  const isInvalid = !formValues.question
  const value = data ? data.question_id : ''
  const filesPlaceholder =
    mode === 'new' ? 'Dokumente hinzufügen' : 'Weitere Dokumente hinzufügen'

  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className="d-flex w-50">
        <div className="w-100">
          <SearchableSelect
            options={funnelDataFields}
            value={value}
            isClearable={true}
            isMulti={false}
            onChange={onChange}
            placeholder={'Titel wählen'}
          />
        </div>
        <div className="ms-4 w-100">
          <SearchableSelect
            options={evidenceOptions}
            isClearable={false}
            isMulti={true}
            onChange={onMultiSelectionChange}
            placeholder={filesPlaceholder}
          />
        </div>
      </div>
      <div className="d-flex viewtab__editnav">
        <div className="d-flex align-items-center">
          <button
            className="btn mt-0 btn btn-secondary btn-rounded"
            onClick={onCancelForm}
          >
            Abbrechen
          </button>
          <button
            className="btn btn-primary btn-rounded mx-3 mt-0 df-submit"
            onClick={onSubmit}
            disabled={isInvalid}
          >
            Speichern
          </button>
        </div>
      </div>
    </div>
  )
}

Form.propTypes = {
  funnelDataFields: PropTypes.array,
  evidenceOptions: PropTypes.array,
  onCancelForm: PropTypes.func,
  data: PropTypes.object,
  onFormSubmit: PropTypes.func,
  files: PropTypes.array,
  mode: PropTypes.string,
}
