import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Collapse } from 'react-bootstrap'

export default function SelectDrafts(props) {
  const [open, setOpen] = useState(false)

  const { drafts, currentDraftId, onSelectDraft, onDeleteDraft } = props

  const handleClick = () => setOpen(!open)

  const renderDraftItem = (item) => {
    const handleClick = (e) => {
      e.preventDefault()
      onSelectDraft(item.id)
    }

    const handleDelete = (e) => {
      e.preventDefault()
      const isConfirmed = confirm(
        'Die Entwurf löschen? Die Änderungen werden nicht gespeichert!',
      )
      if (!isConfirmed) {
        return
      }
      onDeleteDraft(item.id)
    }

    const reciever = item.replyTo
      ? [`${item.replyTo.email} - ${item.replyTo.responseType}`]
      : [
          ...(item.receiver?.filter((el) => el != 'null') ?? []),
          item.customEmail,
        ]

    return (
      <label
        className={`draft-item mb-1 ${
          item.id === currentDraftId ? 'current-draft' : ''
        }`}
        key={`draft-id-${item.id}`}
      >
        <a href="#" onClick={handleDelete} data-id={item.id}>
          <i className="fas fa-trash text-danger me-1" />
        </a>
        <a href="#" onClick={handleClick} data-id={item.id}>
          zuletzt gespeichert am {item.lastSavedDraftOn},{' '}
          <strong>BETREFF: {item.subject}</strong> von {item.sender} bis{' '}
          {reciever.filter((i) => i).join(', ')}
        </a>
      </label>
    )
  }

  return (
    <>
      <div className="MailerApp__drafts-btn" onClick={handleClick}>
        <i className={`fas fa-chevron-${open ? 'up' : 'down'} me-1 fa-sm`} />
        {drafts.length} Entwürfe gespeichert
      </div>
      <Collapse in={open}>
        <div className="draft-rows">
          <div className="d-flex flex-column">
            {drafts.map(renderDraftItem)}
          </div>
        </div>
      </Collapse>
    </>
  )
}

SelectDrafts.propTypes = {
  drafts: PropTypes.array.isRequired,
  currentDraftId: PropTypes.string.isRequired,
  onSelectDraft: PropTypes.func.isRequired,
  onDeleteDraft: PropTypes.func.isRequired,
}

SelectDrafts.defaultProps = {
  drafts: [],
}
