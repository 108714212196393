import styled from 'styled-components'

const colors = {
  automatic_answer_different: '#e56d22',
  low: '#e82201',
  average: '#e5a022',
  high: '#2e9057',
  unknown: '#4c8dff',
  success: '#2ecc71',
  error: '#e82201',
  neutral: 'white',
}

export const Container = styled.div`
  padding: 1.5rem;
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
`
export const ContentContainer = styled.div`
  margin-top: 1rem;
`

export const FormContainer = styled.div`
  display: ${(props) => (props.isStepRent ? 'flex' : 'block')};
  flex-wrap: wrap;
  justify-content: space-between;
`

export const FieldGroup = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isStepRent ? 'column' : 'row')};
  justify-content: space-between;
  margin-bottom: 0.5rem;
  width: ${(props) => (props.isStepRent ? '48%' : '100%')};
`

export const Label = styled.label`
  font-size: 0.7rem;
  color: white;
  width: 180px;
  margin-bottom: 0;
`

export const InputBox = styled.div`
  width: 100%;
`

export const SuggestionSpan = styled.span`
  border: 1px solid transparent;
  background-color: ${(props) => colors[props.backgroundColor]};
  cursor: pointer;
  max-width: 34px;
  display: flex;
  justify-content: center;

  &:active {
    filter: brightness(150%);
  }
`

SuggestionSpan.defaultProps = {
  backgroundColor: 'unknown',
}

export const SubmitMessage = styled.p`
  margin: 1rem 0 0;
  height: 0.8rem;
  font-size: 0.8rem;
  font-weight: 600;
  color: ${(props) => colors[props.color]};
`

SubmitMessage.defaultProps = {
  color: 'neutral',
}

export const Button = styled.button`
  align-self: flex-end;
  background-color: #2ecc71;
  color: #f7f7f9;
  border-radius: 20px;
  border: 1px solid #2ecc71;
  font-weight: 600;
  font-size: 0.8rem;
  padding: 0.375rem 1rem;
  width: 120px;
  margin-top: 1rem;

  &:hover:not(:disabled) {
    filter: brightness(80%);
  }

  &:active:not(:disabled) {
    filter: brightness(60%);
  }

  &:disabled {
    opacity: 0.65;
  }
`
