import React, { useState, useContext } from 'react'
import { IFeedback } from '../types'
import client from '../../../../../client'
import {
  FeedbackContext,
  deleteFeedbacks,
  editFeedbacks,
} from '../store/feedbacksStore'
import ActionButton from '../ActionButton'
import TextArea from '../../engine-ui/TextArea'

interface IProps {
  feedback: IFeedback
}

interface IInputEvent {
  name: string
  value: string
}

export default function Feedback({ feedback }: IProps) {
  const [, dispatch] = useContext(FeedbackContext)
  const [editMode, setEditMode] = useState<boolean>(false)
  const [note, setNote] = useState(feedback.note)

  const switchToEditMode = () => {
    setEditMode(true)
  }

  const handleTextAreaChange = (event: IInputEvent) => {
    setNote(event.value)
  }

  const handleUpdateFeedback = () => {
    const UPDATE_URL = `/admin/requests/${feedback.request_id}/feedbacks/${feedback.id}`

    client
      .patch(UPDATE_URL, { note })
      .then(() => {
        dispatch(
          editFeedbacks({
            ...feedback,
            note,
          }),
        )
      })
      .finally(() => setEditMode(false))

    setEditMode(false)
  }

  const handleUpdateCancel = () => {
    setEditMode(false)
  }

  const handleDeleteFeedback = () => {
    const DELETE_URL = `/admin/requests/${feedback.request_id}/feedbacks/${feedback.id}`
    const result = confirm('Sind Sie sicher?')

    if (result) {
      client.delete(DELETE_URL).then(() => {
        dispatch(deleteFeedbacks(feedback))
      })
    }
  }

  if (editMode) {
    return (
      <tr>
        <td>{feedback.created_at}</td>
        <td>
          <TextArea
            name="note"
            className="form-control"
            value={note}
            onChange={handleTextAreaChange}
            rows="4"
          />
        </td>
        <td>{feedback.agent}</td>
        <td>
          <div className="actions d-flex justify-content-end pe-4">
            <ActionButton
              action={{
                icon: 'fas fa-check',
                link: '#',
                text: 'Speichern',
                color: '',
              }}
              onClick={handleUpdateFeedback}
            />
            <ActionButton
              action={{
                icon: 'fas fa-times',
                link: '#',
                text: 'Abbrechen',
                color: '',
              }}
              onClick={handleUpdateCancel}
            />
          </div>
        </td>
      </tr>
    )
  }

  return (
    <tr>
      <td>{feedback.created_at}</td>
      <td>{feedback.note}</td>
      <td>{feedback.agent}</td>
      <td>
        <div className="actions d-flex justify-content-end pe-4">
          <ActionButton
            action={{
              icon: 'fas fa-edit',
              link: '#',
              text: 'Bearbeiten',
              color: '',
            }}
            onClick={switchToEditMode}
          />
          <ActionButton
            action={{
              icon: 'fas fa-trash-alt',
              link: '#',
              text: 'Löschen',
              color: '',
            }}
            onClick={handleDeleteFeedback}
          />
        </div>
      </td>
    </tr>
  )
}
