import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export default class SelectSender extends React.Component {
  constructor(props) {
    super(props)
  }

  setSelected = (key) => {
    this.props.updateParent('sender', key)
  }

  unselected = (key) => {
    return this.props.selected != '' && this.props.selected != key
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ selected: nextProps.selected })
  }

  generateButtons() {
    return this.props.items.map((item) => {
      return (
        <a
          className={classNames('from-selector', item.key, {
            unselected: this.unselected(item.key),
          })}
          key={'sender-tab-' + item.key}
          id={'from-' + item.key}
          onClick={() => this.setSelected(item.key)}
          style={{ backgroundColor: item.color.box }}
        >
          <div className="text">
            <div className="name">{item.string}</div>
          </div>
        </a>
      )
    })
  }

  render() {
    return (
      <div className="from-row input-rows">
        <div className="label"> VON: </div>
        <div className="field d-flex flex-row"> {this.generateButtons()}</div>
      </div>
    )
  }
}

SelectSender.propTypes = {
  items: PropTypes.array.isRequired,
  selected: PropTypes.string,
  updateParent: PropTypes.func,
}
