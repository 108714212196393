import React from 'react'
import PropTypes from 'prop-types'
import Select from '../engine-ui/Select'

const Filter = (props) => {
  const { value, options, onChange } = props

  return (
    <div className="d-flex justify-content-end my-3">
      <Select
        name="todo_type"
        value={value['todo_type']}
        onChange={onChange}
        options={[{ value: '', text: 'Alle Fristen-Todos' }, ...options]}
      />
    </div>
  )
}

Filter.propTypes = {
  value: PropTypes.object,
  options: PropTypes.array,
  onChange: PropTypes.func,
}

export default Filter
