import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { debounce } from 'lodash/fp'
import Input from './Input'
import Suggestions from './Suggestions'

const Search = (props) => {
  const [data, setData] = useState([])
  const [query, setQuery] = useState('')
  const [_, setSearchQuery] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const getResults = (search) => {
    setIsLoading(true)
    fetch(`${props.url}?search=${search}`, {
      headers: {
        token: props.token,
      },
    })
      .then((data) => data.json())
      .then((result) => {
        setData(result.data)
        setIsLoading(false)
      })
      .catch((error) => {
        console.log('No data found!', error)
      })
  }

  const handleQueryChange = ({ target: { value } }) => {
    setQuery(value)
    if (value.length < 3) {
      return
    }

    const search = debounce(1000)(getResults)
    setSearchQuery((prevSearch) => {
      if (prevSearch.cancel) {
        prevSearch.cancel()
      }
      return search
    })
    search(value)
  }

  return (
    <div className="mx-3">
      <Input handleQueryChange={handleQueryChange} query={query} />
      <div className="result__container my-3">
        {isLoading ? (
          <div className="modal-lifecycle__spinner">
            <span className="loader">
              <span className="loader-inner"></span>
            </span>
          </div>
        ) : (
          <Suggestions data={data} query={query} />
        )}
      </div>
    </div>
  )
}

Search.propTypes = {
  url: PropTypes.string,
  token: PropTypes.string,
}

export default Search
