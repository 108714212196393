import React from 'react'
import TriggeredAction from './TriggeredAction'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export default function TriggeredActionsList(props) {
  const {
    data,
    showCheckboxes,
    onActionCheck,
    selectedActions,
    className,
    withSelectAll,
  } = props

  if (data.length) {
    return (
      <div className="mt-3">
        {showCheckboxes && withSelectAll && (
          <div className="actions-triggered__item">
            <input
              type="checkbox"
              checked={
                selectedActions &&
                data.every((action) => selectedActions.includes(action.id))
              }
              id="select-all"
              onChange={onActionCheck}
              className="mt-1 me-3"
            />
            <i className="actions-triggered__item-ttl">select all</i>
          </div>
        )}

        {data.map((action, index) => {
          return (
            <TriggeredAction
              key={index}
              action={action}
              showCheckboxes={showCheckboxes}
              className={className}
              checked={selectedActions && selectedActions.includes(action.id)}
              onActionCheck={onActionCheck}
            />
          )
        })}
      </div>
    )
  }
  if (data) {
    return (
      <div className="actions-triggered__item mt-4 w-100">
        <h5
          className={classNames('actions-triggered__item-ttl', {
            'actions-triggered__item-ttl--zieb': className === 'zieb',
          })}
        >
          <i className="far fa-check-circle color-primary"></i>
          Es werden keine Aktionen ausgelöst
        </h5>
      </div>
    )
  }
  return null
}

TriggeredActionsList.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onActionCheck: PropTypes.func,
  showCheckboxes: PropTypes.bool,
  selectedActions: PropTypes.array,
  className: PropTypes.string,
}
