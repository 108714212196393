import React from 'react'
import PropTypes from 'prop-types'
import { assign } from 'lodash'

import DocumentName from './document_name'
import DocumentMeta from './document_meta'

const DocumentItem = ({ doc, handleDelete }) => {
  doc = assign(
    {
      percent: 100,
      completed: true,
    },
    doc,
  )

  return (
    <div className="document">
      <div className="document__wrapper col col-md-6">
        <div className="document__icon">
          <i className="far fa-file-alt"></i>
        </div>
        <div className="document__progress_bar">
          <div className="document__progress_background">
            <div
              className="document__progress_actual"
              style={{ width: `${doc.percent}%` }}
            ></div>
            <DocumentName doc={doc} />
          </div>
        </div>

        <DocumentMeta doc={doc} handleDelete={handleDelete} />
      </div>
    </div>
  )
}

DocumentItem.propTypes = {
  doc: PropTypes.object.isRequired,
  handleDelete: PropTypes.func,
}

export default DocumentItem
