import React, { useContext } from 'react'
import { Accordion } from 'react-bootstrap'
import PropTypes from 'prop-types'

import ActionButton from '../ActionButton'
import LogItemHeader from '../LogItemHeader'
import { ActivityLogContext } from '../store'
import Tooltip from '../../engine-ui/Tooltip'

export default function Document(props) {
  const { data, id, open, onClick } = props
  const { filename, sensitive, approved_at, approved_by, attachment } =
    data.content_info

  const dateFormatLong = (date) => {
    return moment(date).locale('de').format('dd, DD.MM.YYYY HH:mm')
  }

  const context = useContext(ActivityLogContext)

  const handleClick = (action) => {
    const documentId = parseInt(id.split('-')[1])
    if (action.title === 'edit')
      V2.trigger('document.show', {
        url: action.url,
        filename: filename,
        documentId: documentId,
      })
    else {
      const isConfirmed = confirm(
        'Es werden keine Dokumente gelöscht, ' +
          'wenn sie mit einer beA-Datei verknüpft sind die sich nicht im "Erstellt"-Status ' +
          'befindet. Dokument sicher löschen?',
      )
      if (!isConfirmed) {
        return
      }
      context.onDelete && context.onDelete(documentId, 'documents')
    }
  }

  const renderActionButtons = () => {
    return data.actions.map((action, index) => {
      return <ActionButton action={action} key={index} onClick={handleClick} />
    })
  }

  return (
    <div>
      <div
        onClick={() => {
          onClick(id)
        }}
      >
        <LogItemHeader data={data} />
      </div>
      <Accordion.Collapse eventKey={id} in={open}>
        <div className="log-item-content__container">
          <div className="log-item-content-header__container">
            <p className="log-item__text">{data.content_title}</p>
            <div className="log-item-content-header__actions">
              {renderActionButtons()}
            </div>
          </div>
          <div className="log-item-content-footer__container">
            <div className={sensitive ? 'color--dark-red' : ''}>
              {sensitive && (
                <Tooltip text="Achtung! Vertrauliche Daten!" placement="top">
                  <i className="fas fa-eye-slash color--dark-red me-1" />
                </Tooltip>
              )}
              {filename}
            </div>
            <div>
              {approved_at && (
                <div>
                  <i className="fas fa-calendar-check footer-icon me-1" />
                  Akzeptiert am {dateFormatLong(approved_at)} von {approved_by}
                </div>
              )}
              {attachment && (
                <div>
                  <i className="fas fa-envelope footer-icon me-1" />
                  Email-Attachment
                </div>
              )}
            </div>
          </div>
        </div>
      </Accordion.Collapse>
    </div>
  )
}

Document.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  open: PropTypes.bool,
  onClick: PropTypes.func,
}
