import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Accordion, { AccordionItem } from '../Accordion'
import TodoBody from './TodoBody'
import TodoForm from './TodoForm'
import Modal from '../engine-ui/Modal'
import Button from '../engine-ui/Button'
import Tooltip from '../engine-ui/Tooltip'
import client from '../../../../client'

export default class TodoContainer extends Component {
  static propTypes = {
    requestId: PropTypes.string,
    todos: PropTypes.array,
    isOpen: PropTypes.bool,
    activeTodo: PropTypes.any,
  }

  state = {
    todos: [],
    displayedTodos: [],
    todoTitleCollection: [],
    todoDeadlineTypeCollection: [],
    todoCategoriesForDeadlineTypeCollection: [],
    todoCategoriesForPrefilledBodyCollection: {},
    teamCollection: [],
    isLoading: false,
    hasLoaded: false,
    fristenEnabled: true,
    action: '',
    formTitle: '',
    formAction: '',
    currentTodo: {},
    modalOpen: false,
    activeTodo: this.props.activeTodo,
  }

  componentDidMount = () => {
    this.fetchTickets(this.state.activeTodo)
    document.addEventListener('phase.updated', this.fetchTickets)
    document.addEventListener('todoRestored', this.handleTodoRestored)
  }

  componentDidUpdate(_, prevState) {
    if (prevState.todos.length === 0 && this.state.todos.length !== 0) {
      const event = new Event('autopilot:start')
      document.dispatchEvent(event)
    }
  }

  componentWillUnmount = () => {
    document.removeEventListener('phase.updated', this.fetchTickets)
    document.removeEventListener('todoRestored', this.handleTodoRestored)
  }

  handleTodoRestored = () => {
    this.fetchTickets(this.state.activeTodo)
  }

  fetchTickets = (activeTodo) => {
    this.setState({ isLoading: true })

    const query = activeTodo ? `?active_todo=${activeTodo}` : ''
    client
      .get(`/admin/v2/requests/${this.props.requestId}/tickets.json` + query)
      .then((response) => response.json())
      .then((data) => {
        this.setState(
          {
            todos: data.todos,
            displayedTodos: data.todos.slice(0, 4),
            currentAgent: data.current_agent,
            todoTitleCollection: data.todoTitleCollection,
            todoDeadlineTypeCollection: data.todoDeadlineTypeCollection,
            todoCategoriesForDeadlineTypeCollection:
              data.todoCategoriesForDeadlineTypeCollection,
            todoCategoriesForPrefilledBodyCollection:
              data.todoCategoriesForPrefilledBodyCollection,
            teamCollection: data.teamCollection,
            isLoading: false,
            hasLoaded: true,
            fristenEnabled: data.fristenEnabled,
          },
          () => {
            const event = new Event('tickets.reloaded')
            document.dispatchEvent(event)
          },
        )
      })
      .catch((error) => {
        console.log('No data found!', error)
      })
  }

  handleModalOpen = () => {
    this.setState({ modalOpen: true })
  }

  handleModalClose = () => {
    this.setState({ modalOpen: false })
  }

  handleChange = ({ name, value }) => {
    const { currentTodo } = this.state
    if (name === 'type') {
      this.setState({
        currentTodo: {
          ...currentTodo,
          [name]: value,
          todo_category_key: value,
        },
      })
    } else {
      this.setState({ currentTodo: { ...currentTodo, [name]: value } })
    }
  }

  preparePayload = (currentTodo) => {
    if (currentTodo.due_time) {
      const formatTime = moment(currentTodo.due_time).toISOString()

      return { ...currentTodo, due_time: formatTime }
    } else {
      return { ...currentTodo, due_time: null }
    }
  }

  handleSubmit = (action) => {
    const { requestId } = this.props
    const { currentTodo } = this.state
    const preparedTodo = this.preparePayload(currentTodo)
    if (action == 'newTodo') {
      return client
        .post(`/admin/v2/requests/${requestId}/applets/generic_ticket`, {
          applet: preparedTodo,
        })
        .then(() => {
          this.fetchTickets()
          this.handleModalClose()
        })
    }

    if (action == 'editTodo') {
      return client
        .patch(`/admin/v2/tickets/${currentTodo.id}`, { ticket: preparedTodo })
        .then(() => {
          this.fetchTickets()
          this.handleModalClose()
        })
    }
  }

  handleNew = () => {
    this.setState(
      {
        action: 'newTodo',
        formTitle: 'erstellen',
        currentTodo: {
          assignee_id: this.state.currentAgent.id,
          todo_category_key: this.state.todoTitleCollection[0].type_key,
          type: this.state.todoTitleCollection[0].type_key,
        },
      },
      this.handleModalOpen,
    )
  }

  handleEdit = (todo) => {
    this.setState(
      {
        action: 'editTodo',
        formTitle: 'bearbeiten',
        currentTodo: {
          ...todo,
          assignee_id: todo.assignee_id,
        },
      },
      this.handleModalOpen,
    )
  }

  handleReschedule = (todo) => {
    return client
      .post(`/admin/v2/tickets/${todo.id}/reschedule.json`, {
        ticket: this.state.currentTodo,
      })
      .then((data) => data.json())
      .then((data) => {
        this.fetchTickets()
        this.setState(
          {
            action: 'editTodo',
            formTitle: 'umplanen',
            currentTodo: {
              ...data.todo,
              assignee_id: todo.assignee_id,
            },
          },
          this.handleModalOpen,
        )
      })
  }

  handleComplete = (todo) => {
    const { id, closing_request } = todo

    const closeTicketsWithAutopilot = () => {
      this.setState({ isLoading: true })
      return client
        .post(`/admin/v2/tickets/${id}/complete.json`, {
          ticket: this.state.currentTodo,
        })
        .then((response) => {
          return response.json()
        })
        .then((data) => {
          if (Object.keys(data).length !== 0) {
            const [nextPath, activeTodoString] = data.redirect_to.split('#')
            if (nextPath == location.pathname) {
              const [_, activeTodoId] = activeTodoString.split('=')
              this.setState({
                activeTodo: activeTodoId,
                isLoading: false,
              })
              this.fetchTickets(activeTodoId)
            } else {
              const nextTicket = window.location.origin + data.redirect_to
              window.location.href = nextTicket
            }
          } else {
            this.fetchTickets()
          }
        })
    }

    if (closing_request) {
      const closingRequestConfirmed = confirm(
        'ACHTUNG: Der Fall wird geschlossen, weil er sich in einer Endphase befindet.',
      )
      if (closingRequestConfirmed) {
        closeTicketsWithAutopilot()
      }
      return
    }

    closeTicketsWithAutopilot()
  }

  handleDelete = (todo) => {
    const { id, closing_request } = todo
    const confirmDelete = confirm('Soll das ToDo wirklich gelöscht werden?')
    let closingRequestConfirmedIfNeeded = true

    if (confirmDelete) {
      if (closing_request) {
        closingRequestConfirmedIfNeeded = confirm(
          'ACHTUNG: Der Fall wird geschlossen, weil er sich in einer Endphase befindet.',
        )
      }

      if (closingRequestConfirmedIfNeeded) {
        const clean_url =
          location.protocol + '//' + location.host + location.pathname
        window.history.pushState({}, document.title, clean_url)
        return client
          .delete(`/admin/v2/tickets/${id}`)
          .then(() => this.fetchTickets())
      }
    }
  }

  createTodo = () => {
    if (
      this.state.hasLoaded &&
      !this.state.isLoading &&
      this.state.todos.length == 0
    ) {
      return (
        <div className="create-todo" id="add_todo">
          <Button onClick={this.handleNew}>Todo erstellen</Button>
        </div>
      )
    }
  }

  renderFristenField = (data) => {
    if (this.state.fristenEnabled) {
      const fristenClasses =
        'me-3 px-1 d-flex align-items-center justify-content-center todo_fristen todo_fristen__' +
        data.deadline_type

      return (
        <div className={fristenClasses}>
          <i className="fas fa-calendar-o"></i>
          <div className="me-1 ms-1">Frist:</div>
          <strong>{data.deadline_type_display_name}</strong>
        </div>
      )
    }
  }

  renderTitle = (data) => {
    const dueTime = moment(data.due_time).format('HH:mm')
    const dueOn = moment(data.due_on, 'D.M.YYYY').format('DD.MM.YYYY')

    return (
      <Fragment>
        <strong className="me-3">{data.assignee.shorthand}</strong>
        <span className="me-3">{dueOn}</span>
        <strong className="title">{data.title}</strong>
        {data.due_time && (
          <span className="mx-3 d-flex align-items-center">
            <i className="far fa-clock me-1"></i>
            {dueTime}
          </span>
        )}
        {this.renderFristenField(data)}
      </Fragment>
    )
  }

  renderBtnsContainer = () => {
    const totalTodoCount = this.state.todos.length
    return (
      <>
        {totalTodoCount > 4 && (
          <div className="todo-container-navigation">
            <div
              className="todo-header-btn"
              onClick={() => this.handleNavClick('up')}
            >
              <i className="fas fa-chevron-circle-up"></i>
            </div>

            <div
              className="todo-header-btn"
              onClick={() => this.handleNavClick('down')}
            >
              <i className="fas fa-chevron-circle-down"></i>
            </div>
          </div>
        )}
      </>
    )
  }

  handleNavClick = (direction) => {
    const { todos, displayedTodos } = this.state
    const position = todos.map((item) => item.id).indexOf(displayedTodos[0].id)

    if (
      (direction === 'down' &&
        position === todos.length - displayedTodos.length) ||
      (direction === 'up' && position === 0)
    )
      return

    const nextSet = () => {
      if (direction === 'down') {
        return todos.slice(position + 1, position + 5)
      } else return todos.slice(position - 1, position + 3)
    }

    this.setState({ displayedTodos: nextSet() })
  }

  render() {
    const todoCount = this.state.displayedTodos.length
    const navigationClassName = this.state.todos.length > 4 ? 'me-4' : ''

    return (
      <Fragment>
        <div className="todo-container">
          <div
            className={classNames(
              'todo-header-btns-container',
              navigationClassName,
            )}
          >
            <div
              id="add_todo"
              className="todo-plus-btn"
              onClick={this.handleNew}
            >
              <Tooltip text="Neues Todo erstellen" placement="left">
                <i className="fas fa-plus"></i>
              </Tooltip>
            </div>
          </div>

          <Accordion
            containerClassName="todos todo_accordion"
            blockName="ticket_accordion"
          >
            {this.state.displayedTodos.map((todo, index) => (
              <AccordionItem
                key={index}
                isOpen={index === 0}
                id={index}
                value={todo.id}
                title={this.renderTitle(todo)}
                activeItemClassName="active"
              >
                <Fragment>
                  <TodoBody
                    todo={todo}
                    onSubmit={this.handleSubmit}
                    onDelete={this.handleDelete}
                    onComplete={this.handleComplete}
                    onReschedule={this.handleReschedule}
                    onEdit={this.handleEdit}
                    onChange={this.handleChange}
                    currentTodo={this.state.currentTodo}
                    todoCount={todoCount}
                    requestId={this.props.requestId}
                    isLoading={this.state.isLoading}
                  />
                </Fragment>
              </AccordionItem>
            ))}
          </Accordion>
          {this.renderBtnsContainer()}
        </div>
        {this.createTodo()}
        <Modal
          onClose={this.handleModalClose}
          isOpen={this.state.modalOpen}
          noBackdrop={true}
        >
          <TodoForm
            titleCollection={this.state.todoTitleCollection}
            teamCollection={this.state.teamCollection}
            deadlineTypeCollection={this.state.todoDeadlineTypeCollection}
            categoriesForDeadlineTypeCollection={
              this.state.todoCategoriesForDeadlineTypeCollection
            }
            categoriesForPrefilledBodyCollection={
              this.state.todoCategoriesForPrefilledBodyCollection
            }
            fristenEnabled={this.state.fristenEnabled}
            data={this.state.currentTodo}
            action={this.state.action}
            formTitle={this.state.formTitle}
            formAction={this.state.formAction}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
          />
        </Modal>
      </Fragment>
    )
  }
}
