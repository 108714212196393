import React from 'react'
import { Accordion } from 'react-bootstrap'
import PropTypes from 'prop-types'
import LogItemHeader from '../LogItemHeader'
import ActionButton from '../ActionButton'

export default function BeaFile(props) {
  const { id, data, open, onClick, token, refetch } = props
  const { status, updated_at, main_file, attachments } = data.content_info

  const renderPdf = (file, attachments) => {
    return (
      <div>
        <a href={file.signed_url} target="_blank" rel="noopener noreferrer">
          <i className="fas fa-file-pdf footer-icon me-2" />
          [MAIN] {file.filename}
          <i className="fas fa-external-link-alt footer-icon ms-1" />
        </a>
        {attachments.map((attachment, _index) => (
          <a
            href={attachment.signed_url}
            key={attachment.filename}
            target="_blank"
            rel="noopener noreferrer"
            className="bea_file_attachment"
          >
            <i className="fas fa-file-pdf footer-icon me-2" />
            [ATTACHMENT] {attachment.filename}
            <i className="fas fa-external-link-alt footer-icon ms-1" />
          </a>
        ))}
      </div>
    )
  }

  const dateFormatLong = (date) => {
    return moment(date).locale('de').format('dd, DD.MM.YYYY HH:mm')
  }

  const handleClick = (action) => {
    if (action.title === 'delete') {
      const isConfirmed = confirm('Dokument sicher löschen?')

      if (!isConfirmed) {
        return
      }

      fetch(action.url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          token: token,
          'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
        },
      }).then(() => refetch())
    }
  }

  const renderActionButtons = () => {
    return data.actions.map((action, index) => {
      return <ActionButton action={action} key={index} onClick={handleClick} />
    })
  }

  return (
    <div>
      <div
        onClick={() => {
          onClick(id)
        }}
      >
        <LogItemHeader data={data} />
      </div>
      <Accordion.Collapse eventKey={id} in={open}>
        <div className="log-item-content__container">
          <div className="log-item-content-header__container">
            <div className="log-item__text"> {data.details_2}</div>
            <div className="log-item-content-header__actions">
              {renderActionButtons()}
            </div>
          </div>
          <div className="log-item-content__card">
            <pre className="log-item-content__pre">
              {renderPdf(main_file, attachments)}
            </pre>
          </div>
          <div className="log-item-content-footer__container">
            <div>
              <i className="fas fa-calendar footer-icon me-2" />
              <strong>Aktualisiert am:</strong> {dateFormatLong(updated_at)}
            </div>
            <div>
              <i className="far fa-question-circle footer-icon me-1" />
              <strong>Status:</strong> {status}
            </div>
            <div>
              <i className="far fa-question-circle footer-icon me-1" />
              <strong>Created_by:</strong> {data.created_by}
            </div>
          </div>
        </div>
      </Accordion.Collapse>
    </div>
  )
}

Document.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  open: PropTypes.bool,
  onClick: PropTypes.func,
  token: PropTypes.string,
}
