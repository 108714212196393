class Client {
  request(method, url, data, headers = {}) {
    const defaultOptions = {
      method,
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0]?.content,
        ...headers,
      },
    }

    const options = data
      ? { ...defaultOptions, body: JSON.stringify(data) }
      : defaultOptions

    return fetch(url, options)
  }

  get(url, data, headers) {
    return this.request('GET', url, data, headers)
  }

  put(url, data) {
    return this.request('PUT', url, data)
  }

  post(url, data, headers = {}) {
    return this.request('POST', url, data, headers)
  }

  patch(url, data, headers) {
    return this.request('PATCH', url, data, headers)
  }

  delete(url) {
    return this.request('DELETE', url)
  }
}

export default new Client()
