import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import client from '../../../../../client'

const RequestsPerAddress = (props) => {
  const { address, request_id } = props
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [message, setMessage] = useState('loading...')
  const [requests, setRequests] = useState([])

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }
  const loadRequests = () => {
    client
      .get(`/admin/v2/requests/${request_id}/requests_by_building`)
      .then((response) => response.json())
      .then((data) => {
        setRequests(data)
        setIsLoading(false)
      })
      .catch((error) => setMessage(error.toString()))
  }

  useEffect(loadRequests, [])
  const List = (props) => {
    const { requests } = props

    return (
      <>
        {requests.map((request) => (
          <ListItem key={request.id} request={request} />
        ))}
      </>
    )
  }
  const ListItem = (props) => {
    const { request } = props

    return (
      <div className="m-3">
        <a
          className="underlined-link"
          target="_blank"
          href={'/admin/requests/' + request.id}
          rel="noreferrer"
        >
          {request.reference_number}{' '}
        </a>
        - {request.display_life_cycle} {request.marked_as_test && '(Testfall)'}{' '}
        {request.closed && '(geschlossener Fall)'}
      </div>
    )
  }
  return (
    <>
      <a
        className="sidebar__address-link underlined-link"
        onClick={toggleModal}
        href="#"
        data-remote="true"
      >
        {address?.map((line) => {
          return <p key={line}> {line} </p>
        })}
      </a>
      <Modal size="lg" show={isModalOpen} onHide={toggleModal}>
        <Modal.Header closeButton>
          <h5 className="modal-title">Fälle mit der gleichen Adresse</h5>
        </Modal.Header>
        <Modal.Body>
          {isLoading ? <span> {message} </span> : <List requests={requests} />}
          <Modal.Footer>
            <div className="bea-ui__modal-footer">
              <div></div>
            </div>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </>
  )
}
export default RequestsPerAddress
