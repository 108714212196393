import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'

export default class RadioGroup extends Component {
  static propTypes = {
    name: PropTypes.string,
    value: PropTypes.any,
    children: PropTypes.any,
    onChange: PropTypes.func,
    optionClassName: PropTypes.string,
  }

  handleChange = (value) => {
    const { name, onChange } = this.props

    onChange && onChange({ name, value })
  }

  render() {
    const { value, children, optionClassName } = this.props

    return (
      <Fragment>
        {React.Children.map(children, (child) => {
          const { value: option } = child.props

          return React.cloneElement(child, {
            checked: value === option,
            onChange: this.handleChange,
            className: optionClassName,
          })
        })}
      </Fragment>
    )
  }
}
