export default class Operation {
  constructor(operator, field, operand) {
    this.less = (first, second) => {
      if (second.length == 24) {
        // Date e.g. 2019-10-08T09:24:39.608Z
        return Date.parse(first) < Date.parse(second)
      } else if (
        first.length == 21 &&
        (second.length == 21 || second.length == 10)
      ) {
        // Date range e.g. 2012-01-01;2016-12-31
        // Or just Date e.g 2012-01-01
        return new Date(first.split(';')[0]) < new Date(second.split(';')[0])
      }
      return parseFloat(first) < parseFloat(second)
    }
    this.greater = (first, second) => {
      if (second.length == 24) {
        // Date e.g. 2019-10-08T09:24:39.608Z
        return Date.parse(first) > Date.parse(second)
      } else if (first.length == 21 && second.length == 21) {
        // Date range e.g. 2012-01-01;2016-12-31
        return new Date(first.split(';')[1]) > new Date(second.split(';')[1])
      } else if (first.length == 21 && second.length == 10) {
        // Date range e.g. 2012-01-01;2016-12-31
        // Or just Date e.g 2012-01-01
        return new Date(first.split(';')[1]) > new Date(second.split(';')[0])
      }
      return parseFloat(first) > parseFloat(second)
    }
    this.equal = (first, second) => {
      if (first.toString() === second.toString()) return true
      else if (
        first.length == 21 &&
        (second.length == 21 || second.length == 10)
      ) {
        // Date range e.g. 2012-01-01;2016-12-31
        // Or just Date e.g 2012-01-01
        let firstFrom = new Date(first.split(';')[0])
        let firstTo = new Date(first.split(';')[1])
        let secondDate = new Date(second)
        return firstFrom <= secondDate && secondDate <= firstTo
      } else return false
    }
    this.different = (first, second) => {
      return !this.equal(first, second)
    }
    this.include = (first, second) => {
      if (first.length == 21 && (second.length == 21 || second.length == 10)) {
        // Date range e.g. 2012-01-01;2016-12-31
        // Or just Date e.g 2012-01-01
        let firstFrom = new Date(first.split(';')[0])
        let firstTo = new Date(first.split(';')[1])
        let secondDate = new Date(second)
        return firstFrom <= secondDate && secondDate <= firstTo
      } else if (!Array.isArray(first)) return false
      return first.indexOf(second) > -1
    }
    this.empty = (first) => {
      return (
        first == null || first == undefined || first == '' || first === false
      )
    }
    this.present = (first) => {
      return !this.empty(first) || first === true
    }
    this.operator = operator
    this.field = field
    this.operand = operand || ''
  }
  static fromJSON(_json) {
    return new Operation(_json.operator, _json.field, _json.operand)
  }
  static fromJsonArray(_jsonArray) {
    return _jsonArray.map((_json) => Operation.fromJSON(_json))
  }
  returns(responses = {}) {
    if (responses == {}) return false
    return this.evaluateOperator()(
      this.firstOperand(responses),
      this.secondOperand(responses),
    )
  }
  firstOperand(responses) {
    if (this.field == 'count') return Object.keys(responses).length
    else if (responses[this.field] == undefined) return ''
    else return responses[this.field]
  }
  secondOperand(responses) {
    if (responses[this.operand] == undefined) return this.operand
    else return responses[this.operand]
  }
  evaluateOperator() {
    switch (this.operator) {
      case '<':
        return this.less
      case '>':
        return this.greater
      case '==':
        return this.equal
      case '!=':
        return this.different
      case 'present?':
        return this.present
      case 'blank?':
        return this.empty
      case 'include?':
        return this.include
      default:
        return () => {
          console.error(`Illegal operator called: ${this.operator}`)
          return false
        }
    }
  }
}
