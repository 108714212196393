import reducer, { changePage } from '../documentViewer'

describe('changePage duck', () => {
  describe('action: changePage', () => {
    it('should return action with payload', () => {
      const payload = 5
      expect(changePage(payload)).toEqual({
        type: 'CHANGE_PAGE',
        payload,
      })
    })

    it('should return action with empty payload', () => {
      expect(changePage()).toEqual({
        type: 'CHANGE_PAGE',
        payload: { pageNumber: 1 },
      })
    })
  })

  describe('reducer', () => {
    it('should return not initialized state', () => {
      const action = { type: '', payload: {} }
      expect(reducer(undefined, action)).toEqual({
        pageNumber: 0,
      })
    })

    it('should return updated state', () => {
      const state = reducer(undefined, {
        payload: { pageNumber: 5 },
      })
      const action = { type: 'CHANGE_PAGE', payload: 7 }
      expect(reducer(state, action)).toEqual({
        pageNumber: 7,
      })
    })
  })
})
