import { createContext, Dispatch } from 'react'
import { IFeedback } from '../types'

export type FeedbackStateType = { [id: string]: IFeedback }

export type FeedbackActionType =
  | { type: 'LOAD_FEEDBACKS'; payload: IFeedback[] }
  | { type: 'ADD_FEEDBACK'; payload: IFeedback }
  | { type: 'EDIT_FEEDBACK'; payload: IFeedback }
  | { type: 'DELETE_FEEDBACK'; payload: IFeedback }

export type FeedbackContextType = [
  FeedbackStateType,
  Dispatch<FeedbackActionType>,
]

const initialState: FeedbackStateType = {}

export function feedbackReducer(
  state = initialState,
  action: FeedbackActionType,
) {
  switch (action.type) {
    case 'LOAD_FEEDBACKS':
      return action.payload.reduce(
        (feedbacks: FeedbackStateType, feedback: IFeedback) => ({
          ...feedbacks,
          [feedback.id]: feedback,
        }),
        {},
      )
    case 'ADD_FEEDBACK':
      return {
        ...state,
        [action.payload.id]: action.payload,
      }
    case 'EDIT_FEEDBACK':
      return {
        ...state,
        [action.payload.id]: action.payload,
      }
    case 'DELETE_FEEDBACK': {
      const { [action.payload.id]: _deletedFeedback, ...restFeedbacks } = state
      return restFeedbacks
    }
    default:
      return state
  }
}

export function loadFeedbacks(payload: IFeedback[]): FeedbackActionType {
  return {
    type: 'LOAD_FEEDBACKS',
    payload,
  }
}

export function AddFeedback(payload: IFeedback): FeedbackActionType {
  return {
    type: 'ADD_FEEDBACK',
    payload,
  }
}

export function editFeedbacks(payload: IFeedback): FeedbackActionType {
  return {
    type: 'EDIT_FEEDBACK',
    payload,
  }
}

export function deleteFeedbacks(payload: IFeedback): FeedbackActionType {
  return {
    type: 'DELETE_FEEDBACK',
    payload,
  }
}

export const FeedbackContext = createContext<FeedbackContextType>([
  initialState,
  () => {},
])
