import React, { useState, createElement } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Tooltip from '../../engine-ui/Tooltip'
import Button from '../../engine-ui/Button'
import NotesForm from './NotesForm'
import FeedbackForm from './FeedbackForm'
import FeedbackTable from './FeedbackTable'
import LogFilter from './LogFilter'
import FeedbackProvider from '../store/FeedbackProvider'

export default function FilterBarContainer(props) {
  const [currentForm, setCurrentForm] = useState(null)
  const [defaultValue, setDefaultValue] = useState({ text: '' })

  const {
    onNoteSubmit,
    onFeedbackSubmit,
    data,
    onFilterTypeClick,
    onSelectChange,
    onSelectAll,
    onClearAll,
    activeTypes,
    valueDirection,
    valueSubject,
    saasNotEnabled,
  } = props

  const handleNoteSubmit = (text, type) => {
    onNoteSubmit(text, type)
    closeForm()
  }

  const handleFeedbackSubmit = (text) => {
    onFeedbackSubmit(text)
    closeForm()
  }

  const handleValueChange = (value) => {
    setDefaultValue((prevState) => ({ ...prevState, ...value }))
  }

  const closeForm = () => {
    setDefaultValue({ text: '' })
    setCurrentForm(null)
  }

  const options = [
    {
      icon: 'fas fa-notes-medical fa-inverse',
      component: FeedbackForm,
      title: 'Hinweis hinzufügen',
      onClick: handleFeedbackSubmit,
    },
    {
      icon: 'fas fa-comment-medical fa-inverse',
      component: NotesForm,
      title: 'Notiz erstellen',
      onClick: handleNoteSubmit,
    },
    {
      icon: 'fas fa-phone-volume fa-inverse',
      component: NotesForm,
      title: 'Notiz "Eingehender Anruf" erstellen',
      onClick: handleNoteSubmit,
      type: 'note/inbound_call',
    },
    {
      icon: 'fas fa-phone-volume fa-inverse fa-rotate-180',
      component: NotesForm,
      title: 'Notiz "Ausgehender Anruf" erstellen',
      onClick: handleNoteSubmit,
      type: 'note/outbound_call',
    },
    {
      icon: 'fas fa-phone-slash fa-inverse fa-sm',
      component: NotesForm,
      title: 'Notiz "Nicht erreichbar" erstellen',
      onClick: handleNoteSubmit,
      type: 'note/not_reached_call',
    },
  ]

  const getFormToggle = () => {
    if (currentForm === null) {
      return null
    }
    const { type, title, onClick, component } = options[currentForm]
    return (
      <div key={`form${currentForm}`}>
        {createElement(component, {
          onClick: onClick,
          closeForm: closeForm,
          title: title,
          type: type ? type : null,
          defaultValue,
          onValueChange: handleValueChange,
        })}
      </div>
    )
  }

  return (
    <FeedbackProvider>
      <div className="activitylog mb-2">
        {data.length ? <FeedbackTable data={data} /> : null}
        <>
          <div className="log-notes__container">
            {options.map((el, index) => {
              return (
                <Tooltip text={el.title} key={`button${index}`}>
                  <Button
                    className={classNames('btn', 'btn-add-note', {
                      'btn-add-note--active': currentForm === index,
                    })}
                    onClick={() => {
                      currentForm === index
                        ? setCurrentForm(null)
                        : setCurrentForm(index)
                    }}
                  >
                    <i className={el.icon} />
                  </Button>
                </Tooltip>
              )
            })}
          </div>
          <LogFilter
            onFilterTypeClick={onFilterTypeClick}
            onSelectChange={onSelectChange}
            onSelectAll={onSelectAll}
            onClearAll={onClearAll}
            activeTypes={activeTypes}
            valueDirection={valueDirection}
            valueSubject={valueSubject}
            saasNotEnabled={saasNotEnabled}
          />
        </>
        {getFormToggle()}
      </div>
    </FeedbackProvider>
  )
}

FilterBarContainer.propTypes = {
  onNoteSubmit: PropTypes.func,
  onFeedbackSubmit: PropTypes.func,
  data: PropTypes.array,
  onFilterTypeClick: PropTypes.func,
  onSelectChange: PropTypes.func,
  onSelectAll: PropTypes.func,
  onClearAll: PropTypes.func,
  activeTypes: PropTypes.array,
  valueDirection: PropTypes.string,
  valueSubject: PropTypes.string,
  saasNotEnabled: PropTypes.bool,
}
