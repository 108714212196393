import React from 'react'
import PropTypes from 'prop-types'

import ChangeLog from './ChangeLog'
import Tooltip from '../engine-ui/Tooltip'

export default function SectionIcons({ item }) {
  const handleClick = () => {
    document.getElementById('evidence-tab').click()
  }

  return (
    <div className="df_section-icons-container">
      {item.changes && item.changes.length > 0 && (
        <Tooltip
          tooltipChildren={[<ChangeLog key={item.name} logs={item.changes} />]}
          placement="right"
          className={'df_section-icons-item'}
        >
          <i className="fas fa-stream df_section-icons-item-icon" />
        </Tooltip>
      )}
      {item.infobox ? (
        <Tooltip
          text={item.infobox}
          placement="right"
          className={'df_section-icons-item'}
        >
          <i className="fas fa-question-circle df_section-icons-item-icon" />
        </Tooltip>
      ) : null}
      {item.hasEvidence ? (
        <Tooltip
          text={'Beweise ansehen'}
          placement="right"
          className={'df_section-icons-item'}
        >
          <i
            className="fas fa-file-image icon-evidence df_section-icons-item-icon"
            onClick={handleClick}
          />
        </Tooltip>
      ) : null}
    </div>
  )
}

SectionIcons.propTypes = {
  item: PropTypes.object,
}
