import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import { orderBy } from 'lodash/fp'
import BoardSection from './BoardSection'

export default class LeaderBoard extends Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    date: PropTypes.string,
    teamIds: PropTypes.array,
  }

  timeout = 250
  ws = new WebSocket('ws://localhost:3000/ws')

  state = {
    data: {},
  }

  getTeamsWithOrderedMembersByTotalDone = (teams) =>
    teams.map((team) => ({
      ...team,
      members: orderBy(['total_done'], ['desc'])(team.members),
    }))

  filterTeamsMembersByTotalDone = (teams) =>
    teams.map((team) => ({
      ...team,
      members: team.members.filter((member) => member.total_done),
    }))

  connectWs = () => {
    let connectInterval

    this.ws.onopen = () => {
      this.timeout = 250
      clearTimeout(connectInterval)
    }

    this.ws.onclose = (event) => {
      console.log(`Socket is closed: ${event.reason}. Reconnecting...`)
      this.timeout *= 2
      connectInterval = setTimeout(
        this.checkWsConnection,
        Math.min(10000, this.timeout),
      )
    }

    this.ws.onerror = (err) => {
      console.error(`Socket encountered error: ${err.message}. Closing socket`)
      this.ws.close()
    }

    this.ws.onmessage = (event) => {
      const message = JSON.parse(event.data)
      this.handleWsMessage(message)
    }
  }

  checkWsConnection = () => {
    if (!this.ws || this.ws.readyState == WebSocket.CLOSED) {
      this.connectWs()
    }
  }

  handleWsMessage = (message) => {
    return message
  }

  fetchData = () => {
    const { token, teamIds, date } = this.props

    const dateParams = date ? `date=${date}&` : ''
    const params = teamIds
      ? teamIds.reduce((acc, item) => {
          acc += `team_id[]=${item}&`
          return acc
        }, dateParams)
      : dateParams

    fetch(`/api/admin/tickets?${params.substring(0, params.length - 1)}`, {
      headers: { token },
    })
      .then((response) => response.json())
      .then((data) => this.setState({ data }))
  }

  componentDidMount() {
    this.fetchData()
    this.intervalId = setInterval(this.fetchData, 30000)
  }

  componentWillUnmount() {
    clearInterval(this.intervalId)
  }

  render() {
    const { data } = this.state
    const { date } = this.props
    const filteredTeams = this.filterTeamsMembersByTotalDone(data.teams || [])
    const teams = this.getTeamsWithOrderedMembersByTotalDone(filteredTeams)

    return (
      <div>
        <h1>CONNY's Powerhouse</h1>
        <div className="stats-container">
          <Fragment>
            {teams &&
              teams.map((item) => <BoardSection key={item.name} data={item} />)}
          </Fragment>

          <div className="total-container">
            <div className="total-due-wrapper mb-5">
              <div className="d-flex flex-column align-items-center">
                <span className="total-due-title">Due today:</span>
                <span className="total-due-number">{data.total_due_today}</span>
                <span>
                  <i className="fas fa-users"></i>
                </span>
              </div>
            </div>
            <div className="total-done-wrapper">
              <div className="d-flex flex-column align-items-center">
                <span className="total-done-title">
                  Done {date ? `at ${date}` : 'today'}:
                </span>
                <span className="total-done-number">{data.total_done}</span>
                {data.total_done > 0 ? (
                  <span>
                    <i className="fas fa-glass-cheers"></i>
                  </span>
                ) : (
                  <span>
                    <i className="fas fa-spinner fa-spin"></i>
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
