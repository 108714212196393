import React, { useState, useEffect } from 'react'
import client from '../../../../client'
import Select from 'react-select'
import TextInput from '../engine-ui/TextInput'
import SafeIdSelect from './SafeIdSelect'
import BeaAttachments from './BeaAttachments'
import Modal from 'react-bootstrap/Modal'
import { LawyerSelect } from './AgentSelect'

const BeaUi = (props) => {
  const { requestId, referenceNr } = props
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [courtOption, setCourtOption] = useState([])
  const [files, setFiles] = useState([])
  const [label, setLabel] = useState('')
  const [touched, setTouched] = useState(false)
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(true)
  const [disableSubjectTxt, setDisableSubjectTxt] = useState(false)
  const [beaMessage, setBeaMessage] = useState({
    subject: referenceNr,
    court_safe_id: null,
    external_lawyer_safe_id: null,
    attachments_prefix: '',
  })
  const [isSending, setIsSending] = useState(false)
  const [submitMessage, setSubmitMessage] = useState({})

  const getCourts = () => {
    client
      .get(`/admin/v2/requests/${requestId}/court_cases.json`)
      .then((response) => response.json())
      .then((data) => {
        setCourtOption(data)
      })
  }

  const getDocuments = () => {
    setIsLoading(true)
    client
      .get(`/admin/v2/requests/${requestId}/files.json`)
      .then((response) => response.json())
      .then((data) => {
        setFiles(data)
        setIsLoading(false)
      })
  }

  const getBeaSubject = async (field, value) => {
    setDisableSubjectTxt(true)
    const courtCaseValue = field == 'court' ? value : '0'

    return client
      .get(
        `/admin/v2/requests/${requestId}/court_cases/${courtCaseValue}/bea_references.json`,
      )
      .then((response) => response.json())
      .then((data) => {
        return data
      })
      .finally(() => {
        setDisableSubjectTxt(false)
      })
  }

  useEffect(() => {
    getDocuments()
    getCourts()
  }, [])

  const handleCourtChange = (event) => {
    if (event == null) {
      setBeaMessage({ ...beaMessage, court_safe_id: null })
      return
    }
    getBeaSubject('court', event.id).then((data) => {
      setBeaMessage({
        ...beaMessage,
        subject: data['subject'],
        court_safe_id: event,
      })
    })
  }

  const handleSubjectChange = (event) => {
    const { value } = event
    setBeaMessage({ ...beaMessage, subject: value })
  }

  const handleLawyerChange = (value) => {
    if (value == null) {
      setBeaMessage({ ...beaMessage, external_lawyer_safe_id: null })
      return
    }
    getBeaSubject('lawyer', value.id).then((data) => {
      setBeaMessage({
        ...beaMessage,
        subject: data['subject'],
        external_lawyer_safe_id: value,
      })
    })
  }

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  const handleLabelChange = (event) => {
    const { value } = event
    const trimmedValue = value.toUpperCase().trim()
    setLabel(trimmedValue)
    setBeaMessage({ ...beaMessage, attachments_prefix: trimmedValue })
  }

  const handleSubmit = () => {
    if (!touched) {
      setTouched(true)
    }
    if (disableSubmitBtn) {
      return
    }
    setIsSending(true)
    client
      .post(`/admin/v2/requests/${requestId}/bea_messages`, {
        ...beaMessage,
        court_safe_id: beaMessage.court_safe_id?.id,
        external_lawyer_safe_id: beaMessage.external_lawyer_safe_id?.id,
        signing_lawyer_id: beaMessage.signing_lawyer?.id,
      })
      .then(async (response) => ({
        status: response.status,
        body: await response.json(),
      }))
      .then((response) => {
        setIsSending(false)
        if (response.status !== 200) {
          setSubmitMessage({
            text: response.body.error || 'Leider etwas schief gelaufen!',
            submitted: false,
            status: 'failed',
          })
          setDisableSubmitBtn(true)
        } else {
          setSubmitMessage({
            text: response.body.message || 'Erfolgreich gesendet!',
            submitted: true,
            status: 'success',
          })
        }
      })
      .catch((err) => {
        console.error(err)
        setSubmitMessage({
          text: 'Leider etwas schief gelaufen!',
          submitted: false,
          status: 'failed',
        })
        setDisableSubmitBtn(true)
      })
  }

  const handleReset = () => {
    setIsModalOpen(!isModalOpen)
    setBeaMessage({
      subject: referenceNr,
      court_safe_id: null,
      external_lawyer_safe_id: null,
      attachments_prefix: '',
    })
    setLabel('')
    setSubmitMessage({})
    setTouched(false)
  }

  const isSubmitted = submitMessage.submitted || false
  return (
    <>
      <button
        type="button"
        className="request-actions-button__btn"
        onClick={toggleModal}
      >
        <i className="fa fa-box"></i>
        beA
      </button>
      <Modal size="lg" show={isModalOpen} onHide={toggleModal}>
        <Modal.Header closeButton>
          <h5 className="bea-ui__modal-title">BEA</h5>
        </Modal.Header>
        <Modal.Body>
          {!isSubmitted && (
            <div className="bea-ui__wrapper">
              <div className="row my-3">
                <div className="col-6 pr-0">
                  <Select
                    id="court-safe-id-select"
                    onChange={handleCourtChange}
                    placeholder="Gericht auswählen"
                    value={beaMessage.court_safe_id}
                    isClearable={true}
                    options={courtOption}
                    getOptionLabel={(e) =>
                      `${e.court_name} - ${e.reference_number}${
                        e.safe_id ? '' : ' (keine Safe-ID)'
                      }`
                    }
                    getOptionValue={(e) => e.id}
                    isOptionDisabled={(e) => !e.safe_id}
                  />
                  <a
                    className="far fa-address-card"
                    target="_blank"
                    href="/admin/court-safe-ids"
                  >
                    {' '}
                  </a>
                </div>
                <div className="col-6">
                  <SafeIdSelect
                    id="lawyer-safe-id-select"
                    lawyer={beaMessage.external_lawyer_safe_id}
                    handleLawyerChange={handleLawyerChange}
                  />
                  <a
                    className="far fa-address-card"
                    target="_blank"
                    href="/admin/counter-party-lawyer-safe-ids"
                  >
                    {' '}
                  </a>
                </div>
              </div>
              <div className="row">
                <div className="col-4 pr-0">
                  <label className="ms-2 mb-1" htmlFor="input-bea-subject">
                    {' '}
                    Betreff{' '}
                  </label>
                  <TextInput
                    id="input-bea-subject"
                    name="ref_nr"
                    className="form-control"
                    value={beaMessage.subject}
                    onChange={handleSubjectChange}
                    disabled={disableSubjectTxt}
                    placeholder="Betreff"
                  />
                </div>
                <div className="col-2 pr-0">
                  <label className="ms-2 mb-1" htmlFor="input-bea-label">
                    {' '}
                    Label{' '}
                  </label>
                  <TextInput
                    id="input-bea-label"
                    name="label"
                    onChange={handleLabelChange}
                    value={label}
                    placeholder="Label"
                    className="form-control"
                  />
                </div>
                <div className="col-6">
                  <label
                    className="ms-2 mb-1"
                    htmlFor="input-bea-signing-lawyer"
                  >
                    {' '}
                    Signatur von{' '}
                  </label>
                  <LawyerSelect
                    id="input-bea-signing-lawyer"
                    placeholder="Signatur von"
                    value={beaMessage.signing_lawyer ?? null}
                    onChange={(e) => {
                      setBeaMessage({ ...beaMessage, signing_lawyer: e })
                    }}
                    className=""
                    defaultSigningLayerEmail={props.defaultSigningLawyerEmail}
                  />
                </div>
              </div>
              {isLoading ? (
                'Dokumente werden geladen ...'
              ) : (
                <BeaAttachments
                  files={files}
                  requestId={requestId}
                  setBeaMessage={setBeaMessage}
                  beaMessage={beaMessage}
                  setDisableSubmitBtn={setDisableSubmitBtn}
                  setSubmitMessage={setSubmitMessage}
                  label={label}
                />
              )}
            </div>
          )}
          {isSending && !isSubmitted && <p>Documents are sending...</p>}
          {submitMessage.text && touched ? (
            <p
              className={`bea-ui__submitmsg bea-ui__submitmsg-${submitMessage.status}`}
            >
              {submitMessage.text}
            </p>
          ) : (
            <></>
          )}

          <Modal.Footer>
            <div className="bea-ui__modal-footer">
              {!isSubmitted ? (
                <>
                  <button
                    className="btn add-status-btn"
                    onClick={() => getDocuments()}
                  >
                    <i className="fas fa-sync me-3 text-white"></i>
                    Dokumente neu laden
                  </button>
                  <div>
                    <button
                      className="btn btn-secondary btn-rounded mt-0"
                      onClick={handleReset}
                    >
                      Abbrechen
                    </button>
                    <button
                      className="btn btn-primary btn-rounded mx-3 mt-0"
                      disabled={touched && disableSubmitBtn}
                      onClick={handleSubmit}
                    >
                      Speichern
                    </button>
                  </div>
                </>
              ) : (
                <button
                  className="btn btn-outline-secondary btn-rounded mt-0"
                  onClick={handleReset}
                >
                  Schließen
                </button>
              )}
            </div>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default BeaUi
