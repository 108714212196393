import React from 'react'
import PropTypes from 'prop-types'

import TextInput from './text_input'
import Checkbox from './checkbox'
import Select from './select'
import Datepicker from './datepicker'
import Group from './group'

const Input = ({ question, handleFormChange, questions, handleBlur }) => {
  const props = {
    question: question,
    state: questions[question.name],
    handleFormChange: handleFormChange,
    handleBlur: handleBlur,
    disabled: question.disabled,
  }

  return (
    {
      checkbox: (
        <div className="col-sm-12">
          <Checkbox {...props} />
        </div>
      ),
      select: (
        <div className="col-sm-6">
          <Select {...props} />
        </div>
      ),
      datepicker: (
        <div className="col-sm-6">
          <Datepicker {...props} />
        </div>
      ),
      number: (
        <div className="col-sm-6">
          <TextInput {...props} type="number" placeholder="0,00" />
        </div>
      ),
      group: <Group {...props} group={question} questions={questions} />,
    }[question.type] || (
      <div className="col-sm-6">
        <TextInput {...props} />
      </div>
    )
  )
}

Input.propTypes = {
  question: PropTypes.object.isRequired,
  questions: PropTypes.object,
  handleFormChange: PropTypes.func,
  handleBlur: PropTypes.func,
}

export default Input
