/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react'
import Checkbox from '../../engine-ui/Checkbox'
import Button from '../../engine-ui/Button'
import { filter, orderBy } from 'lodash/fp'
import Select from 'react-select'

const DocumentsFilter = (props) => {
  const { documents, renderDocuments, setFilterMessage, requestId } = props
  const [inputValue, setInputValue] = useState({
    court_id: '',
    type: '',
    file_extension: [],
    court_type: '',
    court_attachment: false,
  })

  const [filteredItems, setFilteredItems] = useState([])
  const [filteredLabels, setFilteredLabels] = useState({})

  useEffect(() => {
    if (documents.length) {
      renderDocuments(documents)
    }
  }, [documents])

  const filterFunction = (filteredLabels) => {
    return (item) => {
      return Object.keys(filteredLabels)
        .map((key) => {
          if (Array.isArray(filteredLabels[key])) {
            if (filteredLabels[key].length === 0) {
              return true
            }
            if (filteredLabels[key].includes(item[key])) {
              return true
            }
            return false
          } else {
            if (filteredLabels[key] === item[key]) {
              return true
            }

            if (filteredLabels[key] === undefined) {
              return true
            }
            return false
          }
        })
        .every((val) => val === true)
    }
  }
  useEffect(() => {
    const filterCourtAttachment = documents.filter(function (doc) {
      return doc.type !== 'Anlage'
    })
    const filtered = inputValue.court_attachment
      ? filter(filterFunction(filteredLabels))(filterCourtAttachment)
      : filter(filterFunction(filteredLabels))(documents)

    if (Object.keys(filteredLabels).length !== 0 && !filtered.length) {
      setFilterMessage('Keine Treffer gefunden')
    }
    !filteredLabels ? setFilteredItems(documents) : setFilteredItems(filtered)
  }, [filteredLabels])

  useEffect(() => {
    renderDocuments(filteredItems)
  }, [filteredItems])

  const sortedCourtIdOptions = useRef([])
  const sortedDocTypeOptions = useRef([])
  const sortedFileTypeOptions = useRef([])

  useEffect(() => {
    const courtIdOptions = []
    const docTypeOptions = []
    const fileTypeOptions = []
    documents.forEach((doc) => {
      if (doc.type) {
        docTypeOptions.push({
          text: doc.type,
          value: doc.type,
        })
      }

      if (doc.court_id) {
        courtIdOptions.push({
          text: doc.court_id,
          value: doc.court_id,
        })
      } else {
        courtIdOptions.push({
          text: 'nicht gesetzt',
          value: doc.court_id,
        })
      }

      if (doc.file_extension) {
        fileTypeOptions.push({
          text: doc.file_extension.toUpperCase(),
          value: doc.file_extension,
        })
      }
    })
    sortedCourtIdOptions.current = sortedValues(courtIdOptions)
    sortedDocTypeOptions.current = sortedValues(docTypeOptions)
    sortedFileTypeOptions.current = sortedValues(fileTypeOptions)
  }, [documents])

  const sortedValues = (array) => {
    const filteredItems = array.filter(
      (val, index, el) =>
        el.findIndex((item) => item.value === val.value) === index,
    )
    return orderBy('text', ['asc'])(filteredItems)
  }

  const handleChange = (item) => {
    const { name, value } = item
    setInputValue({ ...inputValue, [name]: value })
    setFilteredLabels({ ...filteredLabels, [name]: value })
  }

  const handleHideAttachment = (item) => {
    const { value } = item
    setInputValue({ court_attachment: value })
    if (!value) {
      const { court_attachment: _value, ...newFilteredLabels } = filteredLabels
      setFilteredLabels(newFilteredLabels)
    } else {
      setFilteredLabels({
        ...filteredLabels,
        court_attachment: !value,
      })
    }
  }

  const handleOpenPreviewClick = () => {
    const openPreview = () => {
      return filteredItems.forEach((link) => {
        const url = `/admin/requests/${requestId}/documents/${link.id}`
        return window.open(url, '_blank', 'noopener,noreferrer')
      })
    }
    return openPreview()
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-2">
          <Select
            isMulti
            name="court_id"
            placeholder="Gerichtl. Aktenzeichen"
            onChange={(input) => {
              handleChange({
                name: 'court_id',
                value: input.map((item) => item.value),
              })
            }}
            options={sortedCourtIdOptions.current}
            getOptionLabel={(option) => option.text}
            getOptionValue={(option) => option.value}
            className="basic-multi-select text-dark font-weight-bold"
          />
        </div>
        <div className="col-2">
          <Select
            isMulti
            name="type"
            placeholder="Dokumentenkategorie"
            onChange={(input) => {
              handleChange({
                name: 'type',
                value: input.map((item) => item.value),
              })
            }}
            options={sortedDocTypeOptions.current}
            getOptionLabel={(option) => option.text}
            getOptionValue={(option) => option.value}
            className="basic-multi-select text-dark font-weight-bold"
          />
        </div>
        <div className="col-2">
          <FileTypeSelect
            sortedFileTypeOptions={sortedFileTypeOptions.current}
            handleChange={handleChange}
          />
        </div>
        <div className="col-2">
          <Select
            isClearable
            name="court_type"
            onChange={(event) => {
              handleChange({ name: 'court_type', value: event?.value })
            }}
            placeholder="Verfahrensstand"
            options={[
              { text: 'gerichtlich', value: true },
              { text: 'außergerichtlich', value: false },
            ]}
            getOptionLabel={(option) => option.text}
            getOptionValue={(option) => option.value}
            className="basic-multi-select text-dark font-weight-bold"
          />
        </div>
        <div className="col-4">
          <Checkbox
            name="court_attachment"
            id="court_attachment"
            value={inputValue.court_attachment}
            onChange={handleHideAttachment}
            className="form-check-input me-2 mt-1"
          />
          <label
            htmlFor="court_attachment"
            className="form-check-label text-white mb-0"
          >
            Anhänge ausblenden
          </label>
        </div>
      </div>
      <div className="row">
        <div className="col-3">
          {Object.keys(filteredLabels).length !== 0 && (
            <Button
              onClick={() => handleOpenPreviewClick()}
              className="btn btn-primary btn-rounded d-flex justify-content-center"
            >
              <i className="fas fa-external-link-alt me-1 text-white"></i>
              Alle anzeigen
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}
const FileTypeSelect = (props) => {
  const { sortedFileTypeOptions, handleChange } = props
  const [defaultValue, setDefaultValue] = useState([])
  const [touched, setTouched] = useState(false)
  useEffect(() => {
    if (touched) return

    const tmp = sortedFileTypeOptions.filter(
      (option) => !['pkcs7', 'docx'].includes(option.value),
    )
    setDefaultValue(tmp)
    handleChange({
      name: 'file_extension',
      value: tmp.map((item) => item.value),
    })
  }, [sortedFileTypeOptions])

  return (
    <Select
      isMulti
      name="file_extension"
      placeholder="Datei-Typ"
      classNamePrefix="select"
      onChange={(input) => {
        setTouched(true)
        setDefaultValue(input)
        handleChange({
          name: 'file_extension',
          value: input.map((item) => item.value),
        })
      }}
      value={defaultValue}
      options={sortedFileTypeOptions}
      getOptionLabel={(option) => option.text}
      getOptionValue={(option) => option.value}
      className="basic-multi-select text-dark font-weight-bold"
    />
  )
}

export default DocumentsFilter
