import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '../../engine-ui/Button'

export default function FeedbackForm(props) {
  const { onClick, closeForm, title, defaultValue, onValueChange } = props
  const [text, setText] = useState(defaultValue.text || '')

  const handleTextChange = (e) => {
    const { value } = e.target
    setText(value)
    onValueChange({ text: value })
  }

  return (
    <div className="logitem-container card notes__card">
      <div className="notes__card-header card-header">
        <p className="notes__card-header-title">{title}</p>
        <Button onClick={closeForm} className="btn-close"></Button>
      </div>
      <div className="card-body ">
        <div className="card-body__box">
          <div className="row">
            <div className="col-8">
              <div className="form-group">
                <div className="form-group feedback_note">
                  <p className="notes__card-header-title">Hinweis</p>
                  <textarea
                    className="form-control"
                    rows="2"
                    id="feedback_note"
                    value={text}
                    onChange={handleTextChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <Button
              className="btn btn-primary btn-rounded mt-3"
              onClick={() => onClick(text)}
            >
              Kommentar speichern
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

FeedbackForm.propTypes = {
  onClick: PropTypes.func,
  closeForm: PropTypes.func,
  title: PropTypes.string,
}
