import Dependency from '../utils/dependency'
import Operation from '../utils/operation'

describe('Correct Dependency', () => {
  JSON
  const deJson = {
    conditions: [
      {
        operator: '>',
        operand: '5',
        field: 'user.age',
      },
      {
        operator: 'present?',
        operand: '5',
        field: 'user.name',
      },
    ],
    action: 'disable',
    priority: 1,
  }
  test('new instance from Array', () => {
    let dependency = Dependency.fromJSONArray([deJson, deJson])
    expect(dependency[0].conditions[1]).toBeInstanceOf(Operation)
    expect(dependency[0].conditions[1].field).toBe('user.name')
    expect(dependency[1].action).toBe('disable')
  })
})

describe('scoped dependency', () => {
  JSON
  const deJson = {
    conditions: [
      {
        operator: 'present?',
        operand: '4',
        field: 'user.robot.legs',
      },
    ],
    action: 'disable',
    priority: 1,
    scope: 'user.robot',
  }
  test('new instance from Array', () => {
    let dependency = Dependency.fromJSON(deJson)
    expect(dependency.conditions[0]).toBeInstanceOf(Operation)
    expect(dependency.conditions[0].field).toBe('user.robot.legs')
    expect(dependency.action).toBe('disable')
  })
})

describe('Resolve dependency', () => {
  JSON
  const deJson = {
    conditions: [
      {
        operator: '>',
        operand: '5',
        field: 'user.age',
      },
      {
        operator: 'present?',
        operand: 'goglomoglo',
        field: 'user.name',
      },
    ],
    action: 'disable',
    priority: 1,
  }

  it('and passes and returns action', () => {
    JSON
    const resJson = {
      'user.age': 6,
      'user.name': 'Yolo',
    }

    let dependency = Dependency.fromJSON(deJson)

    expect(dependency.match(resJson).match).toBe(true)
    expect(dependency.match(resJson).action).toBe('disable')
  })

  it('doesnt match and returns empty action', () => {
    JSON
    const resJson = {
      'user.age': 4,
      'user.name': 'Yodo',
    }

    let dependency = Dependency.fromJSON(deJson)

    expect(dependency.match(resJson).match).toBe(false)
    expect(dependency.match(resJson).action).toBe('')
  })
})
