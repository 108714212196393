import React, { useState, useContext, MouseEvent } from 'react'
import classNames from 'classnames'
import { ActivityLogContext } from './store'
import client from '../../../../client'
import Spinner from '../engine-ui/Spinner'

interface IProps {
  marked: boolean
  id: string
}

export default function MarkItemButton({ marked, id }: IProps) {
  // format of the id field from response is `somening-123456` or `something=123456`
  // we need extract the real id from that string
  const loggableId = id.replace(/^\D+/g, '')

  const [isMarked, setIsMarked] = useState(marked)
  const [isLoading, setIsLoading] = useState(false)

  const { requestId, onItemMarkedChange } = useContext(ActivityLogContext)

  const handleMarkClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()

    isMarked ? unMarkLog() : markLog()
  }

  const markLog = () => {
    const MARK_URL = `/admin/v2/requests/${requestId}/feed_items/${loggableId}/mark`

    setIsLoading(true)

    client
      .post(MARK_URL)
      .then((response) => response.json())
      .then((json) => {
        if (json.status === 'ok') {
          onItemMarkedChange(id, true)
          setIsMarked(true)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const unMarkLog = () => {
    const UNMARK_URL = `/admin/v2/requests/${requestId}/feed_items/${loggableId}/unmark`

    setIsLoading(true)

    client
      .post(UNMARK_URL)
      .then((response) => response.json())
      .then((json) => {
        if (json.status === 'ok') {
          onItemMarkedChange(id, false)
          setIsMarked(false)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  if (isLoading) {
    return (
      <div className="log-item__icon-container">
        <Spinner />
      </div>
    )
  }

  return (
    <div className="log-item__icon-container">
      <i
        onClick={handleMarkClick}
        className={classNames('fa-star log-item__icon mark-action', {
          fas: isMarked,
          far: !isMarked,
        })}
      ></i>
    </div>
  )
}
