/* eslint-disable react/prop-types */
import React, { useRef, useEffect } from 'react'

function Input(props) {
  const inputRef = useRef(null)

  useEffect(() => {
    inputRef.current.focus()
  }, [])

  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault()
        }}
        className="input-wrapper"
      >
        <input
          ref={inputRef}
          type="text"
          name="req_search"
          placeholder="&#61442;"
          className="v2modal-fields__search"
          onChange={props.handleQueryChange}
          value={props.query}
        />
        <span className="input-highlight">
          {props.query.replace(/ /g, '\u00a0')}
        </span>
      </form>
    </div>
  )
}

export default Input
