import React from 'react'
import PropTypes from 'prop-types'

import Checkbox from '../engine-ui/Checkbox'
import TextArea from '../engine-ui/TextArea'
import TextInput from '../engine-ui/TextInput'
import NumberInput from '../engine-ui/NumberInput'
import RadioGroup, { RadioOption } from '../engine-ui/RadioGroup'
import Select from 'react-select'
import AdminDatepicker from '../admin_datepicker'

const PaymentForm = (props) => {
  const {
    data = {},
    onChange,
    translations,
    documents,
    showTriggers,
    triggers,
  } = props

  if (!translations.amount) {
    return <span>...</span>
  }

  return (
    <div className="payment-form">
      {/* Betrag and belegdatum */}
      <div>
        <div className="form-group">
          <label>{translations.amount}</label>
          <NumberInput
            step="0.01"
            type="number"
            name="amount"
            value={data.amount}
            onChange={onChange}
            className="form-control"
            placeholder="0,00"
          />
        </div>
        <div className="form-group">
          <label>{translations.payment_date}</label>
          <div className="df_datepicker">
            <AdminDatepicker
              past
              value={data.payment_date}
              onChange={onChange}
              question={{ name: 'payment_date', text: '' }}
            />
          </div>
        </div>
      </div>
      {/* Wahrung and Zuruckgestellt */}
      <div>
        <div className="form-group">
          <label>{translations.currency}</label>
          <TextInput
            name="currency"
            value={data.currency}
            readOnly
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label htmlFor="on_hold">{translations.on_hold}</label>
          <Checkbox
            name="on_hold"
            className="form-check-input"
            value={data.on_hold}
            onChange={onChange}
          />
        </div>
      </div>
      {/* Richtung Zahlung */}
      <div className="form-group">
        <>
          <label>{translations.direction}</label>
          <RadioGroup
            name="direction"
            value={data.direction}
            onChange={onChange}
          >
            {Object.keys(translations.direction_options).map((key) => {
              return (
                <RadioOption
                  key={key}
                  value={key}
                  label={translations.direction_options[key]}
                />
              )
            })}
          </RadioGroup>
        </>
        <>
          <br />
          {/* Konto */}
          <>
            <label>{translations.primary_party}</label>
            <RadioGroup
              name="primary_party"
              value={data.primary_party}
              onChange={onChange}
            >
              {Object.keys(translations.primary_party_options).map((key) => {
                return (
                  <RadioOption
                    key={key}
                    value={key}
                    label={translations.primary_party_options[key]}
                  />
                )
              })}
            </RadioGroup>
          </>
        </>
      </div>

      {/* Category */}
      <div className="form-group">
        <label>{translations.category}</label>
        <RadioGroup name="category" value={data.category} onChange={onChange}>
          {Object.keys(translations.category_options).map((key) => {
            return (
              <RadioOption
                key={key}
                value={key}
                label={translations.category_options[key]}
              />
            )
          })}
        </RadioGroup>
      </div>
      {/* Invoice doc */}
      <div style={{ color: 'black' }}>
        <label>Invoice document</label>
        <Select
          options={documents}
          value={documents.find(
            (doc) => doc.value === data.invoice_document_id,
          )}
          onChange={(e) =>
            onChange({ name: 'invoice_document_id', value: e.value })
          }
        />
      </div>
      {/* Party */}
      <div className="form-group">
        <label>{translations.party}</label>
        <RadioGroup name="party" value={data.party} onChange={onChange}>
          {Object.keys(translations.party_options).map((key) => {
            return (
              <RadioOption
                key={key}
                value={key}
                label={translations.party_options[key]}
              />
            )
          })}
        </RadioGroup>
      </div>
      {/* Instant */}
      <div className="form-group">
        <label>{translations.court_instance}</label>
        <RadioGroup
          name="court_instance"
          value={data.court_instance}
          onChange={onChange}
        >
          {Object.keys(translations.court_instance_options).map((key) => {
            return (
              <RadioOption
                key={key}
                value={key}
                label={translations.court_instance_options[key]}
              />
            )
          })}
        </RadioGroup>
      </div>
      {/* Leistungsart */}
      <div className="payment-form__leistungsart">
        <label>{translations.payment_type}</label>
        <RadioGroup
          name="payment_type"
          value={data.payment_type}
          onChange={onChange}
        >
          {Object.keys(translations.payment_type_options).map((key) => {
            return (
              <RadioOption
                key={key}
                value={key}
                label={translations.payment_type_options[key]}
              />
            )
          })}
        </RadioGroup>
      </div>

      <div className="payment-form__vwz">
        <label>{translations.payment_purpose}</label>
        <TextArea
          name="payment_purpose"
          rows="4"
          value={data.payment_purpose}
          onChange={onChange}
          className="form-control"
        />
      </div>
      <div className="payment-form__kommentar">
        <label>{translations.comment}</label>
        <TextArea
          name="comment"
          rows="5"
          value={data.comment}
          onChange={onChange}
          className="form-control"
        />
      </div>

      {showTriggers && (
        <div className="payment-form__triggers">
          <h6 style={{ color: 'black' }}>
            {' '}
            Triggers to be executed after payment is created{' '}
          </h6>
          <ul style={{ color: 'black' }}>
            {triggers.map((trigger) => {
              return <li key={trigger._id}>{trigger.title}</li>
            })}
          </ul>
        </div>
      )}

      {data.id && (
        <div className="payment-form__dates">
          <label>{translations.created_at}</label>
          <strong className="text-dark">&nbsp;{data.created_at}&nbsp;|</strong>
          <label>{translations.created_by}</label>
          <strong className="text-dark">&nbsp;{data.agent}&nbsp;|</strong>
          <label>{translations.updated_at}</label>
          <strong className="text-dark">&nbsp;{data.updated_at}&nbsp;|</strong>
          <label>{translations.updated_by}</label>
          <strong className="text-dark">
            &nbsp;{data.update_agent}&nbsp;|
          </strong>
          <label>{translations.primary_party}</label>
          <strong className="text-dark">&nbsp;{data.primary_party}</strong>
        </div>
      )}
    </div>
  )
}

PaymentForm.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
  translations: PropTypes.object,
}

export default PaymentForm
