import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import Modal from '../../engine-ui/Modal'
import FormContainer from './FormContainer'
import Spinner from '../../engine-ui/Spinner'
import AlertBanner from '../../engine-ui/AlertBanner'

export default function DynamicTableHead(props) {
  const { onNewRowSubmit, title, requestId, tableScaffoldId, token } = props

  const [isModalOpen, setModalOpen] = useState(false)
  const [newData, setNewData] = useState('')
  const [initialData, setInitialData] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const toggleModal = () => {
    setModalOpen(!isModalOpen)
  }

  const handleClick = () => {
    toggleModal()
    handleNewRowClick()
  }

  const handleSubmit = (selectedActions, inputData) => {
    toggleModal()
    onNewRowSubmit(selectedActions, inputData)
  }

  const handleNewRowClick = () => {
    setLoading(true)
    const url = `/api/admin/requests/${requestId}/dynamic_table_scaffolds/new?table_scaffold_key=${tableScaffoldId}`
    fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setNewData(data.table)
        fillInitialInputData(data.table.fields)
      })
      .catch((err) => {
        console.error(err)
        setError(true)
      })
      .finally(() => setLoading(false))
  }

  const fillInitialInputData = (initialData) => {
    const res = initialData.reduce(
      (obj, item) => (
        item.type === 'checkbox'
          ? (obj[item.name] = false)
          : (obj[item.name] = ''),
        obj
      ),
      {},
    )
    setInitialData(res)
  }

  return (
    <Fragment>
      <div className="d-flex align-items-center justify-content-between">
        <p className="form_category__title">
          <a className="hash-anchor" name={title.replace(/\s*/g, '')}>
            {title}
          </a>
        </p>
        <div className="d-flex me-1 viewtab__editnav" onClick={handleClick}>
          <a className="df_edit me-5">
            <i className="far fa-2x fa-plus-square" />
          </a>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={toggleModal}>
        {error ? (
          <AlertBanner />
        ) : loading ? (
          <Spinner />
        ) : (
          <FormContainer
            data={newData}
            onSubmit={handleSubmit}
            token={token}
            tableScaffoldId={tableScaffoldId}
            requestId={requestId}
            initialData={initialData}
          />
        )}
      </Modal>
    </Fragment>
  )
}

DynamicTableHead.propTypes = {
  onNewRowSubmit: PropTypes.func,
  tableScaffoldId: PropTypes.string,
  title: PropTypes.string,
  token: PropTypes.string,
  requestId: PropTypes.number,
}
