import React from 'react'
import PropTypes from 'prop-types'

const DocumentName = ({ doc }) => {
  const NameWithLink = (
    <div className="document__progress_name">
      <a target="_blank" href={doc.url}>
        {doc.filename}
      </a>
    </div>
  )
  const PlainName = (
    <div className="document__progress_name">{doc.filename}</div>
  )

  return doc.completed ? NameWithLink : PlainName
}

DocumentName.propTypes = {
  doc: PropTypes.object.isRequired,
}

export default DocumentName
