import React from 'react'
import useDocument from '../useDocument'

describe('useDocument', () => {
  let dispatch
  beforeEach(() => {
    dispatch = jest.fn()
    React.useContext = jest.fn(() => ({ dispatch, state: { document: {} } }))
  })

  afterEach(() => {
    dispatch.mockClear()
    React.useContext.mockReset()
  })

  describe('useDocument properties', () => {
    test('should return document properties', () => {
      expect(useDocument()).toHaveProperty('document')
      expect(useDocument()).toHaveProperty('changeDocument')
      expect(useDocument()).toHaveProperty('initializeDocument')
    })
  })

  describe('useDocument: changeDocument', () => {
    test('should call dispatch with changeDocument once', () => {
      const { changeDocument } = useDocument()
      changeDocument({})
      expect(dispatch.mock.calls.length).toEqual(1)
    })
  })

  describe('useDocument: initializeDocument', () => {
    test('should call dispatch initializeDocument once', () => {
      const { initializeDocument } = useDocument()
      initializeDocument({})
      expect(dispatch.mock.calls.length).toEqual(1)
    })
  })
})
