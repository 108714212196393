import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export default class Dropzone extends Component {
  constructor(props) {
    super(props)

    this.state = { hightlight: false }
    this.fileInputRef = React.createRef()
  }

  static propTypes = {
    onFilesAdded: PropTypes.func,
  }

  handleFileAdd = (event) => {
    const { onFileAdded } = this.props

    for (var i = 0; i < event.target.files.length; i++) {
      onFileAdded && onFileAdded(event.target.files.item(i))
    }
  }

  handleFileDialogOpen = () => {
    this.fileInputRef.current.click()
  }

  handleDragOver = (event) => {
    event.preventDefault()
    this.setState({ hightlight: true })
  }

  handleDragLeave = () => {
    this.setState({ hightlight: false })
  }

  handleDrop = (event) => {
    const { onFileAdded } = this.props
    event.preventDefault()

    for (var i = 0; i < event.dataTransfer.files.length; i++) {
      onFileAdded && onFileAdded(event.dataTransfer.files.item(i))
    }

    this.setState({ hightlight: false })
  }

  render() {
    const { hightlight } = this.state
    const containerClassName = classNames('dropzone__container', {
      'dropzone__container--highlighted': hightlight,
    })
    const iconClassName = classNames('dropzone__icon fas fa-cloud-upload-alt', {
      'dropzone__icon--highlighted': hightlight,
    })
    const hintClassName = classNames('dropzone__hint', {
      'dropzone__hint--highlighted': hightlight,
    })

    return (
      <div
        onDrop={this.handleDrop}
        onClick={this.handleFileDialogOpen}
        className={containerClassName}
        onDragOver={this.handleDragOver}
        onDragLeave={this.handleDragLeave}
      >
        <input
          ref={this.fileInputRef}
          type="file"
          multiple
          onChange={this.handleFileAdd}
          className="dropzone__input"
        />
        <i className={iconClassName}></i>
        <span className={hintClassName}>
          Drag & Drop / Click <br /> to upload
        </span>
      </div>
    )
  }
}
