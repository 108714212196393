import React, { useState } from 'react'
import classNames from 'classnames'
import { range, findIndex } from 'lodash/fp'
import DatePicker, { registerLocale } from 'react-datepicker'
import de from 'date-fns/locale/de'

import Button from '../Button'
import Select from '../Select'
import PropTypes from 'prop-types'

registerLocale('de', de)

const DEFAULT_START_YEAR = 1970
const DEFAULT_END_YEAR = new Date().getFullYear() + 5

const DateInput = (props) => {
  const {
    error,
    name,
    value,
    endYear = DEFAULT_END_YEAR,
    className,
    startYear = DEFAULT_START_YEAR,
    placeholder,
    disabled,
    onChange,
  } = props

  const [calendarValue, setCalendarValue] = useState(null)

  const yearOptions = range(startYear)(endYear).map((item) => ({
    text: item,
    value: item,
  }))

  const monthOptions = [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ].map((item) => ({
    text: item,
    value: item,
  }))

  const handleChange = (datePickerValue) => {
    setCalendarValue(datePickerValue)
    const ISOdate = datePickerValue
      ? new Date(
          datePickerValue.getTime() -
            datePickerValue.getTimezoneOffset() * 60000,
        ).toISOString()
      : null
    onChange && onChange({ name, value: ISOdate })
  }

  const inputClassName = classNames(className, { error })

  const selectedValue = calendarValue || value ? new Date(value) : null

  return (
    <DatePicker
      selected={selectedValue}
      onChange={handleChange}
      className={inputClassName}
      dateFormat={'dd.MM.yyyy'}
      locale={'de'}
      isClearable
      placeholderText={placeholder}
      disabled={disabled}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div className="datepicker__header">
          <Button
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
            className="datepicker__nav-button"
          >
            {'<'}
          </Button>
          <Select
            value={date.getFullYear()}
            options={yearOptions}
            className="datepicker__select-year"
            onChange={({ value }) => changeYear(value)}
          />

          <Select
            value={monthOptions[date.getMonth()].value}
            options={monthOptions}
            className="datepicker__select-month"
            onChange={({ value }) =>
              changeMonth(findIndex({ value })(monthOptions))
            }
          />
          <Button
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
            className="datepicker__nav-button"
          >
            {'>'}
          </Button>
        </div>
      )}
    />
  )
}

export default DateInput

DateInput.propTypes = {
  error: PropTypes.bool,
  value: PropTypes.string,
  endYear: PropTypes.number,
  startYear: PropTypes.number,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
}
