import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { keys } from 'lodash/fp'
import classNames from 'classnames'
import Button from '../engine-ui/Button'
import DataTable from '../engine-ui/DataTable'
import Tooltip from '../engine-ui/Tooltip'

import { t, mapFieldToOptionKeys } from './helpers'

export default class PaymentsDataTable extends Component {
  static propTypes = {
    data: PropTypes.array,
    onEdit: PropTypes.func.isRequired,
    onClone: PropTypes.func.isRequired,
    onSplit: PropTypes.func,
    onCancel: PropTypes.func,
    onMarkPaid: PropTypes.func,
    onDelete: PropTypes.func.isRequired,
    onArchive: PropTypes.func.isRequired,
    fieldsOrder: PropTypes.array,
    translations: PropTypes.object,
  }

  renderHead = (head, { index: rowIndex, fieldsOrder }) => {
    const { translations } = this.props

    return (
      <tr key={rowIndex} className="tab-table-row">
        <td>#</td>
        {fieldsOrder.map((field, cellIndex) => {
          const cellClassName = classNames({
            'payments__cell--right': field === 'amount',
            'payments__cell--center': field !== 'amount',
          })
          return (
            <td key={cellIndex} className={cellClassName}>
              {translations[field]}
            </td>
          )
        })}
        <td className="text-end pe-2">Aktionen</td>
      </tr>
    )
  }

  renderRow = (rowData, { index: rowIndex, fieldsOrder }) => {
    const {
      onEdit,
      onClone,
      onCancel,
      onMarkPaid,
      onSplit,
      onDelete,
      onArchive,
      translations,
    } = this.props
    return (
      <tr key={rowIndex} className={`tab-table-row ${rowData.table_tr_class}`}>
        <td>{rowIndex + 1}</td>
        {fieldsOrder.map((field, cellIndex) => {
          const value = mapFieldToOptionKeys[field]
            ? t(rowData[field])(translations[mapFieldToOptionKeys[field]])
            : rowData[field]

          const innerCellClassName = classNames({
            'text-end': field === 'amount',
            'text-center': field !== 'amount',
            'question_display-checkbox': field === 'on_hold',
            'question_display-checkbox-true': field === 'on_hold' && value,
          })

          const tooltip = (value || '').toString().length > 20 ? value : ''
          let formattedValue =
            typeof value === 'string'
              ? value.length > 20
                ? `${value.substring(0, 20)}...`
                : value
              : value

          const isDirectionGrey = rowData.table_tr_class.includes('grey')
          if (formattedValue === 'eingehend') {
            formattedValue = (
              <Tooltip text="eingehend" placement="top">
                <i
                  style={{ margin: 'auto' }}
                  className={`fas fa-sign-in-alt ${
                    isDirectionGrey ? '' : 'text-success'
                  }`}
                />
              </Tooltip>
            )
          } else if (formattedValue === 'ausgehend') {
            formattedValue = (
              <Tooltip text="ausgehend" placement="top">
                <i
                  style={{ margin: 'auto' }}
                  className={`fas fa-sign-out-alt fa-rotate-180 ${
                    isDirectionGrey ? '' : 'text-danger'
                  }`}
                />
              </Tooltip>
            )
          }
          switch (formattedValue) {
            case 'Conny an Kanzlei':
              formattedValue = 'C -> K'
              break
            case 'Kanzlei an Conny':
              formattedValue = 'K -> C'
              break
            case 'Sàrl an Kanzlei':
              formattedValue = 'S -> K'
              break
            case 'Kanzlei an Sàrl':
              formattedValue = 'K -> S'
              break
          }

          return (
            <td
              key={cellIndex}
              title={tooltip}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
            >
              <div className={innerCellClassName}>{formattedValue}</div>
            </td>
          )
        })}
        <td className="ps-0 payments-table__cell text-end pe-2">
          {rowData.invoice_or_verdict ? (
            <Tooltip text="Als bezahlt eintragen" placement="top">
              <Button
                value={rowData.id}
                onClick={onMarkPaid}
                className="btn py-0 px-1"
              >
                <i className="fas fa-euro payments-table__action-icon" />
              </Button>
            </Tooltip>
          ) : null}
          {rowData.invoice_or_verdict ? (
            <Tooltip text="Stornieren" placement="top">
              <Button
                value={rowData.id}
                onClick={onCancel}
                className="btn py-0 px-1"
              >
                <i className="fas fa-ban payments-table__action-icon" />
              </Button>
            </Tooltip>
          ) : null}
          {rowData.incoming && rowData.payment && !rowData.child ? (
            <Tooltip text="Aufteilen" placement="top">
              <Button
                value={rowData.id}
                onClick={onSplit}
                className="btn py-0 px-1"
              >
                <i className="fas fa-plus payments-table__action-icon" />
              </Button>
            </Tooltip>
          ) : null}
          <Tooltip text="Kopieren" placement="top">
            <Button
              value={rowData.id}
              onClick={onClone}
              className="btn py-0 px-1"
            >
              <i className="fas fa-clone payments-table__action-icon" />
            </Button>
          </Tooltip>

          <Tooltip text="Editieren" placement="top">
            <Button
              value={rowData.id}
              onClick={onEdit}
              className="btn py-0 px-1"
            >
              <i className="fas fa-edit payments-table__action-icon" />
            </Button>
          </Tooltip>
          <Tooltip text="Löschen" placement="top">
            <Button value={rowData.id} onClick={onDelete} className="btn p-0">
              <i className="fas fa-trash-alt payments-table__action-icon" />
            </Button>
          </Tooltip>
        </td>
      </tr>
    )
  }

  render() {
    const { data, fieldsOrder } = this.props

    return (
      <DataTable
        rows={data}
        head={keys(data)}
        renderRow={this.renderRow}
        className="table table-striped table-dark-engine-funnel"
        renderHead={this.renderHead}
        fieldsOrder={fieldsOrder}
      />
    )
  }
}
