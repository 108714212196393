import moment from 'moment'
import { find } from 'lodash/fp'

const date = (value) => moment(value).format('DD.MM.YYYY')

const format = (type) => (value, options) => {
  if (!value) {
    return value
  }

  if (type === 'date') {
    return date(value)
  }

  if (type === 'select') {
    return (find({ value })(options) || {}).text
  }

  if (type === 'checkbox') {
    return ''
  }

  if (type === 'number') {
    return parseFloat(value).toFixed(2).toString().replace(/\./, ',')
  }

  if (type === 'boolean') {
    return value ? 'Ja' : 'Nein'
  }

  if (type === 'yes_no') {
    if (value === 'yes') return 'Ja'
    else if (value === 'no') return 'Nein'
    return ''
  }

  return value
}

const chunkWithPosition = (array) => {
  const result = []

  let index = 0
  while (index < array.length) {
    const nextItem = array[index + 1]
    const currentItem = array[index]

    if (currentItem.rhs) {
      result.push([undefined, currentItem])
      index += 1
      continue
    }

    if (!currentItem.rhs && (nextItem || {}).rhs) {
      result.push([array[index], nextItem])
      index += 2
      continue
    }

    result.push([currentItem, undefined])
    index += 1
  }

  return result
}

const transformChangesValue = (changesValue, fieldData) => {
  if (Object.keys(changesValue).length == 0) {
    return
  }

  if (fieldData.question_type == 'date') {
    changesValue.forEach((val) => {
      val.changed_from = moment(val.changed_from)
        .locale('de')
        .format('DD.MM.YYYY')
    })
  }

  if (fieldData.question_type == 'checkbox') {
    changesValue.forEach((val) => {
      val.changed_from =
        val.changed_from === 'nicht gesetzt'
          ? 'Nein'
          : val.changed_from.toString()
    })
  }

  if (fieldData.question_type == 'select') {
    changesValue.forEach((val) => {
      const valueForSelect = fieldData.options.find(
        (o) => o.value == val.changed_from,
      )
      if (valueForSelect) {
        val.changed_from = valueForSelect.text
      }
    })
  }
}

export { format, chunkWithPosition, transformChangesValue }
