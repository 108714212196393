import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class List extends Component {
  static propTypes = {
    name: PropTypes.string,
    value: PropTypes.any,
    children: PropTypes.any,
    listClassName: PropTypes.string,
    onClick: PropTypes.func,
  }

  handleChange = (value) => {
    const { name, onClick } = this.props

    onClick && onClick({ name, value })
  }

  render() {
    const { children, listClassName } = this.props

    return (
      <ul className={listClassName}>
        {React.Children.map(children, (child) => {
          return React.cloneElement(child, {
            onClick: this.handleChange,
          })
        })}
      </ul>
    )
  }
}
