import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class Stepper extends Component {
  static propTypes = {
    children: PropTypes.array.isRequired,
    onCompleteClick: PropTypes.func,
  }

  state = {
    currentStep: 0,
  }

  handlePreviousClick = () => {
    let { currentStep } = this.state
    this.setState({ currentStep: --currentStep })
  }

  handleNextClick = () => {
    let { currentStep } = this.state
    this.setState({ currentStep: ++currentStep })
  }

  handleCompleteClick = () => {
    const { onCompleteClick } = this.props

    onCompleteClick && onCompleteClick()
  }

  render() {
    const { children } = this.props

    return React.Children.map(children, (child, index) => {
      const { currentStep } = this.state

      return React.cloneElement(child, {
        isActive: index === currentStep,
        onNextClick: this.handleNextClick,
        onPreviousClick: this.handlePreviousClick,
        onCompleteClick: this.handleCompleteClick,
      })
    })
  }
}
