import React from 'react'
import PropTypes from 'prop-types'

import Field from './Field'
import { FormContainer } from './styles/documentCaseData'
import { chunkWithPosition } from '../../helpers/helpers'

export default function DynamicForm(props) {
  const { fields, values, onChange, onBatchChange, errors, isStepRent, token } =
    props

  const data =
    Array.isArray(fields) && fields.some((item) => item.rhs)
      ? chunkWithPosition(fields)
      : fields

  return (
    <FormContainer isStepRent={isStepRent}>
      {Array.isArray(data) &&
        data.map((field, index) => {
          return (
            <Field
              key={index}
              field={field}
              onChange={onChange}
              onBatchChange={onBatchChange}
              values={values}
              errors={errors}
              isStepRent={isStepRent}
              token={token}
            />
          )
        })}
    </FormContainer>
  )
}

DynamicForm.propTypes = {
  fields: PropTypes.array,
  values: PropTypes.object,
  onChange: PropTypes.func,
  onBatchChange: PropTypes.func,
  errors: PropTypes.object,
  isStepRent: PropTypes.bool,
  token: PropTypes.string,
}
