import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export default class TextInput extends Component {
  static displayName = 'TextInput'
  static propTypes = {
    type: PropTypes.string,
    name: PropTypes.string,
    error: PropTypes.bool,
    value: PropTypes.any,
    readOnly: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    type: 'string',
  }

  handleInput = (event) => {
    const { value } = event.target
    const { name, onChange } = this.props
    onChange && onChange({ name, value })
  }

  render() {
    const {
      name,
      type,
      value,
      error,
      readOnly,
      className,
      placeholder,
      disabled,
      step,
    } = this.props

    const inputClassName = classNames(className, { error })

    const inputType = type === 'number' ? 'text' : type

    return (
      <input
        type={inputType}
        lang="de"
        name={name}
        step={step}
        value={value}
        onInput={this.handleInput}
        onChange={() => {}}
        readOnly={readOnly}
        className={inputClassName}
        placeholder={placeholder}
        disabled={disabled}
      />
    )
  }
}
