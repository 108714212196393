import React, { useState, useEffect } from 'react'
import Tooltip from '../../engine-ui/Tooltip'
import client from '../../../../../client'

const CaseInfoBox = (props) => {
  const { id, requestId } = props
  const [caseInfoBox, setCaseInfoBox] = useState({})
  const loadCaseInfoBox = () => {
    client
      .get(`/admin/v2/requests/${requestId}/case_info_boxes/${id}`)
      .then((response) => response.json())
      .then((data) => setCaseInfoBox(data))
  }
  useEffect(loadCaseInfoBox, [])
  return (
    <div className="d-flex justify-content-between me-3">
      <div className="sidebar__casedetails-version-title">
        <strong dangerouslySetInnerHTML={{ __html: caseInfoBox.html }}></strong>
      </div>
      {caseInfoBox.tooltip && (
        <>
          <Tooltip
            className="text-center"
            placement="right"
            text={caseInfoBox.tooltip}
          >
            <i className="fas fa-question-circle"> </i>
          </Tooltip>
        </>
      )}
    </div>
  )
}
const CaseInfoBoxes = (props) => {
  const { requestId } = props
  const [caseInfoBoxes, setCaseInfoBoxes] = useState([])
  const loadCaseInfoBoxes = () => {
    client
      .get(`/admin/v2/requests/${requestId}/case_info_boxes`)
      .then((response) => response.json())
      .then((data) => setCaseInfoBoxes(data))
  }

  useEffect(loadCaseInfoBoxes, [])

  return (
    <>
      {caseInfoBoxes.map((caseInfoBox) => (
        <CaseInfoBox
          key={caseInfoBox.id}
          requestId={requestId}
          id={caseInfoBox.id}
        />
      ))}
    </>
  )
}

export default CaseInfoBoxes
