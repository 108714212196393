import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const Badge = (props) => {
  const { type, text } = props

  const className = classNames(
    'badge rounded-pill',
    `deadline-tickets-badge__${type}`,
  )

  if (text === 0) return '-'

  return <span className={className}>{text}</span>
}

Badge.propTypes = {
  text: PropTypes.any,
  type: PropTypes.string,
}

export default Badge
