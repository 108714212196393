/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */

import React from 'react'
import PillBadge from './BadgeLinkCell'
import HeaderCell from './HeaderCell'

import { getFilterHref } from '../helpers'

const config = [
  {
    Header: 'Bearbeiter',
    columns: [
      {
        Header: '',
        accessor: 'agent_name',
      },
    ],
  },
  {
    Header: 'Alle Todos',
    columns: [
      {
        maxWidth: '4%',
        accessor: 'due_today_amount',
        Cell: (props) => (
          <PillBadge
            text={props.value}
            type="intern-due-today"
            href={getFilterHref({
              ...props.row.original,
              filters: {
                filter: 'due_today',
                category: props.filters.todo_type,
              },
            })}
          />
        ),
        Header: () => <HeaderCell text="Bis heute" />,
      },
      {
        maxWidth: '4%',
        accessor: 'due_tomorrow_amount',
        Cell: (props) => (
          <PillBadge
            text={props.value}
            type="intern-due-tomorrow"
            href={getFilterHref({
              ...props.row.original,
              filters: {
                filter: 'due_tomorrow',
                category: props.filters.todo_type,
              },
            })}
          />
        ),
        Header: () => <HeaderCell text="Bis morgen" />,
      },
      {
        maxWidth: '10%',
        Header: '',
        accessor: 'extra_column_2',
      },
    ],
  },
  {
    Header: 'Extern',
    columns: [
      {
        maxWidth: '4%',
        accessor: 'extern_till_today_amount',
        Cell: (props) => {
          return (
            <PillBadge
              text={props.value}
              type="extern-till-today"
              href={getFilterHref({
                ...props.row.original,
                filters: {
                  filter: 'due_today',
                  category: props.filters.todo_type,
                  deadline_type: 'external',
                },
              })}
            />
          )
        },
        Header: () => <HeaderCell text="Ablauf heute" />,
      },
      {
        maxWidth: '4%',
        accessor: 'extern_till_tomorrow_amount',
        Cell: (props) => (
          <PillBadge
            text={props.value}
            type="extern-till-tomorrow"
            href={getFilterHref({
              ...props.row.original,
              filters: {
                filter: 'due_tomorrow',
                category: props.filters.todo_type,
                deadline_type: 'external',
              },
            })}
          />
        ),
        Header: () => <HeaderCell text="Ablauf morgen" />,
      },
      {
        maxWidth: '4%',
        accessor: 'extern_till_current_week_amount',
        Cell: (props) => (
          <PillBadge
            text={props.value}
            type="extern-till-current-week"
            href={getFilterHref({
              ...props.row.original,
              filters: {
                filter: 'due_current_week',
                category: props.filters.todo_type,
                deadline_type: 'external',
              },
            })}
          />
        ),
        Header: () => <HeaderCell text="Diese Woche" />,
      },
      {
        maxWidth: '4%',
        accessor: 'extern_till_next_week_amount',
        Cell: (props) => (
          <PillBadge
            text={props.value}
            type="extern-till-next-week"
            href={getFilterHref({
              ...props.row.original,
              filters: {
                filter: 'due_next_week',
                category: props.filters.todo_type,
                deadline_type: 'external',
              },
            })}
          />
        ),
        Header: () => <HeaderCell text="Nächste Woche" />,
      },
      {
        maxWidth: '4%',
        accessor: 'extern_till_next_3_week_amount',
        Cell: (props) => (
          <PillBadge
            text={props.value}
            type="extern-till-next-3week"
            href={getFilterHref({
              ...props.row.original,
              filters: {
                filter: 'due_end_of_third_week',
                category: props.filters.todo_type,
                deadline_type: 'external',
              },
            })}
          />
        ),
        Header: () => <HeaderCell text="in 2 Wochen" />,
      },
      {
        maxWidth: '4%',
        accessor: 'extern_till_next_4_week_amount',
        Cell: (props) => (
          <PillBadge
            text={props.value}
            type="extern-till-next-4week"
            href={getFilterHref({
              ...props.row.original,
              filters: {
                filter: 'due_end_of_fourth_week',
                category: props.filters.todo_type,
                deadline_type: 'external',
              },
            })}
          />
        ),
        Header: () => <HeaderCell text="in 3 Wochen" />,
      },
      {
        maxWidth: '10%',
        Header: '',
        accessor: 'extra_column_1',
      },
    ],
  },
  {
    Header: 'Intern',
    columns: [
      {
        maxWidth: '4%',
        accessor: 'intern_till_today_amount',
        Cell: (props) => (
          <PillBadge
            text={props.value}
            type="intern-till-today"
            href={getFilterHref({
              ...props.row.original,
              filters: {
                filter: 'due_today',
                category: props.filters.todo_type,
                deadline_type: 'internal',
              },
            })}
          />
        ),
        Header: () => <HeaderCell text="Ablauf heute" />,
      },
      {
        maxWidth: '4%',
        accessor: 'intern_till_tomorrow_amount',
        Cell: (props) => (
          <PillBadge
            text={props.value}
            type="intern-till-tomorrow"
            href={getFilterHref({
              ...props.row.original,
              filters: {
                filter: 'due_tomorrow',
                category: props.filters.todo_type,
                deadline_type: 'internal',
              },
            })}
          />
        ),
        Header: () => <HeaderCell text="Ablauf morgen" />,
      },
      {
        maxWidth: '4%',
        accessor: 'intern_till_current_week_amount',
        Cell: (props) => (
          <PillBadge
            text={props.value}
            type="intern-till-current-week"
            href={getFilterHref({
              ...props.row.original,
              filters: {
                filter: 'due_current_week',
                category: props.filters.todo_type,
                deadline_type: 'internal',
              },
            })}
          />
        ),
        Header: () => <HeaderCell text="Diese Woche" />,
      },
      {
        maxWidth: '4%',
        accessor: 'intern_till_next_week_amount',
        Cell: (props) => (
          <PillBadge
            text={props.value}
            type="intern-till-next-week"
            href={getFilterHref({
              ...props.row.original,
              filters: {
                filter: 'due_next_week',
                category: props.filters.todo_type,
                deadline_type: 'internal',
              },
            })}
          />
        ),
        Header: () => <HeaderCell text="Nächste Woche" />,
      },
      {
        maxWidth: '4%',
        accessor: 'intern_till_next_3_week_amount',
        Cell: (props) => (
          <PillBadge
            text={props.value}
            type="intern-till-next-3week"
            href={getFilterHref({
              ...props.row.original,
              filters: {
                filter: 'due_end_of_third_week',
                category: props.filters.todo_type,
                deadline_type: 'internal',
              },
            })}
          />
        ),
        Header: () => <HeaderCell text="in 2 Wochen" />,
      },
      {
        maxWidth: '4%',
        accessor: 'intern_till_next_4_week_amount',
        Cell: (props) => (
          <PillBadge
            text={props.value}
            type="intern-till-next-4week"
            href={getFilterHref({
              ...props.row.original,
              filters: {
                filter: 'due_end_of_fourth_week',
                category: props.filters.todo_type,
                deadline_type: 'internal',
              },
            })}
          />
        ),
        Header: () => <HeaderCell text="in 3 Wochen" />,
      },
    ],
  },
]

export { config }
