import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

export default function TriggeredAction(props) {
  const { checked, showCheckboxes, onActionCheck, className } = props

  const { errors, icon, id, subtitle, title, body } = props.action

  const openPreview = () => {
    const newWindow = window.open()
    newWindow.document.write(`<html><head></head><body>${body}</body></html>`)
    trackPreviewClick()
  }

  const trackPreviewClick = () => {
    const body = document.getElementsByTagName('body')[0]
    const adminId = body.dataset.agentId

    if (window.analytics) {
      window.analytics.track('Action preview clicked', {
        agent_id: adminId,
        title: title,
      })
    }
  }

  var subtitleIcon = ''
  let style

  if (errors.length) {
    subtitleIcon = 'fas fa-exclamation-triangle text-danger'
  } else if (subtitle.length) {
    subtitleIcon = 'fas fa-info-circle'
  }

  style = showCheckboxes ? { display: 'inline' } : { display: 'none' }

  return (
    <div className="actions-triggered__item">
      <input
        type="checkbox"
        checked={checked}
        id={id}
        onChange={onActionCheck}
        style={style}
        className="mt-1 me-3"
      />
      <div>
        <h5
          className={classNames('actions-triggered__item-ttl', {
            'actions-triggered__item-ttl--clickable': body,
            'actions-triggered__item-ttl--zieb': className === 'zieb',
          })}
        >
          <i className={classNames(icon, 'color-primary', 'me-2')}></i>
          {body ? (
            <a
              target="_blank"
              className="actions-triggered__item-btn"
              onClick={openPreview}
            >
              {title}
            </a>
          ) : (
            title
          )}
        </h5>
        <div className="info">
          <i className={subtitleIcon}></i>
          <span
            className={classNames('ms-1', { 'text-danger': errors.length })}
          >
            {errors.length ? 'Fehler:' : subtitle}
          </span>
          {errors.length ? (
            <ol
              className={
                className === 'zieb' ? 'errors-list--zieb' : 'errors-list'
              }
            >
              {errors.map((error, i) => (
                <li key={`errors${i}`}>{error}</li>
              ))}
            </ol>
          ) : null}
        </div>
      </div>
    </div>
  )
}

TriggeredAction.propTypes = {
  action: PropTypes.object,
  onActionCheck: PropTypes.func,
  showCheckboxes: PropTypes.bool,
  checked: PropTypes.bool,
}
