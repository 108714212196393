import Operation from './operation'
export default class Dependency {
  constructor(conditions, action, priority, scope) {
    this.conditions = conditions
    this.action = action
    this.priority = priority
    this.scope = scope || ''
  }
  static fromJSON(_json) {
    return new Dependency(
      Operation.fromJsonArray(_json['conditions']),
      _json['action'],
      _json['priority'],
      _json['scope'] || '',
    )
  }
  static fromJSONArray(_jsonArray) {
    return _jsonArray.map((_json) => Dependency.fromJSON(_json))
  }
  match(responses = {}) {
    let scoped_responses = this.filter(responses, this.scope)
    let match = this.conditions.reduce((accmltr, val) => {
      return accmltr && val.returns(scoped_responses)
    }, true)
    let action = match ? this.action : ''
    return new Match(match, action)
  }
  filter(hash, scope) {
    if (scope == '' || scope == undefined) return hash
    // This is just filtering the responses array for the matching pattern.
    // ES6 doesn't have any better way of doing it, AFAIK.
    // In ruby it would be: hash.select{|k,_v| k ~= scope }
    return Object.keys(hash)
      .filter((key) => new RegExp(scope).test(key))
      .reduce((accu, key) => ((accu[key] = hash[key]), accu), {})
  }
}
export class Match {
  constructor(match, action) {
    this.match = match
    this.action = action
  }
}
