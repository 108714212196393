import React from 'react'
import PropTypes from 'prop-types'
import { assign, isEmpty, isString } from 'lodash'
import classNames from 'classnames'

import Datetime from 'react-datetime'
const Datepicker = ({
  question,
  handleFormChange,
  state,
  viewMode,
  isValid,
  errors,
  handleBlur,
  disabled,
  inputClassName,
}) => {
  function isValidDate(current) {
    if (isEmpty(current)) {
      return !question.required
    }

    if (isString(current)) {
      return false
    }

    return isValid(current)
  }

  function onChange(momentDate) {
    const value = moment.isMoment(momentDate) ? momentDate.utc().format() : ''

    handleFormChange(question.name, value, isValidDate(momentDate))
  }
  function onBlur() {
    handleBlur(question.name)
  }

  const underscoreName = question.name.replace('[', '_').replace(']', '')
  const value = isEmpty(state.value) ? '' : moment(state.value)

  const inputProps = assign(
    {
      id: underscoreName,
      'aria-invalid': errors.length > 0,
      className: classNames('form-control', {
        invalid: state.showErrors && !isValidDate(value),
        [inputClassName]: inputClassName,
      }),
    },
    { disabled: disabled },
  )
  return (
    <div className={`form-group ${underscoreName}`}>
      <label className="control-label" htmlFor={underscoreName}>
        {question.text}
      </label>
      {errors.length > 0 && (
        <span className="help-block">{errors.join(', ')}</span>
      )}
      <Datetime
        locale="de"
        closeOnSelect={true}
        dateFormat="DD.MM.YYYY"
        timeFormat={false}
        utc={true}
        viewMode={viewMode}
        isValidDate={isValidDate}
        onBlur={onBlur}
        onChange={onChange}
        inputProps={inputProps}
        value={value}
      />
    </div>
  )
}

Datepicker.propTypes = {
  question: PropTypes.object.isRequired,
  handleFormChange: PropTypes.func,
  handleBlur: PropTypes.func,
  state: PropTypes.object,
  viewMode: PropTypes.string,
  isValid: PropTypes.func,
  errors: PropTypes.array,
  disabled: PropTypes.bool,
  inputClassName: PropTypes.string,
}

Datepicker.defaultProps = {
  handleFormChange: () => {},
  handleBlur: () => {},
  viewMode: 'days',
  isValid: () => true,
  errors: [],
  disabled: false,
}

export default Datepicker
