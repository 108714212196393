import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

export default function SearchableSelect(props) {
  const { options, value, isClearable, isMulti, onChange, placeholder } = props

  const colourStyles = {
    option: (styles, { isDisabled }) => ({
      ...styles,
      color: isDisabled ? '#ccc' : 'black',
    }),
  }

  const defaultValueObject = options.find((item) => item.value === value)
  const isLoading = options.length === 0

  return (
    <Select
      options={options}
      styles={colourStyles}
      placeholder={placeholder}
      defaultValue={defaultValueObject}
      isClearable={isClearable}
      isMulti={isMulti}
      onChange={onChange}
      isLoading={isLoading}
      loadingMessage={() => 'lädt...'}
    />
  )
}

SearchableSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
}
