import React, { useState, memo, useEffect } from 'react'

import useDocument from '../../hooks/useDocument'
import useDocumentViewer from '../../hooks/useDocumentViewer'
import { Image, RotateButton } from './styles/documentViewer'
import { Viewer, Worker } from '@react-pdf-viewer/core'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation'

import packageJson from '../../../../../../../../package.json'
const pdfjsVersion = packageJson.dependencies['pdfjs-dist']

function PDFViewer() {
  const defaultLayoutPluginInstance = defaultLayoutPlugin()
  const pageNavigationPluginInstance = pageNavigationPlugin()
  const { document: doc } = useDocument()
  const { pageNumber, change } = useDocumentViewer()
  const [rotateDegree, setRotateDegree] = useState(0)

  const imageExtensions = ['jpeg', 'jpg', 'png', 'gif', 'tiff', 'bmp']

  const rotate = () => {
    if (rotateDegree < 270) {
      setRotateDegree(rotateDegree + 90)
      return
    }
    setRotateDegree(0)
  }

  useEffect(() => {
    // When the user scroll we set the page to null.
    // When we exit early here we avoid the pageJump when the user just scrolls.
    // Without it the scroll experience is very bad.
    if (!pageNumber) {
      return
    }

    pageNavigationPluginInstance.jumpToPage(pageNumber - 1)
  }, [pageNumber])

  if (doc.initialized) {
    if (imageExtensions.includes(doc.data.extension)) {
      return (
        <div>
          <div className="d-flex justify-content-end">
            <RotateButton onClick={rotate}>
              <i className="fas fa-undo fa-flip-vertical text-white" />
            </RotateButton>
          </div>
          <Image
            src={doc.data.url}
            alt="document"
            width="100%"
            rotateDegree={rotateDegree}
          />
        </div>
      )
    } else if (doc.data.extension === 'pdf') {
      return (
        <Worker
          workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.js`}
        >
          <div style={{ height: '1000px' }}>
            <Viewer
              fileUrl={doc.data.content_url}
              initialPage={pageNumber ?? 0}
              onPageChange={(_e) => {
                change(null)
              }}
              plugins={[
                defaultLayoutPluginInstance,
                pageNavigationPluginInstance,
              ]}
            />
          </div>
        </Worker>
      )
    }
    return (
      <iframe
        id="target_document"
        width="100%"
        height="100%"
        src={doc.data.url}
      ></iframe>
    )
  }

  return null
}

export default memo(PDFViewer, () => true)
