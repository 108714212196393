import React from 'react'
import PropTypes from 'prop-types'

import Badge from './Badge'
import { getTicketsTotals } from './helpers'

export default function TeamViewHeader({ data, onClick }) {
  const ticketsTotals = getTicketsTotals(data.agents_tickets_amounts)

  return (
    <div
      id={data.team_name}
      onClick={onClick}
      className="deadline-tickets-teamview-header"
    >
      <div className="deadline-tickets-teamview-header__title">
        {data.team_name}
      </div>
      <div className="deadline-tickets-cell">
        <Badge text={ticketsTotals.dueTodayAmount} type="intern-due-today" />
      </div>
      <div className="deadline-tickets-cell">
        <Badge
          text={ticketsTotals.dueTomorrowAmount}
          type="intern-due-tomorrow"
        />
      </div>
      <div className="deadline-tickets-cell"></div>
      <div className="deadline-tickets-cell">
        <Badge
          text={ticketsTotals.externTillTodayAmount}
          type="extern-till-today"
        />
      </div>
      <div className="deadline-tickets-cell">
        <Badge
          text={ticketsTotals.externTillTomorrowAmount}
          type="extern-till-tomorrow"
        />
      </div>
      <div className="deadline-tickets-cell">
        <Badge
          text={ticketsTotals.externTillCurrentWeekAmount}
          type="extern-till-current-week"
        />
      </div>
      <div className="deadline-tickets-cell">
        <Badge
          text={ticketsTotals.externTillNextWeekAmount}
          type="extern-till-next-week"
        />
      </div>
      <div className="deadline-tickets-cell">
        <Badge
          text={ticketsTotals.externTillNext3WeekAmount}
          type="extern-till-next-3week"
        />
      </div>
      <div className="deadline-tickets-cell">
        <Badge
          text={ticketsTotals.externTillNext4WeekAmount}
          type="extern-till-next-4week"
        />
      </div>
      <div className="deadline-tickets-cell"></div>
      <div className="deadline-tickets-cell">
        <Badge
          text={ticketsTotals.internTillTodayAmount}
          type="intern-till-today"
        />
      </div>
      <div className="deadline-tickets-cell">
        <Badge
          text={ticketsTotals.internTillTomorrowAmount}
          type="intern-till-tomorrow"
        />
      </div>
      <div className="deadline-tickets-cell">
        <Badge
          text={ticketsTotals.internTillCurrentWeekAmount}
          type="intern-till-current-week"
        />
      </div>
      <div className="deadline-tickets-cell">
        <Badge
          text={ticketsTotals.internTillNextWeekAmount}
          type="intern-till-next-week"
        />
      </div>
      <div className="deadline-tickets-cell">
        <Badge
          text={ticketsTotals.internTillNext3WeekAmount}
          type="intern-till-next-3week"
        />
      </div>
      <div className="deadline-tickets-cell">
        <Badge
          text={ticketsTotals.internTillNext4WeekAmount}
          type="intern-till-next-4week"
        />
      </div>
    </div>
  )
}

TeamViewHeader.propTypes = {
  data: PropTypes.object,
  onClick: PropTypes.func,
}
