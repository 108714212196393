/* eslint react/prop-types: 0 */
import React, { Children, useState, cloneElement } from 'react'

import { StyledTabs, StyledContentContainer } from './styles/tabs'

const Tabs = (props) => {
  const { onSelect, children, defaultSelectedIndex = 0 } = props

  const [selectedIndex, setSelectedIndex] = useState(defaultSelectedIndex)

  const handleSelect = (index, value) => {
    setSelectedIndex(index)
    onSelect && onSelect(index, value)
  }

  const [tabListChild, ...tabPanelChildren] = children

  return (
    <>
      <StyledTabs {...props}>
        {cloneElement(tabListChild, {
          onSelect: handleSelect,
          selectedIndex,
        })}
      </StyledTabs>
      <StyledContentContainer>
        {Children.map(tabPanelChildren, (child, index) =>
          cloneElement(child, {
            index,
            selectedIndex,
          }),
        )}
      </StyledContentContainer>
    </>
  )
}

export default Tabs
