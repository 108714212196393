const INITIALIZE_DOCUMENT = 'INITIALIZE_DOCUMENT'
const CHANGE_DOCUMENT = 'CHANGE_DOCUMENT'

export function initializeDocument(payload = {}) {
  return { type: INITIALIZE_DOCUMENT, payload }
}

export function changeDocument(payload = {}) {
  return { type: CHANGE_DOCUMENT, payload }
}

const initialState = { data: {}, initialized: false }
export default function reducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case INITIALIZE_DOCUMENT:
      return {
        data: { ...payload },
        initialized: true,
      }
    case CHANGE_DOCUMENT:
      return {
        ...state,
        data: { ...state.data, ...payload },
      }
    default:
      return state
  }
}
