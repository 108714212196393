import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { keys, groupBy } from 'lodash/fp'

import DocumentForm from './DocumentForm'
import DocumentListForm from './DocumentListForm'
import DocumentsDataTable from './DocumentsDataTable'

import Modal from '../engine-ui/Modal'
import Button from '../engine-ui/Button'

import client from '../../../../client'
import withFetchedDocuments from './hoc/withFetchedDocuments'
import classNames from 'classnames'
import DocumentsFilter from './DocumentsFilter/DocumentsFilter'

class DocumentsTab extends Component {
  static propTypes = {
    agent: PropTypes.object.isRequired,
    requestId: PropTypes.number.isRequired,
    physicalMailEnabled: PropTypes.bool,
    processedDocuments: PropTypes.array,
    unprocessedDocuments: PropTypes.array,
    ziebToken: PropTypes.string,
    token: PropTypes.string,
  }

  state = {
    isModalOpen: false,
    newDocument: {
      incoming: true,
    },
    files: [],
    uploaded: 0,
    uploading: 0,
    renderDocuments: [],
    filterMessage: 'Dokumente werden geladen...',
    fetchingDocuments: false,
  }

  handleDelete = (id) => {
    const { requestId } = this.props
    client
      .delete(`/admin/requests/${requestId}/documents/${id}.json`)
      .then(() => this.props.fetchDocuments())
  }

  handleEdit = (id, field) => {
    const { requestId, token } = this.props
    const url = `/api/admin/requests/${requestId}/documents/${id}.json`
    const payload = { document: field }
    const headers = {
      token: token,
    }
    client.patch(url, payload, headers)
  }

  handleFormSubmit = () => {
    const { requestId } = this.props
    const { newDocument, files } = this.state

    Promise.all(
      files.map(async (file) => {
        return client
          .post(`/admin/requests/${requestId}/documents.json`, {
            document: { ...newDocument, filename: file.name, url: file.url },
          })
          .then((response) => response.json())
      }),
    )
      .then((a) => {
        let {
          edit_url: url,
          document_id: documentId,
          document_name: documentName,
        } = a.find(({ document_name: filename }) => {
          return filename.endsWith('.pdf')
        }) || a[0]
        V2.trigger('fileupload.success', {
          url,
          documentId,
          documentName,
        })
      })
      .then(() => {
        this.handleModalToggle()
      })
  }

  handleFormBulkSubmit = async (data) => {
    const { requestId } = this.props
    const params = data.documents.reduce((acc, item) => {
      acc.append('documents[]', item)
      return acc
    }, new URLSearchParams())

    try {
      let response
      if (data.submit === 'delete_selection') {
        response = await client.delete(
          `/admin/v2/requests/${requestId}/attach/destroy_multiple?${params.toString()}`,
        )
      } else if (data.submit === 'attach_and_save') {
        response = await client.post(
          `/admin/v2/requests/${requestId}/attach?${params.toString()}`,
        )
      }

      const result = await response.json()

      if (result.status === 'ok') {
        this.props.fetchDocuments()
        return { status: 'ok', filename: result.filename }
      } else {
        return { status: 'error', error: result.error }
      }
    } catch (error) {
      console.error('Error:', error)
      return { status: 'error', error }
    }
  }

  handleFileAdded = (file) => {
    const { presignedPost } = this.props
    const formData = new FormData()

    this.setState((prev) => {
      return { ...prev, uploading: prev.uploading + 1 }
    })

    keys(presignedPost['s3-data']).forEach((item) =>
      formData.append(item, presignedPost['s3-data'][item]),
    )
    formData.append('file', file, file.name)

    fetch(presignedPost['s3-url'], {
      method: 'POST',
      body: formData,
    }).then((response) => {
      if (response.ok) {
        this.setState((prev) => {
          return {
            uploading: prev.uploading,
            uploaded: prev.uploaded + 1,
            files: [
              ...prev.files,
              {
                url: `//${presignedPost['s3-host']}/${presignedPost['s3-data'][
                  'key'
                ].replace(/\${filename}$/, file.name)}`,
                filename: file.name,
              },
            ],
          }
        })
      }
    })
  }

  handleFormFieldChange = ({ name, value }) => {
    const { newDocument } = this.state
    this.setState({ newDocument: { ...newDocument, [name]: value } })
  }

  handleModalToggle = () => {
    const { isModalOpen } = this.state
    this.setState({
      uploaded: 0,
      uploading: 0,
      isModalOpen: !isModalOpen,
    })
  }

  setRenderDocuments = (filteredDocuments) => {
    this.setState({ renderDocuments: filteredDocuments })
  }

  setFilterMessage = (msg) => {
    this.setState({ filterMessage: msg })
  }

  componentDidMount = () => {
    document.addEventListener(
      'filesMergedOrDeleted',
      this.handleFilesMergedOrDeleted,
    )
  }

  componentWillUnmount = () => {
    document.removeEventListener(
      'filesMergedOrDeleted',
      this.handleFilesMergedOrDeleted,
    )
  }

  handleFilesMergedOrDeleted = () => {
    if (!this.state.fetchingDocuments) {
      this.setState({ fetchingDocuments: true }, () => {
        this.props.fetchDocuments()
        this.setState({ fetchingDocuments: false })
      })
    }
  }

  render() {
    const { agent, requestId, documents, physicalMailEnabled } = this.props
    const { uploaded, uploading, newDocument, isModalOpen, filterMessage } =
      this.state

    const { true: processedDocuments = [], false: unprocessedDocuments = [] } =
      groupBy({ approved: true })(this.state.renderDocuments)

    return (
      <div className="survey__body request-tab request-tab-survey unprocessed_documents_div">
        <div className="d-flex align-items-start justify-content-between mt-3 mb-5">
          <DocumentsFilter
            requestId={requestId}
            documents={documents}
            renderDocuments={this.setRenderDocuments}
            setFilterMessage={this.setFilterMessage}
          />
          <Button
            className={classNames('btn activitylog__add-btn me-5', {
              'mb-3': physicalMailEnabled,
            })}
            onClick={this.handleModalToggle}
          >
            <i className="documents-tab__upload-icon fas fa-file-upload" />
          </Button>
          <Modal isOpen={isModalOpen} onClose={this.handleModalToggle}>
            <DocumentForm
              document={newDocument}
              onChange={this.handleFormFieldChange}
              onSubmit={this.handleFormSubmit}
              uploaded={uploaded}
              uploading={uploading}
              onFileAdded={this.handleFileAdded}
            />
          </Modal>
        </div>
        {this.state.renderDocuments.length !== 0 ? (
          <>
            <div className="d-flex justify-content-between">
              {physicalMailEnabled ? (
                <p className="form_category__title">Dokumentenübersicht</p>
              ) : (
                <p className="form_category__title align-self-end">
                  Nicht akzeptierte Dokumente
                </p>
              )}
            </div>
            {physicalMailEnabled ? (
              <DocumentsDataTable
                requestId={this.props.requestId}
                documents={this.state.renderDocuments}
                allDocuments={documents}
                ziebToken={this.props.ziebToken}
                agent={agent}
                token={this.props.token}
              />
            ) : null}
            {physicalMailEnabled ? (
              <p className="form_category__title mt-5">
                Nicht akzeptierte Dokumente
              </p>
            ) : null}
            <DocumentListForm
              onDelete={this.handleDelete}
              onSubmit={this.handleFormBulkSubmit}
              onEdit={this.handleEdit}
              requestId={requestId}
              documents={unprocessedDocuments}
              agentEmail={agent.email}
              unprocessed={true}
            />
            <p className="form_category__title">Akzeptierte Dokumente</p>
            <DocumentListForm
              onDelete={this.handleDelete}
              onSubmit={this.handleFormBulkSubmit}
              onEdit={this.handleEdit}
              requestId={requestId}
              documents={processedDocuments}
              agentEmail={agent.email}
              unprocessed={false}
            />
          </>
        ) : (
          <div className="d-flex justify-content-center my-5">
            <em>{filterMessage}</em>
          </div>
        )}
      </div>
    )
  }
}

export default withFetchedDocuments(DocumentsTab)
