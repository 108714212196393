import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class Accordion extends Component {
  static propTypes = {
    id: PropTypes.number,
    openItem: PropTypes.string,
    containerClassName: PropTypes.string,
    blockName: PropTypes.string,
    children: PropTypes.array,
    onItemClick: PropTypes.func,
  }

  state = {
    openItem: undefined,
  }

  static getDerivedStateFromProps(props, state) {
    if (props.children.length - 1 < state.openItem) {
      return { openItem: undefined }
    }
    return state
  }

  handleItemClick = (id, value) => {
    const { onItemClick } = this.props
    this.setState({ openItem: id })

    onItemClick && onItemClick(value)
  }

  render() {
    const { openItem } = this.state
    const { containerClassName, children, blockName } = this.props
    return (
      <div className={`accordion__${containerClassName}`}>
        {React.Children.map(children, (child, index) => {
          const { isOpen: defaultIsOpen } = child.props
          const isOpen =
            typeof openItem === 'undefined' ? defaultIsOpen : openItem === index

          return React.cloneElement(child, {
            isOpen: isOpen,
            blockName,
            onItemClick: this.handleItemClick,
          })
        })}
      </div>
    )
  }
}
