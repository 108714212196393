import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

export default function DynamicSelect(props) {
  const {
    name,
    value,
    getFieldData,
    isClearable,
    isMulti,
    onChange,
    placeholder,
    options,
    token,
  } = props

  const colourStyles = {
    option: (styles, { isDisabled }) => ({
      ...styles,
      color: isDisabled ? '#ccc' : 'black',
    }),
  }

  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [selectedValue, setSelectedValue] = useState({
    label: value,
    value: value,
  })
  const [selectOptions, setSelectOptions] = useState([{ label: '', value: '' }])
  const [isLoading, setIsLoading] = useState(true)
  const handleChange = (selected) => {
    setSelectedValue(selected)
    onChange && onChange({ name, value: selected.value })
  }

  const getOptionName = (nameToFind) => {
    return options.filter((opt) => opt.text === nameToFind)
  }

  const getParams = () => {
    return options
      .filter((opt) => opt.text === 'param')
      .map((opt) => opt.value.replace(/-/g, '.'))
  }

  const refetch = () => {
    const fieldsToGet = getParams()
    if (!fieldsToGet || !fieldsToGet.length) {
      return
    }

    const requiredField = fieldsToGet.reduce((prev, field) => {
      const fieldInput = getFieldData(field)
      const fieldValue =
        fieldInput?.type === 'checkbox'
          ? fieldInput.value === true
          : (fieldInput?.value ?? '')
      return { ...prev, [field]: fieldValue }
    }, {})
    const endpoint = getOptionName('url')[0].value
    const body = JSON.stringify({
      tunnel: JSON.stringify(requiredField),
    })

    if (token && endpoint) {
      fetch(endpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', token },
        body,
      })
        .then((resp) => resp.json())
        .then((data) => {
          const options = data.options.map((item) => ({
            label: item.text,
            value: item.id,
          }))
          setIsLoading(false)
          setSelectOptions([{ label: '', value: '' }, ...options])
          setSelectedValue(options.find((item) => item.value === value))
        })
    }
  }

  useEffect(() => {
    if (!isFirstLoad || !options || options.length === 0) {
      return
    }
    setIsFirstLoad(false)
    refetch()
  }, [])

  return (
    <Select
      onMenuOpen={refetch}
      options={selectOptions}
      styles={colourStyles}
      placeholder={placeholder}
      value={selectedValue}
      isClearable={isClearable}
      isMulti={isMulti}
      onChange={handleChange}
      isLoading={isLoading}
      loadingMessage={() => 'lädt...'}
    />
  )
}

DynamicSelect.displayName = 'DynamicSelect'

DynamicSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  token: PropTypes.string,
  getFieldData: PropTypes.func,
}
