import React from 'react'
import PropTypes from 'prop-types'

import Select from '../../engine-ui/Select'
import Button from '../../engine-ui/Button'
import { filterOptions, filterSaasOptions, buttons } from './filterOptions'
import Tooltip from '../../engine-ui/Tooltip'
import LogFilterButton from './LogFilterButton'

export default function LogFilter(props) {
  const {
    onSelectChange,
    onFilterTypeClick,
    onSelectAll,
    onClearAll,
    activeTypes,
    valueDirection,
    valueSubject,
    saasNotEnabled,
  } = props

  const options = saasNotEnabled ? filterOptions : filterSaasOptions
  const isLargeScreen = window.innerWidth > 1600

  return (
    <div className="log-filter__container">
      {options.map((item) => (
        <Select
          key={item.name}
          name={item.name}
          className={'log-filter__select'}
          options={item.options}
          onChange={onSelectChange}
          value={item.name === 'direction' ? valueDirection : valueSubject}
        />
      ))}
      {buttons.map((button) => (
        <LogFilterButton
          icon={button.icon}
          type={button.type}
          isActive={activeTypes.includes(button.type)}
          isLargeScreen={isLargeScreen}
          onFilterTypeClick={onFilterTypeClick}
          text={button.text}
          title={button.title}
          key={button.type}
        />
      ))}
      <div className="log-filter__green-buttons-container">
        <Tooltip
          className="btn--no-styles"
          text="Alles auswählen"
          placement="right"
        >
          <Button onClick={onSelectAll} className="btn--no-styles">
            <i className="fas fa-check-square activitylog__color--link" />
          </Button>
        </Tooltip>
        <Tooltip
          className="btn--no-styles"
          text="Alle Filter zurücksetzen"
          placement="right"
        >
          <Button
            onClick={onClearAll}
            className="btn--no-styles btn-fa-times-square"
          >
            ×
          </Button>
        </Tooltip>
      </div>
    </div>
  )
}

LogFilter.propTypes = {
  onFilterTypeClick: PropTypes.func,
  onSelectChange: PropTypes.func,
  onSelectAll: PropTypes.func,
  onClearAll: PropTypes.func,
  activeTypes: PropTypes.array,
  valueDirection: PropTypes.string,
  valueSubject: PropTypes.string,
  saasNotEnabled: PropTypes.bool,
}
