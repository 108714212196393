import React from 'react'
import PropTypes from 'prop-types'

import { OCRContainer } from './styles/documentViewer'
import Spinner from '../../../engine-ui/Spinner'

export default function OCRViewer(props) {
  const { text } = props

  return (
    <OCRContainer>
      {text ? <p dangerouslySetInnerHTML={{ __html: text }}></p> : <Spinner />}
    </OCRContainer>
  )
}

OCRViewer.propTypes = {
  text: PropTypes.string,
}
