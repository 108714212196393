const getRequestQuery = (params) => {
  return Object.keys(params)
    .filter((item) => params[item])
    .map((item) => `${item}=${params[item]}`)
    .join('&')
}

const getFilterHref = ({ agent_id, filters }) => {
  const query = getRequestQuery(filters)
  return `/admin/agents/${agent_id}/tickets?${query}`
}

const getTicketsTotals = (agentsTicketsAmounts) => {
  return agentsTicketsAmounts.reduce(
    (acc, item) => {
      return {
        dueTodayAmount: acc.dueTodayAmount + item.due_today_amount,
        dueTomorrowAmount: acc.dueTomorrowAmount + item.due_tomorrow_amount,
        externTillTodayAmount:
          acc.externTillTodayAmount + item.extern_till_today_amount,
        externTillTomorrowAmount:
          acc.externTillTomorrowAmount + item.extern_till_tomorrow_amount,
        externTillCurrentWeekAmount:
          acc.externTillCurrentWeekAmount +
          item.extern_till_current_week_amount,
        externTillNextWeekAmount:
          acc.externTillNextWeekAmount + item.extern_till_next_week_amount,
        externTillNext3WeekAmount:
          acc.externTillNext3WeekAmount + item.extern_till_next_3_week_amount,
        externTillNext4WeekAmount:
          acc.externTillNext4WeekAmount + item.extern_till_next_4_week_amount,
        internTillTodayAmount:
          acc.internTillTodayAmount + item.intern_till_today_amount,
        internTillTomorrowAmount:
          acc.internTillTomorrowAmount + item.intern_till_tomorrow_amount,
        internTillCurrentWeekAmount:
          acc.internTillCurrentWeekAmount +
          item.intern_till_current_week_amount,
        internTillNextWeekAmount:
          acc.internTillNextWeekAmount + item.intern_till_next_week_amount,
        internTillNext3WeekAmount:
          acc.internTillNext3WeekAmount + item.intern_till_next_3_week_amount,
        internTillNext4WeekAmount:
          acc.internTillNext4WeekAmount + item.intern_till_next_4_week_amount,
      }
    },
    {
      dueTodayAmount: 0,
      dueTomorrowAmount: 0,
      externTillTodayAmount: 0,
      externTillTomorrowAmount: 0,
      externTillCurrentWeekAmount: 0,
      externTillNextWeekAmount: 0,
      externTillNext3WeekAmount: 0,
      externTillNext4WeekAmount: 0,
      internTillTodayAmount: 0,
      internTillTomorrowAmount: 0,
      internTillCurrentWeekAmount: 0,
      internTillNextWeekAmount: 0,
      internTillNext3WeekAmount: 0,
      internTillNext4WeekAmount: 0,
    },
  )
}

export { getFilterHref, getRequestQuery, getTicketsTotals }
