import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Tooltip from '../engine-ui/Tooltip'
import { formatDateShort } from '../engine-ui/helpers/format'

function PhaseGraphStatus({ tableRows }) {
  const data = [...tableRows.data].filter((item) => !item.attributes.deleted_at)

  const isLargeScreen = window.innerWidth > 1760
  const displayedStatuses = isLargeScreen ? data.slice(0, 6) : data.slice(0, 4)
  const additionalStatuses = data.length - displayedStatuses.length

  const openStatusTab = () => {
    document.getElementById('status-tab').click()
  }

  return (
    <div className="phasegraph__status">
      {additionalStatuses > 0 && (
        <button className="phasegraph__status-item btn" onClick={openStatusTab}>
          <Tooltip text={'Weitere Status'} placement="top">
            <span className="phasegraph__status-btn-text">
              {`+${additionalStatuses}`}
            </span>
          </Tooltip>
        </button>
      )}
      {displayedStatuses.map((item, index) => {
        return (
          <Tooltip
            key={index}
            text={item.attributes.status_title}
            placement="top"
          >
            <div className="phasegraph__status-item">
              <span className="phasegraph__status-date">
                {formatDateShort(item.attributes.created_at)}:
              </span>
              <span className="phasegraph__status-title">
                {item.attributes.status_title}
              </span>
            </div>
          </Tooltip>
        )
      })}
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    tableRows: state.statusTab.tableRows,
  }
}

PhaseGraphStatus.propTypes = {
  tableRows: PropTypes.object,
}

export default connect(mapStateToProps)(PhaseGraphStatus)
