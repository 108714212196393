import React from 'react'
import PropTypes from 'prop-types'

const EvidenceTitle = ({ title }) => {
  return <p className="form_category__title mb-0">{title}</p>
}

export default EvidenceTitle

EvidenceTitle.propTypes = {
  title: PropTypes.string,
}
