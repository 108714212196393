import { useContext } from 'react'

import Context from '../context/Context'
import {
  initializeCaseData,
  changeCaseData,
  removeCaseData,
} from '../ducks/caseData'

export default function useCaseData() {
  const { state, dispatch } = useContext(Context)
  const { caseData = {} } = state

  const initialize = (payload) => dispatch(initializeCaseData(payload))
  const change = (payload) => dispatch(changeCaseData(payload))
  const remove = () => dispatch(removeCaseData())

  return {
    caseData,
    initializeCaseData: initialize,
    changeCaseData: change,
    removeCaseData: remove,
  }
}
