import styled from 'styled-components'

export const StyledTabs = styled.div`
  display: flex;
  background-color: #2c3035;
  justify-content: ${(props) => props.justifyContent};
`

StyledTabs.defaultProps = {
  justifyContent: 'flex-start',
}

export const StyledContentContainer = styled.div`
  height: 100%;
`
