import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export default class AccordionItem extends Component {
  static propTypes = {
    id: PropTypes.number,
    value: PropTypes.any,
    title: PropTypes.object,
    isOpen: PropTypes.bool,
    children: PropTypes.object,
    onItemClick: PropTypes.func,
    blockName: PropTypes.string,
    activeItemClassName: PropTypes.string,
  }

  static defaultProps = { activeItemClassName: '' }

  handleItemClick = () => {
    const { id, onItemClick, value } = this.props
    onItemClick(id, value)
  }

  render() {
    const { value, title, isOpen, children, blockName, activeItemClassName } =
      this.props

    return (
      // TODO: remove id prop after improve autopilot
      <div
        className={classNames('autopilot-tracking', `${blockName}__wrapper`, {
          [activeItemClassName]: isOpen,
        })}
        id={value}
      >
        <div onClick={this.handleItemClick} className={`${blockName}__header`}>
          {title}
          <span>
            <i
              className={classNames({
                'fas fa-caret-up': isOpen,
                'fas fa-caret-down': !isOpen,
              })}
            ></i>
          </span>
        </div>
        {isOpen && <div className={`${blockName}__body`}>{children}</div>}
      </div>
    )
  }
}
