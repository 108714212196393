import React, { useEffect, useState } from 'react'
import AsyncSelect from 'react-select/async'
import Spinner from '../engine-ui/Spinner'
import Button from '../engine-ui/Button'
import { IOrganization } from './types'
import { useBoolean } from './hooks'
import client from '../../../../client'
interface IProps {
  requestId: string
}

const selectLabel = (option: IOrganization): string => option.name
const selectValue = (option: IOrganization): string => option.id

export default function SwicthOrganization({ requestId }: IProps) {
  const [selectedOrganization, setSelectedOrganization] =
    useState<IOrganization | null>(null)
  const [editable, setEditable] = useBoolean()
  const [loading, setLoading] = useBoolean()
  const [selectLoading, setSelectLoading] = useBoolean()

  useEffect(() => {
    getOrganization()
  }, [])

  const changeSelectedOrganization = (organization: IOrganization) => {
    setSelectedOrganization(organization)
  }

  const saveSelectedOrganization = () => {
    patchRequestOrganization(selectedOrganization as IOrganization)
      .then(setEditable.off)
      .finally(setLoading.off)
  }

  const cancelOrganizationEdit = () => {
    getOrganization()
    setEditable.off()
  }

  const handleInputChange = (value = '') => {
    const trimedValue = value.replace(/\W/g, '')
    return trimedValue
  }

  const handleChange = (organization: IOrganization): void => {
    changeSelectedOrganization(organization)
  }

  const getOrganization = () => {
    const GET_ORGANIZATION_PATH = `/admin/requests/${requestId}/organization`

    setLoading.on()

    client
      .get(GET_ORGANIZATION_PATH)
      .then((data) => data.json())
      .then((organization: IOrganization) => {
        setSelectedOrganization(organization)
        return organization
      })
      .finally(setLoading.off)
  }

  const getOrganizations = (
    searchQuery: string,
    callback: (_organizations: IOrganization[]) => void,
  ) => {
    const GET_ORGANIZATIONS_PATH = `/admin/requests/${requestId}/organizations?${searchQuery}`

    setSelectLoading.on()

    client
      .get(GET_ORGANIZATIONS_PATH)
      .then((data) => data.json())
      .then((_organizations: IOrganization[]) => {
        callback(_organizations)
      })
      .finally(setSelectLoading.off)
  }

  const patchRequestOrganization = (
    organization: IOrganization,
  ): Promise<any> => {
    const PATCH_ORGANIZATION_PATH = `/admin/requests/${requestId}/organization`

    setLoading.on()

    return client
      .patch(PATCH_ORGANIZATION_PATH, organization)
      .then((data) => data.json())
  }

  if (loading) {
    return (
      <div className="switch-organization">
        <Spinner />
      </div>
    )
  }

  if (editable) {
    return (
      <div className="switch-organization">
        {selectedOrganization && (
          <AsyncSelect
            className="switch-organization__select"
            value={selectedOrganization}
            name="organization"
            cacheOptions
            loadOptions={(inputValue, callback) =>
              getOrganizations('live=true', callback)
            }
            defaultOptions
            getOptionLabel={selectLabel}
            getOptionValue={selectValue}
            onInputChange={handleInputChange}
            onChange={handleChange}
            isLoading={selectLoading}
          />
        )}
        <div className="switch-organization__actions">
          <Button
            className="btn mt-0 mx-1 btn-secondary btn-rounded"
            onClick={cancelOrganizationEdit}
          >
            Abbrechen
          </Button>
          <Button
            className="btn btn-primary btn-rounded mx-1 mt-0 df-submit"
            onClick={saveSelectedOrganization}
          >
            Speichern
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div className="switch-organization">
      {selectedOrganization && (
        <p className="switch-organization__label">
          {selectedOrganization.name}
        </p>
      )}
      <div className="switch-organization__actions">
        <i className="far fa-edit" onClick={setEditable.on}></i>
      </div>
    </div>
  )
}
