import React from 'react'
import { Provider } from 'react-redux'
import store from '../store'
import PhasePopup from './PhasePopup'

const App = (props) => {
  return (
    <Provider store={store}>
      <PhasePopup {...props} />
    </Provider>
  )
}

export default App
