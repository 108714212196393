import * as ibantools from 'ibantools'

function isNumeric(num) {
  // !/^-?\d{1,3}(?:\.?\d{3})*(?:,\d+)?$/.test(data)
  let n = num
  if (num.includes(',')) {
    n = num.replaceAll(',', '.')
  }
  return !isNaN(parseFloat(n)) && isFinite(n)
}

function isDate(data) {
  data = data + ''
  let s = data.split('.').reverse().join('.')
  return !isNaN(new Date(s).getTime())
}

function format(data) {
  return data.replaceAll(' ', '').toUpperCase()
}
const validate =
  (type, { required, validations = [] }) =>
  (data) => {
    if (required && data === '') {
      return true
    }

    if (data && type === 'iban') {
      return !ibantools.isValidIBAN(format(data))
    }

    for (
      let counter = 0, length = validations.length;
      counter < length;
      counter++
    ) {
      const { name, operator } = validations[counter]

      switch (name) {
        case 'MAX':
          let n = data
          if (data.includes(',')) {
            n = data.replaceAll(',', '.')
          }
          if (!isNumeric(data)) {
            return true
          }
          if (parseFloat(data) > parseFloat(operator)) {
            return true
          }
          break
        case 'MIN':
          let m = data
          if (data.includes(',')) {
            m = data.replaceAll(',', '.')
          }
          if (!isNumeric(data)) {
            return true
          }
          if (parseFloat(m) < parseFloat(operator)) {
            return true
          }
          break
        case 'NUMERIC':
          if (!isNumeric(data)) {
            return true
          }
          break
        case 'PATTERN':
          if (!new RegExp(operator).test(data)) {
            return true
          }
          break
        case 'PRESENCE':
          if (Array.isArray(data) && data.length === 0) {
            return true
          }
          if (!data) {
            return true
          }
          break
      }
    }

    if (data && type === 'number') {
      if (typeof data === 'string') {
        return !isNumeric(data)
      } else {
        return typeof data !== 'number'
      }
    }
    if (data && type === 'date') {
      if (typeof data === 'string') {
        return !isDate(data)
      }
    }

    return false
  }

export default validate
