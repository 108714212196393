import React from 'react'
import Enzyme, { shallow } from 'enzyme'
import EnzymeAdapter from '@wojtekmaj/enzyme-adapter-react-17'

import MailerAppContainer from './MailerAppContainer'

Enzyme.configure({ adapter: new EnzymeAdapter() })

const setup = (props = {}) => {
  return shallow(<MailerAppContainer {...props} />)
}

test('renders without error', () => {
  const wrapper = setup()
  const MailerAppContainerComponent = wrapper.find(
    '.mailer-app__draggable-container',
  )
  expect(MailerAppContainerComponent.length).toBe(1)
})

describe('by default mailer popup is shown and minimized mailer not', () => {
  const wrapper = setup()

  test('by default mailer popup is shown', () => {
    const MailerAppContainer = wrapper.find('.mailer-app__container')
    expect(MailerAppContainer.hasClass('d-none')).toBe(false)
  })

  test('by default minimized mailer is not shown', () => {
    const MinimizedMailer = wrapper.find('.mailer-app__minimized')
    expect(MinimizedMailer.hasClass('d-none')).toBe(true)
  })
})

describe('clicking on minimize-button changes state from false to true', () => {
  const wrapper = setup()
  const button = wrapper.find('.mailer-app__minimize-button')
  button.simulate('click')

  test('on click mailer popup is not shown', () => {
    const MailerAppContainer = wrapper.find('.mailer-app__container')
    expect(MailerAppContainer.hasClass('d-none')).toBe(true)
  })

  test('on click minimized mailer is shown', () => {
    const MinimizedMailer = wrapper.find('.mailer-app__minimized')
    expect(MinimizedMailer.hasClass('d-none')).toBe(false)
  })
})

describe('clicking on minimized mailer changes state from true to false', () => {
  const wrapper = setup()
  const button = wrapper.find('.mailer-app__minimize-button')
  button.simulate('click')
  const MinimizedMailerButton = wrapper.find('.mailer-app__minimized')
  MinimizedMailerButton.simulate('click')

  test('on click mailer popup is shown', () => {
    const MailerAppContainer = wrapper.find('.mailer-app__container')
    expect(MailerAppContainer.hasClass('d-none')).toBe(false)
  })

  test('on click minimized mailer is not shown', () => {
    const MinimizedMailer = wrapper.find('.mailer-app__minimized')
    expect(MinimizedMailer.hasClass('d-none')).toBe(true)
  })
})
