/* eslint-disable react/display-name */
import React, { useReducer } from 'react'
import ZiebContainer from './components/ziebContainer/ZiebContainer'
import Context from './context/Context'
import PropTypes from 'prop-types'

import reducer from './reducer'

const { Provider } = Context

const initialState = {
  document: {},
}

export default function Zieb(props) {
  const {
    ziebHost,
    ziebToken,
    agentEmail,
    requestId,
    documentId,
    showPrompt,
    token,
  } = props
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <Provider
      value={{
        state,
        dispatch,
        ziebHost: ziebHost,
        ziebToken: ziebToken,
        agentEmail: agentEmail,
        requestId: requestId,
        documentId: documentId,
        showPrompt: showPrompt,
        token: token,
      }}
    >
      <ZiebContainer />
    </Provider>
  )
}

Zieb.propTypes = {
  ziebHost: PropTypes.string.isRequired,
  ziebToken: PropTypes.string.isRequired,
  agentEmail: PropTypes.string.isRequired,
  requestId: PropTypes.number.isRequired,
  documentId: PropTypes.string.isRequired,
  showPrompt: PropTypes.bool.isRequired,
  token: PropTypes.string,
}
