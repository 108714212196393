const CHANGE_PAGE = 'CHANGE_PAGE'

export function changePage(payload = { pageNumber: 1 }) {
  return { type: CHANGE_PAGE, payload }
}

const initialState = { pageNumber: 0 }
export default function reducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case CHANGE_PAGE:
      return {
        pageNumber: payload,
      }
    default:
      return state
  }
}
