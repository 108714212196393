import React, { useState, useEffect } from 'react'
import { Collapse, Spinner, Placeholder } from 'react-bootstrap'
import client from '../../../../client'
import { sPrintQuery } from './Filter'

const TeamMembers = (props) => {
  const { visible, team, ticketStats, members, searchParams } = props

  const [ticketFutureStats, setTicketFutureStats] = useState(null)
  const [formerAgentsTicketStats, setFormerAgentsTicketStats] = useState(null)

  const loadTicketFutureStats = () => {
    client
      .get(
        `/admin/v2/agent/tickets_stats?${sPrintQuery({
          ...searchParams,
          due_in_future: true,
          group_by_assignee_id: true,
        })}`,
      )
      .then((response) => response.json())
      .then((data) => {
        setTicketFutureStats(data)
      })
  }
  const loadFormerAgentsTicketStats = () => {
    client
      .get(
        `/admin/v2/agent/tickets_stats?${sPrintQuery({
          ...searchParams,
          due_in_future: true,
          assignees: members
            .filter((e) => e.attributes.disabledAt !== null)
            .map((e) => e.attributes.id),
        })}`,
      )
      .then((response) => response.json())
      .then((data) => setFormerAgentsTicketStats(data))
  }

  useEffect(() => {
    if (visible && ticketFutureStats === null) {
      loadTicketFutureStats()
    }
  }, [visible])
  useEffect(loadFormerAgentsTicketStats, [members])

  return (
    <Collapse in={visible}>
      <ul>
        {team.attributes.key === 'settlement_team' && (
          <li className="sidebar__item ps-3">
            <a
              className="sidebar__header"
              href={`/admin/tickets?${sPrintQuery({
                ...searchParams,
                unassigned: true,
              })}`}
            >
              Nicht zugewiesen
              <Badge count={ticketStats ? (ticketStats[''] ?? 0) : null} />
            </a>
          </li>
        )}
        <li className="sidebar__item ps-3">
          <a
            className="sidebar__header"
            href={`/admin/tickets?${sPrintQuery({
              ...searchParams,
              former_team_id: team.id,
            })}`}
          >
            ehemalige Agenten
            <Badge count={formerAgentsTicketStats} info={true} />
          </a>
        </li>
        {members
          ?.filter((member) => {
            return member.attributes.disabledAt === null
          })
          .map((member) => {
            return (
              <li key={member.id} className="sidebar__item ps-3">
                <a
                  className="sidebar__header"
                  href={`/admin/agents/${member.id}/tickets?${sPrintQuery(
                    searchParams,
                  )}`}
                >
                  {member.attributes.name}
                  <Badge
                    count={
                      ticketFutureStats
                        ? ticketFutureStats[member.attributes.id] || 0
                        : null
                    }
                    info={true}
                  />
                  <Badge
                    count={
                      ticketStats
                        ? ticketStats[member.attributes.id] || 0
                        : null
                    }
                  />
                </a>
              </li>
            )
          })}
      </ul>
    </Collapse>
  )
}

const TeamFilter = (props) => {
  const { team, searchParams, agent } = props

  const [toggled, setToggled] = useState(agent.team_id === team.attributes.id)
  const [members, setMembers] = useState([])
  const [ticketStats, setTicketStats] = useState(null)
  const [teamCount, setTeamCount] = useState(null)

  const toggle = (event) => {
    event.preventDefault()
    setToggled(!toggled)
  }
  const loadMembers = () => {
    client
      .get(`/admin/v2/agent/teams/${team.id}`)
      .then((response) => response.json())
      .then((data) => {
        setMembers(data.data.attributes.agents.data)
      })
  }
  const loadTicketStats = () => {
    client
      .get(
        `/admin/v2/agent/tickets_stats?${sPrintQuery({
          ...searchParams,
          due_before_tomorrow: true,
          group_by_assignee_id: true,
        })}`,
      )
      .then((response) => response.json())
      .then((data) => {
        setTicketStats(data)
      })
  }

  useEffect(loadTicketStats, [])
  useEffect(loadMembers, [])
  useEffect(() => {
    if (ticketStats === null || members === null) {
      return
    }
    let count = members.reduce((a, c) => {
      return a + (ticketStats[c.attributes.id] || 0)
    }, 0)
    if (team.attributes.key === 'settlement_team') {
      count += ticketStats[''] || 0
    }
    setTeamCount(count)
  }, [ticketStats, members])

  return (
    <li key={team.id} className="sidebar__group">
      <div className="d-flex">
        <a className="sidebar__header" href="#" onClick={toggle}>
          <i className="fas fa-users me-1 sidebar__header-icon"></i>
          {team.attributes.name}
        </a>
        <a
          href={`/admin/teams/${team.id}/tickets?${sPrintQuery(searchParams)}`}
        >
          <Badge count={teamCount} />
        </a>
      </div>
      {
        <TeamMembers
          team={team}
          visible={toggled}
          searchParams={searchParams}
          members={members}
          ticketStats={ticketStats}
        />
      }
    </li>
  )
}

export const Badge = (props) => {
  const { count, info = false } = props
  let className = 'sidebar__pill badge rounded-pill badge-secondary'
  if (info) {
    className = 'sidebar__pill badge rounded-pill badge-info'
  }
  if (count !== null && count !== undefined) {
    return <span className={className}>{count}</span>
  }
  return (
    <Spinner
      className="sidebar__pill"
      animation="border"
      size="sm"
      variant="dark"
    />
  )
}

const TeamFilters = (props) => {
  const { searchParams, currentAgent } = props

  const [teams, setTeams] = useState(null)

  const loadTeams = () => {
    client
      .get('/admin/v2/agent/teams')
      .then((response) => response.json())
      .then((data) => {
        setTeams(data.data)
      })
  }

  useEffect(loadTeams, [])

  if (teams === null) {
    return (
      <>
        <Placeholder as="li" xs={6} animation="glow" className="sidebar__group">
          <Placeholder className="sidebar__header" />
          <ul>
            {Array.from(Array(8).keys()).map((i) => {
              return (
                <Placeholder
                  key={i}
                  as="li"
                  xs={8}
                  animation="glow"
                  className="ps-3 sidebar__item"
                >
                  <Placeholder xs={12} size="sm" />
                </Placeholder>
              )
            })}
          </ul>
        </Placeholder>
        {Array.from(Array(13).keys()).map((i) => {
          return (
            <Placeholder
              key={i}
              as="li"
              xs={6}
              animation="glow"
              className="sidebar__group"
            >
              <Placeholder className="sidebar__header" />
            </Placeholder>
          )
        })}
      </>
    )
  }
  return (
    <>
      {teams.map((team) => {
        return (
          <TeamFilter
            key={team.id}
            team={team}
            searchParams={searchParams}
            agent={currentAgent}
          ></TeamFilter>
        )
      })}
    </>
  )
}

export default TeamFilters
