import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class TextArea extends Component {
  static displayName = 'TextArea'
  static propTypes = {
    name: PropTypes.string,
    rows: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    className: PropTypes.string,
    disabled: PropTypes.bool,
  }

  handleChange = (event) => {
    const { value } = event.target
    const { name, onChange } = this.props

    onChange && onChange({ name, value })
  }

  render() {
    const { name, value, className, rows, disabled, ...props } = this.props

    return (
      <textarea
        {...props}
        name={name}
        value={value}
        onChange={this.handleChange}
        className={className}
        rows={rows}
        disabled={disabled}
      />
    )
  }
}
