import React, { createContext } from 'react'
import PropTypes from 'prop-types'
import Subtopic from './Subtopic'

const TopicContext = createContext()

const Topic = ({ partialTopicId, name, subtopics }) => {
  return (
    <TopicContext.Provider
      value={{ partialTopicUid: partialTopicId, partialName: name }}
    >
      <div className="card">
        <div
          className="card-header"
          id={'subtopics-topic-heading-' + partialTopicId}
        >
          <h5 className="mb-0">
            <button
              className="btn btn-link link-secondary link-offset-2 link-underline-opacity-0 link-underline-opacity-75-hover"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={'#subtopics-topic-' + partialTopicId}
              aria-expanded="true"
              aria-controls={'#subtopics-topic-' + partialTopicId}
            >
              {name}
            </button>
          </h5>
        </div>
        <div
          id={'subtopics-topic-' + partialTopicId}
          className="collapse"
          aria-labelledby={'subtopics-topic-heading-' + partialTopicId}
          data-parent="#emailPartialsaSelect"
        >
          <div className="card-body">
            {subtopics.map((subtopic) => (
              <Subtopic key={subtopic.partialId} {...subtopic} />
            ))}
          </div>
        </div>
      </div>
    </TopicContext.Provider>
  )
}

Topic.propTypes = {
  partialTopicId: PropTypes.string,
  name: PropTypes.string,
  subtopics: PropTypes.array,
}

export default Topic
export { TopicContext }
