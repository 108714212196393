import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Badge, Button, Modal, Spinner } from 'react-bootstrap'
import AlertBanner from '../admin/engine-ui/AlertBanner'

const DocumentUploadModal = ({
  authenticity_token,
  hiddenFields,
  buttonText,
  uploadUrl,
  acceptedFileExtensions,
  fieldName,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isDraggingOver, setIsDraggingOver] = useState(false)

  const [isUploadingMap, setIsUploadingMap] = useState(null)

  const [filesToUpload, setFilesToUpload] = useState([])
  const [error, setError] = useState('')

  const fileInputRef = useRef(null)

  const isAnyFileUploading = () => {
    return (
      isUploadingMap &&
      Object.keys(isUploadingMap).some((key) => isUploadingMap[key].loading)
    )
  }
  const isAnyFileUploaded = () => {
    return (
      isUploadingMap &&
      Object.keys(isUploadingMap).some(
        (key) =>
          isUploadingMap[key].loading === false &&
          isUploadingMap[key].error !== true,
      )
    )
  }

  useEffect(() => {
    setIsUploadingMap(
      filesToUpload.reduce((a, c) => {
        a[c.name] = {}
        return a
      }, {}),
    )
  }, [filesToUpload])

  const handleFilesAdded = (files) => {
    const filesToAdd = []
    const blockedFiles = []
    files.forEach((file) => {
      const extension = file.name.split('.').reverse()[0]
      if (acceptedFileExtensions.indexOf(extension) != -1) {
        filesToAdd.push(file)
      } else {
        blockedFiles.push(file)
      }
    })
    setFilesToUpload(filesToAdd)
    if (blockedFiles.length) {
      setError(
        `Unterstützte Dateitypen: ${acceptedFileExtensions.replaceAll(
          ',',
          ', ',
        )}`,
      )
    } else {
      setError('')
    }
  }

  const handleFileAdd = (event) => {
    handleFilesAdded([...event.target.files])
  }

  const handleDrop = (event) => {
    event.preventDefault()
    setIsDraggingOver(false)
    handleFilesAdded([...event.dataTransfer.files])
  }

  const handleDragOver = (event) => {
    event.preventDefault()
    setIsDraggingOver(true)
  }

  const handleDragLeave = () => {
    setIsDraggingOver(false)
  }

  const handleClose = () => {
    setFilesToUpload([])
    setError('')
    setIsModalOpen(false)
    if (isAnyFileUploaded()) {
      window.location.reload()
    }
  }
  const handleShow = () => setIsModalOpen(true)

  const onSubmit = () => {
    filesToUpload.map(async (file) => {
      setIsUploadingMap((prev) => ({
        ...prev,
        [file.name]: { loading: true },
      }))
      const formData = new FormData()
      formData.append('utf8', '✓')
      Object.keys(hiddenFields).forEach((key) => {
        formData.append(key, hiddenFields[key])
      })
      formData.append('authenticity_token', authenticity_token)
      formData.append(fieldName, file)
      let response = await fetch(uploadUrl, {
        method: 'POST',
        body: formData,
        headers: {
          Accept: 'application/json',
        },
      })

      if (response.ok) {
        await response.json()

        setIsUploadingMap((prev) => {
          const tmp = { ...prev }
          tmp[file.name].loading = true
          return tmp
        })
      } else {
        setIsUploadingMap((prev) => {
          const tmp = { ...prev }
          tmp[file.name].loading = false
          tmp[file.name].error = true
          return tmp
        })
      }
    })
  }
  function uploadStatus(status) {
    if (status === undefined || status === null) {
      return <></>
    }
    if (status.error === true) {
      return (
        <Badge bg="danger" title={status.message}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-x-octagon"
            viewBox="0 0 16 16"
          >
            <path d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353L4.54.146zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1z" />
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
          </svg>
        </Badge>
      )
    }
    if (status.loading === undefined || status.loading === null) {
      return <></>
    }
    if (status.loading) {
      return (
        <Badge bg="secondary" title={status.message}>
          <Spinner as="span" role="status" size="sm" aria-hidden="true" />
        </Badge>
      )
    }
    if (!status.loading) {
      return (
        <Badge bg="success" title={status.message}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-check-square"
            viewBox="0 0 16 16"
          >
            <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
            <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z" />
          </svg>
        </Badge>
      )
    }
  }

  return (
    <>
      <button
        className="btn btn-outline-primary btn-rounded"
        onClick={handleShow}
      >
        {buttonText}
      </button>

      <Modal show={isModalOpen} onHide={handleClose} id="document-upload-modal">
        {/* <Modal.Header closeButton>Document Upload</Modal.Header> */}
        <Modal.Body
          onDrop={handleDrop}
          onClick={() => {
            if (filesToUpload.length == 0) fileInputRef.current.click()
          }}
          className={''}
          style={{ border: isDraggingOver ? '2px dashed grey' : '' }}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
        >
          {error.length > 1 && <AlertBanner message={error} />}
          <div className="pt-5 pb-3">
            <input
              ref={fileInputRef}
              name="bea-file-upload"
              id="bea-file-upload"
              type="file"
              multiple
              onChange={handleFileAdd}
              accept={acceptedFileExtensions}
              style={{ display: 'none' }}
            />

            {filesToUpload.length > 0 ? (
              <div
                style={{
                  textAlign: 'center',
                }}
              >
                <b>Bereit zum Hochladen </b>
                <i
                  className={'fas fa-undo'}
                  onClick={() => {
                    setFilesToUpload([])
                  }}
                ></i>
                <br />
                {filesToUpload.map((file) => {
                  return (
                    <div
                      key={file.name}
                      className="my-1"
                      id={`bea-${file.name.replace(/ /g, '')}`}
                    >
                      {file.name} {uploadStatus(isUploadingMap[file.name])}{' '}
                    </div>
                  )
                })}
              </div>
            ) : (
              <div
                style={{
                  textAlign: 'center',
                }}
              >
                <div>
                  <i className={'fas fa-cloud-upload-alt'}></i>
                </div>
                <span className={''}>
                  Zum Hochladen <br /> Drag & Drop / Klicken
                </span>
                <br />
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="btn-rounded"
            onClick={handleClose}
          >
            Schliessen
          </Button>
          <Button
            variant="primary"
            className="btn-rounded"
            disabled={
              filesToUpload.length == 0 ||
              isAnyFileUploading() ||
              isAnyFileUploaded()
            }
            onClick={onSubmit}
          >
            Hochladen
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

DocumentUploadModal.propTypes = {
  hiddenFields: PropTypes.object,
  authenticity_token: PropTypes.string,
  buttonText: PropTypes.string,
  uploadUrl: PropTypes.string,
  acceptedFileExtensions: PropTypes.string,
  fieldName: PropTypes.string,
}

DocumentUploadModal.defaultProps = {
  handleFormChange: () => {},
}

export default DocumentUploadModal
