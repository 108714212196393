import { getRequestQuery, getFilterHref, getTicketsTotals } from '../helpers'

describe('getRequestQuery', () => {
  it('returns the right queries', () => {
    const params = {
      filter: 'due_today',
      category: 'alle-ausser-mv',
      deadline_type: 'internal',
    }

    const params2 = {
      filter: 'due_tomorrow',
      category: 'check_internal_document',
      deadline_type: 'external',
    }

    expect(getRequestQuery(params)).toBe(
      'filter=due_today&category=alle-ausser-mv&deadline_type=internal',
    )
    expect(getRequestQuery(params2)).toBe(
      'filter=due_tomorrow&category=check_internal_document&deadline_type=external',
    )
  })
})

describe('getFilterHref', () => {
  it('returns the right href', () => {
    const agent_id = 123
    const filters = {
      filter: 'due_today',
      category: 'alle-ausser-mv',
      deadline_type: 'internal',
    }

    expect(getFilterHref({ agent_id, filters })).toBe(
      '/admin/agents/123/tickets?filter=due_today&category=alle-ausser-mv&deadline_type=internal',
    )
  })
})

describe('getTicketsTotals', () => {
  it('returns the right amounts', () => {
    const agentsTicketsAmounts = [
      {
        agent_id: 82,
        agent_name: 'Agent 01',
        due_today_amount: 3,
        due_tomorrow_amount: 1,
        extern_till_current_week_amount: 2,
        extern_till_today_amount: 4,
        extern_till_tomorrow_amount: 5,
        extern_till_next_week_amount: 3,
        extern_till_next_3_week_amount: 3,
        extern_till_next_4_week_amount: 4,
        intern_till_current_week_amount: 5,
        intern_till_next_week_amount: 6,
        intern_till_today_amount: 8,
        intern_till_tomorrow_amount: 7,
        intern_till_next_3_week_amount: 3,
        intern_till_next_4_week_amount: 4,
      },
      {
        agent_id: 90,
        agent_name: 'Agent 02',
        due_today_amount: 10,
        due_tomorrow_amount: 0,
        extern_till_current_week_amount: 4,
        extern_till_next_week_amount: 6,
        extern_till_today_amount: 3,
        extern_till_tomorrow_amount: 8,
        extern_till_next_3_week_amount: 3,
        extern_till_next_4_week_amount: 4,
        intern_till_current_week_amount: 5,
        intern_till_next_week_amount: 12,
        intern_till_today_amount: 8,
        intern_till_tomorrow_amount: 0,
        intern_till_next_3_week_amount: 3,
        intern_till_next_4_week_amount: 4,
      },
      {
        agent_id: 95,
        agent_name: 'Agent 03',
        due_today_amount: 5,
        due_tomorrow_amount: 1,
        extern_till_current_week_amount: 6,
        extern_till_next_week_amount: 8,
        extern_till_today_amount: 9,
        extern_till_tomorrow_amount: 10,
        extern_till_next_3_week_amount: 3,
        extern_till_next_4_week_amount: 4,
        intern_till_current_week_amount: 1,
        intern_till_next_week_amount: 4,
        intern_till_today_amount: 5,
        intern_till_tomorrow_amount: 5,
        intern_till_next_3_week_amount: 3,
        intern_till_next_4_week_amount: 4,
      },
    ]

    expect(getTicketsTotals(agentsTicketsAmounts)).toEqual({
      dueTodayAmount: 18,
      dueTomorrowAmount: 2,
      externTillTodayAmount: 16,
      externTillTomorrowAmount: 23,
      externTillCurrentWeekAmount: 12,
      externTillNextWeekAmount: 17,
      externTillNext3WeekAmount: 9,
      externTillNext4WeekAmount: 12,
      internTillTodayAmount: 21,
      internTillTomorrowAmount: 12,
      internTillCurrentWeekAmount: 11,
      internTillNextWeekAmount: 22,
      internTillNext3WeekAmount: 9,
      internTillNext4WeekAmount: 12,
    })
  })

  it('returns zeros if no data passed', () => {
    const agentsTicketsAmounts = []
    expect(getTicketsTotals(agentsTicketsAmounts)).toEqual({
      dueTodayAmount: 0,
      dueTomorrowAmount: 0,
      externTillTodayAmount: 0,
      externTillTomorrowAmount: 0,
      externTillCurrentWeekAmount: 0,
      externTillNextWeekAmount: 0,
      externTillNext3WeekAmount: 0,
      externTillNext4WeekAmount: 0,
      internTillTodayAmount: 0,
      internTillTomorrowAmount: 0,
      internTillCurrentWeekAmount: 0,
      internTillNextWeekAmount: 0,
      internTillNext3WeekAmount: 0,
      internTillNext4WeekAmount: 0,
    })
  })
})
