import { useRef, useReducer, useLayoutEffect } from 'react'

function useIsMounted() {
  const mounted = useRef(false)
  useLayoutEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])
  return mounted
}

function useCallbackStatus() {
  const isMounted = useIsMounted()
  const [{ status, error }, setState] = useReducer(
    (state, action) => ({ ...state, ...action }),
    { status: 'rest', error: null },
  )

  const safeSetState = (...args) =>
    isMounted.current ? setState(...args) : null

  const isPending = status === 'pending'
  const isRejected = status === 'rejected'

  function run(promise) {
    if (!promise || !promise.then) {
      throw new Error(
        'The argument passed to useCallbackStatus().run must be a promise',
      )
    }
    safeSetState({ status: 'pending', error: null })
    return promise
      .then((response) => {
        return response.json()
      })
      .then((result) => {
        safeSetState({ status: 'rest' })
        return { status: 'rest', result }
      })
      .catch((error) => {
        safeSetState({ status: 'rejected', error })
        return { status: 'rejected', error }
      })
  }

  return {
    run,
    error,
    status,
    isPending,
    isRejected,
  }
}

export default useCallbackStatus
