import React, { useState, useEffect, useRef } from 'react'
import TextInput from '../engine-ui/TextInput'
import Checkbox from '../engine-ui/Checkbox'
import classNames from 'classnames'

const MAXIMUM_FILE_SIZE_SUM = Number.POSITIVE_INFINITY
const MAXIMUM_FILE_SIZE = 140_000_000

const BeaAttachments = (props) => {
  const {
    files,
    requestId,
    setBeaMessage,
    beaMessage,
    setDisableSubmitBtn,
    label,
    setSubmitMessage,
  } = props
  const [selectedAttachments, setSelectedAttachments] = useState(
    beaMessage.documents?.map((e) => e.id) || [],
  )
  const [mainDoc, setMainDoc] = useState(
    beaMessage.documents?.find((e) => e.main_document)?.id,
  )
  const [signature, setSignature] = useState(
    beaMessage.documents?.find((e) => e.signature_document)?.id,
  )
  const [order, setOrder] = useState(
    beaMessage.documents?.reduce((order, document) => {
      order[document.id] = document.order
      return order
    }, {}) || {},
  )
  const [accFileSize, setAccFileSize] = useState([])
  const [fileSizeWarning, setFileSizeWarning] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [searchAttachments, setSearchAttachments] = useState([])
  const documents = useRef(beaMessage.documents || [])

  const getAttIndex = (name) => {
    if (documents.current) {
      return documents.current.findIndex((i) => i.id === name)
    }
  }

  const handleAttChange = (event) => {
    const { value, name } = event

    if (value) {
      setSelectedAttachments((prev) => [...prev, name])
      documents.current.push({
        id: name,
        main_document: false,
        signature_document: false,
        order: null,
      })
    } else {
      setSelectedAttachments((prev) => prev.filter((el) => el !== name))
      if (mainDoc === name) {
        setMainDoc(undefined)
      }
      if (signature === name) {
        setSignature(undefined)
      }
      setOrder((prev) => {
        const newOrder = { ...prev }
        delete newOrder[name]
        return newOrder
      })

      const index = getAttIndex(name)
      if (index > -1) {
        documents.current.splice(index, 1)
      }
    }
    handleDocumentChange()
  }

  const handleMainChange = (event) => {
    const { name } = event
    if (!selectedAttachments.includes(name)) {
      handleAttChange(event)
    }
    let attIndex = getAttIndex(name)
    if (attIndex < 0) {
      attIndex =
        documents.current.push({
          id: name,
          main_document: false,
          signature_document: false,
          order: null,
        }) - 1
    }
    const currentDocument = documents.current[attIndex]
    currentDocument.order = Number()

    if (name === mainDoc) {
      setMainDoc(undefined)
      currentDocument.main_document = false
    } else {
      setMainDoc(name)
      setOrder((prev) => ({ ...prev, [name]: '0' }))
      if (name === signature) {
        setSignature(undefined)
      }
      currentDocument.signature_document = false
      documents.current.forEach((document) => {
        document.main_document = false
      })
      currentDocument.main_document = true
    }
    handleDocumentChange()
  }

  const handleSignatureChange = (event) => {
    const { name } = event
    if (!selectedAttachments.includes(name)) {
      handleAttChange(event)
    }
    let attIndex = getAttIndex(name)
    if (attIndex < 0) {
      attIndex =
        documents.current.push({
          id: name,
          main_document: false,
          signature_document: false,
          order: null,
        }) - 1
    }
    const currentDocument = documents.current[attIndex]
    currentDocument.order = Number()

    if (name === signature) {
      setSignature(undefined)
      currentDocument.signature_document = false
    } else {
      setSignature(name)
      setOrder((prev) => ({ ...prev, [name]: '0' }))
      if (name === mainDoc) {
        setMainDoc(undefined)
      }
      currentDocument.main_document = false
      documents.current.forEach((document) => {
        document.signature_document = false
      })
      currentDocument.signature_document = true
    }
    handleDocumentChange()
  }

  const handleOrderChange = (event) => {
    const { value, name } = event
    const index = getAttIndex(Number(name))
    const numValue = Number(value)
    if (!isNaN(numValue) && index > -1) {
      setOrder((prev) => ({ ...prev, [name]: numValue }))
      documents.current[index].order = numValue
      handleDocumentChange()
    }
  }

  const handleSearchChange = (event) => {
    const { value } = event
    setSearchAttachments(
      files.filter((item) =>
        item.filename.toLowerCase().includes(value.toLowerCase()),
      ),
    )
    setSearchValue(value)
  }

  const calcFileSize = (size) => {
    const units = ['bytes', 'KB', 'MB', 'GB']
    let entity = 0
    let value = parseInt(size, 10) || 0

    while (value >= 1024 && ++entity) {
      value = value / 1024
    }

    return value.toFixed(value < 10 && entity > 0 ? 1 : 0) + units[entity]
  }

  useEffect(() => {
    const sum = selectedAttachments
      .map((id) => files.find((file) => file.id === id))
      .reduce((acc, val) => acc + val.size, 0)
    const result = calcFileSize(sum)
    setAccFileSize(result)
    setFileSizeWarning(sum > MAXIMUM_FILE_SIZE_SUM ? 'danger' : '')
  }, [selectedAttachments, files])

  const handleDocumentChange = () => {
    setBeaMessage((prev) => {
      return {
        ...prev,
        documents: [...documents.current],
      }
    })
  }
  const documentsContainWhitespace = (documents) => {
    return !documents.every((document) => {
      return (
        /\s/.test(files.find((file) => file.id === document.id).filename) ===
        false
      )
    })
  }
  const documentsContainIllegalCharacters = (documents) => {
    return !documents.every((document) => {
      return /^[\x20-\x7FöäüÖÄÜß]*$/.test(
        files.find((file) => file.id === document.id).filename,
      )
    })
  }

  useEffect(() => {
    const allOrders = documents.current
      .filter(
        (attachment) =>
          !attachment.signature_document && !attachment.main_document,
      )
      .map((attachment) => attachment.order)
    const needOrderAndLabel =
      documents.current.filter(
        (attachment) =>
          !attachment.signature_document && !attachment.main_document,
      ).length > 0
    const needMainDoc = signature
    const isAttachmentsCorrect =
      !fileSizeWarning &&
      (!needMainDoc || mainDoc) &&
      documents.current.length > 0 &&
      !documentsContainWhitespace(documents.current) &&
      !documentsContainIllegalCharacters(documents.current)

    const ordersAreCorrect =
      new Set(allOrders.filter((i) => i)).size === allOrders.length
    const isOrderAndLabelCorrect =
      !needOrderAndLabel || (ordersAreCorrect && label)
    const hasCourtOrLawyerId =
      beaMessage.external_lawyer_safe_id || beaMessage.court_safe_id
    if (isOrderAndLabelCorrect && isAttachmentsCorrect && hasCourtOrLawyerId) {
      setSubmitMessage({})
      setDisableSubmitBtn(false)
    } else {
      const errorMessages = []
      if (!isOrderAndLabelCorrect) {
        if (!ordersAreCorrect) {
          errorMessages.push('Reihenfolge ist nicht korrekt')
        }
        if (!label) {
          errorMessages.push('Es wird ein Label benötigt')
        }
      }
      if (!isAttachmentsCorrect) {
        if (fileSizeWarning) {
          errorMessages.push(
            `The Summer der Dateigrößen darf nicht ${calcFileSize(
              MAXIMUM_FILE_SIZE_SUM,
            )} überschreiten`,
          )
        }
        if (needMainDoc && !mainDoc) {
          errorMessages.push('Genau ein Hauptdokument erwartet')
        }
        if (!documents.current.length) {
          errorMessages.push('Mindestens ein Dokument erwartet')
        }
        if (documentsContainWhitespace(documents.current)) {
          errorMessages.push(
            'Dokumente mit Leerzeichen im Namen sind nicht erlaubt',
          )
        }
        if (documentsContainIllegalCharacters(documents.current)) {
          errorMessages.push(
            'Dokumentenamen dürfen nur ascii oder öäüß Zeichen enthalten',
          )
        }
      }
      if (!hasCourtOrLawyerId) {
        errorMessages.push('Ein Gericht oder Anwalt/Anwältin wird erwartet')
      }
      setSubmitMessage({
        text: errorMessages.join(', '),
        status: 'failed',
      })
      setDisableSubmitBtn(true)
    }
  }, [
    order,
    fileSizeWarning,
    selectedAttachments,
    beaMessage.external_lawyer_safe_id,
    beaMessage.court_safe_id,
    signature,
    mainDoc,
    label,
  ])

  const renderAttachments = ((searchValue && searchAttachments) || files).map(
    (item, i) => {
      const isSelected = selectedAttachments.includes(item.id)
      const canSelected = item.size < MAXIMUM_FILE_SIZE
      return (
        <tr
          className="d-flex align-items-center bea-ui__attachment__item"
          key={i}
        >
          <td width="5%">
            <Checkbox
              name={item.id}
              value={isSelected}
              onChange={handleAttChange}
              className="form-check-input"
              disabled={!canSelected}
            />
          </td>
          <td width="55%">
            <label htmlFor={item.id} className="mb-0">
              <span className="me-1">{item.filename}</span>
              {item.type && <span className="me-1">{`(${item.type})`}</span>}
              <span>{calcFileSize(item.size)}</span>
            </label>
          </td>
          {!canSelected ? (
            <td width="50%">
              <p>
                <i className="fas fa-exclamation-triangle me-1"></i>
                Der Anhang darf nicht größer als{' '}
                {calcFileSize(MAXIMUM_FILE_SIZE)} sein!
              </p>
            </td>
          ) : (
            <>
              <td width="5%">
                <span>
                  <a
                    href={`/admin/requests/${requestId}/documents/${item.id}?inline=true`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fas fa-external-link-alt"></i>
                  </a>
                </span>
              </td>
              <td width="10%">
                {canSelected && (
                  <TextInput
                    name={item.id.toString()}
                    className="form-control form-control-sm"
                    value={(order[item.id] || '').toString()}
                    onChange={handleOrderChange}
                    placeholder="Order"
                    disabled={
                      !isSelected ||
                      item.id === mainDoc ||
                      item.id === signature
                    }
                  />
                )}
              </td>
              <td width="15%">
                <div className="bea-ui__attachment__main ms-1">
                  <label
                    htmlFor={item.id}
                    className={classNames('bea-ui__attachment__main-label', {
                      'bea-ui__attachment__main-label-active':
                        item.id === mainDoc,
                    })}
                  >
                    <Checkbox
                      name={item.id}
                      value={item.id === mainDoc}
                      onChange={handleMainChange}
                      className="bea-ui__attachment__main-checkbox"
                    />
                    <span>Main Doc</span>
                  </label>
                </div>
              </td>
              <td width="10%">
                <div className="bea-ui__attachment__main">
                  <label
                    htmlFor={item.id}
                    className={classNames('bea-ui__attachment__main-label', {
                      'bea-ui__attachment__main-label-active':
                        item.id === signature,
                    })}
                  >
                    <Checkbox
                      name={item.id}
                      value={item.id === signature}
                      onChange={handleSignatureChange}
                      className="bea-ui__attachment__main-checkbox"
                    />
                    <span>Signatur</span>
                  </label>
                </div>
              </td>
            </>
          )}
        </tr>
      )
    },
  )

  return (
    <div className="d-flex flex-column my-3 bea-ui__attachment">
      <div className="d-flex justify-content-between">
        <p>{`Anhänge (${selectedAttachments.length} ausgewählt):`}</p>
        {accFileSize !== '0bytes' && (
          <div
            className={classNames('bea-ui__attachment-filesize', {
              'text-danger': fileSizeWarning,
            })}
          >
            <p>Größe Anhang: {accFileSize}</p>
            {fileSizeWarning && (
              <p>
                <i className="fas fa-exclamation-triangle me-1"></i>
                Ausgewählte Anhänge sind zu groß!
              </p>
            )}
          </div>
        )}
      </div>
      <TextInput
        className="form-control"
        value={searchValue}
        onChange={handleSearchChange}
        placeholder="Anhänge suchen..."
      />
      <div className="bea-ui__attachment-container">
        <table className="table table-striped">
          <tbody>{renderAttachments}</tbody>
        </table>
      </div>
    </div>
  )
}

export default BeaAttachments
