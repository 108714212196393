import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'

import client from '../../../../client'
import copyToClipboard from '../../../../utils/copyToClipboard'
import { TopicContext } from './Topic'
import { MailerAppContainerContext } from '../MailerApp/MailerAppContainer'

const linkCSS = css(`
  padding-left: 8px;
`)

const textareaCSS = css(`
  width: 92%;
  font-size: 0.7rem;
  border-color: #2c3035;
  margin-bottom: 1rem,
`)

export default function Subtopic({ partialId, text, name }) {
  const { partialName } = useContext(TopicContext)
  const { requestId } = useContext(MailerAppContainerContext)

  const handleCopyToClipboardClick = (event) => {
    event.preventDefault()

    copyToClipboard(text)
    trackCopiedPartial()
  }

  const trackCopiedPartial = () => {
    const TRACK_COPIED_PARTIAL_PATH = `/admin/requests/${requestId}/track_copied_partials`

    const payload = {
      email_partial_topic_uid: partialId,
      email_partial_topic_name: name,
      partial_name: partialName,
    }

    client.post(TRACK_COPIED_PARTIAL_PATH, payload).catch(console.error)
  }

  return (
    <ul
      className="list-group"
      key={partialId}
      id={`subtopic-data-container-header${partialId}`}
    >
      <div>
        <a
          className="collapsed"
          data-bs-toggle="collapse"
          href={`#subtopic-data-container${partialId}`}
          aria-expanded="false"
          aria-controls={`subtopic-data-container${partialId}`}
        >
          {name}
        </a>
        <a
          href=""
          css={linkCSS}
          onClick={handleCopyToClipboardClick}
          data-testid="copy-link"
        >
          <i
            className="far fa-clipboard mt-clip-icon"
            data-bs-toggle="tooltip"
            title="Text kopieren"
          />
        </a>
      </div>
      <div
        id={`subtopic-data-container${partialId}`}
        className="panel-collapse collapse"
        role="tabpanel"
        aria-labelledby={`subtopic-data-container-header${partialId}`}
      >
        <div className="d-flex">
          <textarea
            id={`subtopic-data${partialId}`}
            className="form-control not-draggable"
            css={textareaCSS}
            cols="30"
            rows="5"
            readOnly
            value={text}
          />
        </div>
      </div>
    </ul>
  )
}

Subtopic.propTypes = {
  partialId: PropTypes.string,
  name: PropTypes.string,
  text: PropTypes.string,
}
