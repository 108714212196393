import React, { createContext } from 'react'
import PropTypes from 'prop-types'
import MailerForm from './MailerForm'
import EmailPartialsSelect from '../EmailPartialsSelect/EmailPartialsSelect'

const MailerAppContext = createContext()

export default class MailerApp extends React.Component {
  constructor(props) {
    super(props)
    this.savedDraft = this.props.handleGetSavedDrafts()[props.requestId]
    this.state = {
      request: null,
      sender: this.savedDraft?.sender ?? '',
      receiver: this.savedDraft?.reciever ?? [props.receiver],
      replyTo: this.savedDraft?.replyTo ?? null,
      responseType: '',
      forward: null,
    }
  }

  componentDidMount() {
    this.loadRequest()
  }

  loadRequest() {
    var url = `/admin/requests/${this.props.requestId}/mailer_app.json`
    url = url + `?receiver=${this.props.receiver}`
    if (this.props.response_of)
      url = url + `&response_of=${this.props.response_of}`
    if (this.props.reply_to) url = url + `&reply_to=${this.props.reply_to}`
    if (this.props.forward) url = url + `&forward=${this.props.forward}`
    if (this.props.r_typ) url = url + `&r_typ=${this.props.r_typ}`
    fetch(url)
      .then((response) => response.json())
      .then((myResponse) => {
        this.setState({
          request: myResponse.request,
          receiver: this.savedDraft?.reciever ?? [myResponse.receiver],
          sender: this.savedDraft?.sender ?? myResponse.sender,
          replyTo: this.savedDraft?.replyTo ?? myResponse.reply_to,
          responseType: myResponse.r_typ,
          forward: myResponse.forward,
        })
      })
  }

  updateFilters = (filter, value) => {
    this.setState({ [filter]: value })
  }

  replyToProps = () => {
    if (this.state.replyTo != null)
      return {
        email: this.state.replyTo,
        responseType: this.state.responseType,
      }
  }

  subject = () => {
    if (this.state.replyTo) return `Aw: ${this.state.request.original_subject}`
    if (this.state.forward) return `Fwd: ${this.state.request.original_subject}`
    return this.state.request.receiver_subject_map[this.state.receiver]
  }

  render() {
    const { request } = this.state

    return (
      <MailerAppContext.Provider value={{ request }}>
        <div className="MailerApp">
          <div className="write-new">
            {request && (
              <MailerForm
                {...request}
                sender={this.state.sender}
                receiver={this.state.receiver}
                id={this.props.requestId}
                updateFilters={this.updateFilters}
                replyToProps={this.replyToProps()}
                subject={this.subject()}
                getSavedDrafts={this.props.handleGetSavedDrafts}
                setSavedDrafts={this.props.handleSetSavedDrafts}
                onMailSent={this.props.onMailSent}
              />
            )}
          </div>
          <div className="choose-partial">
            <EmailPartialsSelect
              sender={this.state.sender}
              receiver={this.state.receiver}
              url={`/admin/requests/${this.props.requestId}/email_partials`}
            />
          </div>
        </div>
      </MailerAppContext.Provider>
    )
  }
}

MailerApp.propTypes = {
  requestId: PropTypes.number,
  receiver: PropTypes.string,
  response_of: PropTypes.string,
  reply_to: PropTypes.string,
  forward: PropTypes.string,
}

export { MailerAppContext }
