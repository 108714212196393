import React from 'react'
import PropTypes from 'prop-types'
import { findIndex, clone, without } from 'lodash'

import activityLog from '../../../utils/activity_log'
import DocumentItem from './document_item'
import DocumentUploader from './document_uploader'

export default class DocumentList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      documents: [],
    }
  }

  getChildContext() {
    return { translations: this.props.translations }
  }

  componentDidMount() {
    activityLog
      .fetchDocuments(this.props.request, this.props.token)
      .then((json) => {
        this.setState({ documents: json.documents })
      })
  }

  replaceDocument = (documents, file, overrideParams) => {
    const nextDocuments = clone(documents)
    const index = findIndex(documents, { id: file.uid })

    nextDocuments.splice(index, 1, {
      id: overrideParams.id || file.uid,
      percent: overrideParams.percent,
      completed: overrideParams.completed,
      filename: file.name,
      url: overrideParams.url,
      user_deletable: overrideParams.user_deletable,
    })

    return nextDocuments
  }

  handleDelete = (doc) => {
    return () => {
      activityLog
        .delete(this.props.request, doc.id, this.props.token)
        .then(() => {
          const index = findIndex(this.state.documents, { id: doc.id })
          this.setState({
            documents: without(
              this.state.documents,
              this.state.documents[index],
            ),
          })
        })
    }
  }

  handleUploadStart = (file) => {
    if (file.size >= 100000000) {
      alert(`Die Datei ${file.name} ist zu groß (max 100mb).`)
      return
    }

    this.setState((prevState) => {
      const nextDocuments = prevState.documents.concat([
        {
          id: file.uid,
          percent: 0,
          completed: false,
          filename: file.name,
        },
      ])

      return { documents: nextDocuments }
    })
  }

  handleUploadProgress = (percent, file) => {
    this.setState((prevState) => {
      const nextDocuments = this.replaceDocument(prevState.documents, file, {
        percent: percent,
        completed: false,
      })

      return { documents: nextDocuments }
    })
  }

  handleUploadError = (event) => {
    throw event
  }

  handleUploadFinish = (signedUrl, file) => {
    this.setState((prevState) => {
      const nextDocuments = this.replaceDocument(prevState.documents, file, {
        percent: 100,
        completed: false,
      })

      return { documents: nextDocuments }
    })

    const objectKey = signedUrl.split('?')[0].replace('https:', '')
    const activityLogProps = {
      file: file,
      token: this.props.request,
      documentType: this.props.document_type,
      objectKey: objectKey,
      auth_token: this.props.token,
    }

    activityLog
      .create(activityLogProps)
      .then((response) => this.handleCreateFinished(file, response))
  }

  handleCreateFinished = (file, response) => {
    this.setState((prevState) => {
      const nextDocuments = this.replaceDocument(prevState.documents, file, {
        percent: 100,
        completed: true,
        url: response.activity_log.url,
        user_deletable: response.activity_log.user_deletable,
        id: response.activity_log.id,
      })

      return { documents: nextDocuments }
    })
  }

  submitDisabled = () => {
    return !this.props.optional && this.state.documents.length <= 0
  }

  render() {
    return (
      <div className="row">
        <div className="col-12">
          <div className="document-uploader">
            <DocumentUploader
              request={this.props.request}
              document_type={this.props.document_type}
              signing_path={this.props.signing_path}
              handleUploadStart={this.handleUploadStart}
              handleUploadProgress={this.handleUploadProgress}
              handleUploadError={this.handleUploadError}
              handleUploadFinish={this.handleUploadFinish}
            />
            <div>
              <div className="document-list">
                {this.state.documents.length > 0 &&
                  this.props.translations.listing_title}
                <div>
                  {this.state.documents.map((doc) => {
                    return (
                      <DocumentItem
                        key={doc.id}
                        doc={doc}
                        handleDelete={this.handleDelete(doc)}
                      />
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12" style={{ paddingTop: '2rem' }}>
          <a
            className={`btn btn-default btn-success ${
              this.submitDisabled() ? 'disabled' : ''
            }`}
            disabled={this.submitDisabled()}
            href={!this.submitDisabled() ? this.props.nextLink : ''}
          >
            Weiter
          </a>
        </div>
      </div>
    )
  }
}

DocumentList.propTypes = {
  request: PropTypes.string.isRequired,
  document_type: PropTypes.string.isRequired,
  signing_path: PropTypes.string.isRequired,
  nextLink: PropTypes.string.isRequired,
  optional: PropTypes.bool,
  translations: PropTypes.object,
  token: PropTypes.string,
}

DocumentList.childContextTypes = {
  translations: PropTypes.object,
}
