import React, { useState, useEffect, useContext, useRef } from 'react'
import { Container } from './styles/documentViewer'

import Tabs, { Tab, TabList, TabPanel } from '../../../Tabs'
import PDFViewer from './PDFViewer'
import SearchablePDF from './SearchablePDF'
import OCRViewer from './OCRViewer'

import Context from '../../context/Context'
import useDocument from '../../hooks/useDocument'
import useCallbackStatus from '../../hooks/useCallbackStatus'

import client from '../../../../../../client'

export default function DocumentViewer() {
  const [searchablePdfUrl, setSearchablePdfUrl] = useState('')
  const [OCRText, setOCRText] = useState('')
  const { document } = useDocument()
  const { ziebHost, ziebToken, documentId } = useContext(Context)
  const { run: runFetchData } = useCallbackStatus()
  const timeoutSearchablePdfId = useRef(null)
  const timeoutOCRId = useRef(null)

  useEffect(() => {
    if (document.initialized) {
      if (document.data.extension === 'pdf') getSearchPdfUrl()
      getOCRText()
    }
  }, [])

  const getSearchPdfUrl = () => {
    const headers = { token: ziebToken }
    const url = `${ziebHost}/api/documents/${documentId}/searchable_pdf`

    runFetchData(client.get(url, null, headers)).then(({ result, error }) => {
      if (error) {
        console.log(error)
        clearTimeout(timeoutSearchablePdfId.current)
      } else if (result.searchable_pdf_url) {
        setSearchablePdfUrl(result.searchable_pdf_url)
        clearTimeout(timeoutSearchablePdfId.current)
      } else {
        timeoutSearchablePdfId.current = setTimeout(() => {
          getSearchPdfUrl()
        }, 10000)
      }
    })
  }

  const getOCRText = () => {
    const headers = { token: ziebToken }
    const url = `${ziebHost}/api/documents/${documentId}/ocr`

    runFetchData(client.get(url, null, headers)).then(({ result, error }) => {
      if (error) {
        console.log(error)
        clearTimeout(timeoutOCRId.current)
      } else if (result.description) {
        setOCRText(result.description)
        clearTimeout(timeoutOCRId.current)
      } else {
        timeoutOCRId.current = setTimeout(() => {
          getOCRText()
        }, 10000)
      }
    })
  }

  if (document.initialized && document.data.extension === 'pdf') {
    return (
      <Container>
        <Tabs justifyContent="flex-end">
          <TabList>
            <Tab value={1}>
              <span>PDF</span>
            </Tab>
            <Tab value={2}>
              {searchablePdfUrl ? (
                <span>
                  <i className="fas fa-search fa-sm color-inherit" /> PDF
                </span>
              ) : (
                <i className="fas fa-spinner fa-spin fa-sm color-inherit" />
              )}
            </Tab>
            <Tab value={3}>
              {OCRText ? (
                <span>
                  <i className="fas fa-align-right fa-sm color-inherit" /> Text
                </span>
              ) : (
                <i className="fas fa-spinner fa-spin fa-sm color-inherit" />
              )}
            </Tab>
          </TabList>
          <TabPanel>
            <PDFViewer />
          </TabPanel>
          <TabPanel>
            <SearchablePDF url={searchablePdfUrl} />
          </TabPanel>
          <TabPanel>
            <OCRViewer text={OCRText} />
          </TabPanel>
        </Tabs>
      </Container>
    )
  }

  return (
    <Container>
      <Tabs justifyContent="flex-end">
        <TabList>
          <Tab value={1}>
            <span>PDF</span>
          </Tab>
          <Tab value={3}>
            {OCRText ? (
              <span>
                <i className="fas fa-align-right fa-sm color-inherit" /> Text
              </span>
            ) : (
              <i className="fas fa-spinner fa-spin fa-sm color-inherit" />
            )}
          </Tab>
        </TabList>
        <TabPanel>
          <PDFViewer />
        </TabPanel>
        <TabPanel>
          <OCRViewer text={OCRText} />
        </TabPanel>
      </Tabs>
    </Container>
  )
}
