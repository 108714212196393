import React, { useContext, useEffect, useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Global, css } from '@emotion/react'

import { MailerAppContext } from './MailerApp'

const COUNTER_PARTY_KEY = 'counter_party'
const COUNTER_PARTY_DEFAULT_EMAIL = 'info@conny.de'

const selectReceiverButtonCSS = css(`
  .disabled-parent {
    cursor: not-allowed;
    opacity: 0.6;
  }

  .disabled-area {
    pointer-events: none;
  }
`)

export default function SelectReceiverButton({
  item,
  unselected,
  toggleSelected,
  selectedEmail,
}) {
  const [disabled, setDisabled] = useState(true)

  const { request } = useContext(MailerAppContext)

  useEffect(() => {
    // reason of this side effect is for a special rule
    // create another one if you want add side effects
    // disables gegenseite button if there is no counter party manager
    // or its mail address equals 'info@conny.de'
    const default_recipient_email =
      request &&
      request.counter_parties_manager &&
      request.counter_parties_manager.default_recipient_email

    if (
      item.key === COUNTER_PARTY_KEY &&
      (!default_recipient_email ||
        default_recipient_email === undefined ||
        default_recipient_email === COUNTER_PARTY_DEFAULT_EMAIL)
    ) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [request])

  const handleToggleSelected = () => {
    !disabled && toggleSelected(item.key)
  }

  const selectReceiverButtonClassNames = classNames('select-receiver-button', {
    'disabled-parent': disabled,
  })

  const toSelectorClassNames = classNames('to-selector', item.key, {
    unselected: unselected(item.key),
    'disabled-area': disabled,
  })

  return (
    <>
      <Global styles={selectReceiverButtonCSS} />
      <div className={selectReceiverButtonClassNames}>
        <a
          className={toSelectorClassNames}
          id={'to-' + item.key}
          onClick={handleToggleSelected}
          key={`receiver-tab-${item.key}`}
          data-key={item.key}
          data-testid="select-receiver-button"
          style={{ backgroundColor: item.color.box }}
        >
          <div className="image d-flex align-items-center">
            <span className="fa-stack fa-lg">
              <i
                className="fas fa-circle fa-stack-2x"
                style={{ color: `${item.color.icon}` }}
              />
              <i className={`${item.icon} fa-stack-1x text-white`} />
            </span>
          </div>
          <div className="text d-flex flex-column align-items-center">
            <div className="name">{item.string}</div>
            <div className="email">{selectedEmail(item)}</div>
          </div>
        </a>
      </div>
    </>
  )
}

SelectReceiverButton.propTypes = {
  item: PropTypes.object,
  unselected: PropTypes.func,
  toggleSelected: PropTypes.func,
  selectedEmail: PropTypes.func,
}
