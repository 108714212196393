import React, { useState } from 'react'
import getConfig from './config'
import classNames from 'classnames'
import client from '../../../../client'

const SidebarNavigation = (props) => {
  const { mailProcessorUrl } = props
  const { navitems, othernavitems } = getConfig(mailProcessorUrl)
  const [toggleOther, setToggleOther] = useState(false)

  const postMailLoaderRequest = () => {
    client.post('/admin/post_mail_loader')
  }

  const getItems = (data) => {
    const returnedItems = []
    data.forEach((item) => {
      if (!item.displayCondition || props[item.displayCondition]) {
        returnedItems.push(
          <a
            key={item.id}
            href={item.link}
            onClick={
              item.id == 'postmailloader' ? postMailLoaderRequest : () => {}
            }
            className={classNames(
              'sb-nav__item link-underline link-underline-opacity-0 link-underline-opacity-75-hover',
              {
                'sb-nav__item-active':
                  window.location.pathname == item.link && !toggleOther,
              },
            )}
          >
            <div className="sb-nav__item-wrapper">
              <i className={`fas ${item.icon} sb-nav__item-icon`}></i>
              <span className="sb-nav__item-text">{item.text}</span>
            </div>
          </a>,
        )
      }
    })
    return returnedItems
  }

  const handleOtherClick = () => {
    setToggleOther(!toggleOther)
  }

  const navItemsToRender = getItems(navitems)
  const otherNavItemsToRender = getItems(othernavitems)

  return (
    <>
      <div className="sb-nav__logo-bg">
        <div className="sb-nav__logo">
          <img
            src="https://s3-mietright-assets.conny.de/conny_engine-white.svg"
            alt="conny engine logo"
          />
        </div>
      </div>

      {navItemsToRender}

      <div
        onClick={handleOtherClick}
        className={classNames('sb-nav__item sb-nav__item-other', {
          'sb-nav__item-active': toggleOther,
        })}
      >
        <div className="sb-nav__item-wrapper">
          <i className="fas fa-ellipsis-h sb-nav__item-icon"></i>
          <span className="sb-nav__item-text">Sonstiges</span>
        </div>
        {toggleOther && (
          <div className="sb-nav__item-other-wrapper">
            {otherNavItemsToRender}
          </div>
        )}
      </div>
    </>
  )
}

export default SidebarNavigation
