import React from 'react'
import { Accordion } from 'react-bootstrap'
import ActionButton from '../ActionButton'

import LogItemHeader from '../LogItemHeader'

export default function Todo(props) {
  const { data, id, open, onClick, onDelete } = props

  const dateFormatShort = (date) => {
    return moment(date).locale('de').format('DD.MM.YYYY')
  }

  const handleClick = () => {
    // TODO build delete and edit serialiser for todo
    // if (action.title === "delete") {
    //   const todoId = parseInt(id.split('-')[1])
    //   const isConfirmed = confirm('Todo sicher löschen?');
    //   if (!isConfirmed) {
    //     return;
    //   }
    //   onDelete && onDelete(todoId);
    // }
  }

  const renderActionButtons = () => {
    return data.actions.map((action, index) => {
      return <ActionButton action={action} key={index} onClick={handleClick} />
    })
  }

  const renderFristenField = () => {
    if (data.content_info.fristen_enabled) {
      return (
        <div>
          <i className="fas fa-calendar-o footer-icon me-1" />
          Frist: <strong>{data.content_info.fristen}</strong>
        </div>
      )
    }
  }

  return (
    <div>
      <div
        onClick={() => {
          onClick(id)
        }}
      >
        <LogItemHeader data={data} />
      </div>
      <Accordion.Collapse eventKey={id} in={open}>
        <div className="log-item-content__container">
          <div className="log-item-content-header__container">
            <p className="log-item__text">{data.content_title}</p>
            <div className="log-item-content-header__actions">
              {renderActionButtons()}
            </div>
          </div>
          {data.content_text && (
            <div className="log-item-content__card">
              <pre
                className="log-item-content__pre"
                dangerouslySetInnerHTML={{ __html: data.content_text }}
              />
            </div>
          )}
          <div className="log-item-content-footer__container">
            <div>
              <i className="far fa-user footer-icon me-1" />
              Zugewiesen: <strong>{data.content_info.assignee}</strong>
            </div>
            <div>
              <i className="fas fa-calandar footer-icon" />
              Zu erledigen bis:{' '}
              <strong> {dateFormatShort(data.content_info.due_on)}</strong>
            </div>
            <div>
              <i className="far fa-user footer-icon me-1" />
              Erledigt von: <strong>{data.content_info.completed_by}</strong>
            </div>
            {renderFristenField()}
            <div className="footer-item4">
              <strong>#{data.content_info.todo_id}</strong>
            </div>
          </div>
        </div>
      </Accordion.Collapse>
    </div>
  )
}
