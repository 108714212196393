import React from 'react'
import renderer from 'react-test-renderer'

import {
  FormContainer,
  FieldGroup,
  SuggestionSpan,
  SubmitMessage,
} from '../../components/documentCaseData/styles/documentCaseData'

describe.skip('FormContainer', () => {
  it('applies display block by default', () => {
    const tree = renderer.create(<FormContainer />).toJSON()
    expect(tree).toHaveStyleRule('display', 'block')
  })

  it('applies display flex when in step rent tab', () => {
    const tree = renderer.create(<FormContainer isStepRent={true} />).toJSON()
    expect(tree).toHaveStyleRule('display', 'flex')
  })
})

describe.skip('FieldGroup', () => {
  it('applies flex-direction row by default', () => {
    const tree = renderer.create(<FieldGroup />).toJSON()
    expect(tree).toHaveStyleRule('flex-direction', 'row')
  })

  it('applies backgroundColor styles from props', () => {
    const tree = renderer.create(<FieldGroup isStepRent={true} />).toJSON()
    expect(tree).toHaveStyleRule('flex-direction', 'column')
  })
})

describe.skip('SuggestionSpan', () => {
  it('applies default backgroundColor styles', () => {
    const tree = renderer.create(<SuggestionSpan />).toJSON()
    expect(tree).toHaveStyleRule('background-color', /#4c8dff/i)
  })

  it('applies backgroundColor styles from props', () => {
    const tree = renderer
      .create(<SuggestionSpan backgroundColor="automatic_answer_different" />)
      .toJSON()
    expect(tree).toHaveStyleRule('background-color', /#e56d22/i)
  })
})

describe.skip('SubmitMessage', () => {
  it('applies default text color white', () => {
    const tree = renderer.create(<SubmitMessage />).toJSON()
    expect(tree).toHaveStyleRule('color', 'white')
  })

  it('applies color styles from props', () => {
    const tree = renderer.create(<SubmitMessage color="success" />).toJSON()
    expect(tree).toHaveStyleRule('color', /#2ecc71/i)
  })
})
