import { chunkWithPosition } from '../helpers/helpers'

describe('chunkWithPosition()', () => {
  it('returns the same array if all left and right fields are balanced', () => {
    const array = [
      { priority: 1, type: 'question', rhs: false },
      { priority: 2, type: 'question', rhs: true },
      { priority: 3, type: 'question', rhs: false },
      { priority: 4, type: 'question', rhs: true },
    ]
    expect(chunkWithPosition(array)).toEqual([
      { priority: 1, type: 'question', rhs: false },
      { priority: 2, type: 'question', rhs: true },
      { priority: 3, type: 'question', rhs: false },
      { priority: 4, type: 'question', rhs: true },
    ])
  })

  it('returns array with empty object on the right place', () => {
    const array = [
      { priority: 1, type: 'question', rhs: false },
      { priority: 2, type: 'question', rhs: false },
      { priority: 3, type: 'question', rhs: true },
      { priority: 4, type: 'question', rhs: false },
      { priority: 5, type: 'question', rhs: true },
    ]
    expect(chunkWithPosition(array)).toEqual([
      { priority: 1, type: 'question', rhs: false },
      {},
      { priority: 2, type: 'question', rhs: false },
      { priority: 3, type: 'question', rhs: true },
      { priority: 4, type: 'question', rhs: false },
      { priority: 5, type: 'question', rhs: true },
    ])
  })
})
