import styled from 'styled-components'

export const Container = styled.div`
  background-color: #b7cea7;
  color: black;
  padding: 1rem;
  width: 100%;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
`

export const Heading = styled.h6`
  margin-bottom: 0;
  font-weight: 700;
`

export const FormContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Label = styled.label`
  font-size: 0.7rem;
  margin: 0.5rem 0 0;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const Button = styled.button`
  background-color: #2ecc71;
  color: #f7f7f9;
  border-radius: 20px;
  border: 1px solid #2ecc71;
  font-weight: 600;
  font-size: 0.8rem;
  padding: 0.375rem 1rem;
  width: 120px;
  margin-top: 1rem;

  &:hover:not(:disabled) {
    background-color: #48e68b;
  }

  &:active:not(:disabled) {
    background-color: #15b358;
  }

  &:disabled {
    opacity: 0.65;
  }
`
