import reducer, {
  initializeContentPages,
  removeContentPages,
} from '../contentPages'

describe('contentPages duck', () => {
  describe('action: initializeContentPages', () => {
    it('should return action with payload', () => {
      const payload = [
        { title: 'Mieter', fields: [] },
        { title: 'Vermieter', fields: [] },
      ]
      expect(initializeContentPages(payload)).toEqual({
        type: 'INITIALIZE_CONTENT_PAGES',
        payload,
      })
    })
  })

  describe('action: removeContentPages', () => {
    it('should return action without payload', () => {
      expect(removeContentPages()).toEqual({
        type: 'REMOVE_CONTENT_PAGES',
      })
    })
  })

  describe('reducer', () => {
    it('should return initialized state', () => {
      const action = {
        type: 'INITIALIZE_CONTENT_PAGES',
        payload: [
          { title: 'Mieter', fields: [] },
          { title: 'Vermieter', fields: [] },
        ],
      }
      expect(reducer(undefined, action)).toEqual({
        data: {
          0: { title: 'Mieter', fields: [] },
          1: { title: 'Vermieter', fields: [] },
        },
        initialized: true,
      })
    })

    it('should return not initialized state', () => {
      const action = { type: '', payload: {} }
      expect(reducer(undefined, action)).toEqual({
        data: {},
        initialized: false,
      })
    })

    it('should return state with empty data and initialized false', () => {
      const state = reducer(undefined, {
        type: 'INITIALIZE_CONTENT_PAGES',
        payload: [
          { title: 'Mieter', fields: [] },
          { title: 'Vermieter', fields: [] },
        ],
      })
      const action = { type: 'REMOVE_CONTENT_PAGES' }
      expect(reducer(state, action)).toEqual({
        data: {},
        initialized: false,
      })
    })
  })
})
