import reducer, { changeDocument, initializeDocument } from '../document'

describe('document duck', () => {
  describe('action: initializeDocument', () => {
    it('should return action with payload', () => {
      const payload = { name: 'document', type: 'Mietvertrag' }
      expect(initializeDocument(payload)).toEqual({
        type: 'INITIALIZE_DOCUMENT',
        payload,
      })
    })
  })

  describe('action: changeDocument', () => {
    it('should return action with payload', () => {
      const payload = { name: 'document', type: 'Mietvertrag' }
      expect(changeDocument(payload)).toEqual({
        type: 'CHANGE_DOCUMENT',
        payload,
      })
    })

    it('should return action with empty payload', () => {
      expect(changeDocument()).toEqual({
        type: 'CHANGE_DOCUMENT',
        payload: {},
      })
    })
  })

  describe('reducer', () => {
    it('should return initialized state', () => {
      const action = {
        type: 'INITIALIZE_DOCUMENT',
        payload: { name: 'document', type: 'Mietvertrag' },
      }
      expect(reducer(undefined, action)).toEqual({
        data: { name: 'document', type: 'Mietvertrag' },
        initialized: true,
      })
    })

    it('should return not initialized state', () => {
      const action = { type: '', payload: {} }
      expect(reducer(undefined, action)).toEqual({
        data: {},
        initialized: false,
      })
    })

    it('should return updated state', () => {
      const state = reducer(undefined, {
        type: 'INITIALIZE_DOCUMENT',
        payload: { name: 'document1', type: 'Mietvertrag' },
      })
      const action = {
        type: 'CHANGE_DOCUMENT',
        payload: { name: 'document2' },
      }
      expect(reducer(state, action)).toEqual({
        data: { name: 'document2', type: 'Mietvertrag' },
        initialized: true,
      })
    })
  })
})
