const filterOptions = [
  {
    name: 'subject',
    options: [
      {
        text: 'Betreff',
        value: 'title',
      },
      {
        text: 'Kunde',
        value: 'client',
      },
      {
        text: 'Gegenseite',
        value: 'counter_party',
      },
      {
        text: 'externer Anwalt',
        value: 'external_lawyer',
      },
      {
        text: 'Gericht',
        value: 'court',
      },
      {
        text: 'Intern',
        value: 'internal',
      },
      {
        text: 'Drittpartei',
        value: 'third_party',
      },
    ],
  },
  {
    name: 'direction',
    options: [
      {
        text: 'Richtung',
        value: 'title',
      },
      {
        text: 'eingehend',
        value: 'incoming',
      },
      {
        text: 'ausgehend',
        value: 'outgoing',
      },
    ],
  },
]

const filterSaasOptions = [
  {
    name: 'subject',
    options: [
      {
        text: 'Betreff',
        value: 'title',
      },
      {
        text: 'Kunde',
        value: 'client',
      },
      {
        text: 'Intern',
        value: 'internal',
      },
    ],
  },
  {
    name: 'direction',
    options: [
      {
        text: 'Richtung',
        value: 'title',
      },
      {
        text: 'eingehend',
        value: 'incoming',
      },
      {
        text: 'ausgehend',
        value: 'outgoing',
      },
    ],
  },
]

const buttons = [
  {
    type: 'email_log',
    icon: 'fas fa-envelope',
    title: 'Emails',
    text: 'E-Mails filtern',
  },
  {
    type: 'document',
    icon: 'fas fa-file',
    title: 'Dokumente',
    text: 'Dokumente filtern',
  },
  {
    type: 'note',
    icon: 'fas fa-comment',
    title: 'Notizen',
    text: 'Manuelle Einträge filtern',
  },
  {
    type: 'ticket',
    icon: 'far fa-check-square',
    title: 'Todos',
    text: 'ToDos filtern',
  },
  {
    type: 'payment',
    icon: 'fas fa-euro-sign',
    title: 'Zahlungen',
    text: 'Zahlungen filtern',
  },
  {
    type: 'life_cycle',
    icon: 'fas fa-circle-notch',
    title: 'Lifecycles',
    text: 'Lifecycles, Status and Reason filtern',
  },
  {
    type: 'automatic_physical_mail',
    icon: 'fas fa-envelope-open-text',
    title: 'Autom. Postausgang',
    text: 'Autom. Postausgang filtern',
  },
  {
    type: 'bea_file',
    icon: 'fas fa-file-contract',
    title: 'BEA',
    text: 'BEA filtern',
  },
  {
    type: 'advo_assist',
    icon: 'fas fa-balance-scale',
    title: 'Advo Assist',
    text: 'AdvoAssist filtern',
  },
  {
    type: 'case_edited',
    icon: 'far fa-folder-open',
    title: 'Fall editiert',
    text: 'Fall-Editierung filtern',
  },
  {
    type: 'marked',
    icon: 'fas fa-star',
    title: '',
    text: '',
  },
]

export { filterOptions, filterSaasOptions, buttons }
