import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Checkbox from '../Checkbox'

export default class MultiSelect extends Component {
  static displayName = 'MultiSelect'
  static propTypes = {
    name: PropTypes.string,
    value: PropTypes.any,
    options: PropTypes.array,
    onChange: PropTypes.func,
    className: PropTypes.string,
    labelClassName: PropTypes.string,
    disabled: PropTypes.bool,
  }

  handleChange = (data) => {
    const { name, value, onChange } = this.props

    let newValue = []
    if (data.value) {
      newValue = [...value, data.name]
    } else {
      const index = value.indexOf(data.name)
      newValue = [...value.slice(0, index), ...value.slice(index + 1)]
    }

    onChange && onChange({ name, value: newValue })
  }

  render() {
    const {
      value = [],
      options,
      className,
      labelClassName,
      disabled,
    } = this.props

    return (
      <div className="d-block">
        {options.map((item) => {
          return (
            <div key={item.value} className={className}>
              <Checkbox
                name={item.value}
                id={item.value}
                type="checkbox"
                value={value.includes(item.value)}
                checked={value.includes(item.value)}
                onChange={this.handleChange}
                disabled={disabled}
              />
              <label htmlFor={item.value} className={labelClassName}>
                {item.text}
              </label>
            </div>
          )
        })}
      </div>
    )
  }
}
