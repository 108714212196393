import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { Tooltip as BootstrapTooltip } from 'react-bootstrap'
import PropTypes from 'prop-types'

export default function Tooltip(props) {
  const { text, className, placement, tooltipChildren } = props
  const renderTooltip = (props) => {
    return (
      <BootstrapTooltip
        id="button-tooltip"
        {...props}
        show={props.show.toString()}
      >
        {tooltipChildren ? (
          <>{tooltipChildren}</>
        ) : (
          <pre className="tooltip-formatting">{text}</pre>
        )}
      </BootstrapTooltip>
    )
  }
  return (
    <OverlayTrigger
      placement={placement}
      delay={{ show: 100, hide: 200 }}
      overlay={renderTooltip}
    >
      <span className={className}>{props.children}</span>
    </OverlayTrigger>
  )
}

Tooltip.propTypes = {
  text: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  placement: PropTypes.string,
}
