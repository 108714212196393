import { useState, useCallback } from 'react'

export function useBoolean(initialState: boolean = false) {
  const [value, setValue] = useState(initialState)

  const on = useCallback(() => setValue(true), [])
  const off = useCallback(() => setValue(false), [])

  return [value, { on, off }] as const
}
