import React from 'react'
import AsyncSelect from 'react-select/async'
import client from '../../../../client'

const SafeIdSelect = (props) => {
  const { disabled, handleLawyerChange, lawyer } = props

  const loadOptions = (inputValue, callback) => {
    client
      .get(
        `/admin/counter-party-lawyer-safe-ids?filter=${encodeURI(inputValue)}`,
        null,
        { Accept: 'application/json' },
      )
      .then((response) => response.json())
      .then((values) => {
        callback(values)
      })
  }

  const handleChange = (event) => {
    handleLawyerChange(event)
  }

  return (
    <AsyncSelect
      id="lawyer-safe-id-select"
      isClearable
      cacheOptions
      value={lawyer}
      loadOptions={loadOptions}
      defaultOptions={true}
      onChange={handleChange}
      placeholder="Ext. Anwälte"
      getOptionLabel={(e) => e.name}
      getOptionValue={(e) => e.id}
      isDisabled={disabled}
    />
  )
}

export default SafeIdSelect
