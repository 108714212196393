import PropTypes from 'prop-types'

const Step = (props) => {
  const { isActive, children, onNextClick, onPreviousClick, onCompleteClick } =
    props

  if (isActive === false) {
    return null
  }

  return children({ onNextClick, onPreviousClick, onCompleteClick })
}

Step.propTypes = {
  isActive: PropTypes.bool,
  children: PropTypes.func.isRequired,
  onNextClick: PropTypes.func,
  onPreviousClick: PropTypes.func,
  onCompleteClick: PropTypes.func,
}

export default Step
