import React from 'react'
import PropTypes from 'prop-types'

import { Label, InputBox } from './styles/documentAttr'
import useMetaData from '../../hooks/useMetaData'

export default function TypeField({ onChange, formValues }) {
  const { metaData } = useMetaData()

  const documentTypes = metaData.data
  const documentTypesOptions = documentTypes[
    formValues.direction.value
  ].document_types.map((item) => item)
  const topSuggestionOptions = documentTypes[
    formValues.direction.value
  ].top_suggestions.map((item) => item)

  const getOptions = (data) =>
    data.map((opt) => (
      <option key={opt.id} value={opt.id}>
        {opt.title}
      </option>
    ))

  const selectOptions = () => {
    if (documentTypes.ocr_present) {
      return (
        <>
          <optgroup label="Top-Vorschläge">
            {getOptions(topSuggestionOptions)}
          </optgroup>
          <optgroup label="Andere">{getOptions(documentTypesOptions)}</optgroup>
        </>
      )
    } else {
      return getOptions(documentTypesOptions)
    }
  }

  const findSubjectById = (id) => {
    return documentTypesOptions.find((item) => item.id === id)?.subject
  }

  const handleChange = (event) => {
    const { value, name } = event.target
    const changedSubject = findSubjectById(value)
    onChange && onChange({ name, value, changedSubject })
  }

  return (
    <>
      <Label>Art: </Label>
      <InputBox>
        <select
          name="type"
          value={formValues.type.value || 'uncategorize'}
          onChange={handleChange}
          className="form-control"
        >
          <option value="uncategorize">Dokumententyp auswählen...</option>
          {selectOptions()}
        </select>
      </InputBox>
    </>
  )
}

TypeField.propTypes = {
  onChange: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
}
