import React, { useState } from 'react'
import moment from 'moment'
import AdminDatepicker from '../../admin_datepicker'
import Button from '../../engine-ui/Button'
import client from '../../../../../client'

moment.updateLocale(moment.locale(), { invalidDate: ' - ' })

interface IProps {
  documentId: string
  requestId: string
  date: string
  fieldName?: string
  token: string
}

export default function DateCell({
  documentId,
  requestId,
  date: defaultDate,
  fieldName = 'eb_date',
  token,
}: IProps) {
  const [editMode, setEditMode] = useState(false)
  const [date, setDate] = useState(defaultDate)

  const editModeOn = () => {
    setEditMode(true)
  }

  const editModeOff = () => {
    setEditMode(false)
  }

  const changeDate = (event: { value: string }) => {
    setDate(event.value)
  }

  const updateDate = () => {
    const UPDATE_URL = `/api/admin/requests/${requestId}/documents/${documentId}`
    const headers = { token: token }

    client
      .patch(UPDATE_URL, { [fieldName]: date }, headers)
      .catch(() => setDate(defaultDate))
      .finally(editModeOff)
  }

  if (editMode) {
    return (
      <div className={`date-cell ${fieldName}`}>
        <AdminDatepicker
          past
          required
          name="eb"
          question={{ name: 'eb' }}
          value={date}
          className="df_datepicker"
          onChange={changeDate}
        />
        <Button className="icon-button" onClick={updateDate}>
          <i className="fas fa-check"></i>
        </Button>
      </div>
    )
  }

  return (
    <div className={`date-cell ${fieldName}`}>
      <span className="cell-text">
        {moment.utc(date).format('DD.MM.YYYY') || ' - '}
      </span>
      <Button className="icon-button" onClick={editModeOn}>
        <i className="fas fa-pencil-alt"></i>
      </Button>
    </div>
  )
}
