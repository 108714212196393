import React from 'react'
import useCaseData from '../useCaseData'

describe('useCaseData', () => {
  let dispatch
  beforeEach(() => {
    dispatch = jest.fn()
    React.useContext = jest.fn(() => ({ dispatch, state: { caseData: {} } }))
  })

  afterEach(() => {
    dispatch.mockClear()
    React.useContext.mockReset()
  })

  describe('useCaseData properties', () => {
    test('should return caseData properties', () => {
      expect(useCaseData()).toHaveProperty('caseData')
      expect(useCaseData()).toHaveProperty('changeCaseData')
      expect(useCaseData()).toHaveProperty('initializeCaseData')
      expect(useCaseData()).toHaveProperty('removeCaseData')
    })
  })

  describe('useCaseData: changeCaseData', () => {
    test('should call dispatch with changeCaseData once', () => {
      const { changeCaseData } = useCaseData()
      changeCaseData({})
      expect(dispatch.mock.calls.length).toEqual(1)
    })
  })

  describe('useCaseData: initializeCaseData', () => {
    test('should call dispatch initializeCaseData once', () => {
      const { initializeCaseData } = useCaseData()
      initializeCaseData({})
      expect(dispatch.mock.calls.length).toEqual(1)
    })
  })

  describe('useCaseData: removeCaseData', () => {
    test('should call dispatch removeCaseData once', () => {
      const { removeCaseData } = useCaseData()
      removeCaseData({})
      expect(dispatch.mock.calls.length).toEqual(1)
    })
  })
})
