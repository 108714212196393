import { filter } from 'lodash'

// save this labels to shared place
const MARKED = 'marked'

const filterFeedItems = (items, filters) => {
  const filterdBySubject = filterSubject(items, filters.subject)
  const filteredByDirection = filterDirection(
    filterdBySubject,
    filters.direction,
  )
  const filteredByType = filterType(filteredByDirection, filters.type)
  const filteredByMarked = filterMarked(filteredByType, filters.type)

  return filteredByMarked
}

const filterSubject = (items, subject) => {
  if (subject == 'title') return items
  else return filter(items, ['subject', subject])
}

const filterDirection = (items, direction) => {
  if (direction == 'title') return items
  else return filter(items, ['subtype', direction])
}

const filterType = (items, type) => {
  const pred = (item) => {
    if (type.includes('ticket')) {
      if (
        item.type == 'ticket' ||
        (item.type == 'system_log' && item.subtype.startsWith('ticket'))
      )
        return true
    }

    if (type.includes('life_cycle')) {
      if (
        item.type == 'system_log' &&
        /^(li|st|re|ph).*/.test(item.subtype) // subtypes life_cycle, status, reason, phase
      )
        return true
    }

    if (type.includes('case_edited')) {
      if (!type.includes('ticket') && item.subtype.startsWith('ticket'))
        return false
      if (
        !type.includes('life_cycle') &&
        /^(li|st|re|ph).*/.test(item.subtype) // subtypes life_cycle, status, reason, phase
      )
        return false
      if (item.type == 'system_log' || item.type === 'admin_edited') return true
    }

    if (type.includes('bea_file') && /^bea_.*/.test(item.subtype)) {
      return true
    }

    return type.includes(item.type)
  }
  return filter(items, pred)
}

function filterMarked(items, types) {
  if (types.includes(MARKED)) {
    return items.filter((item) => item.marked)
  }

  return items
}

export default filterFeedItems
