import React, { useState } from 'react'
import client from '../../../../client'
import { InView } from 'react-intersection-observer'
import { sPrintQuery } from './Filter'
import { Badge } from './TeamFilter'

const MiscToDoFilter = (props) => {
  const { path, text, loadValue } = props
  const [count, setCount] = useState(null)

  return (
    <InView
      onChange={(inView, _e) => {
        if (inView) {
          loadValue(setCount)
        }
      }}
      triggerOnce={true}
    >
      <li className="sidebar__item">
        <a href={path} className="sidebar__header">
          <i className="far fa-user "></i>
          <span className="mx-1">{text}</span>
          <Badge count={count} />
        </a>
      </li>
    </InView>
  )
}
const MiscToDoFilters = (props) => {
  const { basePath = '/admin/tickets', searchParams } = props

  const loadTicketStat = (query) => {
    return (setValue) => {
      client
        .get(`/admin/v2/agent/tickets_stats?${query.toString()}`, null, {
          Accept: 'application/json',
        })
        .then((response) => response.json())
        .then(setValue)
    }
  }

  return (
    <>
      <MiscToDoFilter
        key="not-assigned"
        path={
          basePath + '?' + sPrintQuery({ ...searchParams, assignee: 'none' })
        }
        text="Nicht zugewiesen"
        loadValue={loadTicketStat(
          new URLSearchParams({ unassigned: true, relevant_for_today: true }),
        )}
      />
      <MiscToDoFilter
        path={basePath + '?' + sPrintQuery({ ...searchParams, deleted: true })}
        text="gelöschte ToDos"
        loadValue={loadTicketStat(new URLSearchParams({ deleted: true }))}
      />
      <MiscToDoFilter
        path={
          basePath +
          '?' +
          sPrintQuery({ ...searchParams, hidden_categories: true })
        }
        text="Hidden ToDos"
        loadValue={loadTicketStat(
          new URLSearchParams({
            hidden_categories: true,
            relevant_for_today: true,
          }),
        )}
      />
    </>
  )
}

export default MiscToDoFilters
