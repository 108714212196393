import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

export default function DependentSelect(props) {
  const {
    name,
    value,
    getFieldData,
    isClearable,
    isMulti,
    onChange,
    placeholder,
    options,
    token,
  } = props

  const colourStyles = {
    option: (styles, { isDisabled }) => ({
      ...styles,
      color: isDisabled ? '#ccc' : 'black',
    }),
  }

  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [selectOptions, setSelectOptions] = useState([{ label: '', value: '' }])
  const [isLoading, setIsLoading] = useState(true)
  const handleChange = (selected) => {
    onChange && onChange({ name, value: selected.value })
  }

  const getOptionValue = (valueToFind) => {
    const option = options.find(
      (opt) =>
        opt.text == 'suppliParams' && opt.value.split(',')[0] == valueToFind,
    )
    return option ? option.value.split(',')[1] : null
  }

  useEffect(() => {
    if (!isFirstLoad || !options || options.length == 0) {
      return
    }
    setIsFirstLoad(false)

    const fieldToGet = getOptionValue('dependsOn')
    if (!fieldToGet) {
      return
    }

    const requiredField = getFieldData(fieldToGet)
    if (requiredField && requiredField.text) {
      const queryParamValue = requiredField.text
      const endpoint = getOptionValue('url')
      const queryParam = getOptionValue('queryParam')

      if (queryParamValue && endpoint && queryParam) {
        const headers = { token: token }

        fetch(`${endpoint}?${queryParam}=${queryParamValue}`, {
          headers: headers,
        })
          .then((resp) => resp.json())
          .then((data) => {
            setIsLoading(false)
            setSelectOptions(
              [{ label: '', value: '' }].concat(
                data.items.map((item) => {
                  let label = `${item.name} (${item.aliases.join(',')})`
                  return { label: label, value: item.name }
                }),
              ),
            )
          })
      }
    }
  }, [])

  return (
    <Select
      options={selectOptions}
      styles={colourStyles}
      placeholder={placeholder}
      defaultValue={{ label: value, value: value }}
      isClearable={isClearable}
      isMulti={isMulti}
      onChange={handleChange}
      isLoading={isLoading}
      loadingMessage={() => 'lädt...'}
    />
  )
}

DependentSelect.displayName = 'DependentSelect'

DependentSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  getFieldData: PropTypes.func,
  token: PropTypes.string,
}
