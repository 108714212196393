const INITIALIZE_CONTENT_PAGES = 'INITIALIZE_CONTENT_PAGES'
const REMOVE_CONTENT_PAGES = 'REMOVE_CONTENT_PAGES'

export function initializeContentPages(payload = {}) {
  return { type: INITIALIZE_CONTENT_PAGES, payload }
}

export function removeContentPages() {
  return { type: REMOVE_CONTENT_PAGES }
}

const initialState = { data: {}, initialized: false }
export default function reducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case INITIALIZE_CONTENT_PAGES:
      return {
        data: { ...payload },
        initialized: true,
      }
    case REMOVE_CONTENT_PAGES:
      return {
        data: {},
        initialized: false,
      }
    default:
      return state
  }
}
