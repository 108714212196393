const INITIALIZE_CASE_DATA = 'INITIALIZE_CASE_DATA'
const CHANGE_CASE_DATA = 'CHANGE_CASE_DATA'
const REMOVE_CASE_DATA = 'REMOVE_CASE_DATA'

export function initializeCaseData(payload = {}) {
  return { type: INITIALIZE_CASE_DATA, payload }
}

export function changeCaseData(payload = {}) {
  return { type: CHANGE_CASE_DATA, payload }
}
export function removeCaseData() {
  return { type: REMOVE_CASE_DATA }
}

const initialState = { data: {}, initialized: false }
export default function reducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case INITIALIZE_CASE_DATA:
      return {
        data: { ...payload },
        initialized: true,
      }
    case CHANGE_CASE_DATA:
      return {
        ...state,
        data: { ...state.data, ...payload },
      }
    case REMOVE_CASE_DATA:
      return {
        data: {},
        initialized: false,
      }
    default:
      return state
  }
}
