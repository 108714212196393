const copyToClipboard = (text) => {
  const tempTextarea = document.createElement('textarea')
  document.body.appendChild(tempTextarea)
  tempTextarea.innerHTML = text
  tempTextarea.select()
  document.execCommand('copy')
  document.body.removeChild(tempTextarea)
}

export default copyToClipboard
