import reducer, { initializeMetaData } from '../metaData'

describe('metaData duck', () => {
  describe('action: initializeMetaData', () => {
    it('should return action with payload', () => {
      const payload = {
        incoming: [{ title: 'Allgemein Gegenseite' }],
        outgoing: [{ title: 'Berufung' }],
      }
      expect(initializeMetaData(payload)).toEqual({
        type: 'INITIALIZE_METADATA',
        payload,
      })
    })
  })

  describe('reducer', () => {
    it('should return initialized state', () => {
      const action = {
        type: 'INITIALIZE_METADATA',
        payload: {
          incoming: [{ title: 'Allgemein Gegenseite' }],
          outgoing: [{ title: 'Berufung' }],
        },
      }
      expect(reducer(undefined, action)).toEqual({
        data: {
          incoming: [{ title: 'Allgemein Gegenseite' }],
          outgoing: [{ title: 'Berufung' }],
        },
        initialized: true,
      })
    })

    it('should return not initialized state', () => {
      const action = { type: '', payload: {} }
      expect(reducer(undefined, action)).toEqual({
        data: {},
        initialized: false,
      })
    })
  })
})
