import React from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'

import S3Upload from '../../../utils/s3_upload'

const DocumentUploader = (
  {
    signing_path,
    handleUploadStart,
    handleUploadProgress,
    handleUploadError,
    handleUploadFinish,
  },
  { translations },
) => {
  const handleDrop = (files) => {
    files.forEach(function (file) {
      file.uid = Math.random().toString(36).substr(2, 10)

      const s3upload = new S3Upload({
        file: file,
        handleUploadStart: handleUploadStart,
        handleUploadProgress: handleUploadProgress,
        handleUploadFinish: handleUploadFinish,
        handleUploadError: handleUploadError,
      })

      fetch(
        signing_path +
          `?objectName=${s3upload.objectName()}&contentType=${encodeURIComponent(
            file.type,
          )}`,
      )
        .then((response) => response.json())
        .then((json) => s3upload.start(json.signedUrl))
    })
  }

  return (
    <div className="document-uploader__drop-container">
      <Dropzone
        className="document-uploader__drop-zone"
        activeClassName="document-uploader__drop-zone--active"
        accept="image/*, application/pdf, application/zip"
        onDrop={handleDrop}
        name="document_upload"
      />
      <div className="document-uploader__content">
        <h5 className="hide-on-mobile">{translations.drag_drop.title}</h5>
        <div className="document-uploader__icon hide-on-mobile">
          <i className="fas fa-cloud-upload-alt"></i>
        </div>
        <div className="document-uploader__button">
          <span>{translations.drag_drop.button}</span>
        </div>
      </div>
    </div>
  )
}

DocumentUploader.propTypes = {
  request: PropTypes.string.isRequired,
  document_type: PropTypes.string.isRequired,
  signing_path: PropTypes.string.isRequired,
  handleUploadStart: PropTypes.func.isRequired,
  handleUploadProgress: PropTypes.func.isRequired,
  handleUploadError: PropTypes.func.isRequired,
  handleUploadFinish: PropTypes.func.isRequired,
}

DocumentUploader.contextTypes = {
  translations: PropTypes.object,
}

export default DocumentUploader
