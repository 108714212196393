import { FETCH_STATUS_HISTORY } from '../../actionTypes'

const INITIAL_STATE = {
  tableRows: {
    data: [],
  },
}

const statusHistoryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_STATUS_HISTORY: {
      return {
        ...state,
        tableRows: action.payload,
      }
    }

    default:
      return state
  }
}

export default statusHistoryReducer
