import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Filter from './Filter'
import TeamView from './TeamView'

import { getRequestQuery } from './helpers'
import client from '../../../../client'

const DeadlineTickets = (props) => {
  const { todoTypes } = props
  const [tickets, setTickets] = useState([])
  const [filters, setFilters] = useState({ todo_type: 'alle-ausser-mv' })

  const handleFilterChange = ({ name, value }) => {
    setFilters({ ...filters, [name]: value })
  }

  const fetchTickets = () => {
    const query = getRequestQuery(filters)
    const basePath = '/admin/deadline_tickets.json'
    const path = query ? `${basePath}?${query}` : basePath
    return client.get(path).then((response) => response.json())
  }

  useEffect(() => {
    fetchTickets().then(({ tickets }) => setTickets(tickets))
  }, [filters])

  const filterOptions = todoTypes.map((type) => ({
    text: type.name,
    value: type.type_key,
  }))

  return (
    <div className="container deadline-tickets">
      <Filter
        value={filters}
        options={filterOptions}
        onChange={handleFilterChange}
      />
      {tickets.map((team, index) => (
        <TeamView
          key={index}
          data={team}
          filters={filters}
          expanded={team.team_id === '2'}
        />
      ))}
    </div>
  )
}

DeadlineTickets.propTypes = {
  todoTypes: PropTypes.array,
}

export default DeadlineTickets
