import React, { useState } from 'react'
import client from '../../../../client'
import { InView } from 'react-intersection-observer'
import { Badge } from './TeamFilter'
import { sPrintQuery } from './Filter'
import { Placeholder } from 'react-bootstrap'

const TodoCategoryEntry = (props) => {
  const { todoCategory, path, count } = props
  const urlParams = new URLSearchParams(window.location.search)

  return (
    <li
      key={todoCategory.id}
      className={
        urlParams.get('cat') === todoCategory.attributes.handle
          ? 'sidebar__item sidebar__item--active'
          : 'sidebar__item'
      }
    >
      <a className={'sidebar__header'} href={path}>
        <i className="far fa-share-square mx-1"></i>
        {todoCategory.attributes.title}
        <Badge count={count} />
      </a>
    </li>
  )
}
const TodoCategoryFilters = (props) => {
  const { basePath = '/admin/tickets', searchParams = {} } = props
  const [groupedData, setGroupedData] = useState(null)
  const [ticketTypeCount, setTicketTypeCount] = useState(null)

  const loadTicketTypeCount = () => {
    client
      .get(
        '/admin/v2/agent/tickets_stats?' +
          sPrintQuery({
            ...searchParams,
            relevant_for_today: true,
            group_by_todo_category: true,
          }),
      )
      .then((response) => response.json())
      .then(setTicketTypeCount)
  }
  const loadTodoCategories = () => {
    client
      .get('/admin/v2/todo_categories', null, { Accept: 'application/json' })
      .then((response) => response.json())
      .then((data) => {
        const grouped = data.data.reduce((a, c) => {
          a[c.attributes.majorCategory]
            ? a[c.attributes.majorCategory].push(c)
            : (a[c.attributes.majorCategory] = [c])
          return a
        }, {})
        setGroupedData(grouped)
      })
  }
  const todoCategorySum = (categoryName) => {
    if (groupedData !== null && ticketTypeCount !== null) {
      return groupedData[categoryName].reduce((a, c) => {
        return a + (ticketTypeCount[c.attributes.handle] || 0)
      }, 0)
    }
    return null
  }

  let component = (
    <ul>
      {Array.from(Array(5)).map((i) => {
        return (
          <Placeholder key={i} animation="glow" as="div" className="mt-2">
            <Placeholder xs={6}></Placeholder>
            {Array.from(Array(5)).map((j) => {
              return (
                <Placeholder
                  key={`${i}-${j}`}
                  animation="glow"
                  as="li"
                  className="sidebar__item"
                >
                  <Placeholder xs={7} size="sm"></Placeholder>
                </Placeholder>
              )
            })}
          </Placeholder>
        )
      })}
    </ul>
  )
  if (groupedData !== null) {
    component = (
      <>
        {Object.keys(groupedData)
          .sort()
          .map((v) => {
            return (
              <div key={v}>
                <div className="mt-2">
                  {v}
                  <Badge count={todoCategorySum(v)} />
                </div>
                {groupedData[v]
                  .sort((a, b) => a.attributes.title > b.attributes.title)
                  .map((v) => {
                    return (
                      <TodoCategoryEntry
                        key={v.id}
                        todoCategory={v}
                        count={
                          ticketTypeCount
                            ? ticketTypeCount[v.attributes.handle] || 0
                            : null
                        }
                        path={
                          basePath +
                          '?' +
                          sPrintQuery({
                            ...searchParams,
                            cat: v.attributes.handle,
                          })
                        }
                      />
                    )
                  })}
              </div>
            )
          })}
      </>
    )
  }
  return (
    <InView
      id="todo_category_filters"
      triggerOnce={true}
      onChange={(inView) => {
        if (inView) {
          loadTodoCategories()
          loadTicketTypeCount()
        }
      }}
    >
      {component}
    </InView>
  )
}
export default TodoCategoryFilters
