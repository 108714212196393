import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Collapse } from 'react-bootstrap'
import Table from './Table'
import TeamViewHeader from './TeamViewHeader'

const TeamView = (props) => {
  const { data, filters, expanded } = props
  const [open, setOpen] = useState(expanded)

  const handleClick = () => setOpen(!open)

  return (
    <div>
      <TeamViewHeader data={data} onClick={handleClick} />
      <Collapse in={open}>
        <div id={data.team_name}>
          <Table data={data.agents_tickets_amounts} filters={filters} />
        </div>
      </Collapse>
    </div>
  )
}

TeamView.propTypes = {
  data: PropTypes.object,
  filters: PropTypes.object,
  expanded: PropTypes.bool,
}

export default TeamView
