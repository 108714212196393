import React, { Component } from 'react'
import PropTypes from 'prop-types'
import client from '../../../../../client'

export default function withFetchedDocuments(WrappedComponent) {
  return class withFetchedDocuments extends Component {
    static propTypes = {
      requestId: PropTypes.number.isRequired,
      token: PropTypes.string,
    }

    state = {
      documents: [],
      presignedPost: {},
    }

    fetchDocuments = () => {
      const { requestId, token } = this.props

      const headers = {
        token: token,
      }

      fetch(`/api/admin/requests/${requestId}/documents.json`, {
        method: 'GET',
        headers: headers,
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok')
          }
          return response.json()
        })
        .then(({ request: { files }, presigned_post }) =>
          this.setState({ documents: files, presignedPost: presigned_post }),
        )
        .catch((error) => {
          console.error('Error fetching documents:', error)
        })
    }

    componentDidMount() {
      this.fetchDocuments()
    }

    render() {
      return (
        <WrappedComponent
          documents={this.state.documents}
          presignedPost={this.state.presignedPost}
          fetchDocuments={this.fetchDocuments}
          {...this.props}
        />
      )
    }
  }
}
