import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Table from 'react-bootstrap/Table'

import DynamicTableHead from './DynamicTableHead'
import DynamicTableHeadings from './DynamicTableHeadings'
import DynamicTableRowContainer from './DynamicTableRowContainer'

export default function DynamicTableContainer(props) {
  const { tableScaffoldId, requestId, token, title } = props
  const [data, setData] = useState('')

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    const url = `/api/admin/requests/${requestId}/dynamic_table_scaffolds?table_scaffold_key=${tableScaffoldId}`
    fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
    })
      .then((res) => res.json())
      .then((data) => setData(data))
  }

  const handleNewRowSubmit = (selectedActions, inputData) => {
    const payload = Object.keys(inputData).map((key) => {
      return {
        field: key,
        answer: inputData[key],
      }
    })

    const url = `/api/admin/requests/${requestId}/dynamic_table_scaffolds?table_scaffold_key=${tableScaffoldId}`
    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        token: token,
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
      },
      body: JSON.stringify({
        dynamic_table_scaffold: {
          fields: payload,
          actions: selectedActions,
        },
      }),
    })
      .then((res) => res.json())
      .then((data) => setData(data))
  }

  return (
    <div key={tableScaffoldId}>
      <DynamicTableHead
        tableScaffoldId={tableScaffoldId}
        title={title}
        onNewRowSubmit={handleNewRowSubmit}
        requestId={requestId}
        token={token}
      />
      <div key={tableScaffoldId}>
        <Table className="table table-dark-engine-funnel table-striped">
          <DynamicTableHeadings data={data.table} />
          <tbody>
            {data
              ? data.table.data.map((item) => (
                  <DynamicTableRowContainer
                    data={item}
                    headings={data.table.headings}
                    key={item.id}
                    token={token}
                    tableScaffoldId={tableScaffoldId}
                    requestId={requestId}
                    setData={setData}
                  />
                ))
              : null}
          </tbody>
        </Table>
      </div>
    </div>
  )
}

DynamicTableContainer.propTypes = {
  requestId: PropTypes.number,
  tableScaffoldId: PropTypes.string,
  title: PropTypes.string,
  token: PropTypes.string,
}
