import React from 'react'
import PropTypes from 'prop-types'
import SimpleMDEReact from 'react-simplemde-editor'

export default class SelectBody extends React.Component {
  constructor(props) {
    super(props)
  }
  handleValueChange = (value) => {
    this.props.updateParent('body', value)
  }
  render() {
    return (
      <div className="body-row not-draggable">
        <div id="markdown-here">
          <SimpleMDEReact
            id="simple-md"
            onChange={this.handleValueChange}
            value={this.props.value}
            options={{
              autofocus: true,
              spellChecker: false,
              autoDownloadFontAwesome: false,
              maxHeight: '50vh',
            }}
          />
        </div>
      </div>
    )
  }
}

SelectBody.propTypes = {
  updateParent: PropTypes.func,
  value: PropTypes.string,
}
