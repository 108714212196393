import React from 'react'

const SuggestionItem = (props) => {
  const { url, city, counter_parties_name, phase, reference_number } =
    props.data.attributes
  const { first_name, last_name, email, phone } = props.data.attributes.user

  return (
    <div className="result__item">
      <a href={url} target="_blank">
        <p className="result__item-ttl">
          {first_name != null ? <span>{first_name} </span> : ''}
          {last_name != null ? (
            <span>
              {last_name}
              {', '}
            </span>
          ) : (
            ''
          )}
          {reference_number}
          {phase && (
            <span className="result__item-pr">
              {' | '}
              {phase}
            </span>
          )}
        </p>
        <div className="d-flex">
          {email && (
            <p>
              <i className="fas fa-envelope" />
              <span>{email}</span>
            </p>
          )}
          {phone && (
            <p>
              <i className="fas fa-phone" />
              <span>{phone}</span>
            </p>
          )}
        </div>
        <div className="d-flex">
          {city && (
            <p>
              <i className="fas fa-city" />
              <span>{city}</span>
            </p>
          )}
          {counter_parties_name && (
            <p>
              <i className="fas fa-address-card" />
              <span>{counter_parties_name}</span>
            </p>
          )}
        </div>
      </a>
    </div>
  )
}

export default SuggestionItem
