import {
  formatStringToNumber,
  formatNumberToString,
  formatDateShort,
  formatDateLong,
} from '../helpers/format'

describe('formatStringToNumber()', () => {
  it('should return number', () => {
    expect(formatStringToNumber('300,55')).toEqual(300.55)
    expect(formatStringToNumber('305')).toEqual(305)
    expect(formatStringToNumber('305,00')).toEqual(305)
    expect(formatStringToNumber('1.305,55')).toEqual(1305.55)
  })
})

describe('formatNumberToString()', () => {
  it('should return number', () => {
    expect(formatNumberToString(300.55)).toEqual('300,55')
    expect(formatNumberToString(305)).toEqual('305,00')
    expect(formatNumberToString(1305.55)).toEqual('1305,55')
  })
})

describe('formatDateShort()', () => {
  it('should return date in DD.MM.YYYY format', () => {
    expect(formatDateShort('2020-03-11T14:42:15.696Z')).toEqual('11.03.2020')
    expect(formatDateShort('2020-02-11T00:00:00.000Z')).toEqual('11.02.2020')
  })
})

// These test are skipped because they only work in summer time.
describe.skip('formatDateLong()', () => {
  it('should return date in dd, DD.MM.YYYY HH:mm format', () => {
    expect(formatDateLong('2020-03-11T15:42:15.706+01:00')).toEqual(
      'Mi, 11.03.2020 15:42',
    )
    expect(formatDateLong('2019-07-30T10:15:32.863+02:00')).toEqual(
      'Di, 30.07.2019 10:15',
    )
  })

  it('should return date in dd, DD.MM.YYYY HH:mm format from utc string', () => {
    expect(formatDateLong('2020-03-11T14:42:15.696Z')).toEqual(
      'Mi, 11.03.2020 15:42',
    )
    expect(formatDateLong('2020-02-11T00:00:00.000Z')).toEqual(
      'Di, 11.02.2020 01:00',
    )
  })

  it('works for timestamps', () => {
    expect(formatDateLong(1563895187 * 1000)).toEqual('Di, 23.07.2019 17:19')
    expect(formatDateLong(1563216065 * 1000)).toEqual('Mo, 15.07.2019 20:41')
  })
})
