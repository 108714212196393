import React, { useContext } from 'react'
import ReactDOMServer from 'react-dom/server'
import { Accordion } from 'react-bootstrap'
import PropTypes from 'prop-types'
import LogItemHeader from '../LogItemHeader'
import ActionButton from '../ActionButton'

export default function AdvoAssist(props) {
  const { id, data, open, onClick, token, refetch } = props
  const { status, updated_at, tracking_key } = data.content_info

  const dateFormatLong = (date) => {
    return moment(date).locale('de').format('dd, DD.MM.YYYY HH:mm')
  }

  const handleClick = (action) => {}

  const renderActionButtons = () => {
    return data.actions.map((action, index) => {
      return <ActionButton action={action} key={index} onClick={handleClick} />
    })
  }

  return (
    <div>
      <div
        onClick={() => {
          onClick(id)
        }}
      >
        <LogItemHeader data={data} />
      </div>
      <Accordion.Collapse eventKey={id} in={open}>
        <div className="log-item-content__container">
          <div className="log-item-content-header__container">
            <div className="log-item__text"> {data.details_2}</div>
            <div className="log-item-content-header__actions">
              {renderActionButtons()}
            </div>
          </div>
          <div className="log-item-content__card">
            <pre
              className="log-item-content__pre"
              dangerouslySetInnerHTML={{ __html: data.expanded_html }}
            />
          </div>
          <div className="log-item-content-footer__container">
            <div>
              <i className="fas fa-calendar footer-icon me-2" />
              <strong>Aktualisiert am:</strong> {dateFormatLong(updated_at)}
            </div>
            <div>
              <i className="far fa-question-circle footer-icon me-1" />
              <strong>Status:</strong> {status}
            </div>
            <div>
              <i className="far fa-question-circle footer-icon me-1" />
              <strong>Tracking Key:</strong> {tracking_key}
            </div>
          </div>
        </div>
      </Accordion.Collapse>
    </div>
  )
}

Document.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  open: PropTypes.bool,
  onClick: PropTypes.func,
  token: PropTypes.string,
}
