import React from 'react'
import { Alert } from 'react-bootstrap'
import PropTypes from 'prop-types'

export default function AlertBanner({ message, variant }) {
  const alertMessage = message || 'Ein Problem ist aufgetreten'
  const alertVariant = variant || 'danger'
  return <Alert variant={alertVariant}>{alertMessage}</Alert>
}

AlertBanner.propTypes = {
  message: PropTypes.string,
  variant: PropTypes.string,
}
