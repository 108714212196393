import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Checkbox from '../engine-ui/Checkbox'
import Tooltip from '../engine-ui/Tooltip'
import { DOCUMENT_TYPE } from './helpers'
import DocumentCardActions from './DocumentCardActions'

export default class DocumentCard extends Component {
  static propTypes = {
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
    document: PropTypes.object,
    requestId: PropTypes.number,
    onDocumentSelect: PropTypes.func,
    documentSelected: PropTypes.bool,
    disableCheckbox: PropTypes.bool,
  }

  state = {
    sensitive: this.props.document.sensitive,
  }

  handleEdit = () => {
    const { document } = this.props

    V2.trigger('document.show', {
      url: document.edit_url,
      filename: document.filename,
      documentId: document.id,
    })
  }

  handleDelete = () => {
    const { document, onDelete } = this.props
    const isConfirmed = confirm(
      'Es werden keine Dokumente gelöscht, ' +
        'wenn sie mit einer beA-Datei verknüpft sind die sich nicht im "Erstellt"-Status ' +
        'befindet. Dokument sicher löschen?',
    )
    if (!isConfirmed) {
      return
    }
    onDelete && onDelete(document.id)
  }

  toggleMarkAsSensitive = () => {
    const { document, onEdit } = this.props
    this.setState((prevState) => ({ sensitive: !prevState.sensitive }))
    onEdit && onEdit(document.id, { sensitive: !this.state.sensitive })
  }

  render() {
    const {
      document,
      requestId,
      documentSelected,
      disableCheckbox,
      onDocumentSelect,
    } = this.props

    const { sensitive } = this.state

    const containerClassName = classNames('doc_log card-container my-4', {
      'border-danger': !document.approved,
    })

    return (
      <div className={containerClassName}>
        {document.image && !document.approved && document.original_size > 0 ? (
          <Checkbox
            name={document.id}
            value={documentSelected}
            disabled={disableCheckbox}
            onChange={onDocumentSelect}
          />
        ) : (
          <div className="ms-3"></div>
        )}
        <div className="logitem-container card ms-2">
          <div className="card-header">
            <div>
              <strong>{document.uploaded_by} </strong>
              hat vor
              <span title={document.created_at}>
                {' '}
                {document.created_at_text}{' '}
              </span>
              ein
              <em> {document.incoming ? 'eingehendes' : 'ausgehendes'}</em>{' '}
              Dokument {document.attachment ? 'erstellt' : 'hochgeladen'}
            </div>
            <DocumentCardActions
              sensitive={sensitive}
              requestId={requestId}
              documentId={document.id}
              onMarkAsSensitive={this.toggleMarkAsSensitive}
              onEdit={this.handleEdit}
              onDelete={this.handleDelete}
            />
          </div>
          <div className="card-body d-flex justify-content-between clearfix">
            <div className="w-75">
              <div
                className={classNames('d-inline-block', {
                  'color--dark-red': sensitive,
                })}
              >
                {sensitive && (
                  <Tooltip text="Achtung! Vertrauliche Daten!" placement="top">
                    <i className="fas fa-eye-slash color--dark-red me-1" />
                  </Tooltip>
                )}
                {document.filename} vom Typ
                <nobr>
                  <strong>
                    {' '}
                    {document.type === DOCUMENT_TYPE
                      ? 'Nicht kategorisiert'
                      : document.type}
                  </strong>
                </nobr>
              </div>
              <div>
                {document.attachment ? (
                  <Fragment>
                    <i className="far fa-envelope"></i>
                    Email-Attachment
                  </Fragment>
                ) : document.approved ? (
                  <Fragment>
                    <i className="fa fa-check"></i>
                    Akzeptiert am
                    <span title={document.approved_at}>
                      {' '}
                      {document.approved_at_text}{' '}
                    </span>
                    von {document.approved_by}
                  </Fragment>
                ) : (
                  <strong className="color--dark-red">
                    <i className="fas fa-exclamation-triangle"></i> Noch nicht
                    akzeptiert
                  </strong>
                )}
              </div>
            </div>
            <div className="d-flex w-25 flex-column justify-content-end align-items-end align-content-xl-end">
              <div>
                <Fragment>{document.size}</Fragment>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
