import React, { useEffect, useState } from 'react'
import AsyncSelect from 'react-select/async'
import client from '../../../../client'
import { sPrintQuery } from '../tickets/Filter'

const LawyerSelect = (props) => {
  const [currentAgent, setCurrentAgent] = useState(props.currentAgent)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (currentAgent || props.value) {
      return
    }
    setIsLoading(true)

    Promise.all([
      client
        .get('/admin/v2/agent', null, { Accept: 'application/json' })
        .then((response) => response.json()),
      client
        .get(
          `/admin/v2/agents?${sPrintQuery({
            lawyers: true,
            email: props.defaultSigningLayerEmail,
          })}`,
          null,
          { Accept: 'application/json' },
        )
        .then((response) => response.json()),
    ])
      .then(([myAgent, allAgents]) => {
        let agent = allAgents.data[0]
        if (myAgent.data.attributes.lawyer) {
          agent = myAgent.data
        }
        setCurrentAgent(agent)
        props.onChange(agent)
      })
      .then(() => setIsLoading(false))
  }, [])

  const defaultAgent = () => {
    if (currentAgent?.attributes?.lawyer) {
      return currentAgent
    }
    return undefined
  }

  return (
    <AgentSelect
      queryParams={{ lawyers: true }}
      isLoading={isLoading}
      {...props}
      value={props.value ?? defaultAgent()}
    />
  )
}

const AgentSelect = (props) => {
  const { queryParams = {} } = props
  const loadOptions = (inputValue, callback) => {
    client
      .get(
        `/admin/v2/agents?${sPrintQuery({
          ...queryParams,
          search: inputValue,
        })}`,
        null,
        { Accept: 'application/json' },
      )
      .then((response) => response.json())
      .then((data) => callback(data.data))
  }

  return (
    <AsyncSelect
      cacheOptions
      defaultOptions
      loadOptions={loadOptions}
      getOptionLabel={(e) => e.attributes.name}
      getOptionValue={(e) => e.id}
      {...props}
    />
  )
}

export { LawyerSelect, AgentSelect }
