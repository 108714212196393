import Dependency from './dependency'
export class DependencyResolver {
  constructor(dependency) {
    this.dependencies = Dependency.fromJSONArray(dependency)
  }
  match(responses, condition) {
    return this.resolve(responses) === condition
  }
  isDisable(responses) {
    return this.resolve(responses) == 'disable'
  }
  isEnable(responses) {
    return this.resolve(responses) == 'enable'
  }
  resolve(responses) {
    let dependencies = this.dependencies.sort(
      (n1, n2) => n1.priority - n2.priority,
    )
    for (let dep of dependencies) {
      let depMatch = dep.match(responses)
      if (depMatch.match) {
        return depMatch.action
      }
    }
    return ''
  }
}
