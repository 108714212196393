import React from 'react'

const EmailPartialSearch = (props) => {
  return (
    <div className="emailpartial_search mb-3">
      <input
        className="emailpartial_search-input not-draggable"
        type="text"
        placeholder="Vorlagen durchsuchen..."
        onChange={props.searchQuery}
        value={props.query}
      ></input>
    </div>
  )
}

export default EmailPartialSearch
