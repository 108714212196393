export const mapFieldToOptionKeys = {
  party: 'party_options',
  category: 'category_options',
  direction: 'direction_options',
  payment_type: 'payment_type_options',
  court_instance: 'court_instance_options',
}

// todo: rename >.<
export const t = (key) => (translations) => translations[key]

export const formatNumber = (value) =>
  parseFloat(value.toString().replace(/,/, '.'))
