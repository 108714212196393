import React from 'react'
import PropTypes from 'prop-types'

const HeaderCell = (props) => {
  const { text } = props
  return <div className="deadline-tickets-header__cell">{text}</div>
}

HeaderCell.propTypes = {
  text: PropTypes.string,
}

export default HeaderCell
