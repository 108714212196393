import React, { Component } from 'react'
import Table from 'react-bootstrap/Table'
import StatusRow from './StatusRow'
import PropTypes from 'prop-types'
import { fetchStatusHistory } from '../PhasePopup/actions/phaseAction'
import { getSavedStatusesAndReasons, deleteStatus } from '../PhasePopup/api'
import { connect } from 'react-redux'

class StatusTab extends Component {
  componentDidMount() {
    const { requestId, dispatch } = this.props
    getSavedStatusesAndReasons(requestId).then((data) => {
      fetchStatusHistory(dispatch, {
        data: data.data,
        id: new Date().toISOString(),
      })
    })
  }

  renderRow = () => {
    if (this.props.tableRows.data.length === 0) return
    else
      return this.props.tableRows.data.map((row, index) => {
        return (
          <StatusRow row={row} key={`row-${index}`} onDelete={this.onDelete} />
        )
      })
  }

  onDelete = (reasonRequestStatusId) => {
    const { requestId, dispatch } = this.props
    deleteStatus(requestId, reasonRequestStatusId).then(() =>
      getSavedStatusesAndReasons(requestId).then((data) => {
        fetchStatusHistory(dispatch, {
          data: data.data,
          id: new Date().toISOString(),
        })
      }),
    )
  }

  render() {
    return (
      <div className="tab-content cp_tab-content">
        <p className="form_category__title">Statusübersicht</p>
        <Table className="table table-dark-engine-funnel table-striped">
          <thead>
            <tr id="status-tab__head-row">
              <th>Status</th>
              <th>Grund</th>
              <th>Erstellt am</th>
              <th>Erstellt von</th>
              <th>Deaktiviert am</th>
              <th>Deaktiviert von</th>
              <th>Aktionen</th>
            </tr>
          </thead>
          <tbody>{this.renderRow()}</tbody>
        </Table>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    tableRows: state.statusTab.tableRows,
  }
}

StatusTab.propTypes = {
  tableRows: PropTypes.object,
  requestId: PropTypes.number,
  dispatch: PropTypes.func,
}

export default connect(mapStateToProps)(StatusTab)
